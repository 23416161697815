<template>
  <div style="min-width: 1050px">
    <el-tabs v-model="activeName" @tab-click="tabClick">
      <el-tab-pane label="使用维度展示成长报告" name="1">
        <!-- 综合素养 -->
        <Comprehensive
          :TXYSoptions="TXYSoptions"
          :EJYMSZoptions="EJYMSZoptions"
          :levelOptions="levelOptions"
          :activities="activities"
          :fixActivity="fixActivity"
          :dataDimension="dataDimension"
        />
      </el-tab-pane>
      <el-tab-pane label="其它设置" name="2">
        <OtherSet
          :activities="otherSetActivities"
          :levelOption="levelOptions"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import OtherSet from "@/views/Integrated/GrowthReportSet/OtherSet/Index.vue";
import Comprehensive from "@/views/Integrated/GrowthReportSet/Comprehensive/Index.vue";

export default {
  name: "GrowthReportSet",
  components: {
    OtherSet,
    Comprehensive
  },
  data() {
    return {
      userInfo: {},
      allActivities: [], //所有活动
      activities: [], //过程活动和课辅活动
      fixActivity: [], //固定活动
      fixActivityLabel: [
        {
          name: "获奖管理",
          value: 20
        },
        {
          name: "家校共育",
          value: 7
        },
        {
          name: "学业总评",
          value: 40
        },
        {
          name: "作业管理",
          value: 2
        },
        {
          name: "体检数据",
          value: 38
        },
        {
          name: "阅读数据",
          value: 6
        }
      ],
      dataDimension: [], //数据维度
      dataDimensionLabel: [
        {
          name: "体质数据",
          value: 8 //模块编码对应值
        },
        {
          name: "运动数据",
          value: 5
        },
        {
          name: "阅读数据",
          value: 6
        },
        {
          name: "体检数据",
          value: 38
        },
        {
          name: "心理健康",
          value: 39
        }
      ],
      activeName: "1", //tab
      // 图像样式下拉
      TXYSoptions: [
        {
          value: 1,
          label: "雷达图"
        },
        {
          value: 2,
          label: "环状图"
        }
      ],
      // 二级页面设置下拉
      EJYMSZoptions: [
        // {
        //   value: 1,
        //   label: "二级维度+维度明细"
        // },
        {
          value: 2,
          label: "二级维度+活动明细"
        },
        {
          value: 3,
          label: "活动+活动明细"
        }
      ],
      // 学期总评下拉
      levelOptions: [],
      otherSetActivities: []
    };
  },
  async mounted() {
    let userInfo = localStorage.getItem("userinfo");
    if (userInfo) this.userInfo = JSON.parse(userInfo);

    await this.getLevelOptions();
    await this.getAllActivities();
    this.handleActivities();
  },
  methods: {
    // 获取等级下拉
    getLevelOptions() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/levelmanager/GetLeaveSet",
          {
            SchoolId: this.userInfo.SSZZId,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.levelOptions = res.Target || [];
              resolve();
            }
          },
          err => reject(err)
        );
      });
    },
    // 获取所有活动
    getAllActivities() {
      return new Promise((resolve, reject) => {
        this.$api.get(
          "/v1/Growth/GetActivitye",
          {
            SSXX: this.userInfo.SSZZId,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.allActivities = res.Target || [];
              if (this.allActivities.length === 0)
                this.$message.error("未获取到活动");

              resolve();
            }
          },
          err => reject(err)
        );
      });
    },
    // 处理活动 将固定活动 自定义活动 阶段活动分类
    handleActivities() {
      // 评价学生的过程性活动和课辅活动
      this.activities = this.allActivities.reduce((pre, cur) => {
        if (cur.BPJDXLX === 1) {
          return pre.concat({
            BPJDXLX: cur.BPJDXLX,
            label: cur.PJHDMC,
            GLId: cur.Id, //关联ID
            checked: false,
            GLSJLX: cur.HDLX === 1 ? 2 : 4, //关联数据类型1.维度 2.自定义活动 3.固定活动 4.阶段活动
            GLDJLX: "", //关联等级类型 1分数，2不展示，3关联等级,4.百分比转等级 5.分数转等级
            GLDJId: "", //关联等级Id
            EJWDId: "" //二级维度Id
          });
        } else return pre;
      }, []);
      this.activities.sort((a, b) => a.label.localeCompare(b.label));

      this.activities.push({
        label: "课辅活动",
        GLId: 3, //关联ID
        checked: false,
        GLSJLX: 2, //关联数据类型1.维度 2.自定义活动 3.固定活动 4.阶段活动
        GLDJLX: "", //关联等级类型 1分数，2不展示，3关联等级,4.百分比转等级 5.分数转等级
        GLDJId: "", //关联等级Id
        EJWDId: "" //二级维度Id
      });
      // 固定活动
      this.fixActivity = this.fixActivityLabel.reduce((pre, cur) => {
        return pre.concat({
          label: cur.name,
          GLId: cur.value, //关联ID
          checked: false,
          GLSJLX: 3, //关联数据类型1.维度 2.自定义活动 3.固定活动
          GLDJLX: "", //关联等级类型 1分数，2不展示，3关联等级,4.百分比转等级 5.分数转等级
          GLDJId: "", //关联等级Id
          EJWDId: "" //二级维度Id
        });
      }, []);
      // 数据维度
      this.dataDimension = this.dataDimensionLabel.reduce((pre, cur) => {
        return pre.concat({
          label: cur.name,
          GLId: cur.value, //关联ID
          checked: false,
          GLSJLX: 1, //关联数据类型1.维度 2.自定义活动 3.固定活动
          GLDJLX: "", //关联等级类型 1分数，2不展示，3关联等级,4.百分比转等级 5.分数转等级
          GLDJId: "", //关联等级Id
          EJWDId: "" //二级维度Id
        });
      }, []);
    },
    // tab切换
    tabClick(vm) {
      if (vm.name === "2") {
        let arr = [];
        arr = this.activities.filter(
          item => item.GLSJLX === 2 && item.BPJDXLX === 1
        );
        this.otherSetActivities = arr.concat(this.fixActivity);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
