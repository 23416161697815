var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cont-whole-box"},[_c('div',{staticStyle:{"padding":"30px"}},[_c('div',{staticClass:"margno-t"},[_c('div',{staticClass:"table-er-box"},[_c('el-button',{attrs:{"plain":"","icon":"el-icon-edit"},on:{"click":function($event){return _vm.editItem({})}}},[_vm._v("新增")])],1),_c('Table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"tableData":_vm.dataTable
            .slice()
            .sort(function (a, b) {
              return a.PXH - b.PXH;
            })
            .slice(
              (_vm.pagerInfo.pageIndex - 1) * _vm.pagerInfo.pageSize,
              (_vm.pagerInfo.pageIndex - 1) * _vm.pagerInfo.pageSize +
                _vm.pagerInfo.pageSize
            ),"user":_vm.user},on:{"editItem":_vm.editItem,"delItem":_vm.delItem}}),_c('div',{staticClass:"pagin-box"},[_c('div',{staticClass:"pagin-wz"},[_c('Pager',{attrs:{"pagerInfo":_vm.pagerInfo},on:{"changeIndex":_vm.changeIndex}})],1)])],1)]),_c('Form',{attrs:{"data":_vm.formData,"visible":_vm.formVisible,"title":_vm.formTitle,"shapes":_vm.shapes,"user":_vm.user},on:{"closeDialog":_vm.closeDialog,"saveDialog":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }