var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.visible,"close-on-click-modal":false,"width":"51%","height":"100%","center":"","before-close":_vm.closeDialog},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"popv-cent",staticStyle:{"padding":"10px","position":"relative","height":"516px"}},[_c('div',{staticClass:"popo-xk-cent"},[_c('el-checkbox-group',{on:{"change":_vm.handleCheckedActivities},model:{value:(_vm.checkedIds),callback:function ($$v) {_vm.checkedIds=$$v},expression:"checkedIds"}},_vm._l((_vm.activitysData),function(item){return _c('div',{key:item.Id,staticClass:"disilb-box"},[(
              ((item.EvaluationActivityType === 1 &&
                _vm.disableData.findIndex(function (c) { return c.HDId === item.Id; }) !== -1) ||
                (item.EvaluationActivityType !== 1 &&
                  _vm.disableData.findIndex(
                    function (c) { return c.HDLX === item.EvaluationActivityType; }
                  ) !== -1)) &&
                item.Id !== _vm.currentActivity.HDId
            )?_c('el-checkbox',{staticClass:"tablil-xz-name epp-ov",attrs:{"disabled":"","label":item.Id}},[_vm._v(_vm._s(item.Name))]):_c('el-checkbox',{staticClass:"tablil-xz-name epp-ov",attrs:{"label":item.Id}},[_vm._v(_vm._s(item.Name))])],1)}),0)],1),_c('div',{staticClass:"popo-xk-centa"},[_c('div',{staticClass:"operate-box-supple"},[_vm._v(" 个人得分时小组的得分 "),_c('el-input',{staticClass:"popv-inpt jf-form",staticStyle:{"width":"50px"},attrs:{"placeholder":"请输入","size":"small"},model:{value:(_vm.GRYXXZ),callback:function ($$v) {_vm.GRYXXZ=$$v},expression:"GRYXXZ"}}),_vm._v("% ")],1),_c('div',{staticClass:"operate-box-supple"},[_vm._v(" 小组得分时个人的得分 "),_c('el-input',{staticClass:"popv-inpt jf-form",staticStyle:{"width":"50px"},attrs:{"placeholder":"请输入","size":"small"},model:{value:(_vm.XZYXGR),callback:function ($$v) {_vm.XZYXGR=$$v},expression:"XZYXGR"}}),_vm._v("% ")],1)])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("确 定")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }