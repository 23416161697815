<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="75%"
    center
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="tab-heng-box">
      <el-form :model="formData" ref="ruleForm">
        <!-- 等级名称 -->
        <div class="table-er-box">
          <el-form-item
            label="等级名称："
            :rules="[
              {
                required: true,
                message: '请输入等级名称',
                trigger: 'change'
              }
            ]"
            prop="DJMC"
            :inline-message="true"
          >
            <el-input
              v-model="formData.DJMC"
              style="width: 160px;"
              size="small"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>

        <table cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <td v-if="formData.DJLX === 2">分数区间</td>
              <td v-if="formData.DJLX === 3">百分比区间</td>
              <td>等级</td>
              <td v-if="formData.DJLX === 1">分数值</td>
              <td v-if="formData.DJLX !== 1">等级描述</td>
              <td>操作</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData.details" :key="index">
              <!-- 分转等 -->
              <td v-if="formData.DJLX === 2">
                <div class="percent-block">
                  <el-form-item
                    style="display:inline-block"
                    class="jf-form-item"
                    :rules="[
                      {
                        validator: validPersent,
                        trigger: 'blur',
                        index,
                        lx: 0
                      }
                    ]"
                    :prop="'details[' + index + '].ZDF'"
                  >
                    <el-input-number
                      v-model="item.ZDF"
                      size="small"
                      :min="-1000"
                      :max="1500"
                      :precision="2"
                      :controls="false"
                      style="width: 80px;"
                      placeholder="请输入"
                    ></el-input-number>
                  </el-form-item>
                  <span class="disilb-box-ll"> ≤ xx &lt;</span>
                  <el-form-item
                    style="display:inline-block"
                    class="jf-form-item"
                    :rules="[
                      {
                        validator: validPersent,
                        trigger: 'blur',
                        index,
                        lx: 1
                      }
                    ]"
                    :prop="'details[' + index + '].ZGF'"
                  >
                    <el-input-number
                      v-model="item.ZGF"
                      size="small"
                      :min="-1000"
                      :max="1500"
                      :precision="2"
                      :controls="false"
                      style="width: 80px;"
                      placeholder="请输入"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </td>
              <!-- 百分比转等 -->
              <td v-if="formData.DJLX === 3">
                <div class="percent-block">
                  <el-form-item
                    style="display:inline-block"
                    class="jf-form-item"
                    :prop="'details[' + index + '].ZDF'"
                    :rules="[
                      {
                        validator: validPersent,
                        trigger: 'blur',
                        index,
                        lx: 0
                      }
                    ]"
                  >
                    <el-input-number
                      v-model="item.ZDF"
                      size="small"
                      :min="-1000"
                      :max="1500"
                      :precision="2"
                      :controls="false"
                      style="width: 100px;"
                      placeholder="百分比下限"
                    ></el-input-number>
                  </el-form-item>
                  <span class="disilb-box-ll"> ≤ xx &lt;</span>
                  <el-form-item
                    style="display:inline-block"
                    class="jf-form-item"
                    :prop="'details[' + index + '].ZGF'"
                    :rules="[
                      {
                        validator: validPersent,
                        trigger: 'blur',
                        index,
                        lx: 1
                      }
                    ]"
                  >
                    <el-input-number
                      v-model="item.ZGF"
                      size="small"
                      :min="-1000"
                      :max="1000"
                      :precision="2"
                      :controls="false"
                      style="width: 100px;"
                      placeholder="百分比上限"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </td>
              <td>
                <el-form-item
                  class="jf-form-item"
                  :rules="[
                    {
                      required: true,
                      message: '请输入等级',
                      trigger: 'change'
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (
                          rule.validateData.filter(c => c === value).length > 1
                        ) {
                          return callback(new $window.Error(rule.message1));
                        } else return callback();
                      },
                      message: '已有相同的等级',
                      trigger: 'change',
                      validateData: formData.details
                        .filter(c => c)
                        .map(c => c.DJ)
                    }
                  ]"
                  :prop="'details[' + index + '].DJ'"
                >
                  <el-input
                    v-model="item.DJ"
                    size="small"
                    style="width: 160px;"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </td>

              <!-- 等转分 -->
              <td v-if="formData.DJLX === 1">
                <el-form-item
                  class="jf-form-item"
                  :rules="[
                    {
                      required: true,
                      message: '请输入分数值',
                      trigger: 'blur'
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (
                          rule.validateData.filter(c => c === value).length > 1
                        ) {
                          return callback(new $window.Error(rule.message1));
                        } else return callback();
                      },
                      message: '已有相同的分数',
                      trigger: 'blur',
                      validateData: formData.details
                        .filter(c => c)
                        .map(c => c.ZDF)
                    }
                  ]"
                  :prop="'details[' + index + '].ZDF'"
                >
                  <el-input-number
                    v-model="item.ZDF"
                    size="small"
                    :min="-1000"
                    :max="1000"
                    :precision="2"
                    :controls="false"
                    style="width: 160px;"
                    placeholder="请输入"
                  ></el-input-number>
                </el-form-item>
              </td>

              <!-- 等级描述 -->
              <td v-if="formData.DJLX !== 1">
                <el-form-item
                  class="jf-form-item"
                  :prop="'details[' + index + '].MS'"
                >
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="item.MS"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </td>

              <td>
                <div class="tab-wz-butt">
                  <el-link
                    style="margin-left: 10px;"
                    class="c-red"
                    :underline="false"
                    @click="delItem(index)"
                    >删除</el-link
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- 计算范围 -->
        <div
          class="table-er-box"
          v-if="formData.DJLX === 3"
          style="display:flex;justify-content:center;margin-top:10px"
        >
          <el-form-item prop="LX">
            <el-radio v-model="formData.LX" :label="1"
              >在年级范围内计算</el-radio
            >
            <el-radio v-model="formData.LX" :label="2"
              >在班级范围内计算</el-radio
            >
          </el-form-item>
        </div>
      </el-form>

      <div class="bigtit-add-box">
        <div class="tab-wz-buttl">
          <el-link type="primary" :underline="false" @click="addItem"
            >+ 新增</el-link
          >
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirmDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      labelWidth: "250px",
      rules: {},
      formData: {},
      details: []
    };
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {};
      }
    },
    dataList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    user: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    init() {
      this.formData = JSON.parse(JSON.stringify(this.data));
      this.$set(
        this.formData,
        "details",
        JSON.parse(JSON.stringify(this.dataList))
      );
    },
    closeDialog() {
      this.$confirm("确认关闭？").then(() => {
        this.$emit("closeDialog");
      });
    },
    confirmDialog() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.formData.details = this.formData.details.map(item => {
            item.ZGF = item.ZGF ? item.ZGF : 0;
            return item;
          });
          this.$api.post(
            "/v1/levelmanager/EditLeaveSet",
            {
              token: this.$store.state.token,
              LevelInfo: this.formData,
              DetailList: this.formData.details,
              SchoolId: this.user.SSZZId,
              UserId: this.user.Id,
              UserName: this.user.XSM
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: "success",
                  message: r.Message
                });
                this.$emit("saveDialog");
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    addItem() {
      if (!this.formData.details) this.formData.details = [];
      this.formData.details.push({ ZDF: undefined, ZGF: undefined, DJ: "" });
    },
    delItem(index) {
      this.formData.details.splice(index, 1);
    },
    // 校验百分比 index: 区间下标 lx:区间上下限
    validPersent(rule, value, callback) {
      let { index, lx } = rule;
      if (lx) {
        if (!this.formData.details[index].ZGF)
          return callback(new Error("请输入上限"));
        else if (
          this.formData.details[index].ZGF <= this.formData.details[index].ZDF
        )
          return callback(new Error("必须大于下限"));
        else if (this.handleCompare(lx, index))
          return callback(new Error("区间不能重复"));
        else return callback();
      } else {
        if (
          this.formData.details[index].ZDF === "" ||
          this.formData.details[index].ZDF === null ||
          this.formData.details[index].ZDF === undefined
        )
          return callback(new Error("请输入下限"));
        else if (
          this.formData.details[index].ZDF >= this.formData.details[index].ZGF
        )
          return callback(new Error("必须小于上限"));
        else if (this.handleCompare(lx, index))
          return callback(new Error("区间不能重复"));
        else return callback();
      }
    },
    handleCompare(lx, index) {
      return this.formData.details.some((item, idx) => {
        if (
          index !== idx &&
          item.ZGF !== "" &&
          item.ZGF !== undefined &&
          item.ZGF !== null &&
          Number(item.ZGF) !== 0 &&
          item.ZDF !== "" &&
          item.ZDF !== undefined &&
          item.ZDF !== null &&
          Number(item.ZDF) !== 0
        ) {
          let data = this.formData.details[index];
          if (
            data &&
            data.ZGF !== "" &&
            data.ZGF !== undefined &&
            data.ZGF !== null &&
            Number(data.ZGF) !== 0 &&
            data.ZDF !== "" &&
            data.ZDF !== undefined &&
            data.ZDF !== null &&
            Number(data.ZDF) !== 0
          ) {
            if (
              this.formData.details[index].ZGF <= item.ZGF &&
              this.formData.details[index].ZGF > item.ZDF
            ) {
              return true;
            } else if (
              this.formData.details[index].ZDF < item.ZGF &&
              this.formData.details[index].ZDF >= item.ZDF
            ) {
              return true;
            } else if (
              this.formData.details[index].ZGF >= item.ZGF &&
              this.formData.details[index].ZDF <= item.ZDF
            ) {
              return true;
            } else return false;
          }
          return false;
        } else return false;
      });
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.init();
        // 显示
      } else {
        this.$refs.ruleForm.resetFields();
        // 隐藏
      }
    }
  }
};
</script>
<style lang="scss" scoped>
div::v-deep .el-dialog {
  min-width: 537px;
}
.percent-block {
  ::v-deep .el-form-item__error {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
