<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<div :class="'totalscore' + this.exportType">
					<img :src="xsinfo.ZP" :class="'studenticon' + this.exportType" />
					<div :class="'scoredesc' + this.exportType">
						<el-row>
							<el-col :class="'scoretitle' + this.exportType">
								{{ xsinfo.XM }}
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="8" :class="'scoredescstr' + this.exportType">
								性别：{{ xsinfo.XBM==1?'男':'女' }}
							</el-col>
							<el-col :span="8" :class="'scoredescstr' + this.exportType">
								年龄：{{ xsinfo.NL }}岁
							</el-col>
							<el-col :span="8" :class="'scoredescstr' + this.exportType">
								班级：{{ xsinfo.BJMC }}
							</el-col>
						</el-row>
					</div>
					<div class="clboth"></div>
				</div>
				<div id="sgChart" :class="'scorebar' + this.exportType"></div>
				<div id="tzChart" :class="'scorebar' + this.exportType"></div>
				<div :class="'detailinfo' + this.exportType">
					<el-row>
						<span :class="'detailstr' + this.exportType">BMI指数</span>
					</el-row>
					<el-row style="width:100%;text-align:left;padding:0 calc(1%)">
						<span :class="'detailscore' + this.exportType" :style="score_margin_left" v-show="xsinfo.BMI">
							{{ xsinfo.BMI }}
						</span>
					</el-row>
					<el-row style="width:100%;text-align:left;padding:0 calc(1%)">
						<img src="../../../../style/growth/mark.png" :class="'detailmark' + this.exportType"
							v-show="xsinfo.BMI" :style="score_margin_left" />
					</el-row>
					<el-row>
						<div :class="'fvcbar' + this.exportType"></div>
					</el-row>
					<el-row :gutter="5" :class="'djmcrow' + this.exportType">
						<el-col :span="24 / tzsjdf.length" v-for="item in tzsjdf" :key="item.djmc"
							:class="'djmcstr' + exportType">
							{{ item.djmc }}
						</el-col>
					</el-row>
				</div>
				<div :class="'detailrow' + this.exportType">
					{{ xsinfo.stxt }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				sgChart: null,
				tzChart: null,
				backgroudimage: "",
				exportType: 1,
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "身心健康-身体形态"
				},
				xsinfo: {
					SSNJ: "",
					NJ: "",
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					BMI: "",
					stxt: ""
				},
				weightdata: [],
				heightdata: [],
				tzsjdf: [{
						djmc: "低体重",
						kszs: 0,
						jszs: 13.6
					},
					{
						djmc: "正常",
						kszs: 13.61,
						jszs: 18.4
					},
					{
						djmc: "超重",
						kszs: 18.41,
						jszs: 20.4
					},
					{
						djmc: "肥胖",
						kszs: 20.41,
						jszs: 50
					}
				]
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			// console.log(tempuser);
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			drawSGLine(xarry, xsarr, bjarr) {
				if (
					this.sgChart != null &&
					this.sgChart != "" &&
					this.sgChart != undefined
				) {
					this.sgChart.dispose();
				}
				this.sgChart = this.$echarts.init(document.getElementById("sgChart"));

				let min_xs = Math.floor(Math.min(...xsarr));
				let min_bj = Math.floor(Math.min(...bjarr));
				let max_xs = Math.ceil(Math.max(...xsarr));
				let max_bj = Math.ceil(Math.max(...bjarr));

				this.sgChart.setOption({
					tooltip: {},
					xAxis: {
						data: xarry
					},
					yAxis: {
						type: 'value',
						min: min_xs < min_bj ? min_xs : min_bj,
						// max: max_xs > max_bj ? max_xs : max_bj
					},
					legend: {
						bottom: "10",
						data: ["个人身高", "同龄身高均值"]
					},
					series: [{
							name: "个人身高",
							type: "line",
							data: xsarr,
							itemStyle: {
								color: "#009830"
							}
						},
						{
							name: "同龄身高均值",
							type: "line",
							data: bjarr,
							itemStyle: {
								color: "#B861BB"
							}
						}
					]
				});
			},
			drawTZLine(xarry, xsarr, bjarr) {
				if (
					this.tzChart != null &&
					this.tzChart != "" &&
					this.tzChart != undefined
				) {
					this.tzChart.dispose();
				}
				this.tzChart = this.$echarts.init(document.getElementById("tzChart"));


				let min_xs = Math.floor(Math.min(...xsarr));
				let min_bj = Math.floor(Math.min(...bjarr));
				let max_xs = Math.ceil(Math.max(...xsarr));
				let max_bj = Math.ceil(Math.max(...bjarr));

				this.tzChart.setOption({
					tooltip: {},
					xAxis: {
						data: xarry
					},
					yAxis: {
						type: 'value',
						min: min_xs < min_bj ? min_xs : min_bj,
						// max: max_xs > max_bj ? max_xs : max_bj
					},
					legend: {
						bottom: "10",
						data: ["个人体重", "同龄体重均值"]
					},
					series: [{
							name: "个人体重",
							type: "line",
							data: xsarr,
							itemStyle: {
								color: "#009830"
							}
						},
						{
							name: "同龄体重均值",
							type: "line",
							data: bjarr,
							itemStyle: {
								color: "#B861BB"
							}
						}
					]
				});
			},
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.Id = xsdata.Id;
				// this.xsinfo.XM = xsdata.XM;
				// this.xsinfo.XBM = xsdata.XBM;
				// this.xsinfo.ZP = xsdata.ZP;
				// this.xsinfo.XQAH = xsdata.XQAH;
				// this.xsinfo.SSXX = xsdata.SSXX;
				// this.xsinfo.SSXQ = xsdata.SSXQ;
				// this.xsinfo.SSXN = xsdata.SSXN;
				// this.xsinfo.XQLX = xsdata.XQLX;
				// this.xsinfo.BZRMC = xsdata.BZRMC;
				// this.xsinfo.NL = xsdata.NL;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXTB = xsdata.XXTB;
				// this.xsinfo.BJMC = xsdata.BJMC;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXBM = xsdata.XXBM;
				// this.xsinfo.XZ = xsdata.XZ;
				// this.xsinfo.SSBJ = xsdata.SSBJ;
				// this.xsinfo.SSNJ = xsdata.SSNJ;
				// this.xsinfo.NJ = xsdata.NJ;
				if (
					this.xsinfo.ZP == null ||
					this.xsinfo.ZP == undefined ||
					this.xsinfo.ZP == ""
				) {
					this.xsinfo.ZP =
						this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}

				let terms = JSON.parse(localStorage.getItem("terms"));
				let templateTerm = localStorage.getItem("templateTerm");
				let currentterm = localStorage.getItem("currentterm");
				let templateTermModel = terms.find(o => o.Id == templateTerm);
				let currenttermModel = terms.find(o => o.Id == currentterm);
				this.xsinfo.NJ = parseInt(this.xsinfo.NJ) - (parseInt(currenttermModel.SSXN.substr(0, 4)) - parseInt(
					templateTermModel.SSXN.substr(0, 4)));
				this.getphysicalruledata();
				this.getxsreportdata();
			},
			getphysicalruledata: function() {
				this.$api.post(
					"v1/accountmanage/GetSchoolPhysicalFitnessLevel", {
						GradeNumber: this.xsinfo.NJ,
						FitnessTypes: 1,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							if (r.Target.length > 0)
								this.tzsjdf = [];
							for (let i = 0; i < r.Target.length; i++) {
								if (r.Target[i].XSXB == this.xsinfo.XBM) {
									let obj = new Object();
									obj.djmc = r.Target[i].DJMC;
									obj.kszs = r.Target[i].KSZS;
									obj.jszs = r.Target[i].JSZS;
									this.tzsjdf.push(obj);
								}
							}
						}
					}
				);
			},
			getxsreportdata: function() {
				this.$api.post(
					"v1/growthreport/GetStudentHeightWeight", {
						SchoolId: this.xsinfo.SSXX,
						TermId: this.xqid,
						SSXN: this.xsinfo.SSXN,
						SchoolCode: this.xsinfo.XXBM,
						StudentId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							var info = r.Target;
							if (info != null && info != undefined) {
								this.xsinfo.BMI = info.BMI;
								this.xsinfo.stxt = info.AnalysisContent;
								if (info.Heights != null && info.Heights != undefined) {
									this.heightdata = JSON.parse(info.Heights);
								}
								if (info.Weights != null && info.Weights != undefined) {
									this.weightdata = JSON.parse(info.Weights);
								}
								this.drawcharts();
							}
						}
					}
				);
			},
			drawcharts: function() {
				this.drawSGLine(
					this.heightdata.map(item => {
						return item.MC;
					}),
					this.heightdata.map(item => {
						return item.Score;
					}),
					this.heightdata.map(item => {
						return item.NScore;
					})
				);
				this.drawTZLine(
					this.weightdata.map(item => {
						return item.MC;
					}),
					this.weightdata.map(item => {
						return item.Score;
					}),
					this.weightdata.map(item => {
						return item.NScore;
					})
				);
			}
		},
		mounted() {},
		computed: {
			score_margin_left: function() {
				let bmi = this.xsinfo.BMI;
				let dfarr = this.tzsjdf;
				let leftcount = dfarr.length - 1;
				let kszs = 0;
				let jszs = 0;
				let marginleft = 1;
				let find = false;
				let sublength = (50 / dfarr.length).toFixed(2);
				for (var i = 0; i < dfarr.length; i++) {
					if (dfarr[i].kszs <= bmi && dfarr[i].jszs >= bmi) {
						kszs = dfarr[i].kszs;
						jszs = dfarr[i].jszs;
						leftcount = i;
						find = true;
						break;
					}
				}
				if (find) {
					if (leftcount == dfarr.length - 1) {
						marginleft = sublength * ((leftcount) * 2);
						marginleft += sublength * 2 * ((bmi - kszs) / (jszs - kszs)).toFixed(2);
					} else if (leftcount > 0) {
						marginleft = sublength * ((leftcount) * 2);
						marginleft += sublength * 2 * ((bmi - kszs) / (jszs - kszs)).toFixed(2);
					} else {
						marginleft = sublength * 2 * ((bmi - kszs) / (jszs - kszs)).toFixed(2);
					}
				} else {
					marginleft = 1;
				}
				return "margin-left: calc(" + marginleft + "% - 10px)";
			}
		}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.studenticon1 {
		width: 125px;
		height: 125px;
		text-align: center;
		float: left;
		margin-top: 20px;
		margin-left: 20px;
	}

	.studenticon2 {
		width: 80px;
		height: 80px;
		text-align: center;
		float: left;
		margin-top: 2px;
		margin-left: 2px;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/orange_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/orange_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 715px;
		width: 100%;
	}

	.totalscore1 {
		height: 150px;
		width: 760px;
		margin-top: 10px;
		margin-left: 20px;
		/*background: #ffffff;*/
		border-radius: 25px;
	}

	.totalscore2 {
		height: 90px;
		width: 535px;
		margin-top: 20px;
		margin-left: 30px;
		/*background: #ffffff;*/
		border-radius: 15px;
	}

	.scoretitle1 {
		/*margin-top: 25px;*/
		width: 125px;
		height: 30px;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}

	.scoretitle2 {
		width: 80px;
		height: 25px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}

	.scoredescstr1 {
		width: 180px;
		height: 22px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		vertical-align: middle;
		margin-top: 20px;
	}

	.scoredescstr2 {
		width: 125px;
		height: 18px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		vertical-align: middle;
		margin-top: 10px;
	}

	.scoredesc1 {
		width: 560px;
		height: 112px;
		text-align: left;
		float: left;
		vertical-align: middle;
		margin-top: 40px;
		margin-left: 20px;
	}

	.scoredesc2 {
		width: 410px;
		height: 55px;
		text-align: left;
		float: left;
		vertical-align: middle;
		margin-top: 12px;
		margin-left: 10px;
	}

	.xsinfodiv1 {
		margin-top: 90px;
		margin-left: 20px;
		display: table-row;
		float: left;
		width: 165px;
	}

	.xsinfodiv2 {
		margin-top: 50px;
		margin-left: 15px;
		display: table-row;
		float: left;
		width: 125px;
	}

	.scorebar1 {
		height: 260px;
		width: 760px;
		margin-left: 40px;
		margin-top: 10px;
		background: #ffffff;
		border-radius: 15px;
	}

	.scorebar2 {
		height: 180px;
		width: 535px;
		margin-top: 10px;
		margin-left: 30px;
		background: #ffffff;
		border-radius: 10px;
	}

	.detailinfo1 {
		width: 760px;
		height: 150px;
		margin-top: 10px;
		margin-left: 40px;
		background: #ffffff;
		border-radius: 15px;
		text-align: center;
	}

	.detailinfo2 {
		width: 535px;
		height: 110px;
		margin-top: 10px;
		margin-left: 30px;
		background: #ffffff;
		border-radius: 10px;
		text-align: center;
	}

	.detailrow1 {
		height: 80px;
		width: 720px;
		margin-top: 10px;
		margin-left: 40px;
		background: #ffffff;
		border-radius: 15px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		padding: 10px 20px;
		color: #080808;
	}

	.detailrow2 {
		height: 60px;
		width: 515px;
		margin-top: 10px;
		margin-left: 30px;
		background: #ffffff;
		border-radius: 10px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		padding: 5px 10px;
		color: #080808;
	}

	.detailstr1 {
		width: 760px;
		text-align: left;
		line-height: 25px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #720604;
		margin-top: 20px;
	}

	.detailstr2 {
		width: 530px;
		text-align: left;
		line-height: 20px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #720604;
		margin-top: 10px;
	}

	.detailscore1 {
		text-align: left;
		line-height: 20px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}

	.detailscore2 {
		text-align: left;
		line-height: 15px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
	}

	.fvcbar1 {
		width: 746px;
		height: 20px;
		background: linear-gradient(90deg,
				#9900cc,
				#6699ff,
				#00cc33,
				#ffcc00,
				#ff0000);
		box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.2);
		border-radius: 15px;
		margin: 0 5px;
	}

	.fvcbar2 {
		width: 525px;
		height: 15px;
		background: linear-gradient(90deg,
				#9900cc,
				#6699ff,
				#00cc33,
				#ffcc00,
				#ff0000);
		box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		margin: 0 5px;
	}

	.djmcrow1 {
		width: 760px;
	}

	.djmcrow2 {
		text-align: center;
		line-height: 70px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #720604;
		margin-top: 10px;
	}

	.djmcstr1 {
		text-align: center;
		line-height: 20px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #720604;
		margin-top: 20px;
	}

	.djmcstr2 {
		text-align: center;
		line-height: 15px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #720604;
		margin-top: 5px;
	}

	.detailmark1 {
		height: 27px;
		width: 19px;
		margin-bottom: -8px;
		z-index: 999;
	}

	.detailmark2 {
		height: 21px;
		width: 15px;
		margin-bottom: -6px;
		z-index: 999;
	}
</style>