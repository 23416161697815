<template>
  <div>
    <div class="operate-box-l" style="padding: 10px 0">
      榜单计算频次
      <!-- <el-input
        size="mini"
        class="popv-inpt"
        style="width: 40px; padding-left: 0px;"
        v-model="setInfo.ZXPC"
        placeholder="请输入"
      ></el-input> -->
      <!-- 执行频次 1.每周 2.每月-->
      <el-radio v-model="setInfo.ZXPC" :label="1">每周生成</el-radio>
      <el-radio v-model="setInfo.ZXPC" :label="2">每月生成(自然月)</el-radio>
      &nbsp;&nbsp;&nbsp;&nbsp; 周期结束(23:59:59)前
      <!-- 偏移量 -->
      <el-input
        size="mini"
        class="popv-inpt"
        style="width: 40px; padding-left: 0px"
        v-model="setInfo.PYL"
        placeholder="请输入"
      ></el-input
      >小时启动计算
      <el-tooltip
        :disabled="!setInfo.YSC"
        effect="dark"
        content="已有生成结果，无法修改"
        placement="right"
      >
        <el-button type="primary" size="mini" @click="saveSet">提交</el-button>
      </el-tooltip>
      <el-button type="primary" size="mini" @click="backroll"
        >重算最后一次体系</el-button
      >
    </div>
    <div class="operate-box-l" style="padding: 10px 0">
      <span style="margin-left: 20px">生成方式&nbsp;&nbsp;</span>
      <el-radio
        v-model="setInfo.SCFS"
        :label="1"
        @change="scfsClick(1)"
        :disabled="this.disabled1"
        >按维度生成</el-radio
      >
      <el-radio
        v-model="setInfo.SCFS"
        :label="2"
        @change="scfsClick(2)"
        :disabled="this.disabled2"
        >按活动生成</el-radio
      >

      <span
        >&nbsp;&nbsp;&nbsp;&nbsp;注：此处的计算频次仅为榜单的计算频次。</span
      >
    </div>
    <div class="table-er-box">
      <el-button plain @click="addItem"
        >新增{{ this.scfs === 1 ? '-按维度' : '-按活动' }}</el-button
      >
    </div>
    <el-table
      v-loading="isLoading"
      :data="
        dataTable.slice(
          (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
          (pagerInfo.pageIndex - 1) * pagerInfo.pageSize + pagerInfo.pageSize
        )
      "
      border
      tooltip-effect="dark"
      style="width: 100%"
    >
      <el-table-column
        prop="GRCZTXInfo.PXH"
        label="排序"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="GRCZTXInfo.MC"
        label="名称"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="类别" show-overflow-tooltip>
        <template slot-scope="scope">
          {{
            scope.row.GRCZTXInfo.TXLX === 1
              ? '系统体系'
              : scope.row.GRCZTXInfo.TXLX === 2
              ? '自定义体系'
              : ''
          }}
        </template>
      </el-table-column>
      <el-table-column label="生成方式" show-overflow-tooltip>
        <template slot-scope="scope">
          {{
            scope.row.GRCZTXInfo.SCFS === 1
              ? '按维度生成'
              : scope.row.GRCZTXInfo.SCFS === 2
              ? '按活动生成'
              : '--'
          }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button @click="editItem(scope.row)" type="text" size="small"
            >编辑</el-button
          >
          <el-button
            @click="delItem(scope.row.GRCZTXInfo.Id)"
            type="text"
            size="small"
            class="tab-wz-butta c-red"
            >删除</el-button
          >
          <el-button type="text" size="small" @click="backroll">重算</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="prompt-title-box">
      备注：计算时评价记录从创建时间 ≥
      从上周期末的启动时间~当前开始计算的时间，手动重算时，开始时间是一样的，截止时间是当前时间。
    </div>
    <!--页码开始-->
    <div class="pagin-box">
      <div class="pagin-wz">
        <!--分页-->
        <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
      </div>
    </div>
  </div>
</template>

<script>
import Pager from '@/components/Pager.vue'
export default {
  components: {
    Pager
  },
  data() {
    return {
      isLoading: false,
      dataTable: [],
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      token: '',
      user: {},
      schoolId: '',
      setInfo: {},
      scfs: 1,
      disabled1: false,
      disabled2: false
    }
  },
  props: {
    setList: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  created: function() {
    this.token = this.$store.state.token
    this.user = JSON.parse(localStorage.getItem('userinfo'))
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId
      }
    }

    const setInfo = JSON.parse(
      JSON.stringify(this.setList.find(c => c.CZTXLX === 1) || {})
    )
    if (setInfo) {
      this.setInfo = setInfo
      this.setInfo.SCFS = 1
    }

    this.getData()
  },
  methods: {
    backroll() {
      alert('重算')
    },
    saveSet() {
      if (!this.setInfo.YSC) {
        this.setInfo.CZTXLX = 2
        this.setInfo.SSXX = this.schoolId
        this.$confirm('确定保存设置?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$api.post(
              '/v1/growthsystem/SetGroupSet',
              {
                token: this.token,
                CZTXSZInfo: this.setInfo,
                UserId: this.user.Id
              },
              r => {
                if (r.Code === 0) {
                  this.$emit('getGroupSet')
                  this.$message({
                    type: 'success',
                    message: r.Message
                  })
                }
              }
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消保存设置'
            })
          })
      }
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val
    },
    scfsClick(val) {
      this.scfs = val
    },
    getData() {
      this.isLoading = true
      this.$api.post(
        '/v1/growthsystem/GetPersonSetList',
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            const list = r.Target?.filter(item => item.TXLX === 2) //2是个人体系
            list.sort((a, b) => a.PXH - b.PXH)
            this.dataTable = []
            this.disabled1 = false
            this.disabled2 = false
            if (list !== null && list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                this.dataTable.push({
                  GRCZTXInfo: list[i],
                  SchoolId: this.schoolId,
                  UserId: this.user.Id,
                  UserName: this.user.XSM
                })
              }
              this.setInfo.SCFS = list[0].SCFS
              this.scfs = list[0].SCFS
              if (this.scfs === 1) {
                this.disabled1 = false
                this.disabled2 = true
              }
              if (this.scfs === 2) {
                this.disabled1 = true
                this.disabled2 = false
              }
            }
            this.pagerInfo.totalCount = this.dataTable.length
          }
          this.isLoading = false
        }
      )
    },
    delItem(id) {
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$api.post(
            '/v1/growthsystem/DelPersonSet',
            {
              token: this.token,
              Id: id,
              SchoolId: this.schoolId,
              UserId: this.user.Id,
              UserName: this.user.XSM
            },
            r => {
              if (r.Code === 0) {
                this.$message({
                  type: 'success',
                  message: r.Message
                })
                this.getData()
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    addItem() {
      const data = {
        GRCZTXInfo: { HDFW: 1, TXLX: 2 },
        CZTXJJList: [],
        FLCZSZList: [],
        SchoolId: this.schoolId,
        UserId: this.user.Id,
        UserName: this.user.XSM,
        scfs: this.scfs
      }
      this.$router.push({
        path: '/growthsystem/persongrowth/form',
        query: { data: data }
      })
    },
    editItem(data) {
      data.scfs = data.GRCZTXInfo.SCFS
      this.$router.push({
        path: '/growthsystem/persongrowth/form',
        query: { data: data }
      })
    }
  },
  watch: {
    setList: {
      handler(val) {
        if (val) {
          this.setInfo = JSON.parse(
            JSON.stringify(val.find(c => c.CZTXLX === 2) || {})
          )
          // this.setInfo.SCFS = 1
        }
      },
      deep: true
    }
  }
}
</script>
