<template>
  <div class="exportpdf-wrap" v-loading="!debug">
    <div class="main-wrap pdfDom" id="pdfDom" ref="pdfDom">
      <div>
        <div
          class="pagedataclass"
          :class="[
            'node-article-content',
            exportType === 2 ? 'node-article-content-center' : ''
          ]"
          v-for="(pageDatas_item, index) in pagedataAll"
          :key="index"
        >
          <component
            v-for="(item, index) in pageDatas_item"
            :key="index"
            :is="item.MBMC"
            :info="item"
            :msg="msg"
            ref="componentlist"
          >
          </component>
        </div>
      </div>
    </div>
    <div v-if="!debug" class="progress">
      <div>{{ this.MS }}</div>
      <el-progress
        style="width:90%"
        :text-inside="false"
        :stroke-width="18"
        :percentage="percentage"
        :status="status"
        :color="customColors"
      ></el-progress>
    </div>
    <div>
      <div id="canvas"></div>
    </div>
  </div>
</template>

<script>
import basicInfo from '@/views/manager/pdf/templates/basicInfo.vue'
import cover from '@/views/manager/pdf/templates/cover.vue'
// import imagecover from "@/views/manager/pdf/templates/imagecover.vue";
import points from '@/views/manager/pdf/templates/points.vue'
import comment from '@/views/manager/pdf/templates/comment.vue'
import physicalExamination from '@/views/manager/pdf/templates/physicalExamination.vue'
import honor from '@/views/manager/pdf/templates/honor.vue'
// import performance from "@/views/manager/pdf/templates/performance.vue";
import starsActivity from '@/views/manager/pdf/templates/starsActivity.vue'
import childrenStarsActivity from '@/views/manager/pdf/templates/childrenStarsActivity.vue'
import dimension from '@/views/manager/pdf/templates/dimension.vue'
import bodyfunction from '@/views/manager/pdf/templates/bodyfunction.vue'
import bodyfigure from '@/views/manager/pdf/templates/bodyfigure.vue'
import physique from '@/views/manager/pdf/templates/physique.vue'
import fitness from '@/views/manager/pdf/templates/fitness.vue'
import reportOverview from '@/views/manager/pdf/templates/reportOverview.vue'
import knowledge from '@/views/manager/pdf/templates/knowledge.vue'
import curriculaActivity from '@/views/manager/pdf/templates/curriculaActivity.vue'
// import txtemplate from "@/views/manager/pdf/templates/txtemplate.vue";
import generalAct from '@/views/manager/pdf/templates/generalAct.vue'
import homeWork from '@/views/manager/pdf/templates/homeWork.vue'
import JXGY from '@/views/manager/pdf/templates/JXGY.vue'
import subject from '@/views/manager/pdf/templates/subject.vue'

import JSZip from 'jszip'
import FileSaver from 'file-saver'
import { number } from 'echarts'

export default {
  components: {
    basicInfo,
    cover,
    // imagecover,
    points,
    comment,
    physicalExamination,
    honor,
    // performance,
    childrenStarsActivity,
    dimension,
    starsActivity,
    bodyfunction,
    bodyfigure,
    physique,
    fitness,
    reportOverview,
    knowledge,
    curriculaActivity,
    // txtemplate,
    generalAct,
    homeWork,
    JXGY,
    subject
  },
  data() {
    return {
      customColors: [
        {
          color: '#e6a23c',
          percentage: 95
        },
        {
          color: '#5cb87a',
          percentage: 100
        }
      ],
      backgroudimage: '',
      ssxx: '',
      xqid: '',
      token: '',
      percentage: 0,
      debug: false, //false
      exportType: 1,
      msg: '',
      bjid: '',
      njid: '',
      myVar: null,
      pdffiles: [],
      meters: [],
      pageDatas: [],
      xslist: [],
      templatelist: [],
      zips: null,
      MS: '',
      pagedataAll: []
    }
  },
  created() {
    this.xqid = localStorage.getItem('templateTerm')
    // console.log(this.xqid);
    var tempuser = localStorage.getItem('userinfo')
    if (tempuser) {
      var user = JSON.parse(tempuser)
      this.ssxx = user.SSZZId
      this.token = user.Token
    }
    this.exportType = this.$route.query.exportType
      ? this.$route.query.exportType * 1
      : 1
    this.bjid = this.$route.query.bjid
    this.pageDatas = JSON.parse(sessionStorage.getItem('pageDatas'))
    if (this.pageDatas && this.pageDatas.length > 0) {
      this.pagedataAll = []
      let pagedata2 = []

      for (let y = 0; y < this.pageDatas.length; y++) {
        pagedata2.push(this.pageDatas[y])
        if (pagedata2.length == 6 || y === this.pageDatas.length - 1) {
          this.pagedataAll.push(pagedata2)
          pagedata2 = []
        }
      }
      // console.log(this.pagedataAll)
      this.njid = this.$route.query.njid

      this.loadtemplate()
    }
  },
  mounted() {
    //setTimeout(() => {
    //  this.fetchData();
    //}, 4000);
  },
  methods: {
    fetchData() {
      // if (this.debug) {
      this.$nextTick(() => {
        this.doExport()
      })
      // }
    },
    async doExport() {
      let _this = this
      _this.pdffiles = []
      let xsarrtemp = this.xslist
      let intervalsec = 1
      let pernum = xsarrtemp.length
      _this.zips = new JSZip()
      let date = new Date()
      // console.log(new Date())
      _this.MS = '正在导出至PDF，请稍等...'
      // _this.percentage = 0.01;
      //pernum
      for (var i = 0; i < pernum; i++) {
        intervalsec = intervalsec * (i + 1)
        let tempxs = xsarrtemp[i] //[i]
        let title = '成长手册' + '-' + tempxs.xsxx.XM + '-' + tempxs.xsxx.BJMC
        // console.log(title)
        // var tempmeter1 = setTimeout(() => {
        for (var j = 0; j < _this.$refs.componentlist.length; j++) {
          _this.$refs.componentlist[j].changexsdata(tempxs)
        }
        // var tempmeter2 = setTimeout(() => {
        // _this.pdffiles.length = 0;
        await new Promise(res => setTimeout(res, 1000)) //等待后执行，1000为1秒
        let p
        if (_this.exportType === 2) {
          // _this.pdffiles.push(p);
          if (!_this.debug) {
            p = await _this.getPdfFromHtml2('pagedataclass', title)
            await _this.zips.file(
              `${p.name}-${new Date().getTime()}.pdf`,
              p.PDF.output('blob')
            )
          }
        } else {
          // p = await Promise.all(_this.getPdfFromHtml1("pdfDom", title));
          // _this.pdffiles.push(p);
          if (!_this.debug) {
            p = await _this.getPdfFromHtml1('pagedataclass', title)
            await _this.zips.file(
              `${p.name}-${new Date().getTime()}.pdf`,
              p.PDF.output('blob')
            )
          }
        }

        // }, 1000);
        // _this.meters.push(tempmeter2);
        // }, intervalsec * 1000);
        // _this.meters.push(tempmeter1);
        let progress = ((i + 1) / pernum) * 100
        _this.percentage = progress.toFixed(2) * 1 //Math.round(progress);
        // console.log('当前进度：' + Math.round(progress) + '%');
        if (progress == 100) {
          _this.MS = '正在打包为压缩文件，请稍等...'
        }
      }

      let usedTime = new Date() - date
      let days = Math.floor(usedTime / (24 * 3600 * 1000)) // 计算出天数
      let leavel = usedTime % (24 * 3600 * 1000) // 计算天数后剩余的时间
      let hours = Math.floor(leavel / (3600 * 1000)) // 计算剩余的小时数
      let leavel2 = leavel % (3600 * 1000) // 计算剩余小时后剩余的毫秒数
      let minutes = Math.floor(leavel2 / (60 * 1000)) // 计算剩余的分钟数
      let leavel3 = leavel2 % (60 * 1000)
      let miao = Math.floor(leavel3 / 1000) // 计算剩余的分钟数
      console.log(
        '导出共用时：' +
          days +
          '天' +
          hours +
          '时' +
          minutes +
          '分' +
          miao +
          '秒'
      )

      //最后一个执行完成后再加同样的间隔时间
      intervalsec += 1

      var tempmeter = setTimeout(() => {
        if (
          _this.pdffiles != [] &&
          _this.pdffiles != null &&
          _this.pdffiles != undefined
        ) {
          // _this.exportMeeting(_this.pdffiles);

          _this.MS = '请选择保存位置并点击保存！'
          if (!_this.debug) {
            _this.zips
              .generateAsync({
                type: 'blob'
              })
              .then(content => {
                FileSaver.saveAs(
                  content,
                  '成长手册导出' + new Date().getTime() + '.zip'
                )
              })
          }
        } else {
          _this.$message({
            type: 'success',
            message: '生成失败，请稍后再试!'
          })
        }
      }, intervalsec * 1000)
      _this.meters.push(tempmeter)
    },
    async exportMeeting(pdfarr) {
      try {
        const zip = new JSZip()
        // 等到所有的promise执行完成依次压缩到zip中
        Promise.all(pdfarr)
          .then(async pdfs => {
            // 如果只是导出一个pdf，则导出pdf格式
            if (pdfs.length === 1) {
              console.log('开始下载文件，请稍等...')
              const { PDF, name } = pdfs[0]
              PDF.save(`${name}-${new Date().getTime()}.pdf`)
            } else {
              console.log('正在打包为压缩文件，请稍等...')
              for (let i = 0; i < pdfs.length; i++) {
                const { PDF, name } = pdfs[i]
                // 否则添加到压缩包里面
                await zip.file(
                  `${name}-${new Date().getTime()}.pdf`,
                  PDF.output('blob')
                )
              }
            }
            if (pdfs.length > 1) {
              console.log('开始下载压缩文件，请稍等...')
              zip
                .generateAsync({
                  type: 'blob'
                })
                .then(content => {
                  FileSaver.saveAs(
                    content,
                    '成长手册导出' + new Date().getTime() + '.zip'
                  )
                })
            }
          })
          .finally(() => {})
      } catch (e) {
        throw new Error(e)
      }
    },
    loadstudentdata: function() {
      if (this.bjid == '' || this.bjid.length <= 0) {
        return
      }
      // let _this = this;
      this.MS = '正在获取数据，请稍等...'
      let temp_xslist = localStorage.getItem(this.bjid + '_' + this.xqid)
      if (temp_xslist) {
        this.xslist = JSON.parse(temp_xslist)
        this.fetchData()
      } else {
        this.$api.post(
          'v1/Growth/GetDataTemplateByClass',
          {
            ssxx: this.ssxx,
            xqid: this.xqid,
            bjId: this.bjid,
            njId: this.njid,
            token: this.token
          },
          r => {
            if (r.Code == 0) {
              this.xslist = []
              var list = r.Target
              if (list != null && list != undefined && list.length > 0) {
                //list = list.filter(
                //  c =>
                //    c.Id == "92bb2800-584f-4876-9029-8964c68d4cd6" ||
                //    c.Id == "e74468a8-a679-4465-9b26-f2d4d3d4d910"
                //);
                for (var i = 0; i < list.length; i++) {
                  this.xslist.push(list[i])
                }
                // console.log(this.xslist)
                localStorage.setItem(
                  this.bjid + '_' + this.xqid,
                  JSON.stringify(this.xslist)
                )
                this.fetchData()
              } else {
                this.MS = '该学期未生成数据！'
              }
            }
          }
        )
      }
    },
    loadtemplate: function() {
      let temp_xslist = localStorage.getItem('schooltemplate_' + this.xqid)
      if (temp_xslist) {
        this.templatelist = JSON.parse(temp_xslist)
        this.loadstudentdata()
      } else {
        this.$api.post(
          'v1/Growth/GetTemplate',
          {
            ssxx: this.ssxx,
            xqid: this.xqid,
            token: this.token
          },
          r => {
            if (r.Code == 0) {
              this.templatelist = []
              var list = r.Target
              if (list && list != 'null') {
                this.templatelist = JSON.parse(list)
                localStorage.setItem('schooltemplate_' + this.xqid, list)
                this.loadstudentdata()
              } else {
                this.MS = '该学期未生成数据！'
              }
            }
          }
        )
      }
    }
  },
  computed: {
    status() {
      return this.percentage === 100 ? 'success' : undefined
    }
  },
  beforeDestroy() {
    if (this.meters != null && this.meters.length > 0) {
      for (var i = 0; i < this.meters.length; i++) {
        clearTimeout(this.meters[i])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin clearfix {
  &:before {
    display: table;
    content: '';
    clear: both;
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

.node-article-content {
  width: 840px;
}

.node-article-content-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 1188px;
}

.exportpdf-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.progress {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*width: 70%;*/
  top: 45vh;
  z-index: 1111111111;
  width: 70%;
  height: 200px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  box-shadow: 0 2px 12px 0 #0000001a;

  ::v-deep .el-progress-bar {
    width: 95%;
  }
}

.el-loading-spinner {
  top: 40vh;
}

.main-wrap {
  /*width: 100%;*/
}

.progress {
  ::v-deep .el-progress-bar__inner:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-image: repeating-linear-gradient(
      -45deg,
      hsla(0, 0%, 100%, 0.15) 25%,
      transparent 0,
      transparent 50%,
      hsla(0, 0%, 100%, 0.15) 0,
      hsla(0, 0%, 100%, 0.15) 75%,
      transparent 0,
      transparent
    );
    background-size: 40px 40px;
    animation: mymove 2s linear infinite;
  }
}

@keyframes mymove {
  0% {
    background-position: 0;
  }

  25% {
    background-position: 50px;
  }

  50% {
    background-position: 100px;
  }

  75% {
    background-position: 150px;
  }

  100% {
    background-position: 200px;
  }
}
</style>
