<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <!--表格区开始-->
      <div class="tab-heng-box margno-t">
        <div class="table-er-box">
          <el-select
            v-model="SSXD"
            placeholder="请选择学段"
            @change="getBaseList"
          >
            <el-option
              v-for="sxd in SchoolPeriod"
              :label="sxd.Name"
              :key="sxd.Id"
              :value="sxd.Id"
            ></el-option>
          </el-select>
          <el-button
            style="margin-left:15px;margin-right:15px"
            type="primary"
            @click="set_JC(0)"
            >添加</el-button
          >
        </div>
        <!-- 课表列表 -->
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="label" label="日期" width="180">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="set_JC(scope.row)">
                设置
              </el-button>
              <el-button size="small" type="danger" @click="del_KB(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="course-main" v-show="JC_Visible">
          <!-- 日期勾选 -->
          <div class="main-title">
            <div class="text">周设置</div>
            <el-checkbox-group v-model="CheckList">
              <el-checkbox
                v-for="date in dateOptions"
                :key="date.value"
                :disabled="disCheckList.includes(date.value)"
                :label="date.value"
                >{{ date.label }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <!-- 节次设置 -->
          <div class="main-body">
            <div class="text">课节设置</div>
            <div class="body-table">
              <div style="padding:10px 20px;text-align:right">
                <el-button type="success" size="small" @click="addrow"
                  >新增</el-button
                >
              </div>
              <el-table :data="XXKCBJCSZList">
                <el-table-column prop="JC" label="节次">
                  <template slot-scope="scope">
                    <el-input-number
                      style="width:100%"
                      :min="1"
                      :disabled="!!scope.row.Id"
                      :max="20"
                      :precision="0"
                      :step="1"
                      v-model="scope.row.JC"
                      placeholder="节次"
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column label="开始时间" width="200">
                  <template slot-scope="scope">
                    <el-time-picker
                      style="width:100%"
                      v-model="scope.row.KSSJ"
                      placeholder="开始时间"
                      value-format="HH:mm:ss"
                    ></el-time-picker>
                  </template>
                </el-table-column>
                <el-table-column prop="JSSJ" label="结束时间" width="200">
                  <template slot-scope="scope">
                    <el-time-picker
                      style="width:100%"
                      v-model="scope.row.JSSJ"
                      placeholder="结束时间"
                      value-format="HH:mm:ss"
                    ></el-time-picker>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="mini"
                      @click="deleteRow(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="course-main-btn">
            <el-button
              type="primary"
              v-loading="loading"
              @click="submitshowdata"
              >保存</el-button
            >
            <el-button type="info" @click="cancelClick">取消</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handle_SSXD3 } from '@/utils/common'
export default {
  name: 'CourseTableSet',
  data() {
    return {
      token: this.$store.state.token,
      SSXD: 1,
      SSXX: '',
      SchoolPeriod: [],
      XXKCBSZInfo: {
        Id: 0,
        SSXD: 1,
        CJR: '',
        CJSJ: '2019-09-26 07:35:39',
        SFSC: 0,
        SSXX: '',
        KKTS: ''
      },
      XXKCBJCSZList: [],
      disCheckList: [], //禁用的周几
      CheckList: [], //勾选的周几
      userName: '',
      userId: '',
      baseList: [], //初始课表
      tableData: [],
      dateOptions: [
        {
          value: 1,
          label: '周一'
        },
        {
          value: 2,
          label: '周二'
        },
        {
          value: 3,
          label: '周三'
        },
        {
          value: 4,
          label: '周四'
        },
        {
          value: 5,
          label: '周五'
        },
        {
          value: 6,
          label: '周六'
        },
        {
          value: 7,
          label: '周天'
        }
      ], //日期值
      JC_Visible: false,
      loading: false
    }
  },
  created: function() {
    var userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    this.userName = userinfo.DLM
    this.userId = userinfo.Id
    this.SSXX = userinfo.SSZZId
    // 获取学校学段
    let schoolinfo = JSON.parse(sessionStorage.getItem('schoolinfo'))
    this.SchoolPeriod = handle_SSXD3(schoolinfo)
    this.getBaseList()
  },
  methods: {
    handleTableData() {
      this.tableData = this.baseList.reduce((pre, cur) => {
        if (cur.XXKCBSZInfo && cur.XXKCBSZInfo.SSXD === this.SSXD) {
          let label = ''
          cur.CheckList.forEach(item => {
            let dateItem = this.dateOptions.find(
              dateItem => dateItem.value === item
            )
            if (dateItem) label += dateItem.label + ' '
          })
          pre.push({
            CheckList: cur.CheckList,
            label,
            XXKCBJCSZList: cur.XXKCBJCSZList,
            XXKCBSZInfo: cur.XXKCBSZInfo
          })
        }
        return pre
      }, [])
    },
    // 获取设置信息
    getBaseList() {
      this.JC_Visible = false
      this.$api.post(
        '/v1/cousertimetable/GetXXKCBSZByCourseSet',
        {
          SchoolId: this.SSXX,
          Period: this.SSXD,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            if (r.Target) {
              r.Target.forEach(item => {
                item.XXKCBJCSZList.forEach(it => {
                  it.KSSJ = it.KSSJ.split(' ')[1]
                  it.JSSJ = it.JSSJ.split(' ')[1]
                })
              })
              this.baseList = r.Target
              this.handleTableData()
            }
          }
        }
      )
    },
    // 添加课节
    addrow: function() {
      let res = this.XXKCBJCSZList.find(item => item.SZId)
      let rowinfo = {
        Id: 0,
        SZId: res ? res.SZId : 0,
        JC: 0,
        JCMC: '',
        KSSJ: '',
        JSSJ: ''
      }
      if (
        this.XXKCBJCSZList &&
        this.XXKCBJCSZList[this.XXKCBJCSZList.length - 1]
      ) {
        let obj = this.XXKCBJCSZList[this.XXKCBJCSZList.length - 1]
        if (typeof Number(obj.JC) === 'number') rowinfo.JC = obj.JC + 1
        rowinfo.JCMC = obj.JCMC
      }

      this.XXKCBJCSZList.push(rowinfo)
    },
    // 删除行
    deleteRow: function(index, row) {
      if (this.XXKCBJCSZList.length === 1)
        return this.$message.error('只剩最后一行了')

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.XXKCBJCSZList.splice(index, 1)
          if (row.Id > 0) {
            this.$api.post(
              '/v1/cousertimetable/DeleteXXKCBJCSZ',
              {
                SZId: row.SZId,
                Id: row.Id,
                UserName: this.userName,
                UserId: this.userId,
                token: this.token
              },
              r => {
                // eslint-disable-next-line no-empty
                if (r.Code === 0) {
                }
              }
            )
          }
        })
        .catch(() => {
          // this.$message({
          //    type: 'info',
          //    message: '已取消删除'
          // });
        })
    },
    // 保存
    submitshowdata: function() {
      // 以周一的节次和名称为基准 其它日期的不能修改
      // 第一次必须新增周一的节次
      let monday = this.baseList.find(item => item.CheckList.includes(1))
      if (monday) {
        // 如果有周一的 且当前修改的是周一 且没勾选周一
      } else {
        // 没有周一 且当前新建的没勾选周一
        if (!this.CheckList.some(item => item === 1))
          return this.$message.error('第一次新建课表请先新建周一的节次')
      }
      if (this.CheckList.length === 0) return this.$message.error('请勾选星期')

      let abb = this.XXKCBJCSZList.map(item => item.JC)
      if (
        this.XXKCBJCSZList.some(
          item => abb.filter(i => i === item.JC).length >= 2
        )
      )
        return this.$message.error('节次不能重复')

      if (this.XXKCBJCSZList.some(item => !item.KSSJ || !item.JSSJ))
        return this.$message.error('节次时间不能为空')
      try {
        this.XXKCBJCSZList.forEach(item => {
          let minArr = this.XXKCBJCSZList.filter(el => el.JC < item.JC)
          if (minArr && minArr.length > 0) {
            let min = minArr.sort((a, b) => b.JC - a.JC)[0]
            let ccc = item.KSSJ.split(':')
            let ddd = min.JSSJ.split(':')
            if (
              new Date().setHours(ccc[0], ccc[1], ccc[2]) <=
              new Date().setHours(ddd[0], ddd[1], ddd[2])
            ) {
              throw `第${item.JC}节的开始时间必须大于第${min.JC}节的结束时间`
            }
          }

          if (new Date(item.JSSJ) <= new Date(item.KSSJ))
            throw `第${item.JC}节的开始时间必须小于结束时间`
        })

        this.$confirm('此操作保存数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 提交
            this.$api.post(
              'v1/cousertimetable/AddCouserSet',
              {
                XXKCBJCSZList: this.XXKCBJCSZList,
                XXKCBSZInfo: this.XXKCBSZInfo,
                CheckList: this.CheckList,
                UserName: this.userName,
                UserId: this.userId,
                token: this.token
              },
              r => {
                if (r.Code === 0) {
                  this.getBaseList()
                  this.$notify({
                    type: 'success',
                    message: r.Message,
                    duration: 3000
                  })
                } else {
                  this.$message({
                    type: 'error',
                    message: r.Message,
                    showClose: true
                  })
                }
                this.JC_Visible = false
              }
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消保存'
            })
          })
      } catch (err) {
        this.$message.error(err)
      }
    },
    // 设置节次
    set_JC(row) {
      if (row) {
        this.CheckList = row.CheckList
        let arr = this.baseList.filter(
          item => item.XXKCBSZInfo.Id !== row.XXKCBSZInfo.Id
        )
        this.disCheckList = arr.reduce((pre, cur) => {
          cur.CheckList.forEach(it => {
            if (!pre.some(i => i === it) && !this.CheckList.some(i => i === it))
              pre.push(it)
          })
          return pre
        }, [])

        let bbb = row.XXKCBJCSZList.reduce((pre, cur) => {
          pre.push({
            ...cur
          })
          return pre
        }, [])
        this.XXKCBJCSZList = bbb.sort((a, b) => a.JC - b.JC)
        this.XXKCBSZInfo = row.XXKCBSZInfo
      } else {
        let arr = this.dateOptions.map(item => item.value)
        let arr2 = this.baseList.reduce((pre, cur) => {
          pre.concat(cur.CheckList)
          return pre
        }, [])
        if (arr.every(item => arr2.includes(item)))
          return this.$message.error('日期已全部设置，请先删除一个')
        // 如果没有周一的课表 那么只增加一行 如果有周一的课表 那么当前要增加的课表的节次和节次名称 全部以周一为准
        if (!this.baseList || this.baseList.length === 0) {
          this.XXKCBJCSZList = [
            {
              Id: 0,
              SZId: 0,
              JC: 1,
              JCMC: '上午',
              KSSJ: '',
              JSSJ: ''
            }
          ]
          this.CheckList = [1]
          this.XXKCBSZInfo = {
            Id: 0,
            CJR: this.userId,
            SSXD: this.SSXD,
            SSXX: this.SSXX
          }
        } else {
          this.CheckList = []
          let mondyInfo = this.baseList.find(item => item.CheckList.includes(1))
          this.XXKCBJCSZList = mondyInfo.XXKCBJCSZList.reduce((pre, cur) => {
            pre.push({
              Id: 0,
              SZId: 0,
              JC: cur.JC,
              JCMC: cur.JCMC,
              KSSJ: cur.KSSJ,
              JSSJ: cur.JSSJ
            })
            return pre
          }, [])
          this.XXKCBSZInfo = {
            Id: 0,
            CJR: this.userId,
            SSXD: this.SSXD,
            SSXX: this.SSXX
          }
        }
        if (this.baseList && this.baseList.length > 0) {
          this.disCheckList = this.baseList.reduce((pre, cur) => {
            if (cur.CheckList) {
              pre = pre.concat(cur.CheckList)
            }
            return pre
          }, [])
        } else {
          this.disCheckList = []
        }
      }
      this.JC_Visible = true
    },
    // 删除节次
    del_KB(row) {
      if (row.CheckList.includes(1))
        return this.$message.error('包含周一的课表只能修改不能删除')

      this.$confirm('此操作将删除课表, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$api.post(
            '/v1/cousertimetable/DeleteXXKCBSZ',
            {
              Id: row.XXKCBSZInfo.Id,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.cancelClick()
                this.getBaseList()
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除'
          })
        })
    },
    cancelClick() {
      this.JC_Visible = false
      this.XXKCBJCSZList = []
      this.XXKCBSZInfo = {}
    }
  }
}
</script>

<style>
.tab-heng-box table thead tr:hover,
.tab-heng-box table tbody tr:hover {
  background: #fff;
}

body .el-table td {
  text-align: left;
}
</style>

<style lang="scss" scoped>
.cont-whole-box {
  // min-width: 1214px;
}
.course-main {
  font-size: 14px;
  box-shadow: 1px 1px 4px #ccc;
  // min-width: 1154px;
  margin-top: 10px;
  .main-title,
  .main-body {
    display: flex;
    align-items: center;
    width: 100%;
    .text {
      width: 100px;
      text-align: center;
    }
    .body-table {
      width: calc(100% - 100px);
      .el-table {
        // min-width: 1050px;
        width: 100%;
        min-height: 500px;
      }
    }
  }
  .main-title {
    padding: 20px 0;
  }

  .course-main-btn {
    padding: 20px 0;
    text-align: center;
  }
}
</style>
