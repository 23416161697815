<template>
  <div>
    <el-form :model="form" :rules="rules" ref="form" inline-message>
      <div class="cent-topbutt-box" style="height: 75px;">
        <el-form-item
          prop="BJCZTXInfo.MC"
          style="display: inline-block;"
          label-width="0px"
        >
          <span class="ts-span-sm">
            名称：
            <el-input
              size="medium"
              style="width: 160px; vertical-align: middle;"
              v-model="form.BJCZTXInfo.MC"
              placeholder="请输入"
            ></el-input>
          </span>
        </el-form-item>
      </div>

      <div class="leftrig-box">
        <div class="leftrig-left-box" style="vertical-align: middle;">
          <div class="leftrig-left-cent">
            请在右边选择班级参与 该成长体系的类目及系数
          </div>
        </div>
        <div class="leftrig-rig-box" style="vertical-align: middle;">
          <el-checkbox-group v-model="checkedActivities">
            <div
              class="operate-box-l"
              v-for="(item, index) in activitiesData"
              :key="item.Id"
            >
              <el-form-item>
                <div class="disilb-box-l" style="width: 190px;">
                  <el-checkbox :label="item">{{ item.Name }}</el-checkbox>
                </div>
                <!-- 比例 -->
                <el-input-number
                  size="small"
                  placeholder="输入"
                  style="width: 80px; vertical-align: middle; margin-right: 20px;"
                  v-model="item.DF"
                  :controls="false"
                  :precision="1"
                  :min="0"
                  :disabled="
                    checkedActivities.findIndex(c => c === item) === -1
                  "
                />
                <!-- 活动全部参与 -->
                <div class="disilb-box-l">
                  <el-radio
                    :disabled="
                      checkedActivities.findIndex(c => c === item) === -1
                    "
                    v-model="item.CYFS"
                    label="1"
                    >全部参与时</el-radio
                  >
                </div>
                <!-- 活动指标参与 -->
                <div class="disilb-box-l">
                  <el-radio
                    :disabled="
                      checkedActivities.findIndex(c => c === item) === -1
                    "
                    v-model="item.CYFS"
                    label="3"
                    >按指标参与</el-radio
                  >
                </div>
                <el-button
                  v-if="
                    item.CYFS !== '1' &&
                      checkedActivities.findIndex(c => c === item) !== -1
                  "
                  size="mini"
                  type="primary"
                  @click="handdleCYFS(item.CYFS, index)"
                  >编辑</el-button
                >
              </el-form-item>
            </div>
          </el-checkbox-group>
          <el-form-item
            class="jf-form-item"
            prop="checkedActivities"
          ></el-form-item>
        </div>
        <div class="fg-box-2"></div>
        <div class="leftrig-left-box" style="vertical-align: middle;">
          <div class="leftrig-left-cent tx-ertop-cent">
            <div class="tx-ertop-smbox-l" style="margin-top: 50px;">
              <img
                class="ertop-smbox-img-l"
                src="../../../../style/images/back_line_classa.png"
              />
            </div>
            <div class="tx-ertop-smbox-l">
              <span
                class="ertop-smbox-span"
                v-if="form.BJCZTXInfo && form.BJCZTXInfo.JJSXGS"
                >获得{{ form.BJCZTXInfo.JJSXGS }}个星级班级</span
              >
              <img
                class="ertop-smbox-img"
                src="../../../../style/images/back_line_jdl.png"
              />
            </div>
            <div class="tx-ertop-smbox-l">
              <img
                class="ertop-smbox-img-l"
                src="../../../../style/images/back_line_classb.png"
              />
            </div>
          </div>
        </div>
        <div class="leftrig-rig-box" style="vertical-align: middle;">
          <div class="operate-box-l">
            <el-form-item
              class="jf-form-item"
              :rules="[
                {
                  validator: (rule, value, callback) => {
                    if (
                      !value ||
                      (rule.HDFW === '2' &&
                        rule.PBLX === 1 &&
                        !rule.ZBSZ_fixed) ||
                      (rule.HDFW === '2' &&
                        rule.PBLX === 2 &&
                        !rule.ZBSZ_percent)
                    ) {
                      return callback(new $window.Error(rule.message))
                    } else return callback()
                  },
                  message: '该项未设置完全',
                  trigger: 'change',
                  ZBSZ_fixed: ZBSZ_fixed,
                  ZBSZ_percent: ZBSZ_percent,
                  PBLX: form.BJCZTXInfo.PBLX,
                  HDFW: form.BJCZTXInfo.HDFW
                }
              ]"
              prop="BJCZTXInfo.HDFW"
            >
              <div style="display: inline-block;margin-top: 20px">
                <div
                  style="margin-left: 40px;display:none;align-items: center"
                  v-if="form.BJCZTXInfo.HDFW == 2"
                >
                  <div>每次产生的星级班级数:</div>
                  <el-radio
                    v-model="form.BJCZTXInfo.PBLX"
                    :label="1"
                    style="margin-left: 20px;"
                  >
                    <div class="tablil-xz-name">
                      <el-input-number
                        :disabled="form.BJCZTXInfo.PBLX !== 1"
                        :min="0"
                        :controls="false"
                        size="small"
                        placeholder="输入"
                        style="width: 60px; vertical-align: middle; margin-right: 5px;"
                        v-model="ZBSZ_fixed"
                      ></el-input-number
                      >个
                    </div>
                  </el-radio>
                  <el-radio v-model="form.BJCZTXInfo.PBLX" :label="2">
                    <div class="tablil-xz-name">
                      <el-input-number
                        :disabled="form.BJCZTXInfo.PBLX !== 2"
                        :min="0"
                        :controls="false"
                        :precision="1"
                        size="small"
                        placeholder="输入"
                        style="width: 60px; vertical-align: middle; margin-right: 5px;"
                        v-model="ZBSZ_percent"
                      ></el-input-number
                      >%
                    </div>
                  </el-radio>
                </div>
                <div>
                  <div
                    style="display: flex;justify-content:flex-start;flex-direction:column;margin-left:40px"
                  >
                    <div v-for="(item, index) of groups" :key="index">
                      <el-checkbox v-model="item.checked"
                        ><el-input
                          placeholder="组名，8个字以内"
                          maxlength="8"
                          style="margin-top: 5px;"
                          v-model="item.ZM"
                          @input="change($event)"
                        ></el-input>
                      </el-checkbox>

                      <el-popover
                        style="margin-left:100px"
                        placement="right"
                        width="200"
                        trigger="click"
                      >
                        <div
                          class="center"
                          style="flex-direction:column;display: flex;justify-content: center;align-items: center;"
                        >
                          <div style="display: inline-flex;">
                            <el-checkbox-group v-model="item.checkGrades">
                              <el-checkbox
                                style="width:70px;margin:5px 15px 5px 15px"
                                v-for="grade in grades"
                                :key="grade.value"
                                :label="grade.value"
                                >{{ grade.label }}</el-checkbox
                              >
                            </el-checkbox-group>
                          </div>
                          <div style="margin-right:40px;margin-top:20px">
                            每次产生星级班级数:
                          </div>
                          <div style="margin-top:10px">
                            <el-radio
                              style="margin-right:10px"
                              v-model="item.PBLX"
                              :label="1"
                            >
                              <el-input-number
                                :min="0"
                                :precision="0"
                                :controls="false"
                                style="width:47px;"
                                size="mini"
                                class="nopadding"
                                v-model="item.ZBSZ[0]"
                              ></el-input-number>
                              个
                            </el-radio>
                            <el-radio v-model="item.PBLX" :label="2"
                              ><el-input-number
                                :min="0"
                                :max="100"
                                :precision="2"
                                :controls="false"
                                style="width:60px;"
                                class="nopadding"
                                size="mini"
                                v-model="item.ZBSZ[1]"
                              ></el-input-number>
                              %</el-radio
                            >
                          </div>
                        </div>
                        <el-button
                          slot="reference"
                          style="margin-left:10px"
                          type="primary"
                          size="mini"
                          >编辑</el-button
                        >
                      </el-popover>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>

          <div class="operate-box-l">
            <el-form-item
              class="jf-form-item"
              :rules="[
                {
                  validator: (rule, value, callback) => {
                    if (!value || (value && !rule.QSF && rule.QSF !== 0)) {
                      return callback(new $window.Error(rule.message))
                    } else return callback()
                  },
                  message: '该项未设置完全',
                  trigger: 'change',
                  QSF: form.BJCZTXInfo.QSF
                }
              ]"
              prop="BJCZTXInfo.QSFLX"
            >
              <div
                class="disilb-box-l"
                style="width: 180px; text-align: right;"
              >
                <div class="tablil-xz-name epp-ov-l">起算分:</div>
              </div>
              <el-select
                size="small"
                style="width: 80px; margin-left: 8px;"
                v-model="form.BJCZTXInfo.QSFLX"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in 2"
                  :key="item"
                  :label="item === 1 ? '>' : '>='"
                  :value="item"
                ></el-option>
              </el-select>
              <el-input-number
                :min="-99"
                :controls="false"
                size="small"
                placeholder="输入"
                style="width: 60px; vertical-align: middle; margin-left: 10px;"
                v-model="form.BJCZTXInfo.QSF"
              ></el-input-number>
              <span>（起算分包含活动默认分）</span>
            </el-form-item>
          </div>
          <div class="operate-box-l" style="border-bottom: none;">
            <div style="margin-top: 20px;">
              <div
                class="disilb-box-l"
                style="width: 180px; text-align: right;"
              >
                <div class="tablil-xz-name epp-ov-l">星级班级的图标:</div>
                <div class="tab-wz-buttl">
                  <Upload
                    :itemPic="
                      form.BJCZTXInfo.JCDJTB ? form.BJCZTXInfo.JCDJTB : ''
                    "
                    :width="50"
                    :height="50"
                    @getResultUrl="getResultUrl"
                    :circle="true"
                    :itemId="'JCDJTB'"
                    :html="uploadBtnHtml"
                  />
                </div>
              </div>
            </div>
            <div style="margin-top: 20px;">
              <div
                class="disilb-box-l"
                style="width: 180px; text-align: right;"
              >
                <div class="tablil-xz-name epp-ov-l">星级班级的置灰图标:</div>
                <div class="tab-wz-buttl">
                  <Upload
                    :itemPic="form.BJCZTXInfo.HTB ? form.BJCZTXInfo.HTB : ''"
                    :width="50"
                    :height="50"
                    @getResultUrl="getResultUrl"
                    :circle="true"
                    :itemId="'HTB'"
                    :html="uploadBtnHtml"
                  />
                </div>
              </div>
            </div>
            <div style="margin-top: 20px;">
              <div
                class="disilb-box-l"
                style="width: 180px; text-align: right;"
              >
                <div class="tablil-xz-name epp-ov-l">班级晋级需要的星级数:</div>
              </div>
              <div class="disilb-box-l" style="width: 200px;">
                <div class="tablil-xz-name">
                  <el-form-item
                    class="jf-form-item"
                    :rules="[
                      {
                        required: true,
                        message: '班级晋级需要的星级数',
                        trigger: 'change'
                      }
                    ]"
                    prop="BJCZTXInfo.JJSXGS"
                  >
                    <el-input-number
                      :min="0"
                      :controls="false"
                      size="small"
                      placeholder="输入"
                      style="width: 160px;vertical-align: middle;margin-left: 8px;"
                      v-model="form.BJCZTXInfo.JJSXGS"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div style="margin-top: 20px;">
              <div
                class="disilb-box-l"
                style="width: 180px; text-align: right;"
              >
                <div class="tablil-xz-name epp-ov-l">班级晋级图标:</div>
                <div class="tab-wz-buttl">
                  <Upload
                    :itemPic="form.BJCZTXInfo.JJTB ? form.BJCZTXInfo.JJTB : ''"
                    :width="50"
                    :height="50"
                    @getResultUrl="getResultUrl"
                    :circle="true"
                    :itemId="'JJTB'"
                    :html="uploadBtnHtml"
                  />
                </div>
              </div>
            </div>
            <div style="margin-top: 20px;">
              <div
                class="disilb-box-l"
                style="width: 180px; text-align: right;"
              >
                <div class="tablil-xz-name epp-ov-l">班级晋级灰图标:</div>
                <div class="tab-wz-buttl">
                  <Upload
                    :itemPic="
                      form.BJCZTXInfo.JJHTB ? form.BJCZTXInfo.JJHTB : ''
                    "
                    :width="50"
                    :height="50"
                    @getResultUrl="getResultUrl"
                    :circle="true"
                    :itemId="'JJHTB'"
                    :html="uploadBtnHtml"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cent-bott-butt">
          <a @click="save" class="cent-bott-butta">确&nbsp;认</a>
          <a @click="cancel" class="cent-bott-butta">取&nbsp;消</a>
        </div>
      </div>

      <el-dialog
        title="设置对象分值比重"
        :visible.sync="objectVisible"
        :close-on-click-modal="false"
        top="15%"
        width="30%"
        center
      >
        <div class="tab-shu-box over-css" style="height: 300px;">
          <el-checkbox-group v-model="checkObjectList">
            <div
              class="operate-box-l"
              v-for="item in objectList"
              :key="item.ObjectId"
            >
              <div class="disilb-box-l" style="width: 190px;">
                <el-checkbox :label="item">{{ item.ObjectName }}</el-checkbox>
              </div>
              <el-input
                size="small"
                style="width: 60px; vertical-align: middle; margin-right: 5px;"
                v-model="item.DF"
                placeholder="输入"
              ></el-input
              >%
            </div>
          </el-checkbox-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="objectVisible = false"
            >关 闭</el-button
          >
        </span>
      </el-dialog>

      <el-dialog
        title="设置指标分值比重"
        :visible.sync="indexVisible"
        :close-on-click-modal="false"
        top="15%"
        width="30%"
        center
      >
        <div class="tab-shu-box over-css" style="height: 300px;">
          <el-checkbox-group v-model="checkIndexList">
            <div
              class="operate-box-l"
              v-for="item in indexList"
              :key="item.IndexId"
            >
              <div class="disilb-box-l" style="width: 190px;">
                <el-checkbox :label="item.IndexId">{{
                  item.IndexName
                }}</el-checkbox>
              </div>
              <el-input
                size="small"
                style="width: 60px; vertical-align: middle; margin-right: 5px;"
                v-model="item.DF"
                placeholder="输入"
              ></el-input
              >%
            </div>
          </el-checkbox-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="indexSubmit"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import Upload from '@/components/Upload.vue'

export default {
  components: {
    Upload
  },
  data() {
    var validateCheck = function(rule, value, callback) {
      if (value && value.Id) {
        let activity = rule.checkedActivities.find(c => c.Id === value.Id)
        if (activity) {
          let msg = []
          if (!activity.DF) {
            msg.push('请输入活动得分占比')
          }
          if (
            activity.CYFS === '3' &&
            rule.checkIndexList.filter(c => c.ActivityId === value.Id)
              .length === 0
          ) {
            msg.push('请设置指标')
          } else if (
            activity.CYFS === '2' &&
            rule.checkObjectList.filter(c => c.ActivityId === value.Id)
              .length === 0
          ) {
            msg.push('请设置对象')
          }

          rule.message = msg.join(',')
          if (rule.message) {
            return callback(new Error(rule.message))
          }
          return callback()
        } else {
          return callback()
        }
      } else {
        rule.message = '未知校验错误'
        return callback(new Error(rule.message))
      }
    }
    return {
      validateCheckMethod: validateCheck,
      form: this.$route.query.data,
      checkedActivities: [],
      dialogTitle: '',
      activity: {},
      objectVisible: false,
      indexVisible: false,
      indexList: [],
      checkIndexList: [],
      objectList: [],
      checkObjectList: [],
      activitiesData: [],
      grades: [
        {
          value: 1,
          label: '一年级'
        },
        {
          value: 2,
          label: '二年级'
        },
        {
          value: 3,
          label: '三年级'
        },
        {
          value: 4,
          label: '四年级'
        },
        {
          value: 5,
          label: '五年级'
        },
        {
          value: 6,
          label: '六年级'
        }
      ],
      groups: [
        {
          number: 1,
          checked: false,
          PBLX: 1,
          ZBSZ: [undefined, undefined],
          checkGrades: [],
          ZM: ''
        },
        {
          number: 2,
          checked: false,
          PBLX: 1,
          ZBSZ: [undefined, undefined],
          checkGrades: [],
          ZM: ''
        },
        {
          number: 3,
          checked: false,
          PBLX: 1,
          ZBSZ: [undefined, undefined],
          checkGrades: [],
          ZM: ''
        },
        {
          number: 4,
          checked: false,
          PBLX: 1,
          ZBSZ: [undefined, undefined],
          checkGrades: [],
          ZM: ''
        },
        {
          number: 5,
          checked: false,
          PBLX: 1,
          ZBSZ: [undefined, undefined],
          checkGrades: [],
          ZM: ''
        },
        {
          number: 6,
          checked: false,
          PBLX: 1,
          ZBSZ: [undefined, undefined],
          checkGrades: [],
          ZM: ''
        }
      ],
      groupCount: 1,
      groupCountLimit: 9,
      uploadBtnHtml:
        '<a class="tab-wz-butta c-guree" href="javascript:void(0);">上传图标</a>',
      ZBSZ_fixed: undefined,
      ZBSZ_percent: undefined,
      rules: {
        'form.BJCZTXInfo.MC': [
          {
            required: true,
            message: '请输入体系名称',
            trigger: 'change'
          },
          {
            min: 0,
            max: 20,
            message: '不能超过20个字',
            trigger: 'change'
          }
        ]
      }
    }
  },
  props: {
    activities: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  created: function() {
    this.init()
  },
  methods: {
    change(e) {
      //解决input输入框无法输入
      this.$forceUpdate()
    },
    async init() {
      let activitiesData = JSON.parse(
        JSON.stringify(
          this.activities.filter(
            c =>
              ((c.EvaluatedObject === 3 || c.CYFS === 99) &&
                ((c.EvaluationActivityType !== 42 &&
                  c.EvaluationActivityType !== 2) ||
                  c.EvaluationActivityType !== 1) &&
                c.EvaluatedObject !== 2) ||
              (c.EvaluatedObject === 1 &&
                c.EvaluationActivityType === 1 &&
                c.HDLX === 1 &&
                c.EvaluationObjectList &&
                c.EvaluationObjectList.find(obj => obj.PJFS === 1))
          )
        )
      )
      activitiesData.sort((a, b) => a.Name.localeCompare(b.Name))

      activitiesData.forEach(item => {
        item.CYFS = item.CYFS + ''
        item.checkIndexList = []
      })

      if (this.form.BJCZTXInfo.Id) {
        this.form.BJCZTXInfo.HDFW = this.form.BJCZTXInfo.HDFW + ''
        if (this.form.BJCZTXInfo.PBLX === 1) {
          this.ZBSZ_fixed = this.form.BJCZTXInfo.ZBSZ + ''
        } else if (this.form.BJCZTXInfo.PBLX === 2) {
          this.ZBSZ_percent = this.form.BJCZTXInfo.ZBSZ + ''
        }

        if (
          this.form.BJCZTXInfo &&
          this.form.BJCZTXInfo.BJCZTXGLNRList &&
          this.form.BJCZTXInfo.BJCZTXGLNRList.length > 0
        ) {
          this.checkedActivities = this.form.BJCZTXInfo.BJCZTXGLNRList.reduce(
            (pre, item) => {
              let info = activitiesData.find(c => c.Id === item.GLId)
              if (info) {
                info.DF = item.DF + ''
                info.CYFS = item.CYFS + ''
                if (info.CYFS === '3') {
                  info.indexList2 = this.form.BJCZTXInfo.EvaluationObjectAndIndexList.filter(
                    it => it.HDId === info.Id
                  )
                  info.checkIndexList = info.indexList2.map(it => it.GLId)
                }
                pre.push(info)
              }
              return pre
            },
            []
          )
        }
        if (
          this.form.BJCZTXInfo.BJCZTXNJJJList &&
          this.form.BJCZTXInfo.BJCZTXGLNJList
        ) {
          for (let group of this.groups) {
            let item1 = this.form.BJCZTXInfo.BJCZTXNJJJList.find(
              c => c.ZB === group.number
            )
            if (item1) {
              group.ZBSZ[item1.PBLX - 1] =
                item1.PBLX === 2 ? item1.ZBSZ * 100 : item1.ZBSZ
              group.PBLX = item1.PBLX
              group.checked = true
              group.ZM = item1.ZM
            }
            group.checkGrades = this.form.BJCZTXInfo.BJCZTXGLNJList.filter(
              c => c.ZB === group.number
            ).map(c => c.NJ)
          }
        }
      }
      this.activitiesData = activitiesData
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // eslint-disable-next-line no-unused-vars
          this.$confirm('确认提交？').then(r => {
            // 执行提交方法
            // 将form中的几个参数删除掉
            let checkIndexList = this.checkedActivities.reduce((pre, cur) => {
              cur.checkIndexList.forEach(item => {
                let info = cur.indexList?.find(it => it.IndexId === item)
                if (info) {
                  pre.push({
                    DF: info.DF,
                    IndexId: info.IndexId,
                    ActivityId: cur.Id,
                    IndexName: info.IndexName
                  })
                } else {
                  let info = cur.indexList2?.find(it => it.GLId === item)
                  if (info) {
                    pre.push({
                      DF: info.DF,
                      IndexId: info.GLId,
                      ActivityId: cur.Id,
                      IndexName: info.IndexName
                    })
                  }
                }
              })
              return pre
            }, [])

            this.form.BJCZTXGLNRList = []
            this.form.BJCZTXGLNRList = this.checkedActivities
            this.form.HDFW = Number(this.form.BJCZTXInfo.HDFW)
            this.form.BJCZTXInfo.ZBSZ =
              this.form.BJCZTXInfo.PBLX === 1
                ? Number(this.ZBSZ_fixed)
                : Number(this.ZBSZ_percent)
            this.form.checkObjectList = this.checkObjectList
            this.form.checkIndexList = checkIndexList
            this.form.BJCZTXGLNJList = []
            this.form.BJCZTXNJJJList = []
            //if (this.form.HDFW === 1) {

            let groups = this.groups.filter(
              c => c.checked && c.checkGrades.length > 0 //&& c.ZBSZ[c.PBLX - 1]
            )
            var njArr = []
            for (let group of groups) {
              for (let grade of group.checkGrades) {
                njArr.push(grade)
                this.form.BJCZTXGLNJList.push({
                  Id: 0,
                  CZTXId: '',
                  ZB: group.number,
                  NJ: grade
                })
              }
              this.form.BJCZTXNJJJList.push({
                Id: 0,
                CZTXId: '',
                PBLX: group.PBLX,
                ZBSZ:
                  group.PBLX === 2
                    ? Number((group.ZBSZ[1] / 100).toFixed(4))
                    : group.ZBSZ[0],
                ZB: group.number,
                ZM: group.ZM
              })
            }
            var b = this.isRepeat(njArr)
            if (b) {
              return this.$message({
                type: 'error',
                message: '年级不能被重复选中'
              })
            }
            //}
            if (!this.form.BJCZTXInfo.Id || this.form.HDFW == 1) {
              this.form.BJCZTXInfo.ZBSZ = 0
            }

            let form = JSON.parse(JSON.stringify(this.form))
            delete form.BJCZTXInfo.BJCZTXGLNJList
            delete form.BJCZTXInfo.BJCZTXGLNRList
            delete form.BJCZTXInfo.BJCZTXNJJJList
            delete form.BJCZTXInfo.EvaluationObjectAndIndexList
            this.$api.post(
              '/v1/growthsystem/EditClassSet',
              {
                token: this.$store.state.token,
                BJCZTXSubmitInfo: form
              },
              r => {
                if (r.Code === 0) {
                  this.$message({
                    type: 'success',
                    message: r.Message
                  })
                  this.$router.push({
                    path: '/growthsystem/classgrowth'
                  })
                }
              }
            )
          })
          // .catch(r => {});
        } else {
          scrollTo(0, 0)
          return false
        }
      })
    },
    // 验证重复元素，有重复返回true；否则返回false
    isRepeat(arr) {
      var hash = {}
      for (var i in arr) {
        if (hash[arr[i]]) {
          return true
        }
        // 不存在该元素，则赋值为true，可以赋任意值，相应的修改if判断条件即可
        hash[arr[i]] = true
      }
      return false
    },
    cancel() {
      this.$router.push('/growthsystem/classgrowth')
    },
    objectJoin(index) {
      this.objectList = this.activitiesData[index].EvaluationObjectList
      for (let item of this.checkObjectList) {
        this.objectList = this.objectList.map(c => {
          if (
            c.ObjectId === item.ObjectId &&
            c.ActivityId === item.ActivityId
          ) {
            c = item
          }
          return c
        })
      }
      this.checkObjectList = this.checkObjectList.filter(
        c =>
          !this.checkObjectList
            .filter(
              c =>
                c.ActivityId === this.currentActivityId &&
                !this.objectList.map(item => item.ObjectId).includes(c.ObjectId)
            )
            .map(c => c.ObjectId)
            .includes(c.ObjectId)
      )
      this.objectVisible = true
    },
    // 指标参与 处理指标
    indexJoin(index) {
      let activity = this.activitiesData[index]
      if (activity) {
        if (!activity.indexList || activity.indexList.length === 0) {
          this.$api.post(
            '/v1/evaluationobject/GetEvaluationObjectIndexByTop',
            {
              token: this.$store.state.token,
              ObjectIds: activity.EvaluationObjectList.map(c => c.ObjectId)
            },
            r => {
              if (r.Code === 0) {
                activity.indexList = (r.Target || []).map(item => {
                  let value = this.form.BJCZTXInfo?.EvaluationObjectAndIndexList?.find(
                    it => it.HDId === activity.Id && it.GLId === item.IndexId
                  )
                  return {
                    IndexId: item.IndexId,
                    IndexName: item.IndexName,
                    DF: value ? value.DF : '',
                    ActivityId: activity.Id
                  }
                })
                this.indexList = activity.indexList
                this.checkIndexList = activity.checkIndexList || []
                this.activity = activity
                this.indexVisible = true
              }
            }
          )
        } else {
          this.indexList = activity.indexList
          this.checkIndexList = activity.checkIndexList
          this.activity = activity
          this.indexVisible = true
        }
      }
    },
    // 打开指标弹窗
    handdleCYFS(type, index) {
      type = type ? parseInt(type) : 0
      if (type === 2) {
        this.objectJoin(index)
      } else if (type === 3) {
        this.indexJoin(index)
      }
    },
    getResultUrl(filePath, key) {
      this.form.BJCZTXInfo[key] = filePath
    },
    // 指标确认
    indexSubmit() {
      this.activity.checkIndexList = [...this.checkIndexList]
      this.indexVisible = false
    },
    // 获取活动详情 得到对象中的指标
    getActivityInfo(activities) {
      let term = JSON.parse(localStorage.getItem('curTerm'))
      let user = JSON.parse(localStorage.getItem('userinfo'))
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/evaluationactivity/GetEvaluationActivity',
          {
            EvaIds: activities.map(item => item.Id), //	是	字符数组	活动Id数组
            TermId: term.Id, //	是	string	当前学期Id
            TermBeginTime: term.BeginTime, //	是	datetime	学期开始时间
            TermEndTime: term.EndTime, //	是	datetime	学期结束时间
            SchoolId: user.SSZZId, //	是	string	学校Id
            token: user.Token
          },
          res => {
            if (res.Code === 0 && res.Target) {
              // 获取到活动详情
              activities.forEach(item => {
                let act_info = res.Target.find(it => it.Id === item.Id)
                if (act_info.UserEObjectList) {
                  act_info.UserEObjectList.reduce((pre, obj) => {
                    if (typeof obj.EvaIndexList === 'string')
                      obj.EvaIndexList = JSON.parse(obj.EvaIndexList)

                    if (obj.EvaIndexList) {
                      obj.EvaIndexList.forEach(i => {
                        if (!pre.find(it => i.GLId === it.Id)) {
                          //
                        }
                      })
                    }
                    return pre
                  }, [])
                }
              })
              resolve()
            }
          }
        )
      })
    }
  }
}
</script>
<style lang="scss" scoped>
div::v-deep .center {
  display: flex;
  justify-content: center;
  align-items: center;
}

div::v-deep .nopadding {
  .el-input__inner {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
