var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.visible,"width":"60%","top":"1%","center":"","before-close":_vm.closeDialog,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{ref:"formData",attrs:{"model":_vm.formData,"rules":_vm.rules}},[_c('div',{staticClass:"quest-titl-box"},[_c('div',{staticClass:"quest-titl",staticStyle:{"height":"40px"}},[_c('el-form-item',{attrs:{"prop":"WJMC","rules":[
            {
              required: true,
              message: '问卷名称不能为空',
              trigger: 'change'
            },
            {
              min: 1,
              max: 20,
              message: '不能超过20 个字符',
              trigger: 'change'
            }
          ]}},[_c('el-input',{staticClass:"jf-quest-titl",attrs:{"placeholder":"请输入问卷名称"},model:{value:(_vm.formData.WJMC),callback:function ($$v) {_vm.$set(_vm.formData, "WJMC", $$v)},expression:"formData.WJMC"}})],1)],1)]),_c('div',{staticClass:"operate-box",staticStyle:{"padding-bottom":"10px","margin-top":"10px","font-size":"14px"}},[_c('span',{staticClass:"c-g-9",staticStyle:{"margin-left":"10px"}},[_vm._v("所属维度:")]),_c('el-form-item',{staticStyle:{"display":"inline-block","margin-bottom":"unset"},attrs:{"prop":"SSWD","rules":[
          { required: true, message: '请选择所属维度', trigger: 'change' }
        ],"inline-message":true}},[_c('el-cascader',{staticClass:"jf-form",staticStyle:{"margin-left":"5px"},attrs:{"props":{ expandTrigger: 'hover' },"options":_vm.dimensions,"show-all-levels":false,"placeholder":"请选择末级维度","size":"small"},on:{"change":_vm.handleWdSelected},model:{value:(_vm.formData.SSWD),callback:function ($$v) {_vm.$set(_vm.formData, "SSWD", $$v)},expression:"formData.SSWD"}})],1)],1),_vm._l((_vm.formData.WJTMBList),function(item,index){return _c('div',{key:index,staticClass:"whole-tit-mianbox"},[_c('div',{staticStyle:{"font-size":"0px","box-sizing":"border-box","padding-bottom":"15px"}},[_c('div',{staticClass:"whole-tit-topleft",staticStyle:{"vertical-align":"top","position":"relative","top":"14px","height":"44px"}},[_vm._v(" "+_vm._s(index + 1)+". ")]),_c('div',{staticClass:"whole-tit-toprig",staticStyle:{"height":"44px"}},[_c('el-form-item',{attrs:{"prop":'WJTMBList[' + index + '].TMMC',"rules":[
              { required: true, message: '请输入题目', trigger: 'change' },
              {
                min: 1,
                max: 50,
                message: '不能超过50 个字符',
                trigger: 'change'
              }
            ]}},[_c('el-input',{staticClass:"jf-textarea-wj jf-form",attrs:{"type":"textarea","autosize":"","placeholder":"请输入不超过50字"},model:{value:(item.TMMC),callback:function ($$v) {_vm.$set(item, "TMMC", $$v)},expression:"item.TMMC"}})],1)],1),_c('div',{staticClass:"jf_sele-l-box",staticStyle:{"vertical-align":"top","position":"relative","top":"3px","height":"44px"}},[_c('el-form-item',{attrs:{"prop":'WJTMBList[' + index + '].SSLB',"rules":[
              {
                required: true,
                message: '请选择题目类型',
                trigger: 'change'
              }
            ]}},[_c('el-select',{staticClass:"jf-form",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","size":"small"},model:{value:(item.SSLB),callback:function ($$v) {_vm.$set(item, "SSLB", $$v)},expression:"item.SSLB"}},_vm._l((_vm.SSLB),function(item){return _c('el-option',{key:item.Key,attrs:{"label":item.Value,"value":item.Key}})}),1)],1)],1),(item.SSLB === 2)?_c('div',{staticClass:"titdel-add-box",staticStyle:{"vertical-align":"top","position":"relative","top":"9px"}},[_vm._v(" 最多能选的项数： "),_c('div',{staticClass:"digit-cutl",on:{"click":function($event){return _vm.clickBtnChangeInput(2, item.Id)}}}),_c('div',{staticClass:"digit-textl"},[_c('div',{staticClass:"jf_sele-l-box"},[_c('el-form-item',{staticClass:"jf_wj_dx",attrs:{"prop":'WJTMBList[' + index + '].JDXX',"rules":[
                  {
                    required: true,
                    message: '数目不能为空',
                    trigger: 'change'
                  },
                  {
                    type: 'number',
                    message: '数目必须为数字',
                    trigger: 'change'
                  }
                ]}},[_c('el-input',{staticClass:"jf_digit-textl-input",attrs:{"type":"number"},model:{value:(item.JDXX),callback:function ($$v) {_vm.$set(item, "JDXX", _vm._n($$v))},expression:"item.JDXX"}})],1)],1)]),_c('div',{staticClass:"digit-addl",staticStyle:{"z-index":"8000","position":"relative"},on:{"click":function($event){return _vm.clickBtnChangeInput(1, item.Id)}}}),_c('div',{staticClass:"tab-wz-buttl",staticStyle:{"font-size":"14px","float":"right"}},[_c('a',{staticClass:"tab-wz-butta c-red",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.delItem(index)}}},[_vm._v("X 删除此题")])])]):_vm._e()]),(item.SSLB !== 3)?_c('div',{staticClass:"smtit-whole-box"},[_c('div',{staticClass:"smtit-box"},_vm._l((_vm.formData.WJFXBList.filter(
              function (c) { return c.TMId === item.Id; }
            )),function(fx,i){return _c('div',{key:i,staticClass:"smtit-cent"},[_vm._v(" "+_vm._s(_vm.ZM[i])+". "),(fx.FXLX === 1)?_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"prop":'WJFXBList[' + i + '].TMMC',"rules":[
                { required: true, message: '请输入答案', trigger: 'change' },
                {
                  min: 1,
                  max: 20,
                  message: '不能超过20 个字符',
                  trigger: 'change'
                }
              ]}},[_c('el-input',{staticClass:"jf-form",staticStyle:{"width":"300px","margin-left":"5px"},attrs:{"size":"small","placeholder":"请输入内容"},model:{value:(fx.TMMC),callback:function ($$v) {_vm.$set(fx, "TMMC", $$v)},expression:"fx.TMMC"}})],1):_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("其他")]),_vm._v(" 分数： "),_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"prop":'WJFXBList[' + i + '].DF',"rules":[
                { required: true, message: '请输入分数', trigger: 'change' },
                {
                  validator: _vm.validateNumber,
                  message: '分数必须为数字',
                  trigger: 'change'
                }
              ]}},[_c('el-input',{staticClass:"jf-form",staticStyle:{"width":"90px"},attrs:{"size":"small","placeholder":"请输入"},model:{value:(fx.DF),callback:function ($$v) {_vm.$set(fx, "DF", $$v)},expression:"fx.DF"}})],1),_c('div',{staticClass:"tab-wz-buttl",staticStyle:{"margin-left":"30px"}},[_c('a',{staticClass:"tab-wz-butta c-red",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.delFX(fx.Id)}}},[_vm._v("删除")])])],1)}),0),(item.SSLB !== 3)?_c('div',{staticClass:"smtit-butt-box"},[_c('div',{staticClass:"tab-wz-buttl",staticStyle:{"margin-right":"20px"}},[_c('a',{staticClass:"tab-wz-butta c-guree",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.addFX(item.Id, 1)}}},[_vm._v("+添加普通选项")])]),_c('div',{staticClass:"tab-wz-buttl"},[(
                _vm.formData.WJFXBList.findIndex(
                  function (c) { return c.TMId === item.Id && c.FXLX === 2; }
                ) === -1
              )?_c('a',{staticClass:"tab-wz-butta c-guree",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.addFX(item.Id, 2)}}},[_vm._v("+添加“其他”选项")]):_vm._e()])]):_vm._e()]):_vm._e()])})],2),_c('div',{staticClass:"bigtit-add-box"},[_c('div',{staticClass:"tab-wz-buttl"},[_c('a',{staticClass:"tab-wz-butta c-guree",attrs:{"href":"javascript:;"},on:{"click":_vm.addItem}},[_vm._v("+ 新增问题")])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitting},on:{"click":_vm.confirmDialog}},[_vm._v("确 定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }