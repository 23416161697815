var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline-block"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"disabled":_vm.disabled,"action":_vm.uploadURL,"show-file-list":false,"on-success":_vm.handleAvatarSuccess,"before-upload":_vm.beforeAvatarUpload}},[(_vm.resultUrl)?_c('img',{key:_vm.resultUrl,style:({
        width: _vm.width + 'px',
        height: _vm.height + 'px',
        display: 'block',
        cursor: 'pointer',
        'border-radius': _vm.radius + '%',
        'margin-left': _vm.left + 'px',
        'margin-top': _vm.top + 'px'
      }),attrs:{"src":_vm.resultUrl}}):(_vm.html)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.html)}}):(_vm.button)?_vm._t("default"):_c('i',{staticClass:"el-icon-plus"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }