var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{ref:"dialog",attrs:{"top":"5vh","width":"60%","visible":_vm.value,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.value=$event}}},[_c('div',{staticClass:"center"},[_c('div',{staticClass:"activity"},[_c('div',{staticClass:"activity-item center"},[_c('div',{staticClass:"activity-title"},[_vm._v("活动名称")]),_c('div',{staticClass:"activity-content"},[_vm._v(_vm._s(_vm.form.PJHDMC))])]),_c('div',{staticClass:"activity-item center"},[_c('div',{staticClass:"activity-title"},[_vm._v("动被评人")]),_c('div',{staticClass:"activity-content vertical"},[_vm._v(" "+_vm._s((_vm.baseInfo.BeEvaluationObjects ? _vm.baseInfo.BeEvaluationObjects.filter( function (c) { return c.Key === _vm.form.BPJDXLX; } ).map(function (c) { return c.Value; }) : [] ).join(""))+" "),(_vm.form.BPJDXLX === 2)?_c('div',{staticClass:"center activity-content-expand"},_vm._l(((_vm.baseInfo.RoleList
                ? _vm.baseInfo.RoleList
                : []
              ).filter(function (c) { return (_vm.form.roleList ? _vm.form.roleList : []).includes(c.Id); }
              )),function(item){return _c('el-tag',{key:item.Id,attrs:{"type":"primary","size":"small"}},[_vm._v(_vm._s(item.Name))])}),1):_vm._e()])]),_c('div',{staticClass:"activity-item center"},[_c('div',{staticClass:"activity-title"},[_vm._v("活动参评人")]),_c('div',{staticClass:"activity-content"},[_vm._v(" "+_vm._s((_vm.baseInfo.EvaluationObjects ? _vm.baseInfo.EvaluationObjects : []) .filter(function (c) { return c.Key === _vm.form.HDCPR; }) .map(function (c) { return c.Value; }) .join(""))+" ")])]),_c('div',{staticClass:"activity-item center"},[_c('div',{staticClass:"activity-title"},[_vm._v("活动形式")]),_c('div',{staticClass:"activity-content"},_vm._l(((_vm.baseInfo.EvaluationActivityStyles
              ? _vm.baseInfo.EvaluationActivityStyles
              : []
            ).filter(function (c) { return (_vm.form.hdxsarry ? _vm.form.hdxsarry : []).includes(c.Key); }
            )),function(item){return _c('el-tag',{key:item.Key,attrs:{"type":"primary","size":"small"}},[_vm._v(_vm._s(item.Value))])}),1)]),_c('div',{staticClass:"activity-item center"},[_c('div',{staticClass:"activity-title"},[_vm._v("小组参与")]),_c('div',{staticClass:"activity-content"},[_vm._v(" "+_vm._s((_vm.baseInfo.GroupParticipationTypes ? _vm.baseInfo.GroupParticipationTypes : [] ) .filter(function (c) { return c.Key === _vm.form.XZCYFS; }) .map(function (c) { return c.Value; }) .join(""))+" ")])]),_c('div',{staticClass:"activity-item center"},[_c('div',{staticClass:"activity-title"},[_vm._v("加载课表")]),_c('div',{staticClass:"activity-content"},[_vm._v(" "+_vm._s((_vm.baseInfo.CourseTableTypes ? _vm.baseInfo.CourseTableTypes : []) .filter(function (c) { return c.Key === _vm.form.JZKBLX; }) .map(function (c) { return c.Value; }) .join(""))+" ")])]),_c('div',{staticClass:"activity-item center"},[_c('div',{staticClass:"activity-title"},[_vm._v("评价方式")]),_c('div',{staticClass:"activity-content"},[_vm._v(" "+_vm._s((_vm.baseInfo.EvalutionFormat ? _vm.baseInfo.EvalutionFormat : []) .filter(function (c) { return c.Key === _vm.form.PJFS; }) .map(function (c) { return c.Value; }) .join(""))+" ")])]),_c('div',{staticClass:"activity-item center"},[_c('div',{staticClass:"activity-title"},[_vm._v("评价频次")]),_c('div',{staticClass:"activity-content"},[_vm._v(" "+_vm._s((_vm.baseInfo.EvFrequencys ? _vm.baseInfo.EvFrequencys : []) .filter(function (c) { return c.Key === _vm.form.DCPJSZ; }) .map(function (c) { return c.Value; }) .join(""))+" ")])]),_c('div',{staticClass:"activity-item center"},[_c('div',{staticClass:"activity-title"},[_vm._v("选择按钮")]),_c('div',{staticClass:"activity-content"},_vm._l(((_vm.baseInfo.Btns ? _vm.baseInfo.Btns : []).filter(function (c) { return (_vm.form.btnList ? _vm.form.btnList : []).includes(c.ANLX); }
            )),function(item){return _c('el-tag',{key:item.Id,attrs:{"type":"primary","size":"small"}},[_vm._v(_vm._s(item.ANMC))])}),1)]),_c('div',{staticClass:"activity-item center"},[_c('div',{staticClass:"activity-title"},[_vm._v("选择报表模版")]),_c('div',{staticClass:"activity-content"})]),_c('div',{staticClass:"activity-item center"},[_c('div',{staticClass:"activity-title"},[_vm._v("设置晋级")]),_c('div',{staticClass:"activity-content"})]),_c('div',{staticClass:"activity-item center"},[_c('div',{staticClass:"activity-title"},[_vm._v("设置评价语模板")]),_c('div',{staticClass:"activity-content"})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }