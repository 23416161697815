<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <!--表格区开始-->

      <div class="operate-box">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <el-form-item label="学段">
            <el-select
              v-model="searchForm.SSXD"
              placeholder="学段"
              @change="xdselectchange"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option
                v-for="sxd in SchoolPeriod"
                :key="sxd.Id"
                :label="sxd.Name"
                :value="sxd.Id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级">
            <el-select
              v-model="searchForm.gradeId"
              placeholder="年级"
              @change="gradechange"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in gradelist"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班级">
            <el-select v-model="searchForm.classId" placeholder="班级">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in classlist"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否毕业">
            <el-select
              v-model="searchForm.isby"
              style="width:120px"
              placeholder="是否毕业"
            >
              <el-option label="全部" value="0"></el-option>
              <el-option label="未毕业" value="2"></el-option>
              <el-option label="已毕业" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否启用">
            <el-select
              v-model="searchForm.SYZT"
              style="width:120px"
              placeholder="是否启用"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option label="启用" :value="1"></el-option>
              <el-option label="禁用" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="学生姓名/学号">
            <el-input
              v-model="searchForm.serach"
              placeholder="学生姓名/学号"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="searchSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-er-box" style="white-space: pre-wrap; height: auto;">
        <el-button type="primary" icon="el-icon-edit" @click="openadd"
          >新增</el-button
        >

        <el-button
          type="primary"
          icon="el-icon-folder-delete"
          @click="updatestudenttrueorfalse('2')"
          >禁用</el-button
        >

        <el-button
          type="primary"
          icon="el-icon-folder-checked"
          @click="updatestudenttrueorfalse('1')"
          >启用</el-button
        >

        <el-button
          type="primary"
          icon="el-icon-tickets"
          @click="batchclassassignment"
          >分班</el-button
        >

        <el-button type="primary" icon="el-icon-delete" @click="batchdeleteRow"
          >删除</el-button
        >

        <el-button
          type="primary"
          icon="el-icon-edit"
          @click="ResetParentPassword(1)"
          >重置密码</el-button
        >

        <el-button
          type="primary"
          @click="openuploadstudent"
          icon="el-icon-top-right"
          >导入数据</el-button
        >

        <el-button
          type="primary"
          icon="el-icon-bottom-right"
          @click="exportschoolstudentdata"
          >导出数据</el-button
        >

        <!--<el-button type="primary" icon="el-icon-bottom-right">导出模板</el-button>-->

        <el-button
          type="primary"
          icon="el-icon-setting"
          @click="qrCodeFormVisible = true"
          >设置二维码样式</el-button
        >

        <el-button
          type="primary"
          icon="el-icon-bottom-right"
          @click="exportbyqrCodeimage"
          >导出二维码</el-button
        >

        <!--<el-button type="primary" icon="el-icon-bottom-right"
                                          >导出校牌</el-button
                                        >-->

        <el-button
          type="primary"
          icon="el-icon-bottom-right"
          @click="exportbyoriginalzp"
          >导出照片原图</el-button
        >

        <el-button
          type="primary"
          icon="el-icon-top-right"
          @click="openuploadrfid"
          >导入Rfcard</el-button
        >
        <el-button type="primary" :loading="exportPDFLoading" @click="exportPDF"
          >导出家长</el-button
        >
      </div>

      <br />
      <el-table
        :data="tableData"
        class="jf-table"
        border
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        style="width: 100%;"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="XH" label="学生账号"></el-table-column>
        <el-table-column prop="XJH" label="学籍号"></el-table-column>
        <el-table-column
          prop="XM"
          width="100"
          label="学生姓名"
        ></el-table-column>
        <el-table-column prop="KH" label="Rf_Cardid"></el-table-column>
        <el-table-column prop="ClassName" label="班级"></el-table-column>
        <el-table-column prop="LXDH" label="家长电话"></el-table-column>
        <el-table-column prop="XBM" width="60" label="性别">
          <template slot-scope="scope">
            <p v-if="scope.row.XBM == '1'">男</p>
            <p v-else-if="scope.row.XBM == '2'">女</p>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="oddupdatestate(scope.row, 1)"
              v-if="scope.row.SYZT == 2"
              >启用</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="oddupdatestate(scope.row, 2)"
              v-else
              >禁用</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="editRow(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="deleteRow(scope.$index, scope.row)"
              >删除</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="ResetParentPassword(scope.row)"
              >重置家长密码</el-button
            >
            <!--<el-button type="text" size="mini" @click="classassignment(scope.$index, scope.row)">分班</el-button>-->
            <!--<el-button
                                                  type="text"
                                                  size="mini"
                                                  @click="classassignment(scope.row)"
                                                  >分班</el-button
                                                >-->
          </template>
        </el-table-column>
      </el-table>

      <br />
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="rowcount"
        ></el-pagination>
      </div>
      <!--表格区结束-->
    </div>
    <el-dialog
      title="导入学生"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleImportClose"
    >
      <el-form>
        <el-form-item label="导入：">
          <el-upload
            class="upload-demo"
            ref="upload"
            accept=".xls, .xlsx"
            :action="uploadURL"
            :on-change="upload"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="模板：">
          <a
            :href="`${publicPath}template/学生导入模板.xlsx`"
            class="essay-bt-textbunn"
            target="_blank"
            style="margin-left:0!important;"
            >学生导入模板下载</a
          >
        </el-form-item>
        <el-form-item>
          <el-button @click="handleImportClose()">取 消</el-button>
        </el-form-item>
        <div class="py-text" v-if="errormessage != ''">{{ errormessage }}</div>
      </el-form>
    </el-dialog>
    <el-dialog
      title="导入Rfcard"
      :visible.sync="dialogRFIDVisible"
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleImportRFIdClose"
    >
      <el-form>
        <el-form-item label="导入：">
          <el-upload
            class="upload-demo"
            ref="upload"
            accept=".xls, .xlsx"
            :action="uploadURL"
            :on-change="uploadrfid"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="模板：">
          <a
            :href="`${publicPath}template/RFId导入模板.xls`"
            class="essay-bt-textbunn"
            target="_blank"
            style="margin-left:0!important;"
            >导入Rfcard模板下载</a
          >
        </el-form-item>
        <el-form-item>
          <el-button @click="handleImportRFIdClose()">取 消</el-button>
        </el-form-item>

        <div class="py-text" v-if="errormessage != ''">{{ errormessage }}</div>
      </el-form>
    </el-dialog>
    <!--弹出框-->
    <el-dialog
      title="二维码设置"
      :visible.sync="qrCodeFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="EWMSZInfo" label-width="80px">
        <div :class="qrCodeborderclass">
          <!--"hs-sty"红色-->
          <div class="twor-box hs-back">
            <img class="twor-box-img" :src="qrCodeimg" />
            <div
              :class="qrsize"
              v-if="EWMSZInfo.EWMYS != 3 && EWMSZInfo.EWMYS != '3'"
            >
              <span class="twor-mian-span">XXXX级XX班</span>
              <br v-if="EWMSZInfo.EWMYS == 4 && EWMSZInfo.EWMYS == '4'" />
              <span class="twor-mian-span">学生姓名</span>
            </div>
          </div>
          <div class="xzsty-box">
            <div
              class="tablil-xz tablil-xz-sty"
              v-if="EWMSZInfo.EWMYS == 3 || EWMSZInfo.EWMYS == '3'"
              @click="boderselect(3)"
            ></div>
            <div class="tablil-xz" v-else @click="boderselect(3)"></div>
            <div
              @click="boderselect(3)"
              class="tablil-xz-name"
              style="font-size: 14px;"
            >
              只要中间的二维码
            </div>
            <div
              class="tablil-xz tablil-xz-sty"
              v-if="EWMSZInfo.EWMYS == 4 || EWMSZInfo.EWMYS == '4'"
              @click="boderselect(4)"
            ></div>
            <div class="tablil-xz" v-else @click="boderselect(4)"></div>
            <div
              @click="boderselect(4)"
              class="tablil-xz-name"
              style="font-size: 14px;"
            >
              中间的二维码及班级姓名列二排
            </div>
            <br />
            <div
              class="tablil-xz tablil-xz-sty"
              v-if="EWMSZInfo.EWMYS == 2 || EWMSZInfo.EWMYS == '2'"
              @click="boderselect(2)"
            ></div>
            <div class="tablil-xz" v-else @click="boderselect(2)"></div>
            <div
              @click="boderselect(2)"
              class="tablil-xz-name"
              style="font-size: 14px;"
            >
              中间的二维码及一排字样班级姓名
            </div>
            <div
              class="tablil-xz tablil-xz-sty"
              v-if="EWMSZInfo.EWMYS == 1 || EWMSZInfo.EWMYS == '1'"
              @click="boderselect(1)"
            ></div>
            <div class="tablil-xz" v-else @click="boderselect(1)"></div>
            <div
              @click="boderselect(1)"
              class="tablil-xz-name"
              style="font-size: 14px;"
            >
              保留边框及一排字
            </div>
          </div>

          <div class="xj-box">
            <div class="xj-name">
              <span class="xj-name-span">边框颜色：</span>
              <div class="diy" style="z-index: 99999;">
                <div :class="bordercolorclass">
                  <div class="hs-back"></div>
                  <span>{{ bordercolorText }}</span>
                  <img src="../../style/images/icon_col_xl.png" />
                </div>
                <ul>
                  <li
                    class="hs-sty"
                    @click="bordercolorselect('红色', 'hs-sty', 'OrangeRed')"
                  >
                    <!--红色=“OrangeRed”-->
                    <div class="hs-back"></div>
                    <span>红色</span>
                  </li>
                  <li
                    class="ls-sty"
                    @click="bordercolorselect('绿色', 'ls-sty', 'Chartreuse')"
                  >
                    <!--绿色=“Chartreuse”-->
                    <div class="hs-back"></div>
                    <span>绿色</span>
                  </li>
                  <li
                    class="lss-sty"
                    @click="bordercolorselect('黑色', 'lss-sty', 'Black')"
                  >
                    <!--黑色=“black”-->
                    <div class="hs-back"></div>
                    <span>黑色</span>
                  </li>
                  <li
                    class="ys-sty"
                    @click="bordercolorselect('黄色', 'ys-sty', 'Gold')"
                  >
                    <!--黄色=“Gold”-->
                    <div class="hs-back"></div>
                    <span>黄色</span>
                  </li>
                  <li
                    class="yss-sty"
                    @click="bordercolorselect('橙色', 'yss-sty', 'DarkOrange')"
                  >
                    <!--橙色=“DarkOrange”-->
                    <div class="hs-back"></div>
                    <span>橙色</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="xj-name">
              <span class="xj-name-span">二维码颜色：</span>
              <div class="diy" style="z-index: 99998;">
                <div :class="qrCodecolorclass">
                  <div class="hs-back"></div>
                  <span>{{ qrCodecolorText }}</span>
                  <img src="../../style/images/icon_col_xl.png" />
                </div>
                <ul>
                  <li
                    class="hs-sty"
                    @click="
                      qrCodecolorselect(
                        '红色',
                        'hs-sty',
                        'img_l_OrangeRed.png',
                        'OrangeRed'
                      )
                    "
                  >
                    <!--红色=“OrangeRed”-->
                    <div class="hs-back"></div>
                    <span>红色</span>
                  </li>
                  <li
                    class="ls-sty"
                    @click="
                      qrCodecolorselect(
                        '绿色',
                        'ls-sty',
                        'img_l_Chartreuse.png',
                        'Chartreuse'
                      )
                    "
                  >
                    <!--绿色=“Chartreuse”-->
                    <div class="hs-back"></div>
                    <span>绿色</span>
                  </li>
                  <li
                    class="lss-sty"
                    @click="
                      qrCodecolorselect(
                        '黑色',
                        'lss-sty',
                        'code-img.jpg',
                        'Black'
                      )
                    "
                  >
                    <!--黑色=“black”-->
                    <div class="hs-back"></div>
                    <span>黑色</span>
                  </li>
                  <li
                    class="ys-sty"
                    @click="
                      qrCodecolorselect(
                        '黄色',
                        'ys-sty',
                        'img_l_Glod.png',
                        'Gold'
                      )
                    "
                  >
                    <!--黄色=“Gold”-->
                    <div class="hs-back"></div>
                    <span>黄色</span>
                  </li>
                  <li
                    class="yss-sty"
                    @click="
                      qrCodecolorselect(
                        '橙色',
                        'hs-back',
                        'img_l_DarkOrange.png',
                        'DarkOrange'
                      )
                    "
                  >
                    <!--橙色=“DarkOrange”-->
                    <div class="hs-back"></div>
                    <span>橙色</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="xj-name">
              <span class="xj-name-span">字体颜色：</span>
              <div class="diy" style="z-index: 99993;">
                <div :class="sizecolorclass">
                  <div class="hs-back"></div>
                  <span>{{ sizecolorText }}</span>
                  <img src="../../style/images/icon_col_xl.png" />
                </div>
                <ul>
                  <li
                    class="hs-sty"
                    @click="sizecolorselect('红色', 'hs-sty', 'OrangeRed')"
                  >
                    <!--红色=“OrangeRed”-->
                    <div class="hs-back"></div>
                    <span>红色</span>
                  </li>
                  <li
                    class="ls-sty"
                    @click="sizecolorselect('绿色', 'ls-sty', 'Chartreuse')"
                  >
                    <!--绿色=“Chartreuse”-->
                    <div class="hs-back"></div>
                    <span>绿色</span>
                  </li>
                  <li
                    class="lss-sty"
                    @click="sizecolorselect('黑色', 'lss-sty', 'Black')"
                  >
                    <!--黑色=“black”-->
                    <div class="hs-back"></div>
                    <span>黑色</span>
                  </li>
                  <li
                    class="ys-sty"
                    @click="sizecolorselect('黄色', 'ys-sty', 'Gold')"
                  >
                    <!--黄色=“Gold”-->
                    <div class="hs-back"></div>
                    <span>黄色</span>
                  </li>
                  <li
                    class="yss-sty"
                    @click="sizecolorselect('橙色', 'yss-sty', 'DarkOrange')"
                  >
                    <!--橙色=“DarkOrange”-->
                    <div class="hs-back"></div>
                    <span>橙色</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="qrCodeFormVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="qrCodesubmit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 导出家长账号进度条 -->
    <div class="exportPDFPercentage" v-show="showProgress">
      <el-progress
        :text-inside="true"
        :stroke-width="26"
        status="success"
        :percentage="exportPDFPercentage"
      ></el-progress>
    </div>
    <!-- pdf导出前的容器 -->
    <div class="pdf-box">
      <div class="pdf-tables">
        <el-table
          :data="pdfData"
          class="pdfTable"
          border
          style="width: 1000px;"
        >
          <el-table-column prop="XM" label="学生姓名"></el-table-column>
          <el-table-column prop="JZZH" label="家长账号"></el-table-column>
          <el-table-column prop="SJHM" label="家长手机号"></el-table-column>
        </el-table>
      </div>
    </div>
    <!-- <div class="pdf-box">
      <div class="pdf-tables" v-for="item in pdfDataAll" :key="item.NJSZ">
        <el-table
          v-for="it in item.NJSJ"
          :key="it.BH"
          :data="it.BJSJ"
          :class="`letter${item.NJSZ}${it.BH}pdf-table`"
          border
          style="width: 1000px;"
        >
          <el-table-column prop="XM" label="学生姓名"></el-table-column>
          <el-table-column prop="JZZH" label="家长账号"></el-table-column>
          <el-table-column prop="SJHM" label="家长手机号"></el-table-column>
        </el-table>
      </div>
    </div> -->
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import XLSX from 'xlsx'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export default {
  computed: {
    //文件的上传路径
    uploadURL: function() {
      return '/file/file/fileservice/UploadFile?token=' + this.token
    }
  },
  name: 'StudentList',
  data() {
    return {
      excelData: {
        results: null
      },
      errormessage: '',
      publicPath: process.env.BASE_URL,
      token: this.$store.state.token,
      gradelist: [],
      classlist: [],
      schoolOrgList: [],
      searchForm: {
        schoolId: '',
        SSXD: 0,
        gradeId: '0',
        classId: '0',
        isby: '0',
        serach: '',
        SYZT: 0 //启用禁用
      },
      tableData: [],
      currentPage: 0,
      pagesize: 10,
      rowcount: 0,
      multipleSelection: [],
      munlipleStudentId: '',
      munlipleStudentIndex: [],
      dialogFormVisible: false,
      dialogRFIDVisible: false,
      formLabelWidth: '120px',
      dialogForm: {
        titleName: '导入框',
        pathUrl: ''
      },
      // 设置二维码
      qrCodeFormVisible: false,
      borderno: false,
      borderyes: true,
      // 二维码框
      qrCodeborderclass: 'popo-twoe-main lss-sty',
      qrCodeimg: require('@/style/images/code-img.jpg'),
      qrsize: 'twor-mian lss-sty',
      // 选择颜色
      bordercolorclass: 'xlx-box lss-sty',
      bordercolorText: '黑色',
      qrCodecolorclass: 'xlx-box lss-sty',
      qrCodecolorText: '黑色',
      sizecolorclass: 'xlx-box lss-sty',
      sizecolorText: '黑色',
      // 分班
      dialogSeClassVisible: false,
      // 二维码提交参数
      EWMSZInfo: {
        Id: 0,
        SSXX: '',
        SFSC: 0,
        SYZT: 0,
        EWMYS: 1,
        SZLX: 1,
        BKYSM: 'Black',
        EWMYSM: 'Black',
        ZTYSM: 'Black'
      },
      fileServerApiPath: '',
      webFilePath: '',
      userName: '',
      userId: '',
      gradeId: '',
      classId: '',
      selectstudent: '',
      SchoolPeriod: [],
      qrcodefontset: [
        { name: '红色', classname: 'hs-sty', color: 'OrangeRed' },
        { name: '绿色', classname: 'ls-sty', color: 'Chartreuse' },
        { name: '黑色', classname: 'lss-sty', color: 'Black' },
        { name: '黄色', classname: 'ys-sty', color: 'Gold' },
        { name: '橙色', classname: 'yss-sty', color: 'DarkOrange' }
      ],
      qrcodeborderset: [
        { name: '红色', classname: 'hs-sty', color: 'OrangeRed' },
        { name: '绿色', classname: 'ls-sty', color: 'Chartreuse' },
        { name: '黑色', classname: 'lss-sty', color: 'Black' },
        { name: '黄色', classname: 'ys-sty', color: 'Gold' },
        { name: '橙色', classname: 'yss-sty', color: 'DarkOrange' }
      ],
      qrcodecolorset: [
        {
          name: '红色',
          classname: 'hs-sty',
          color: 'OrangeRed',
          img: 'img_l_OrangeRed.png'
        },
        {
          name: '绿色',
          classname: 'ls-sty',
          color: 'Chartreuse',
          img: 'img_l_Chartreuse.png'
        },
        {
          name: '黑色',
          classname: 'lss-sty',
          color: 'Black',
          img: 'code-img.jpg'
        },
        {
          name: '黄色',
          classname: 'ys-sty',
          color: 'Gold',
          img: 'img_l_Glod.png'
        },
        {
          name: '橙色',
          classname: 'yss-sty',
          color: 'DarkOrange',
          img: 'img_l_DarkOrange.png'
        }
      ],
      pdfDataAll: [],
      pdfData: [],
      count: 0,
      curCount: 0,
      exportPDFLoading: false,
      showProgress: false,
      exportPDFPercentage: 0
    }
  },
  created: function() {
    // 当前用户
    const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    this.EWMSZInfo.SSXX = userinfo.SSZZId
    this.searchForm.schoolId = userinfo.SSZZId
    this.schoolId = userinfo.SSZZId
    this.fileServerApiPath = userinfo.fileServerApiPath
    this.webFilePath = userinfo.webFilePath
    this.userName = userinfo.DLM
    this.userId = userinfo.Id

    // 获取学生
    const pagerInfo = { pageIndex: 0, pageSize: this.pagesize }

    this.$api.post(
      'v1/student/GetXSXXBList',
      {
        schoolId: this.searchForm.schoolId,
        PagerInfo: pagerInfo,
        token: this.token
      },
      r => {
        if (r.Code === 0) {
          this.tableData = r.Target
          this.currentPage = r.PagerInfo.PageIndex
          this.pagesize = r.PagerInfo.PageSize
          this.rowcount = r.PagerInfo.RowCount
        }
      }
    )
    // 获取学校年级
    this.$api.post(
      'v1/accountmanage/GetSchoolOrg',
      { SchoolId: this.searchForm.schoolId, token: this.token },
      r => {
        if (r.Code === 0) {
          this.schoolOrgList = r.Target || []
          this.SchoolPeriod = this.schoolOrgList.reduce((pre, cur) => {
            if (!pre.find(item => item.Id === cur.SSXDLX))
              pre.push({
                Name:
                  cur.SSXDLX === 1
                    ? '小学'
                    : cur.SSXDLX === 2
                    ? '初中'
                    : '高中',
                Id: cur.SSXDLX
              })
            return pre
          }, [])
          this.gradelist = this.schoolOrgList
            .filter(c => c.SSXDLX === this.searchForm.SSXD)
            .sort(c => c.GradeNumber)

          this.xdselectchange(this.searchForm.SSXD)
        }
      }
    )
  },
  mounted: function() {
    //获取二维码设置
    this.getqrcodeset()
  },
  methods: {
    upload(file) {
      let files = { 0: file.raw }
      this.readExcel(files)
    },
    openuploadstudent: function() {
      this.errormessage = ''
      this.dialogFormVisible = true
    },
    openuploadrfid: function() {
      this.errormessage = ''
      this.dialogRFIDVisible = true
    },
    readExcel: function(files) {
      if (files.length <= 0) {
        //如果没有文件名
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      const fileReader = new FileReader()
      fileReader.onload = e => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'array' })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const results = XLSX.utils.sheet_to_json(worksheet, { raw: false })
        this.excelData.results = results
        this.submitimportdata(results)
        this.$refs.upload.value = ''
      }
      fileReader.readAsArrayBuffer(files[0])
    },
    submitimportdata: function(readdata) {
      if (readdata.length <= 0) {
        this.$message({
          type: 'info',
          message: '上传文件中无正确数据!'
        })
        return
      }
      this.importdata = []
      for (var i = 0; i < readdata.length; i++) {
        var obj = {
          StudentName: readdata[i].学生姓名,
          StudentSex: readdata[i].性别,
          IdCardNo: readdata[i].身份证号,
          XJH: readdata[i].学籍号,
          MZM: readdata[i].民族,
          LXDH: readdata[i].家长电话,
          JG: readdata[i].籍贯,
          Birthday: readdata[i].出生日期,
          FamilyAddress: readdata[i].家庭地址,
          RFID: readdata[i].RfCard卡号,
          ClassName: readdata[i].班级
        }
        this.importdata.push(obj)
      }
      this.$api.post(
        'v1/student/ImportSchoolStudent',
        {
          DataList: this.importdata,
          SchoolId: this.schoolId,
          CJR: this.userId,
          token: this.token
        },
        r => {
          this.errormessage = r.Message
          if (r.Code == 0) {
            this.handleSizeChange()
          }
        }
      )
    },
    handleImportClose: function() {
      this.errormessage = ''
      this.dialogFormVisible = false
    },
    uploadrfid(file) {
      let files = { 0: file.raw }
      this.readrfidExcel(files)
    },
    readrfidExcel: function(files) {
      if (files.length <= 0) {
        //如果没有文件名
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      const fileReader = new FileReader()
      fileReader.onload = e => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'array' })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const results = XLSX.utils.sheet_to_json(worksheet, { raw: false })
        this.excelData.results = results
        this.submitrfidimportdata(results)
        this.$refs.upload.value = ''
      }
      fileReader.readAsArrayBuffer(files[0])
    },
    submitrfidimportdata: function(readdata) {
      if (readdata.length <= 0) {
        this.$message({
          type: 'info',
          message: '上传文件中无正确数据!'
        })
        return
      }
      this.importdata = []
      for (var i = 0; i < readdata.length; i++) {
        var obj = {
          Id: readdata[i].学生Id,
          OrganizationName: readdata[i].学生班级,
          XM: readdata[i].学生姓名,
          Rf_Cardid: readdata[i].Rf_Cardid
        }
        this.importdata.push(obj)
      }
      this.$api.post(
        'v1/student/ImportSchoolStudentByIdCode',
        {
          DataList: this.importdata,
          token: this.token
        },
        r => {
          this.errormessage = r.Message
          if (r.Code == 0) {
            this.handleSizeChange()
          }
        }
      )
    },
    handleImportRFIdClose: function() {
      this.errormessage = ''
      this.dialogRFIDVisible = false
    },
    selectGrade(data) {
      this.gradeId = data
    },
    selectclass: function(data) {
      this.classId = data.Id
    },
    handleSizeChange(e) {
      // console.log(`每页 ${val} 条`);
      this.pagesize = e || 10
      const pagerInfo = { pageIndex: 0, pageSize: this.pagesize }

      this.$api.post(
        'v1/student/GetXSXXBList',
        {
          SSXD: this.searchForm.SSXD,
          SchoolId: this.searchForm.schoolId,
          GradeId: this.searchForm.gradeId,
          ClassId: this.searchForm.classId,
          IsBY: this.searchForm.isby,
          SYZT: this.searchForm.SYZT,
          Search: this.searchForm.serach,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target
            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          }
        }
      )
    },
    handleCurrentChange(val) {
      const pagerInfo = { pageIndex: val, pageSize: this.pagesize }

      this.$api.post(
        'v1/student/GetXSXXBList',
        {
          SchoolId: this.searchForm.schoolId,
          GradeId: this.searchForm.gradeId,
          ClassId: this.searchForm.classId,
          IsBY: this.searchForm.isby,
          SYZT: this.searchForm.SYZT,
          Search: this.searchForm.serach,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target
            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          }
        }
      )
    },
    // 学段选择
    xdselectchange(val) {
      if (val === 0) {
        this.gradelist = this.schoolOrgList
      } else {
        this.gradelist = this.schoolOrgList
          .filter(c => c.SSXDLX === this.searchForm.SSXD)
          .sort(c => c.GradeNumber)
      }
      this.classlist = this.gradelist.reduce((pre, cur) => {
        if (cur.ChidOrgList && cur.ChidOrgList.length > 0) {
          pre = pre.concat(cur.ChidOrgList)
        }
        return pre
      }, [])
      this.searchForm.gradeId = ''
      this.searchForm.classId = ''
    },
    // 年级选择
    gradechange(val) {
      const grade = this.schoolOrgList.find(
        c => c.Id === this.searchForm.gradeId
      )
      if (grade) {
        this.classlist = grade.ChidOrgList.sort(c => c.Number)
      } else {
        this.classlist = []
      }
      this.searchForm.classId = ''
    },
    // 条件查询
    searchSubmit: function() {
      const pagerInfo = { pageIndex: 0, pageSize: this.pagesize }
      // 提交
      this.$api.post(
        'v1/student/GetXSXXBList',
        {
          SchoolId: this.searchForm.schoolId,
          GradeId: this.searchForm.gradeId,
          ClassId: this.searchForm.classId,
          IsBY: this.searchForm.isby,
          SYZT: this.searchForm.SYZT,
          Search: this.searchForm.serach,
          PagerInfo: pagerInfo,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.tableData = r.Target

            this.currentPage = r.PagerInfo.PageIndex
            this.pagesize = r.PagerInfo.PageSize
            this.rowcount = r.PagerInfo.RowCount
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    // 复选框选中
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.munlipleStudentId = ''
      this.munlipleStudentIndex = []
      for (var i = 0; i < this.multipleSelection.length; i++) {
        this.munlipleStudentId += this.multipleSelection[i].Id + ','
        this.munlipleStudentIndex.push(i)
      }
    },
    // 新增
    openadd: function() {
      this.$router.push({
        path: 'EditStudent'
      })
    },
    // 修改
    editRow: function(index, row) {
      if (row.SSBJ === '') {
        this.$message({
          type: 'info',
          message: '该学生未分班，请选择年级'
        })

        return
      }
      this.$router.push({ name: 'EditStudent', params: { xsxxbInfo: row } })
    },
    // 重置家張密碼
    ResetParentPassword: function(row) {
      if (row === 1) {
        if (this.munlipleStudentId === '') {
          this.$message({
            type: 'error',
            message: '未选中学生',
            showClose: true
          })

          return
        }

        this.$confirm('此操作将重置密码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 提交
            this.$api.post(
              'v1/accountmanage/BatchResetPassword',
              {
                Ids: this.multipleSelection.map(item => item.Id),
                UserType: 3,
                token: this.token
              },
              r => {
                if (r.Code === 0) {
                  this.$notify({
                    type: 'success',
                    message: r.Message,
                    duration: 3000
                  })
                } else {
                  this.$message({
                    type: 'error',
                    message: r.Message,
                    showClose: true
                  })
                }
              }
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
      } else {
        this.$confirm('确定重置该学生家长密码?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            var sIds = []
            sIds.push(row.Id)
            this.$api.post(
              'v1/accountmanage/BatchResetPassword',
              {
                Ids: sIds,
                UserType: 3,
                token: this.token
              },
              r => {
                if (r.Code === 0) {
                  this.$notify({
                    type: 'success',
                    message: r.Message
                  })
                }
              }
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      }
    },
    // 删除
    deleteRow: function(index, row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 提交
          this.$api.post(
            'v1/student/DeleteXSXXB',
            {
              Id: row.Id,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.searchSubmit()

                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 批量删除
    batchdeleteRow: function() {
      if (this.munlipleStudentId === '') {
        this.$message({
          type: 'error',
          message: '未选中学生',
          showClose: true
        })

        return
      }

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 提交
          this.$api.post(
            'v1/student/DeleteXSXXB',
            { Id: this.munlipleStudentId, token: this.token },
            r => {
              if (r.Code === 0) {
                this.searchSubmit()
                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })
              } else {
                this.$message({
                  type: 'error',
                  message: r.Message,
                  showClose: true
                })
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 启用、禁用
    oddupdatestate: function(row, trueOrFalse) {
      // 提交
      this.$api.post(
        'v1/student/UpdateStudentTrueOrFalse',
        { Ids: row.Id, trueOrFalse: trueOrFalse, token: this.token },
        r => {
          if (r.Code === 0) {
            row.SYZT = trueOrFalse

            this.$notify({
              type: 'success',
              message: r.Message,
              duration: 3000
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    // 批量修改启用、禁用状态
    updatestudenttrueorfalse: function(trueOrFalse) {
      if (this.munlipleStudentId === '') {
        this.$message({
          type: 'error',
          message: '未选中学生',
          showClose: true
        })

        return
      }

      // 提交
      this.$api.post(
        'v1/student/UpdateStudentTrueOrFalse',
        {
          ids: this.munlipleStudentId,
          trueOrFalse: trueOrFalse,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.munlipleStudentIndex.forEach(item => {
              this.tableData[item].SYZT = trueOrFalse
            })
            this.$notify({
              type: 'success',
              message: r.Message,
              duration: 3000
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    // 分班
    classassignment: function(row) {
      // this.multipleSelection = [];
      // this.multipleSelection.push(row);
      // this.$router.push({ name: 'ClassAssignment', params: { studentList: this.multipleSelection}});
      this.gradeId = row.SSNJ
      this.classId = row.SSBJ
      this.selectstudent = row.Id
      this.dialogSeClassVisible = true
    },
    // 批量分班
    batchclassassignment: function() {
      if (this.multipleSelection.length < 1) {
        this.$message({
          type: 'error',
          message: '请选择学生',
          showClose: true
        })
        return
      }

      this.$router.push({
        name: 'ClassAssignment',
        params: { studentList: this.multipleSelection }
      })
    },
    // 保存分班
    saveclassassignment: function() {
      this.$api.post(
        'v1/student/ConfirmStudentGroupClass',
        {
          StudentIds: this.selectstudent,
          ClassId: this.classId,
          GradeId: this.gradeId,
          SchoolId: this.searchForm.schoolId,
          UserName: this.userName,
          UserId: this.userId,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$notify({
              type: 'success',
              message: r.Message,
              duration: 3000
            })
            this.dialogSeClassVisible = false
            this.searchSubmit()
          }
        }
      )
    },
    // 提交学生导入
    importschoolstudent: function() {
      this.$api.post(
        'v1/student/ImportSchoolStudent',
        { token: this.token },
        r => {
          if (r.Code === 0) {
            this.$notify({
              type: 'success',
              message: r.Message,
              duration: 3000
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    // 学生导出
    exportschoolstudentdata: function() {
      this.$api.post(
        'v1/student/ExportSchoolStudentData',
        {
          SchoolId: this.searchForm.schoolId,
          GradeId: this.searchForm.gradeId,
          ClassId: this.searchForm.classId,
          IsBY: this.searchForm.isby,
          Search: this.searchForm.serach,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            window.open(r.Message)
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },

    // 二维码边框选择
    boderselect: function(type) {
      if (
        type == 2 ||
        type == '2' ||
        type == 3 ||
        type == '3' ||
        type == 4 ||
        type == '4'
      ) {
        this.borderno = true
        this.borderyes = false
        this.EWMSZInfo.EWMYS = type
        this.qrCodeborderclass = ''
      } else {
        this.borderno = false
        this.borderyes = true
        this.EWMSZInfo.EWMYS = '1'
        this.qrCodeborderclass = 'popo-twoe-main lss-sty'
        var borderinfo = this.qrcodeborderset.filter(
          c => c.color == this.EWMSZInfo.BKYSM
        )[0]
        if (borderinfo != null && borderinfo != undefined) {
          this.qrCodeborderclass = 'popo-twoe-main ' + borderinfo.classname
        }
      }
    },
    // 边框颜色
    bordercolorselect: function(sizeText, classText, values) {
      this.bordercolorclass = 'xlx-box ' + classText
      this.bordercolorText = sizeText
      if (this.borderyes) {
        this.qrCodeborderclass = 'popo-twoe-main ' + classText
      }
      this.EWMSZInfo.BKYSM = values
    },
    // 二维码颜色
    qrCodecolorselect: function(sizeText, classText, imgname, values) {
      this.qrCodecolorclass = 'xlx-box ' + classText
      this.qrCodecolorText = sizeText

      this.qrCodeimg = require('@/style/images/' + imgname)
      this.EWMSZInfo.EWMYSM = values
    },
    // 字体颜色
    sizecolorselect: function(sizeText, classText, values) {
      this.sizecolorclass = 'xlx-box ' + classText
      this.sizecolorText = sizeText
      this.qrsize = 'twor-mian ' + classText
      this.EWMSZInfo.ZTYSM = values
    },
    getqrcodeset: function() {
      this.$api.post(
        'v1/quickmarkregulation/GetEWMSZ',
        { SSXX: this.searchForm.schoolId, QrCodeSetType: 1, token: this.token },
        r => {
          if (r.Target != null && r.Target != undefined) {
            this.EWMSZInfo = r.Target
            this.boderselect(this.EWMSZInfo.EWMYS)
            var fontinfo = this.qrcodefontset.filter(
              c => c.color == this.EWMSZInfo.ZTYSM
            )[0]
            if (fontinfo != null && fontinfo != undefined) {
              this.sizecolorselect(
                fontinfo.name,
                fontinfo.classname,
                fontinfo.color
              )
            }
            var borderinfo = this.qrcodeborderset.filter(
              c => c.color == this.EWMSZInfo.BKYSM
            )[0]
            if (borderinfo != null && borderinfo != undefined) {
              this.bordercolorselect(
                borderinfo.name,
                borderinfo.classname,
                borderinfo.color
              )
            }
            var qrcolorinfo = this.qrcodecolorset.filter(
              c => c.color == this.EWMSZInfo.EWMYSM
            )[0]
            if (qrcolorinfo != null && qrcolorinfo != undefined) {
              this.qrCodecolorselect(
                qrcolorinfo.name,
                qrcolorinfo.classname,
                qrcolorinfo.img,
                qrcolorinfo.color
              )
            }
          }
        }
      )
    },
    // 提交二维码设置
    qrCodesubmit: function() {
      this.EWMSZInfo.SZLX = 1
      this.$api.post(
        'v1/quickmarkregulation/AddEWMSZ',
        { EWMSZInfo: this.EWMSZInfo, token: this.token },
        r => {
          if (r.Code === 0) {
            this.qrCodeFormVisible = false
            this.$notify({
              type: 'success',
              message: r.Message,
              duration: 3000
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },

    // 导出二维码
    exportbyqrCodeimage: function() {
      //if (this.munlipleStudentId.length <= 0) {
      //  this.$message({
      //    type: "info",
      //    message: "请勾选要导出的学生",
      //    showClose: true
      //  });
      //  return;
      //}
      this.$api.post(
        'v1/student/ExportByQRCodeImage',
        {
          SchoolId: this.searchForm.schoolId,
          GradeId: this.searchForm.gradeId,
          ClassId: this.searchForm.classId,
          IsBY: this.searchForm.isby,
          Search: this.searchForm.serach,
          StudentIds: this.munlipleStudentId,
          schoolId: this.schoolId,
          FileWebPath: this.FileWebPath,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            window.open(r.Message)
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    // 导出学生原图
    exportbyoriginalzp: function() {
      //if (this.munlipleStudentId.length <= 0) {
      //  this.$message({
      //    type: "info",
      //    message: "请勾选要导出的学生",
      //    showClose: true
      //  });
      //  return;
      //}
      this.$api.post(
        'v1/student/ExportByOriginalZp',
        {
          SchoolId: this.searchForm.schoolId,
          GradeId: this.searchForm.gradeId,
          ClassId: this.searchForm.classId,
          IsBY: this.searchForm.isby,
          Search: this.searchForm.serach,
          StudentIds: this.munlipleStudentId,
          schoolId: this.schoolId,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            window.open(r.Message)
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    // 导入学生Rf_Cardid
    importschoolstudentbyidCode: function() {
      this.$api.post(
        'v1/student/ImportSchoolStudentByIdCode',
        { token: this.token },
        r => {
          if (r.Code === 0) {
            this.$notify({
              type: 'success',
              message: r.Message,
              duration: 3000
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    exportZip() {
      this.$api.post(
        'v1/student/GetParentInfoBySchool',
        {
          GradeId: this.searchForm.gradeId || '',
          ClassId: this.searchForm.classId || '',
          IsBY:
            this.searchForm.isby === '0'
              ? 0
              : this.searchForm.isby === '1'
              ? 1
              : 2, //1 毕业 2未毕业
          Search: this.searchForm.serach,
          SchoolId: this.schoolId,
          token: this.token
        },
        r => {
          let sources = r.Target
          sources = sources.sort(this.sortBy('NJSZ'))

          import('@/style/js/Export2Excel').then(excel => {
            var zip = new JSZip()
            for (let item of sources) {
              var folder = zip.folder(item.NJMC)
              let NJSJ = JSON.parse(item.NJSJ)
              NJSJ = NJSJ.sort(this.sortBy('BJSZ'))
              for (let NJ of NJSJ) {
                let tHeader = ['学生姓名', '家长帐号', '家长手机号码']
                let filterVal = ['XM', 'JZZH', 'SJHM']
                let data = this.formatJson(
                  filterVal,
                  NJ.BJSJ.map(c => {
                    c.SJHM = this.encryptPhoneNumber(c.SJHM)
                    return c
                  })
                )
                let excelData = excel.json_to_excel({
                  header: tHeader, //表头 必填
                  data, //具体数据 必填
                  filename: NJ.BJMC + '' //非必填
                })
                folder.file(NJ.BJMC + '.xlsx', excelData, { type: 'blob' })
              }
            }
            zip.generateAsync({ type: 'blob' }).then(function(content) {
              // see FileSaver.js
              saveAs(content, new Date().getTime() + '.zip')
            })
          })
        }
      )
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j]
        })
      )
    },
    sortBy(attr, rev) {
      //第二个参数没有传递 默认升序排列
      if (rev == undefined) {
        rev = 1
      } else {
        rev = rev ? 1 : -1
      }
      return function(a, b) {
        a = a[attr]
        b = b[attr]
        if (a < b) {
          return rev * -1
        }
        if (a > b) {
          return rev * 1
        }
        return 0
      }
    },
    encryptPhoneNumber(cellValue) {
      if (Number(cellValue) && String(cellValue).length === 11) {
        var mobile = String(cellValue)

        var reg = /^(\d{3})\d{4}(\d{4})$/

        return mobile.replace(reg, '$1****$2')
      } else {
        return cellValue
      }
    },
    exportPDF() {
      this.exportPDFLoading = true
      this.showProgress = true
      this.$api.post(
        'v1/student/GetParentInfoBySchool',
        {
          GradeId: this.searchForm.gradeId || '',
          ClassId: this.searchForm.classId || '',
          IsBY:
            this.searchForm.isby === '0'
              ? 0
              : this.searchForm.isby === '1'
              ? 1
              : 2, //1 毕业 2未毕业
          Search: this.searchForm.serach,
          SchoolId: this.schoolId,
          token: this.token
        },
        async res => {
          if (res.Code === 0) {
            try {
              let reg = /^(\d{3})\d{4}(\d{4})$/
              let res2 = res.Target || []
              res2.sort(this.sortBy('NJSZ'))
              // 因为涉及到异步函数和回调函数 所以需要这两个来计数
              this.count = 0 //总数
              this.curCount = 0 //当前完成了的数量

              res2.forEach(item => {
                if (item.NJSJ && typeof item.NJSJ === 'string') {
                  item.NJSJ = JSON.parse(item.NJSJ)
                }
                item.NJSJ.forEach(it => {
                  this.count += 1
                  if (it.BJSJ) {
                    it.BJSJ.forEach(stu => {
                      if (stu.SJHM) {
                        // 中间四位隐藏
                        stu.SJHM = stu.SJHM.replace(reg, '$1****$2')
                      }
                    })
                  }
                })
              })

              this.pdfDataAll = res2

              for (let index = 0; index < res2.length; index++) {
                if (res2[index].NJSJ) {
                  let NJSJ = res2[index].NJSJ
                  for (let idx = 0; idx < NJSJ.length; idx++) {
                    this.pdfData = NJSJ[idx].BJSJ
                    await this.handleExportPDF(res2, index, NJSJ, idx)
                    console.log(222, '')
                  }
                }
              }
            } catch (err) {
              this.exportPDFLoading = false
              this.showProgress = false
            }
          } else {
            this.exportPDFLoading = false
            this.showProgress = false
          }
        },
        err => {
          this.exportPDFLoading = false
          this.showProgress = false
        }
      )
    },
    handleExportPDF(res2, index, NJSJ, idx) {
      return new Promise((resolve, reject) => {
        try {
          this.$nextTick(async () => {
            // 获取要导出的Vue组件
            // const vueComponent = document.querySelector(
            //   `.letter${res2[index].NJSZ}${NJSJ[idx].BH}pdf-table`
            // );
            const vueComponent = document.querySelector(`.pdfTable`)
            // 使用html2canvas将Vue组件渲染为图片
            const canvas = await html2canvas(vueComponent)
            // let img = new Image();
            // img.src = canvas.toDataURL("image/png");
            // canvas转base64
            let img = canvas.toDataURL('image/png')
            // base64转blob
            const arr = img.split(',')
            const mime = arr[0].match(/:(.*?);/)[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n)
            }
            NJSJ[idx].result = new Blob([u8arr], { type: mime })
            this.curCount += 1
            this.exportPDFPercentage = parseInt(
              (this.curCount / this.count) * 100
            )
            console.log(1111, '')
            resolve()
          })
        } catch (err) {
          reject()
        }
      })
    }
  },
  watch: {
    curCount(val) {
      if (val && val === this.count) {
        let zip = new JSZip()
        this.pdfDataAll.forEach(item => {
          let folder = zip.folder(item.NJMC)
          item.NJSJ.forEach(it => {
            folder.file(it.BJMC + '.png', it.result, { type: 'blob' })
          })
        })
        zip.generateAsync({ type: 'blob' }).then(content => {
          saveAs(content, new Date().getTime() + '.zip')
        })
        this.exportPDFLoading = false
        this.showProgress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-er-box button {
  margin-top: 20px;
}

.cell {
  text-align: left;
}
.pdf-box {
  position: relative;
  overflow: hidden;
  .pdf-tables {
    position: absolute;
    left: -100000px;
    top: 0;
  }
}
.exportPDFPercentage {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .el-progress {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
  }
}
</style>
