<template>
  <div class="cont-whole-box" style="box-shadow:unset" v-loading="loading">
    <div class="multi-opt-box" style="margin-top:0px">
      <div class="multi-opt">
        <div class="multi-opt-left">年级</div>
        <div class="multi-opt-rig">
          <el-radio-group
            v-model="gradeId"
            @change="handleGrade"
            class="jf-radio"
            size="small"
          >
            <el-row>
              <el-col
                :span="grades.length / 8"
                v-for="item in grades"
                :key="item.Id"
                ><el-radio :label="item.Id" border>{{
                  item.Name
                }}</el-radio></el-col
              >
            </el-row>
          </el-radio-group>
        </div>
      </div>
      <div class="multi-opt">
        <div class="multi-opt-left">班级</div>
        <div class="multi-opt-rig">
          <el-radio-group
            v-model="classId"
            @change="handleClass"
            class="jf-radio"
            size="small"
          >
            <el-row>
              <el-col
                :span="
                  classDatas.length / 8 === 1 ? 1.1 : classDatas.length / 8
                "
                v-for="item in classDatas.filter((c) => c.ParentId === gradeId)"
                :key="item.Id"
                ><el-radio :label="item.Id" border>{{
                  item.Name
                }}</el-radio></el-col
              >
            </el-row>
          </el-radio-group>
        </div>
      </div>
      <div class="multi-opt">
        <div class="multi-opt-left">课程</div>
        <div class="multi-opt-rig">
          <el-radio-group
            v-model="courseId"
            class="jf-radio"
            style="width:100%"
            size="small"
          >
            <div class="course-item-block">
              <div
                class="course-item"
                :class="{ 'cur-course': courseId === item.Id }"
                v-for="(item, index) in courseList"
                :key="index"
              >
                <el-radio :label="item.Id" border>{{ item.Name }}</el-radio>
              </div>
            </div>
          </el-radio-group>
        </div>
      </div>
    </div>
    <!--内容操作区结束-->
    <div style="padding:30px 0">
      <el-button icon="el-icon-download" @click="downloadTemplateVisible = true"
        >下载成绩模版</el-button
      >
      <el-tooltip
        effect="dark"
        content="最大仅支持按整个年级导入，且本页面需要选择对应的年级"
        placement="top"
      >
        <UploadExcel
          style="display: inline-block;margin-left: 10px;margin-right: 10px;"
          :on-success="importScore"
          ><el-button class="butt-box" icon="el-icon-upload2"
            >导入</el-button
          ></UploadExcel
        >
      </el-tooltip>

      <el-button icon="el-icon-download" @click="exportScoreVisible = true"
        >导出</el-button
      >
      <div style="display:inline-block;margin-left:10px" v-if="courseId">
        <el-button @click="submit" icon="el-icon-finished" type="primary"
          >保存</el-button
        >
        <el-button
          v-if="!openMissTest"
          @click="openMissTest = true"
          icon="el-icon-finished"
          type="danger"
          >打开缺考</el-button
        >
        <el-button
          v-else
          @click="openMissTest = false"
          icon="el-icon-finished"
          type="danger"
          plain
          >关闭缺考</el-button
        >
      </div>

      <el-input
        style="width:150px;float:right"
        placeholder="请输入学号/姓名"
        suffix-icon="el-icon-search"
        v-model="searchStr"
      >
      </el-input>
    </div>
    <!--表格区开始-->
    <div>
      <vxe-table
        class="jftable-scrollbar"
        border
        resizable
        show-overflow
        keep-source
        align="center"
        height="416"
        v-loading="initTableLoading"
        :data="
          tableData
            .filter(
              (c) => c.Name.includes(searchStr) || c.XH.includes(searchStr)
            )
            .sort((a, b) => a.Name.localeCompare(b.Name))
        "
      >
        <vxe-table-column
          fixed="left"
          field="XH"
          title="学号"
          sortable
          min-width="80px"
        ></vxe-table-column>
        <vxe-table-column
          fixed="left"
          field="Name"
          title="姓名"
          sortable
          min-width="80px"
        ></vxe-table-column>
        <vxe-table-column
          v-for="(item, index) in tableDataHeader.filter((c) =>
            c.CourseId.includes(courseId)
          )"
          min-width="105px"
          :key="index"
          :field="item.CourseId"
          :title="item.CourseName"
        >
          <template v-slot="{ row, column }">
            <div v-if="courseId">
              <el-tag
                v-if="
                  row.CourseScoreList &&
                    row.CourseScoreList.find(
                      (c) => c.CourseId === column.property
                    ).MissTest &&
                    !openMissTest
                "
                disable-transitions
                type="danger"
                style="width:100%"
                >缺考</el-tag
              >
              <el-input-number
                v-else-if="
                  row.CourseScoreList &&
                    row.CourseScoreList.find((c) => c.CourseId === courseId) &&
                    !row.CourseScoreList.find((c) => c.CourseId === courseId)
                      .MissTest &&
                    !openMissTest &&
                    row.CourseScoreList.find((c) => c.CourseId === courseId)
                      .InputType === 1
                "
                style="width:100%"
                v-model="
                  row.CourseScoreList.find((c) => c.CourseId === courseId).Score
                "
                :min="0"
                :precision="1"
                :max="
                  row.CourseScoreList.find((c) => c.CourseId === courseId)
                    .ScoreLimit
                "
                :controls="false"
                placeholder="输入分数"
              ></el-input-number>
              <el-select
                v-else-if="
                  row.CourseScoreList &&
                    !row.CourseScoreList.find(
                      (c) => c.CourseId === column.property
                    ).MissTest &&
                    !openMissTest &&
                    row.CourseScoreList.find(
                      (c) => c.CourseId === column.property
                    ) &&
                    row.CourseScoreList.find(
                      (c) => c.CourseId === column.property
                    ).InputType === 2
                "
                v-model="
                  row.CourseScoreList.find(
                    (c) => c.CourseId === column.property
                  ).Level
                "
                clearable
                placeholder="请选择等级"
              >
                <el-option
                  v-for="(o, i) in row.CourseScoreList.find(
                    (c) => c.CourseId === courseId
                  ).LevelDatas"
                  :key="i"
                  :label="o.DJ"
                  :value="o.Id"
                >
                </el-option>
              </el-select>
              <el-checkbox
                v-else-if="openMissTest"
                class="jf-checkbox-red"
                v-model="
                  row.CourseScoreList.find(
                    (c) => c.CourseId === column.property
                  ).MissTest
                "
                :disabled="disabledQK(row, column)"
                border
                style="width:100%"
                >{{
                  row.CourseScoreList.find(
                    (c) => c.CourseId === column.property
                  ).InputType === 1
                    ? row.CourseScoreList.find(
                        (c) => c.CourseId === column.property
                      ).Score
                      ? "此处已有成绩"
                      : "缺考"
                    : row.CourseScoreList.find(
                        (c) => c.CourseId === column.property
                      ).Level
                    ? "此处已有等级"
                    : "缺考"
                }}</el-checkbox
              >
            </div>
            <div v-else>
              <el-tag
                v-if="
                  row.CourseScoreList &&
                    row.CourseScoreList.find(
                      (c) => c.CourseId === column.property
                    ) &&
                    row.CourseScoreList.find(
                      (c) => c.CourseId === column.property
                    ).MissTest
                "
                disable-transitions
                type="danger"
                style="width:100%"
                >缺考</el-tag
              >
              <div v-else>
                <div
                  v-if="
                    row.CourseScoreList &&
                      row.CourseScoreList.find(
                        (c) => c.CourseId === column.property
                      ) &&
                      row.CourseScoreList.find(
                        (c) => c.CourseId === column.property
                      ).InputType === 1
                  "
                >
                  {{
                    row.CourseScoreList &&
                      row.CourseScoreList.find(
                        (c) => c.CourseId === column.property
                      ) &&
                      row.CourseScoreList.find(
                        (c) => c.CourseId === column.property
                      ).Score
                  }}
                </div>
                <div v-else>
                  {{
                    row.CourseScoreList &&
                      row.CourseScoreList.find(
                        (c) => c.CourseId === column.property
                      ) &&
                      row.CourseScoreList.find(
                        (c) => c.CourseId === column.property
                      ).Level &&
                      row.CourseScoreList.find(
                        (c) => c.CourseId === column.property
                      ).LevelDatas.find(
                        (l) =>
                          l.Id ===
                          row.CourseScoreList.find(
                            (c) => c.CourseId === column.property
                          ).Level
                      ) &&
                      row.CourseScoreList.find(
                        (c) => c.CourseId === column.property
                      ).LevelDatas.find(
                        (l) =>
                          l.Id ===
                          row.CourseScoreList.find(
                            (c) => c.CourseId === column.property
                          ).Level
                      ).DJ
                  }}
                </div>
              </div>
            </div>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <!-- 导入成绩 -->
    <el-dialog
      :visible.sync="importStatusVisible"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="cancelImport"
    >
      <el-collapse v-model="importMsgIndex">
        <el-collapse-item
          :disabled="item.status === 'success'"
          v-for="(item, index) in importArray"
          :key="index"
        >
          <template slot="title">
            <div style="display: inline-block;color:#303133">
              {{ item.className }}
            </div>
            <el-progress
              style="display: inline-block;width:370px;margin-left: 10px;"
              :stroke-width="3"
              :percentage="item.percentage"
              :status="item.status"
            ></el-progress>
            <el-link
              v-if="item.status"
              style="display: inline-block;margin-left: -25px;"
              :underline="false"
              :type="item.status === 'exception' ? 'danger' : item.status"
              >{{
                item.status === "success"
                  ? "导入成功"
                  : item.status === "warning"
                  ? "点击查看警告"
                  : item.status === "exception"
                  ? "点击查看错误"
                  : ""
              }}
            </el-link>
          </template>
          <el-alert
            :closable="false"
            :title="m.title"
            v-for="(m, i) in item.Msg"
            :key="i"
            :type="item.status === 'exception' ? 'error' : item.status"
          >
            <template slot="title">
              <div>{{ m.title + ":" }}</div>
              <div v-for="(desc, dex) in m.description" :key="dex">
                {{ desc }}
              </div>
            </template>
          </el-alert>
        </el-collapse-item>
      </el-collapse>
      <div v-for="item in importArray" :key="item.className"></div>
      <span slot="footer" class="dialog-footer">
        <el-button
          :loading="!importSuccess"
          type="primary"
          @click="importStatusVisible = false"
          >完成</el-button
        >
      </span>
    </el-dialog>
    <!-- 导出模板 -->
    <exportTemplate
      v-model="downloadTemplateVisible"
      :examinationCourse="
        examination && examination.KSNJXKList ? examination.KSNJXKList : []
      "
      :basicInfo="$attrs.basicInfo"
      :teacherCourse="$attrs.teacherCourse"
      :userId="user.Id"
      :token="this.token"
      :gradesList="grades"
    ></exportTemplate>
    <!-- 导出成绩 -->
    <exportScore
      v-model="exportScoreVisible"
      :examinationCourse="
        examination && examination.KSNJXKList ? examination.KSNJXKList : []
      "
      :basicInfo="$attrs.basicInfo"
      :userId="user.Id"
      :token="this.token"
      :termId="this.termId"
      :examinationId="this.examination ? this.examination.Id : ''"
      :gradesList="grades"
    ></exportScore>

    <!-- 导入异常数据展示框 -->
    <el-dialog
      title="导入异常"
      :visible.sync="showImportError"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="err-msg-container">
        <div
          class="class-container"
          v-for="classInfo in importErrorData"
          :key="classInfo.Class"
        >
          <div class="class-item">
            {{ classInfo.Class }}
            <span v-if="classInfo.errorMsg">{{ classInfo.errorMsg }}</span>
          </div>
          <div v-if="!classInfo.errorMsg" class="class-item">
            <div
              class="student-item"
              v-for="(student, index) in classInfo.Data"
              :key="index"
            >
              <div v-if="student.errorData && student.errorData.length > 0">
                <div
                  style="display:flex;width:100%;border-bottom:1px solid #e6ebeb"
                >
                  <span style="width:100px">{{ student["姓名"] }}：</span>
                  <div>
                    <div
                      v-for="(errMsg, index) in student.errorData"
                      :key="index"
                      style="border-bottom:1px solid #d8dbdb;margin-bottom:5px"
                    >
                      <span>{{ errMsg }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="display:flex;justify-content:flex-end;width:100%;margin-top:10px"
      >
        <el-button type="info" size="mini" @click="showImportError = false"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { handleDownload } from "@/style/js/Common.js";
import UploadExcel from "@/components/ImportExaminationScore.vue";
import exportTemplate from "./exporttemplate";
import exportScore from "./exportScore";
import { mapGetters } from "vuex";
export default {
  components: {
    UploadExcel,
    exportTemplate,
    exportScore,
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0,
      },
      user: {},
      examination: {},
      schoolId: "",
      termId: "",
      termYear: "",
      termType: 0,
      token: "",
      gradeId: "",
      gradeNumber: 1,
      classId: "",
      courseId: "",
      grades: [],
      classDatas: [],
      courseTable: [],
      headerClass: [],
      courseList: [],
      tableData: [],
      courseScoreList: [],
      searchStr: "",
      tableDataHeader: [],
      openMissTest: false,
      tableData1: [],
      studentScore: [],
      loading: false,
      initTableLoading: false,
      importStatusVisible: false,
      importSuccess: false,
      importArray: [],
      importMsgIndex: [],
      downloadTemplateVisible: false,
      exportScoreVisible: false,
      teacherCourse: {},
      importErrorData: [],
      showImportError: false,
    };
  },
  computed: {
    ...mapGetters(["currentExamination"]),
  },
  async created() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.termId = this.$route.query.termId;
    this.termYear = this.$route.query.termYear;
    this.termType = this.$route.query.termType;
    this.examination = this.currentExamination;
    console.log(this.currentExamination.Id, "currentExamination");
    this.teacherCourse = this.$attrs.teacherCourse;
    await this.getStudentScore();
    this.init();
  },
  methods: {
    // 初始化 筛选考试包含年级 获取年级学生，班级课程，教师所教课程
    async init() {
      this.loading = true;
      // 筛选出考试勾选的年级
      let grades = this.$attrs.basicInfo.GradeList.filter((c) =>
        this.examination.KSNJXKList.map((item) => item.KSNJ).includes(c.Number)
      );
      this.grades = JSON.parse(JSON.stringify(grades || []));
      // 获取教师班主任班级
      await this.getClassTeacher();
      // 筛选所有考试年级的班级
      let classArr = this.grades.reduce((pre, cur) => {
        if (cur.ChidOrgList && cur.ChidOrgList.length > 0)
          pre = pre.concat(cur.ChidOrgList);
        return pre;
      }, []);

      // 循环请求班级课表
      for (let i = 0; i < classArr.length; i++) {
        let res = await this.getCourseTable(classArr[i].Id);
        classArr[i].courseTable = res;
      }

      classArr.forEach((classInfo) => this.handleFilter(classInfo));

      this.grades.forEach((grade) => {
        grade.ChidOrgList = classArr.filter(
          (classInfo) =>
            classInfo.ParentId === grade.Id && classInfo.finalCourse.length > 0
        );
      });

      this.grades = this.grades.filter((grade) => grade.ChidOrgList.length > 0);

      if (this.grades.length > 0 && this.grades[0] && this.grades[0].Id) {
        this.handleGrade(this.grades[0].Id);
      } else {
        this.$message.error("未匹配到课程");
        this.loading = false;
      }
      this.loading = false;
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    getCourseTable(ClassId) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/evaluationactivity/GetClassSchedule",
          {
            token: this.token,
            // UserId: this.user.Id,
            ClassId,
            ClassScheduleType: 2,
            SchoolId: this.schoolId,
            TermId: this.termId,
          },
          (r) => {
            if (r.Code === 0) {
              this.courseTable = r.Target?.ClassScheduleList || [];
              resolve(r.Target?.ClassScheduleList || []);
            } else reject();
          },
          (err) => reject(err)
        );
      });
    },
    getClassTeacher() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/accountmanage/GetClassTeacher",
          {
            token: this.token,
            termId: this.examination.SSXQ,
            UserId: this.user.Id,
          },
          (r) => {
            if (r.Code === 0) {
              this.headerClass = r.Target;
              this.headerClass = [].concat(
                this.headerClass ? this.headerClass : []
              );
              resolve();
            }
          }
        );
      });
    },
    // 获取考试的所有学生的成绩
    getStudentScore() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/examination/GetExaminationStudentScore",
          {
            token: this.token,
            SchoolId: this.schoolId,
            ExaminationId: this.examination.Id,
            // ClassId: this.classId,//传班级就获取该班级学生成绩 不传就获取这个考试勾选年级下的所有学生成绩
            TermId: this.examination.SSXQ,
          },
          (r) => {
            if (r.Code === 0) {
              this.studentScore = r.Target || [];
              resolve();
            } else reject();
          }
        );
      });
    },
    // 获取年级所有学生
    getStudentData(gradeId) {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetOrgStudent",
          {
            token: this.token,
            SchoolOrgId: gradeId,
            OrgType: 1,
            SourceType: 1,
          },
          (r) => {
            if (r.Code === 0) {
              this.tableData1 = r.Target;
              resolve();
            }
          }
        );
      });
    },
    // 点击年级变化
    async handleGrade(gradeId) {
      let grade = this.grades.find((item) => item.Id === gradeId);
      if (grade) {
        this.gradeId = gradeId;
        this.gradeNumber = grade.Number;
        this.classDatas = grade.ChidOrgList;
        // 获取年级学生
        await this.getStudentData(gradeId);
        // 处理年级学生成绩
        this.initGradeStudentScore();
        // if (this.classDatas[0]) {
        //   this.handleClass(this.classDatas[0].Id);
        //   }
        if (this.classDatas) {
          this.classDatas.forEach((bj) => {
            this.handleClass(bj.Id);
          });
        }
      }
    },
    // 点击班级变化
    handleClass(classId) {
      this.classId = classId;
      this.courseList = this.grades
        .find((grade) => grade.Id === this.gradeId)
        .ChidOrgList.find((classInfo) => classInfo.Id === classId).finalCourse;
      this.courseId = this.courseList[0].Id;
      // 处理班级学生成绩
      this.initTableData();
    },
    // 点击保存
    submit() {
      let convertrule = [];
      let grade = this.$attrs.basicInfo.GradeList.find(
        (c) => c.Id === this.gradeId
      );
      if (grade) {
        convertrule = this.$attrs.basicInfo.ConvertRuleList.filter(
          (c) => c.FJZ === grade.Number && c.KSLB === this.examination.KSXZM
        );

        if (this.courseId) {
          convertrule = convertrule.filter((c) => c.FJLX === this.courseId);
        }
      }
      let curClass = this.classDatas.find((item) => item.Id === this.classId);

      let data = this.tableData.reduce((prev, item) => {
        let { changed } = this.handleChangeScore(item, 0);
        return prev.concat(
          changed.map((c) => ({
            XSId: item.Id,
            KCH: c.CourseId,
            KCMC: c.CourseName,
            DFFS: c.InputType,
            KCCJ: c.MissTest
              ? null
              : c.InputType === 1
              ? c.Score || 0
              : c.LevelDatas.find((l) => l.Id === c.Level)
              ? c.LevelDatas.find((l) => l.Id === c.Level).ZGF
              : 0,
            KCDJ: c.MissTest ? null : c.Level || null,
            SFQK: c.MissTest ? 1 : 2,
            SSZZJG: item.ClassId,
            SSNJ: item.GradeId,
            NJSZ: curClass ? curClass.GradeNumber : undefined,
            JGSJ:
              (convertrule.find((item) => item.FJLX === c.CourseId)
                ? convertrule.find((item) => item.FJLX === c.CourseId).JBZ / 100
                : 1) *
              (c.InputType === 1
                ? c.Score || null
                : c.LevelDatas.find((l) => l.Id === c.Level)
                ? c.LevelDatas.find((l) => l.Id === c.Level).ZGF
                : 0),
          }))
        );
      }, []);
      if (data && data.length === 0) return this.$message.error("成绩未修改");

      this.$api.post(
        "/v1/examination/SaveExaminationResult",
        {
          token: this.token,
          UserId: this.user.Id,
          ExaminationId: this.examination.Id,
          XSCJBList: data,
          ClassId: this.classId,
          SchoolId: this.schoolId,
          UserName: this.user.XSM,
          CourseId: this.courseId,
          TermId: this.examination.SSXQ,
          TermYearStr: this.termYear,
          TermType: this.termType,
          ExaminationName: this.examination.KSMC,
          SchCode: this.user.SchoolCode,
          ExamType: this.examination.KSXZM,
          ExamInsertType: 3,
          NJSZ: this.gradeNumber,
        },
        async (r) => {
          if (r.Code === 0) {
            await this.getStudentScore();
            this.initGradeStudentScore();
            this.initTableData();
            this.$message.success("保存成功");
          } else this.$message.error("出错了");
        }
      );
    },
    // 导入保存
    submitForImport(importData) {
      return new Promise((resolve) => {
        let convertrule = [];
        let grade = this.$attrs.basicInfo.GradeList.find(
          (c) => c.Id === this.gradeId
        );
        if (grade) {
          convertrule = this.$attrs.basicInfo.ConvertRuleList.filter(
            (c) => c.FJZ === grade.Number && c.KSLB === this.examination.KSXZM
          );
          if (this.courseId) {
            convertrule = convertrule.filter((c) => c.FJLX === this.courseId);
          }
        }
        let data = importData.reduce((prev, item) => {
          let { changed, noChange } = this.handleChangeScore(item, 1);
          // 分数没有改变的 且值为空的 要在导入后显示出来
          let noChange2 = noChange.reduce((pre, cur) => {
            cur.changed = 0;
            if (
              cur.InputType === 1 &&
              !cur.MissTest &&
              !(cur.Score === 0 || cur.Score)
            )
              pre.push(cur);
            if (cur.InputType === 2 && !cur.MissTest && !cur.Level)
              pre.push(cur);
            return pre;
          }, []);
          // 分数改变了的 且值为空的 要在导入后显示出来
          let changed2 = changed.reduce((pre, cur) => {
            cur.changed = 1;
            if (
              cur.InputType === 1 &&
              !cur.MissTest &&
              !(cur.Score === 0 || cur.Score)
            )
              pre.push(cur);
            if (cur.InputType === 2 && !cur.MissTest && !cur.Level)
              pre.push(cur);
            return pre;
          }, []);

          let errorMsg = [...noChange2, ...changed2].reduce((pre, cur) => {
            pre.push(
              `${cur.CourseName}成绩${cur.changed ? "有" : "无"}改变 且为空`
            );
            return pre;
          }, []);
          let className = this.grades[0]?.ChidOrgList.find(
            (classInfo) => classInfo.Id === item.ClassId
          )?.Name;
          let importErrorDataItem = this.importErrorData.find(
            (classItem) => classItem.Class == className
          );
          if (importErrorDataItem) {
            let student = importErrorDataItem.Data.find(
              (student) => student["姓名"] === item.Name
            );
            if (student) {
              student.errorData = [...student.errorData, ...errorMsg];
            }
          }

          return prev.concat(
            changed.map((c) => ({
              XSId: item.Id,
              KCH: c.CourseId,
              KCMC: c.CourseName,
              DFFS: c.InputType,
              KCCJ: c.MissTest
                ? null
                : c.InputType === 1
                ? c.Score || 0
                : c.LevelDatas.find((l) => l.Id === c.Level)
                ? c.LevelDatas.find((l) => l.Id === c.Level).ZGF
                : 0,
              KCDJ: c.MissTest ? null : c.Level || null,
              SFQK: c.MissTest ? 1 : 2,
              SSZZJG: item.ClassId,
              SSNJ: item.GradeId,
              JGSJ:
                (convertrule.find((item) => item.FJLX === c.CourseId)
                  ? convertrule.find((item) => item.FJLX === c.CourseId).JBZ /
                    100
                  : 1) *
                (c.InputType === 1
                  ? c.Score || 0
                  : c.LevelDatas.find((l) => l.Id === c.Level)
                  ? c.LevelDatas.find((l) => l.Id === c.Level).ZGF || 0
                  : 0),
              NJSZ: this.gradeNumber,
            }))
          );
        }, []);

        if (!data || data.length === 0) {
          resolve(true);
        } else {
          this.$api.post(
            "/v1/examination/SaveExaminationResult",
            {
              token: this.token,
              UserId: this.user.Id,
              ExaminationId: this.examination.Id,
              XSCJBList: data,
              // CourseId: courseIds.join(),
              ClassId: this.classId, //原来是单个班级 现在是多个班级 需要修改
              SchoolId: this.schoolId,
              UserName: this.user.XSM,
              TermId: this.examination.SSXQ,
              TermYearStr: this.termYear,
              TermType: this.termType,
              ExaminationName: this.examination.KSMC,
              SchCode: this.user.SchoolCode,
              ExamType: this.examination.KSXZM,
              ExamInsertType: 2, //1 单个班新增 2:导入 3.修改
            },
            async (res) => {
              if (res.Code === 0) {
                await this.getStudentScore();
                this.initGradeStudentScore();
                this.initTableData();
                this.$message.success("保存成功");
                resolve();
              }
            },
            (f) => {
              if (f.Code !== 0) {
                this.$message.error(f.Message);
                resolve();
              }
            }
          );
        }
      });
    },
    // 下载模板
    downloadTemplate() {
      handleDownload(
        "/template/学生成绩导入模版.xlsx",
        "学生成绩导入模版.xlsx"
      );
    },
    // 导入成绩
    importScore({ results }) {
      // 导入的数据是以班级为单位分类(目前导入只支持一个班级)
      this.importErrorData = [];
      let allImportData = [];
      for (let result of results) {
        let importData = [];
        //找到对应的班级
        let classInfo = this.classDatas.find(
          (c) => c.Name === result.Class.trim()
        );
        if (classInfo) {
          this.importErrorData.push(result);
          for (let item of result.Data) {
            item.errorData = []; //如果学科的分数不规范 或者没改变 就放置在这里面
            let SourceStudents = this.tableData1.filter(
              //在当前年级中的所有学生中找到对应班级 对应名字的学生
              (c) => c.Name === item["姓名"] + "" && c.ClassId === classInfo.Id
            );
            console.log(SourceStudents, "SourceStudents");
            if (SourceStudents.length !== 0) {
              // 学生多个的时候 重名了
              if (SourceStudents.length === 1) {
                let student = JSON.parse(JSON.stringify(SourceStudents[0]));
                if (student.CourseScoreList) {
                  console.log(item, "result。data。item");
                  Object.keys(item).forEach((xk) => {
                    if (!xk.includes("EMPTY")) {
                      let score = student.CourseScoreList.find(
                        (c) => c.CourseName === xk
                      );
                      if (score !== undefined && score !== null) {
                        if (item[xk] === "缺考") score.MissTest = true;
                        else {
                          // 学科分数是输入的
                          if (score.InputType === 1) {
                            let studentScore = "";
                            if (item[xk] !== "" && !isNaN(item[xk])) {
                              studentScore = Number(item[xk]);
                              if (studentScore < 0) studentScore = 0;
                            }

                            if (studentScore !== "") {
                              score.Score =
                                studentScore > score.ScoreLimit
                                  ? score.ScoreLimit
                                  : studentScore;
                              score.MissTest = false;
                            } else {
                              score.Score = "";
                              score.MissTest = false;
                            }
                          } else {
                            // 学科分数是下拉选择的
                            let levelData = score.LevelDatas.find(
                              (c) =>
                                c.DJ.toLowerCase() ===
                                (item[xk] + "").toLowerCase()
                            );
                            if (levelData) {
                              score.Level = levelData.Id;
                              score.MissTest = false;
                            } else {
                              item.errorData.push(`未找到${xk}成绩对应的等级`);
                              score.Level = undefined;
                            }
                          }
                        }
                      } else {
                        if (
                          xk !== "errorData" &&
                          xk !== "学号" &&
                          xk !== "姓名"
                        ) {
                          item.errorData.push(
                            `未在${item["姓名"]}的成绩中找到${xk}学科`
                          );
                        }
                      }
                    }
                  });
                }

                importData.push(student);
              } else {
                // 遇到重名的学生 按照导入失败处理
                item.errorData.push(
                  `学生${item["姓名"]}(${item["学号"]})在数据库中与另外的学生重名`
                );
              }
            } else {
              item.errorData.push(
                `未在${classInfo.Name}中找到学生${item["姓名"]}`
              );
            }
          }
        } else {
          // 通过名字没有找到对应的班级
          result.errorMsg = `未在系统中找到${result.Class}`;
          this.importErrorData.push(result);
        }
        allImportData = allImportData.concat(importData);
      }

      this.submitForImport(allImportData).then((type) => {
        if (type) this.$message.error("成绩未改变");

        if (this.importErrorData.length > 0) {
          this.importErrorData.forEach((item) => {
            item.Data.sort((a, b) => a["姓名"]?.localeCompare(b["姓名"]));
            if (item.errorMsg) this.showImportError = true;
            item.Data.forEach((it) => {
              if (it.errorData?.length > 0) this.showImportError = true;
            });
          });
        }
      });
    },
    cancelImport() {
      this.importStatusVisible = false;
    },
    // 处理班级表格数据
    initTableData() {
      let courseList = this.courseList.filter(
        (item) => item.NJId === this.gradeId
      );

      this.courseScoreList = [];
      // 筛选年级中对应当前的班级学生
      let classStudentScore = this.tableData1.filter(
        (c) => c.ClassId === this.classId
      );
      this.tableData = classStudentScore.map((c) => {
        c.CourseScoreList = courseList.reduce((prev, item) => {
          let sut = this.studentScore.find(
            (s) => s.KCH === item.Id && s.XSId === c.Id
          );
          if (item.LevelList && item.LevelList.length > 0) {
            item.LevelList.sort((a, b) => a.DJ.localeCompare(b.DJ));
          }

          let level = "";
          if (item.LevelList && item.LevelList.length > 0) {
            if (sut && sut.KCDJ) {
              let res = item.LevelList.find((el) => el.Id === sut.KCDJ);

              res ? (level = res.Id) : "";
            }
          }

          let obj = {
            CourseId: item.Id,
            CourseName: item.Name,
            Score: sut ? sut.KCCJ : undefined,
            LevelDatas: item.LevelList || [],
            Level: level,
            ScoreLimit: item.ScoreLimit,
            InputType: item.InputType,
            MissTest: sut ? (sut.SFQK == 1 ? true : false) : false,
          };
          prev.push(obj);
          return prev;
        }, []);
        this.courseScoreList = c.CourseScoreList;
        c.CourseScoreList = this.courseScoreList;
        return c;
      });
      this.tableDataHeader = this.courseList
        .filter((item) => item.NJId === this.gradeId)
        .map((item) => ({
          CourseId: item.Id,
          CourseName: item.Name,
        }));
    },
    // 将考试的学生的成绩关联至当前年级的学生
    initGradeStudentScore() {
      let courseList = this.courseList.filter(
        (item) => item.NJId === this.gradeId
      );
      this.tableData1 = this.tableData1.map((c) => {
        c.CourseScoreList = courseList.reduce((prev, item) => {
          let obj = {
            CourseId: item.Id,
            CourseName: item.Name,
            Score: undefined,
            LevelDatas: item.LevelList,
            Level: undefined,
            ScoreLimit: item.ScoreLimit,
            InputType: item.InputType,
            MissTest: false,
          };
          prev.push(obj);
          return prev;
        }, []);
        return c;
      });
      this.tableData1.sort((a, b) => a.Name.localeCompare(b.Name));
    },
    // 筛选登录人匹配的年级 班级 课程
    handleFilter(classInfo) {
      // 1.筛选出考试对应的年级 2.循环每个年级下的班级 获取班级课表，筛选考试对应的课程 3.判断老师是否这个班的班主任，是否教这个班级，是否指定录入人，是否显示这个班级
      // 年级课程
      let gradeCourse = this.$attrs.basicInfo.CourseList.find(
        (item) => item.Grade === classInfo.GradeNumber
      );
      let result = [];
      let course1 = []; //此班全部符合考试的课程
      let course2 = []; //科任课程
      if (gradeCourse && gradeCourse.GradeCourseList && classInfo.courseTable) {
        //筛选此班的全部课程 (班级课表和年级课程匹配 再和考试课程匹配)
        let classCourse = gradeCourse.GradeCourseList.reduce((pre, cur) => {
          classInfo.courseTable.forEach((item) => {
            if (
              cur.Id === item.CourseId &&
              !pre.find((el) => el.Id === cur.Id)
            ) {
              pre.push({
                Id: cur.Id,
                NJId: cur.gradeId,
                GradeNumber: classInfo.GradeNumber,
                classId: classInfo.Id,
                Name: cur.Name,
                InputType: cur.InputType,
                ScoreLimit: cur.ScoreLimit,
                LevelList: cur.LevelList,
              });
            }
          });
          return pre;
        }, []);
        if (this.examination.KSNJXKList) {
          course1 = classCourse.filter((item) =>
            this.examination.KSNJXKList.some(
              (it) => it.KSNJ === item.GradeNumber && it.KCId === item.Id
            )
          );
        }
        // 筛选科任课程
        let teacherCourse = classInfo.courseTable.reduce((pre, cur) => {
          if (
            cur.TeacherId === this.user.Id &&
            !pre.find((item) => item.CourseId === cur.CourseId)
          )
            pre.push(cur);
          return pre;
        }, []);

        teacherCourse = gradeCourse.GradeCourseList.reduce((pre, cur) => {
          teacherCourse.forEach((item) => {
            if (
              cur.Id === item.CourseId &&
              !pre.find((el) => el.Id === item.CourseId)
            ) {
              pre.push({
                Id: item.CourseId,
                NJId: cur.gradeId,
                GradeNumber: classInfo.GradeNumber,
                classId: classInfo.Id,
                Name: cur.Name,
                InputType: cur.InputType,
                ScoreLimit: cur.ScoreLimit,
                LevelList: cur.LevelList,
              });
            }
          });
          return pre;
        }, []);
        if (this.examination.KSNJXKList) {
          course2 = teacherCourse.filter((item) =>
            this.examination.KSNJXKList.some(
              (it) => it.KSNJ === item.GradeNumber && it.KCId === item.Id
            )
          );
        }
      }

      if (
        // 指定录入人和创建人
        this.examination.KSLRRYBList.some(
          (item) => item.CZRId === this.user.Id
        ) ||
        this.examination.CJR === this.user.Id
      ) {
        result = course1;
      } else if (
        this.examination.LRRLX === 2 &&
        this.headerClass &&
        this.headerClass.includes(classInfo.Id)
      ) {
        //班主任
        result = course1;
      } else if (this.examination.LRRLX === 1 && course2.length > 0) {
        //只是科任
        result = course2;
      }

      if (result.length > 0) {
        result = [{ Id: "", Name: "全部", NJId: "" }].concat(result);
      }
      console.log(result, "resultresult");
      classInfo.finalCourse = result;
    },
    // 禁用缺考
    disabledQK(row, column) {
      let course = row.CourseScoreList.find(
        (c) => c.CourseId === column.property
      );
      if (course) {
        if (
          course.InputType === 1 &&
          row.CourseScoreList.find((c) => c.CourseId === column.property).Score
        ) {
          return true;
        } else if (
          course.InputType === 2 &&
          row.CourseScoreList.find((c) => c.CourseId === column.property).Level
        ) {
          return true;
        } else return false;
      } else return true;
    },
    // 判断成绩是否变化
    handleChangeScore(item, isImport) {
      // 只传修改过的成绩
      // 判断该学生的成绩是分数还是等级或者是缺考 如果跟原本的成绩有区别 则符合上传条件
      let noChange = [];
      let changed = item.CourseScoreList.filter((c) => {
        let oldScore = this.studentScore.find(
          (old) =>
            old.XSId === item.Id &&
            old.KCH === c.CourseId &&
            old.SSZZJG === item.ClassId
        );
        if (oldScore) {
          // 如果分数或者等级为空 就默认为缺考
          if (!isImport) {
            if (c.InputType === 1 && c.Score !== 0 && !c.Score)
              c.MissTest = true;
            if (c.InputType === 2 && !c.Level) c.MissTest = true;
          }

          //从缺考变为考试
          if (oldScore.SFQK === 1 && !c.MissTest) return true;
          //从考试变为缺考
          else if (oldScore.SFQK === 2 && c.MissTest) return true;
          //考试没有改变
          else if (oldScore.SFQK === 2 && !c.MissTest) {
            if (c.InputType === 1) {
              //判断分数是否改变
              if (!isImport) {
                if (oldScore.KCCJ === 0 || oldScore.KCCJ)
                  return !(oldScore.KCCJ === c.Score);
                else return c.Score === 0 || c.Score;
              } else {
                if (c.Score === 0 || c.Score) {
                  if (oldScore.KCCJ === 0 || oldScore.KCCJ)
                    return !(oldScore.KCCJ === c.Score);
                  else return c.Score === 0 || c.Score;
                } else return false; //导入的时候 没值的情况默认为不变
              }
            } else {
              //判断等级是否改变
              if (!isImport) return !(oldScore.KCDJ === c.Level);
              else {
                if (c.Level) return !(oldScore.KCDJ === c.Level);
                else return false;
              }
            }
          } else if (oldScore.SFQK === 1 && c.MissTest) return false;
          else return false; //新旧成绩都是缺考 也就是没变
        } else return c.MissTest || c.Score === 0 || c.Score || c.Level; // 数据库没得旧成绩的记录
      });
      noChange = item.CourseScoreList.filter(
        (el) => !changed.find((element) => element.CourseId === el.CourseId)
      );
      return { changed, noChange };
    },
  },
};
</script>
<style lang="scss" scoped>
.jf-radio {
  .course-item-block {
    display: flex;
    flex-wrap: wrap;
    .course-item {
      margin: 0 10px 10px 0;
      &.cur-course {
        background-color: #14d089;
        border-radius: 5px;
        ::v-deep .el-radio__label {
          color: #fff;
        }
      }
      ::v-deep .el-radio__input {
        opacity: 0;
      }
      ::v-deep .el-radio {
        border: none;
        padding-left: 0 !important;
      }
    }
  }
}
.butt-box {
  margin-bottom: 10px;
}
.err-msg-container {
  .class-container {
    .class-item {
      padding: 10px;
      border-bottom: 1px solid #ccc;
      .student-item {
        // padding: 5px 0;
      }
    }
  }
}
</style>
