<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<div :class="'maindiv' + this.exportType">
					<div :class="'list' + exportType">
						<!-- 模块 -->
						<div class="module-container">
							<div class="module">
								<div class="module-item" v-for="(item,index) in mklist" :key="index">
									<div class="name">{{item.name}}</div>
									<div class="score" v-if="item.type!=2">{{item.value}}</div>
									<div class="score" v-if="item.type==2&&item.value<=5">
										<img :class="'indexscore' + exportType" v-for="(i,index) in item.value"
											:key="index" src="../../../../style/growth/staricon.png" />
									</div>
									<div class="score" v-if="item.type==2&&item.value>5">
										{{item.value}}颗
										<img :class="'indexscore' + exportType"
											src="../../../../style/growth/staricon.png" />
									</div>
								</div>
							</div>
						</div>
						<div class="list-item" v-for="(item,index) in subject" :key="index">
							<div :class="'infoicon' + exportType"></div>
							<!-- 图表 -->
							<div class="charts-container">
								<div class="title">{{ item.name }}</div>
								<div :id="'charts' + item.id" class="charts"></div>
							</div>
							<!-- 表格 -->
							<div class="index-table" v-if="item.tableType === 1">
								<div class="head">
									<div></div>
									<div v-for="(el, i) in item.table" :key="i">
										{{ el.name }}
									</div>
								</div>
								<div class="head" v-for="it in item.indexlist" :key="it.name">
									<div>{{ it.name }}</div>
									<div v-for="(el, i) in item.table" :key="i">
										<div v-if="it.type!=2" style="border: 0;">{{ el[it.name] }}</div>
										<div v-if="it.type==2&&el[it.name]<=5" style="border: 0;padding-top: 2px;">
											<img :class="'indexscore' + exportType" v-for="(i,index) in el[it.name]"
												:key="index" src="../../../../style/growth/staricon.png" />
										</div>
										<div v-if="item.type==2&&el[it.name]>5">
											{{el[it.name]}}颗
											<img :class="'indexscore' + exportType"
												src="../../../../style/growth/staricon.png" />
										</div>
									</div>
								</div>
							</div>
							<!-- 表格2 -->
							<el-table v-else-if="item.tableType === 2" :data="item.tabdata_arr">
								<el-table-column :prop="hede.props" :label="hede.label"
									v-for="(hede,index) in item.headerlist" :key="index" align="center">
									<template slot-scope="scope">
										<div :class="'text-remark'+exportType" v-if="scope.row[hede.props+'type']!=2">
											{{scope.row[hede.props]}}
										</div>
										<div class="score"
											v-if="scope.row[hede.props+'type']==2&&scope.row[hede.props]<=5">
											<img :class="'indexscore' + exportType"
												v-for="(i,index) in scope.row[hede.props]" :key="index"
												src="../../../../style/growth/staricon.png" />
										</div>
										<div class="score"
											v-if="scope.row[hede.props+'type']==2&&scope.row[hede.props]>5">
											{{scope.row[hede.props]}}颗
											<img :class="'indexscore' + exportType"
												src="../../../../style/growth/staricon.png" />
										</div>
									</template>
								</el-table-column>
							</el-table>
							<!-- 表格3 -->
							<el-table v-else border :span-method="(params)=> objectSpanMethod(params,item)"
								:data="item.tabdata_arr" max-height="610px">
								<el-table-column :prop="hede.props" v-for="(hede,index) in item.headerlist" :key="index"
									:label="hede.label"
									:min-width="hede.props=='description'?(exportType==2?'100':'150'):''" align="center"
									:width="((hede.type==1||hede.type==2)&&(hede.props!='description'))?(exportType==2?'90':'130'):''">
									<template slot-scope="scope">
										<div :class="'text-remark'+exportType" v-if="scope.row[hede.props+'type']!=2">
											{{scope.row[hede.props]}}
										</div>
										<div class="score"
											v-if="scope.row[hede.props+'type']==2&&scope.row[hede.props]<=5">
											<img :class="'indexscore' + exportType"
												v-for="(i,index) in scope.row[hede.props]" :key="index"
												src="../../../../style/growth/staricon.png" />
										</div>
										<div class="score"
											v-if="scope.row[hede.props+'type']==2&&scope.row[hede.props]>5">
											{{scope.row[hede.props]}}颗
											<img :class="'indexscore' + exportType"
												src="../../../../style/growth/staricon.png" />
										</div>
									</template>
								</el-table-column>

							</el-table>
							<div class="remark" v-if="item.tableType==1||item.tableType==3">{{ item.remark }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		number
	} from 'echarts';
	export default {
		data() {
			return {
				exportType: 1,
				xqid: '',
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "语文"
				},
				xsinfo: {
					SSNJ: "",
					NJ: "",
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					ZFS: ""
				},
				subject: [],
				mklist: [],
				myChart: null,
				Type: 0,
				tableType: 1,
				tableData1: [],
				tableData2: [],
				header: [],
				tableData3: [],
				tableData3_Arr: [],
				rowNum: []
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			cons(item) {
				console.log(item)
			},
			init() {},
			handleTable(tablearr) {
				let table_temp = [];
				tablearr.sort((a, b) => {
					return b - a;
				})
				tablearr.forEach(item => {
					if (item == 0) {
						let obj = new Object();
						obj.name = '综合';
						table_temp.push(obj);
					} else if (item == 1) {
						let obj = new Object();
						obj.name = '自评';
						table_temp.push(obj);
					} else if (item == 2) {
						let obj = new Object();
						obj.name = '互评';
						table_temp.push(obj);
					} else if (item == 4) {
						let obj = new Object();
						obj.name = '主评';
						table_temp.push(obj);
					}
				});
				let table = [];
				table_temp.forEach((item) => {
					if (table_temp.length == 2) {
						if (item.name != '综合') {
							table.push(item);
						}
					} else {
						table.push(item);
					}
				})
				this.subject.forEach(item => {
					table.forEach(it => {
						item.indexlist.forEach(el => {
							if (it.name === "主评") {
								it[el.name] = el.main;
							}
							if (it.name === "自评") {
								it[el.name] = el.oneself;
							}
							if (it.name === '综合') {
								it[el.name] = el.synthesize;
							}
							if (it.name === '互评') {
								it[el.name] = el.mutual;
							}
						});
					});
					// console.log(table)
					item.table = table;
				});
			},
			drawBar() {
				// this.chartsAll.forEach(item => item.dispose())
				// this.chartsAll=[]
				this.$nextTick(() => {
					this.subject.forEach(item => {
						// if (this.myChart) {
						// 	this.myChart.dispose();
						// }
						let dom = document.getElementById(`charts${item.id}`);
						this.myChart = this.$echarts.init(dom);
						let dataName = item.indexlist.map(it => it.name);
						let person = item.indexlist.map(it => it.person);
						let classval = item.indexlist.map(it => it.classval);
						let grade = item.indexlist.map(it => it.grade);
						// this.chartsAll.push(myChart)
						let serieslist = [];

						let obj = new Object();

						obj.name = '个人';
						obj.data = person;
						obj.barGap = '0%';
						obj.type = 'bar';
						obj.barMaxWidth = '30px';

						serieslist.push(obj)
						if (this.Type == 1 || this.Type == 3) {
							let obj1 = new Object();
							obj1.name = '班级';
							obj1.data = classval;
							obj1.barGap = '0%';
							obj1.type = 'bar';
							obj1.barMaxWidth = '30px';

							serieslist.push(obj1);
						}
						if (this.Type == 2 || this.Type == 3) {
							let obj2 = new Object();
							obj2.name = '年级';
							obj2.data = grade;
							obj2.barGap = '0%';
							obj2.type = 'bar';
							obj2.barMaxWidth = '30px';

							serieslist.push(obj2);
						}
						// console.log(serieslist,"serieslist")
						let option = {
							color: [
								"rgba(0, 170, 0, 1)",
								"rgba(0,85,255,1)",
								"rgba(255, 170, 0, 1)"
							],
							textStyle: {
								color: "#000000"
							},
							grid: {
								left: '5%',
								bottom: '15%',
								top: '5%'
							},
							legend: {
								orient: "vertical",
								right: "10",
								top: "center",
								textStyle: {
									color: "#000000"
								}
							},
							xAxis: {
								type: "category",
								data: dataName,
								axisLabel: {
									color: "#000000"
								}
							},
							yAxis: [{
								type: "value",
								min: 0,
								interval: 10,
								show: false
							}],
							series: serieslist
						};

						this.myChart.setOption(option);
					})
				})
			},
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.XM = xsdata.XM;
				// this.xsinfo.XQMC = xsdata.XQMC;
				// this.xsinfo.BJMC = xsdata.BJMC;
				var template = JSON.parse(localStorage.getItem("schooltemplate_" + this.xqid)).XYCZ;
				// console.log(template, 'template')
				var templatedata = JSON.parse(xsdata.XYCZ);
				// console.log(templatedata, 'templatedata')
				if (templatedata) {
					// console.log(templatedata, "templatedata");
					//图表展示范围
					this.Type = template.Type;
					//获取JSON对应学科数据
					let temp_data = templatedata.find(o => o.Id == this.info.GLId);
					// console.log(temp_data, 'temp_data');
					let terms = JSON.parse(localStorage.getItem("terms"));
					let templateTerm = localStorage.getItem("templateTerm");
					let currentterm = localStorage.getItem("currentterm");
					let templateTermModel = terms.find(o => o.Id == templateTerm);
					let currenttermModel = terms.find(o => o.Id == currentterm);
					this.xsinfo.NJ = parseInt(this.xsinfo.NJ) - (parseInt(currenttermModel.SSXN.substr(0, 4)) - parseInt(
						templateTermModel.SSXN.substr(0, 4)));
					//获取模板对应学科对应年级数据
					let temp_temp = template.XYCZXQ.find(o => o.Id == this.info.GLId && o.NJ.find(y => y == this.xsinfo
						.NJ));
					// console.log(temp_temp, 'temp_temp')
					this.mklist = [];
					this.subject = [];
					this.tableData2 = [];
					this.tableData3 = [];
					let tablearr = [];
					//JSON展示模块
					// console.log(temp_temp, "temp_temp")
					// console.log(temp_data, "temp_data")
					if (temp_temp.ZSMK && temp_data) {
						for (let i = 0; i < temp_data.ZSMK.length; i++) {
							//获取对应模板展示模块
							let zs_temp = temp_temp.ZSMK.find(o => o.Id == temp_data.ZSMK[i].Id);
							if (zs_temp) {
								// console.log(zs_temp)
								//为顶部模块列表赋值
								let obj = new Object();
								obj.name = zs_temp.Name;
								obj.type = zs_temp.Type;
								obj.pxh = zs_temp.PXH == 0 ? 99 : zs_temp.PXH;
								if (zs_temp.Type == 2) {
									obj.value = parseFloat(temp_data.ZSMK[i].Value);
								} else {
									obj.value = temp_data.ZSMK[i].Value;
								}
								this.mklist.push(obj);

								//判断模块数不大于2个
								if (this.subject.length < 2) {
									if (zs_temp.Zsfs > 0) {
										//模块数据外层
										let obj_sub = new Object();
										obj_sub.id = temp_data.ZSMK[i].Id;
										obj_sub.name = zs_temp.Name;
										obj_sub.indexlist = [];
										obj_sub.pxh = zs_temp.PXH == 0 ? 99 : zs_temp.PXH;
										obj_sub.table = [];
										obj_sub.headerlist = [];
										obj_sub.tabdata = [];
										obj_sub.tableType = 1;
										obj_sub.remark = '';

										// console.log(zs_temp, "zs_temp")

										//横向 通用
										if (zs_temp.Zsfs == 1) {
											obj_sub.tableType = 1;

											//JSON字段模块循环
											for (let y = 0; y < temp_data.ZSMK[i].ZDMK.length; y++) {
												//JSON字段模块数据
												let zszd_data = temp_data.ZSMK[i].ZDMK[y];

												//获取模板字段模块
												let zszd_temp = zs_temp.ZDMK.find(o => o.Id == zszd_data.Id);

												//评语赋值
												if (zs_temp.Zslx == 1) { //1自定义描述语
													obj_sub.remark = zs_temp.ms ?? '';
												} else if (zs_temp.Zslx ==
													2) { //2等级描述(前缀？+返回评语？or zdms([字段+主评等级描述]+sfkq&&mkms)
													if (zszd_data.Type == zs_temp.Hdxs) {
														obj_sub.remark = zs_temp.Qzms ?? '';
														obj_sub.remark += temp_data.ZSMK[i].Py ?? '';
														let zsms_temp = zs_temp.ZDMS.find(o => o.Dj == zszd_data.Value && o
															.ZdId ==
															zszd_data.Id);
														obj_sub.remark += zsms_temp ? (zsms_temp.Value ?? '') : '';
														obj_sub.remark += zsms_temp ? (zsms_temp.Ms ?? '') : '';
														if (zs_temp.Sfkq) {
															let mkms_temp = zs_temp.MKMS.find(o => o.Dj == temp_data.ZSMK[
																	i]
																.Value);
															obj_sub.remark += mkms_temp ? (mkms_temp.Ms ?? '') : '';
														}
													}

												} else if (zs_temp.Zslx == 3) { //3使用统计数据展示(前缀？+返回评语？+sfkq&&mkms)
													if (obj_sub.remark == '') {
														obj_sub.remark = zs_temp.Qzms ?? '';
														obj_sub.remark += temp_data.ZSMK[i].Py ?? '';
														if (zs_temp.Sfkq) {
															let mkms_temp = zs_temp.MKMS.find(o => o.Dj == temp_data.ZSMK[
																	i]
																.Value);
															obj_sub.remark += mkms_temp ? (mkms_temp.Ms ?? '') : '';
														}
													}
												} else if (zs_temp.Zslx ==
													4) { //4展示字段等级描述(返回评语？or zdms([字段+主评等级描述]+sfkq&&mkms)
													if (zszd_data.Type == zs_temp.Hdxs) {
														obj_sub.remark = temp_data.ZSMK[i].Py ?? '';
														let zsms_temp = zs_temp.ZDMS.find(o => o.Dj == zszd_data.Value && o
															.ZdId ==
															zszd_data.Id);
														obj_sub.remark += zsms_temp ? (zsms_temp.Value ?? '') : '';
														obj_sub.remark += zsms_temp ? (zsms_temp.Ms ?? '') : '';
														if (zs_temp.Sfkq) {
															let mkms_temp = zs_temp.MKMS.find(o => o.Dj == temp_data.ZSMK[
																	i]
																.Value);
															obj_sub.remark += mkms_temp ? (mkms_temp.Ms ?? '') : '';
														}
													}
												}

												//获取指定模块名称的已缓存数据
												let item = obj_sub.indexlist.find(o => o.name == zszd_temp.Name);
												if (item) { //存在，判断对应类型，为不同字段赋值，用于表格展示									
													if (zszd_data.Type == 1) {
														if (zszd_temp.Type == 2) {
															item.oneself = parseFloat(zszd_data.Value);
														} else {
															item.oneself = zszd_data.Value;
														}
													} else if (zszd_data.Type == 2) {
														if (zszd_temp.Type == 2) {
															item.mutual = parseFloat(zszd_data.Value);
														} else {
															item.mutual = zszd_data.Value;
														}
													} else if (zszd_data.Type == 4) {
														if (zszd_temp.Type == 2) {
															item.main = parseFloat(zszd_data.Value);
														} else {
															item.main = zszd_data.Value;
														}
													} else if (zszd_data.Type == 0) { //类型为0(综合)的将缓存为展示图表数据
														if (zszd_temp.Type == 2) {
															item.synthesize = parseFloat(zszd_data.Value);
														} else {
															item.synthesize = zszd_data.Value;
														}
														item.person = zszd_data.FS;
														item.classval = zszd_data.CAVG;
														item.grade = zszd_data.GAVG;
													}
												} else { //不存在则新增，判断对应类型，为不同字段赋值，用于表格展示	
													let obj_index = new Object();
													obj_index.name = zszd_temp.Name;
													obj_index.type = zszd_temp.Type;
													if (zszd_data.Type == 0) { //类型为0(综合)的将缓存为展示图表数据
														obj_index.person = zszd_data.FS;
														obj_index.classval = zszd_data.CAVG;
														obj_index.grade = zszd_data.GAVG;
														if (zszd_temp.Type == 2) {
															obj_index.synthesize = parseFloat(zszd_data.Value);
														} else {
															obj_index.synthesize = zszd_data.Value;
														}
													} else if (zszd_data.Type == 1) {
														if (zszd_temp.Type == 2) {
															obj_index.oneself = parseFloat(zszd_data.Value);
														} else {
															obj_index.oneself = zszd_data.Value;
														}
													} else if (zszd_data.Type == 2) {
														if (zszd_temp.Type == 2) {
															obj_index.mutual = parseFloat(zszd_data.Value);
														} else {
															obj_index.mutual = zszd_data.Value;
														}
													} else if (zszd_data.Type == 4) {
														// console.log(zszd_temp, 'zszd_temp')
														// console.log(zszd_data, 'zszd_data')
														if (zszd_temp.Type == 2) {
															obj_index.main = parseFloat(zszd_data.Value);
														} else {
															obj_index.main = zszd_data.Value;
														}
													}
													obj_sub.indexlist.push(obj_index);
												}
												//判断表格数据行类型
												if (!tablearr.includes(zszd_data.Type)) {
													// console.log(tablearr, zszd_data.Type, !tablearr.includes(zszd_data.Type))
													tablearr.push(zszd_data.Type);
												}
											}
											// console.log(temp_data.ZSMK[i], 'temp_data.ZSMK[i]')
											// console.log(zs_temp, 'zs_temp')
										} else if (zs_temp.Zsfs == 2) { //纵向 星星、问卷
											let head_list = [];
											this.tableData3 = [];
											this.header = [];

											let obj_head = new Object();
											obj_head.props = 'indexname1';
											obj_head.label = '问卷名称';
											obj_head.sort = 1;
											this.header.push(obj_head);

											obj_head = new Object();
											obj_head.props = 'indexname2';
											obj_head.label = '评价项';
											obj_head.sort = 2;
											this.header.push(obj_head);
											// console.log(zs_temp, "zs_temp")
											if (zs_temp.Zslx == 5) { //问卷
												// console.log(xsdata.WJXQ, 'WJXQ')
												obj_sub.tableType = 3;
												for (let y = 0; y < temp_data.ZSMK[i].ZDMK.length; y++) {
													let zszd_data = temp_data.ZSMK[i].ZDMK[y];
													if (zszd_data.Type == 0) {
														let zszd_temp = zs_temp.ZDMK.find(o => o.Id == zszd_data.Id);
														//组装图表数据
														let obj_index = new Object();
														obj_index.name = zszd_temp.Name;
														obj_index.person = zszd_data.FS;
														obj_index.classval = zszd_data.CAVG;
														obj_index.grade = zszd_data.GAVG;
														obj_sub.indexlist.push(obj_index);
													}
												}
												if (zs_temp.Sfkq || zs_temp.sfkq) {
													//组装表头
													obj_head = new Object();
													obj_head.props = 'description';
													obj_head.label = '描述';
													obj_head.type = 1;
													obj_head.sort = 7;
													this.header.push(obj_head);
												}

												for (let y = 0; y < template.WJXQ.length; y++) {
													let wj = template.WJXQ[y].MKId.find(o => o == zs_temp.Id);
													if (wj) {
														let obj_wj = new Object();
														obj_wj.id = template.WJXQ[y].Id;
														obj_wj.indexname1 = template.WJXQ[y].Name;
														obj_wj.data = [];
														for (let t = 0; t < template.WJXQ[y].WJTM.length; t++) {
															// console.log(template.WJXQ[y].WJTM[t], 'template.WJXQ[y].WJTM[t]')
															let tm_data = xsdata.WJXQ.filter(o => o.Id == template.WJXQ[y]
																.Id &&
																o.TMId == template.WJXQ[y].WJTM[t].Id &&
																(o.XKId == this.info.GLId || o.PJXS == 0));
															for (let q = 0; q < tm_data.length; q++) {
																if (!head_list.find(o => o.bttype == tm_data[q].PJXS)) {
																	let obj_hedertype = new Object();
																	obj_hedertype.bttype = tm_data[q].PJXS;
																	obj_hedertype.pjtype = 1;
																	head_list.push(obj_hedertype);
																}

																let obj_tm = new Object();

																obj_tm.indexname2 = template.WJXQ[y].WJTM[t].TMMC;
																if (tm_data[q].PJXS == 0) {
																	obj_tm.zih = tm_data[q].TMDA;
																} else if (tm_data[q].PJXS == 1) {
																	obj_tm.zip = tm_data[q].TMDA;
																} else if (tm_data[q].PJXS == 2) {
																	obj_tm.hup = tm_data[q].TMDA;
																} else if (tm_data[q].PJXS == 4) {
																	obj_tm.score = tm_data[q].TMDA;
																}
																let dams_temp = template.WJXQ[y].WJTM[t].WJDA.find(o => o
																	.TMDA ==
																	tm_data[q].TMDA);
																if (dams_temp) {
																	obj_tm.description = dams_temp.DAMS;
																}
																obj_wj.data.push(obj_tm);
															}
														}
														this.tableData3.push(obj_wj);
													}
												}
											} else { //星星
												obj_sub.tableType = 3;

												//表头
												if (zs_temp.sfkq) {
													//组装表头
													obj_head = new Object();
													obj_head.props = 'description';
													obj_head.label = '描述';
													obj_head.sort = 7;
													this.header.push(obj_head);
												}

												let obj_wj = new Object();
												obj_wj.id = zs_temp.Id;
												obj_wj.indexname1 = zs_temp.Name;
												obj_wj.data = [];
												//JSON字段模块循环
												for (let y = 0; y < temp_data.ZSMK[i].ZDMK.length; y++) {
													//JSON字段模块数据
													let zszd_data = temp_data.ZSMK[i].ZDMK[y];

													//获取模板字段模块
													let zszd_temp = zs_temp.ZDMK.find(o => o.Id == zszd_data.Id);

													//评语赋值
													if (zs_temp.Zslx == 1) { //1自定义描述语
														obj_sub.remark = zs_temp.ms ?? '';
													} else if (zs_temp.Zslx ==
														2) { //2等级描述(前缀？+返回评语？or zdms([字段+主评等级描述]+sfkq&&mkms)
														if (zszd_data.Type == zs_temp.Hdxs) {
															obj_sub.remark = zs_temp.Qzms ?? '';
															obj_sub.remark += temp_data.ZSMK[i].Py ?? '';
															let zsms_temp = zs_temp.ZDMS.find(o => o.Dj == zszd_data
																.Value && o
																.ZdId ==
																zszd_data.Id);
															obj_sub.remark += zsms_temp ? (zsms_temp.Value ?? '') : '';
															obj_sub.remark += zsms_temp ? (zsms_temp.Ms ?? '') : '';
															if (zs_temp.Sfkq) {
																let mkms_temp = zs_temp.MKMS.find(o => o.Dj == temp_data
																	.ZSMK[
																		i]
																	.Value);
																obj_sub.remark += mkms_temp ? (mkms_temp.Ms ?? '') : '';
															}
														}

													} else if (zs_temp.Zslx == 3) { //3使用统计数据展示(前缀？+返回评语？+sfkq&&mkms)
														if (obj_sub.remark == '') {
															obj_sub.remark = zs_temp.Qzms ?? '';
															obj_sub.remark += temp_data.ZSMK[i].Py ?? '';
															if (zs_temp.Sfkq) {
																let mkms_temp = zs_temp.MKMS.find(o => o.Dj == temp_data
																	.ZSMK[
																		i]
																	.Value);
																obj_sub.remark += mkms_temp ? (mkms_temp.Ms ?? '') : '';
															}
														}
													} else if (zs_temp.Zslx ==
														4) { //4展示字段等级描述(返回评语？or zdms([字段+主评等级描述]+sfkq&&mkms)
														if (zszd_data.Type == zs_temp.Hdxs) {
															obj_sub.remark = temp_data.ZSMK[i].Py ?? '';
															let zsms_temp = zs_temp.ZDMS.find(o => o.Dj == zszd_data
																.Value && o
																.ZdId ==
																zszd_data.Id);
															obj_sub.remark += zsms_temp ? (zsms_temp.Value ?? '') : '';
															obj_sub.remark += zsms_temp ? (zsms_temp.Ms ?? '') : '';
															if (zs_temp.Sfkq) {
																let mkms_temp = zs_temp.MKMS.find(o => o.Dj == temp_data
																	.ZSMK[
																		i]
																	.Value);
																obj_sub.remark += mkms_temp ? (mkms_temp.Ms ?? '') : '';
															}
														}
													}

													if (!head_list.find(o => o.bttype == zszd_data.Type)) {
														let obj_hedertype = new Object();
														obj_hedertype.bttype = zszd_data.Type;
														obj_hedertype.pjtype = zszd_temp.Type;
														head_list.push(obj_hedertype);
													}

													if (zszd_data.Type == 0) { //类型为0(综合)的将缓存为展示图表数据
														let obj_index = new Object();
														obj_index.name = zszd_temp.Name;
														obj_index.type = zs_temp.Type;
														obj_index.person = zszd_data.FS;
														obj_index.classval = zszd_data.CAVG;
														obj_index.grade = zszd_data.GAVG;
														obj_sub.indexlist.push(obj_index);
													}

													let wjdata = obj_wj.data.find(o => o.indexname2 == zszd_temp.Name)
													if (wjdata) {
														if (zszd_data.Type == 0) {
															if (zszd_temp.Type == 2) {
																wjdata.zih = parseFloat(zszd_data.Value);
															} else {
																wjdata.zih = zszd_data.Value;
															}
															wjdata.zihtype = zszd_temp.Type;
														} else if (zszd_data.Type == 1) {
															if (zszd_temp.Type == 2) {
																wjdata.zip = parseFloat(zszd_data.Value);
															} else {
																wjdata.zip = zszd_data.Value;
															}
															wjdata.ziptype = zszd_temp.Type;
														} else if (zszd_data.Type == 2) {
															if (zszd_temp.Type == 2) {
																wjdata.hup = parseFloat(zszd_data.Value);
															} else {
																wjdata.hup = zszd_data.Value;
															}
															wjdata.huptype = zszd_temp.Type;
														} else if (zszd_data.Type == 4) {
															if (zszd_temp.Type == 2) {
																wjdata.score = parseFloat(zszd_data.Value);
															} else {
																wjdata.score = zszd_data.Value;
															}
															wjdata.scoretype = zszd_temp.Type;
														}
													} else {
														let obj_tm = new Object();

														obj_tm.indexname2 = zszd_temp.Name;
														if (zszd_data.Type == 0) {
															if (zszd_temp.Type == 2) {
																obj_tm.zih = parseFloat(zszd_data.Value);
															} else {
																obj_tm.zih = zszd_data.Value;
															}
															obj_tm.zihtype = zszd_temp.Type;
														} else if (zszd_data.Type == 1) {
															if (zszd_temp.Type == 2) {
																obj_tm.zip = parseFloat(zszd_data.Value);
															} else {
																obj_tm.zip = zszd_data.Value;
															}
															obj_tm.ziptype = zszd_temp.Type;
														} else if (zszd_data.Type == 2) {
															if (zszd_temp.Type == 2) {
																obj_tm.hup = parseFloat(zszd_data.Value);
															} else {
																obj_tm.hup = zszd_data.Value;
															}
															obj_tm.huptype = zszd_temp.Type;
														} else if (zszd_data.Type == 4) {
															if (zszd_temp.Type == 2) {
																obj_tm.score = parseFloat(zszd_data.Value);
															} else {
																obj_tm.score = zszd_data.Value;
															}
															obj_tm.scoretype = zszd_temp.Type;
														}
														// let dams_temp = zs_temp.ZDMS.WJDA.find(o => o.Dj == tm_data[q].TMDA&&ZdId==);
														// if (dams_temp) {
														obj_tm.description = '';
														// }
														obj_wj.data.push(obj_tm);
													}
												}
												this.tableData3.push(obj_wj);
											}
											head_list.forEach((item) => {
												if (item.bttype == 0) {
													//组装表头
													obj_head = new Object();
													obj_head.props = 'zih';
													obj_head.label = '综合';
													obj_head.type = item.pjtype;
													obj_head.sort = 6;
													this.header.push(obj_head);
												} else if (item.bttype == 1) {
													//组装表头
													obj_head = new Object();
													obj_head.props = 'zip';
													obj_head.label = '自评';
													obj_head.type = item.pjtype;
													obj_head.sort = 5;
													this.header.push(obj_head);
												} else if (item.bttype == 2) {
													//组装表头
													obj_head = new Object();
													obj_head.props = 'hup';
													obj_head.label = '互评';
													obj_head.type = item.pjtype;
													obj_head.sort = 4;
													this.header.push(obj_head);
												} else if (item.bttype == 4) {
													//组装表头
													obj_head = new Object();
													obj_head.props = 'score';
													obj_head.label = '主评';
													obj_head.type = item.pjtype;
													obj_head.sort = 3;
													this.header.push(obj_head);
												}
											})
										} else {
											obj_sub.tableType = 2;

											let obj_head = new Object();
											obj_head.props = 'score';
											obj_head.label = zs_temp.Name;
											obj_head.type = zs_temp.Type;
											obj_head.sort = 1;
											this.header.push(obj_head);

											let obj_wj = new Object();
											obj_wj.id = zs_temp.Id;
											obj_wj.indexname1 = '';
											obj_wj.data = [];
											let obj_tm = new Object();

											obj_tm.indexname2 = '';
											obj_tm.score = temp_data.ZSMK[i].Value;
											obj_tm.scoretype = zs_temp.Type;
											obj_wj.data.push(obj_tm);

											this.tableData3.push(obj_wj);
										}

										//对表头进行排序
										this.header.sort((a, b) => {
											return a.sort - b.sort
										});
										this.header.forEach((item) => {
											obj_sub.headerlist.push(item);
										});
										this.tableData3.forEach((item) => {
											//判断问卷问题行不超过3行
											if (obj_sub.tabdata.length < 4) {
												obj_sub.tabdata.push(item);
											}
										})

										this.subject.push(obj_sub);
									}
								}
							}
						}
						let zp_temp = temp_temp.ZSMK.find(o => o.Id == 'MX');
						if (zp_temp) {
							let obj1 = new Object();
							obj1.name = zp_temp.Name;
							obj1.value = temp_data.XValue;
							obj1.pxh = 100;

							this.mklist.push(obj1);
						}
					}
					// for (let i = 0; i < 14; i++) {
					// this.mklist.push(obj1);
					// }
					this.mklist.sort((a, b) => {
						return a.pxh - b.pxh;
					})
					this.subject.sort((a, b) => {
						return a.pxh - b.pxh;
					})
					this.subject.forEach((item) => {
						if (item.tableType == 1) {
							this.handleTable(tablearr);
						} else if (item.tableType == 2) {
							// console.log(this.tableType);
							this.handleTableData();
						} else {
							this.handleTableData();
						}
					})

					this.drawBar();
				}
			},
			handleTableData() {
				let rowNum = [];
				this.subject.forEach((sub_item) => {
					sub_item.tabdata_arr = sub_item.tabdata.reduce((pre, cur, index) => {
						rowNum.push({
							start: index === 0 ? index : rowNum[index - 1].start + rowNum[index -
									1].data
								.length,
							data: []
						});
						cur.data.forEach((item, idx) => {
							if (index === 0) {
								rowNum[0].data.push(idx);
							} else {
								rowNum[index].data.push(rowNum[index].start + idx);
							}
							pre.push({
								indexname1: cur.indexname1,
								...item
							});
						});
						return pre;
					}, []);
					sub_item.rowNum = rowNum;
				})
			},
			objectSpanMethod(e, data) {
				if (e.columnIndex === 0) {
					let res = data.rowNum.find(item => item.start === e.rowIndex);
					if (res) {
						return {
							rowspan: res.data.length,
							colspan: 1
						};
					} else
						return {
							rowspan: 0,
							colspan: 0
						};
				}
			},

		},
		mounted() {
			this.init();
		}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: #2ea9b1;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: #2ea9b1;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/dot_orange_radius.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		height: 1000px;
		width: 100%;
		padding-top: 10px;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/dot_orange_radius.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 715px;
		width: 100%;
	}

	.marchart1 {
		height: 395px;
		width: 395px;
		border-radius: 15px;
	}

	.marchart2 {
		height: 270px;
		width: 270px;
		border-radius: 15px;
	}

	.firstcharttitle1 {
		border-radius: 20px;
		height: 80px;
		width: 750px;
		background-image: url("../../../../style/growth/purple_title_bar.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		line-height: 80px;
		padding-left: 30px;
	}

	.firstcharttitle2 {
		border-radius: 15px;
		height: 60px;
		width: 535px;
		background-image: url("../../../../style/growth/purple_title_bar.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		line-height: 60px;
		padding-left: 20px;
	}

	.secondcharttitle1 {
		border-radius: 20px;
		height: 80px;
		width: 750px;
		background-image: url("../../../../style/growth/blue_title_bar.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		line-height: 80px;
		padding-left: 30px;
	}

	.secondcharttitle2 {
		border-radius: 15px;
		height: 60px;
		width: 535px;
		background-image: url("../../../../style/growth/blue_title_bar.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		line-height: 60px;
		padding-left: 20px;
	}

	.maindiv1 {
		width: 756px;
		height: 960px;
		// background: #fbfbfb;
		margin-top: 30px;
		margin-left: 40px;
		// border-radius: 20px;
		// margin: 20px 30px 20px 30px;
	}

	.maindiv2 {
		height: 682px;
		// background: #fbfbfb;
		// border-radius: 15px;
		// margin: 20px 20px 10px 20px;
	}

	.zbrow1 {
		width: 300px;
		height: 100px;
		background: #ffffff;
		border-radius: 15px;
		margin-top: 50px;
	}

	.zbrow2 {
		width: 240px;
		height: 80px;
		background: #ffffff;
		border-radius: 10px;
		margin-top: 35px;
	}

	.zbmccol1 {
		font-size: 45px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 100px;
	}

	.zbmccol2 {
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 80px;
	}

	.zbscorecol1 {
		font-size: 45px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #602897;
		line-height: 100px;
	}

	.zbscorecol2 {
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #602897;
		line-height: 80px;
	}

	.indexscore1 {
		width: 18px;
		height: 18px;
		line-height: 35px;
		margin: 0 5px;
	}

	.indexscore2 {
		width: 14px;
		height: 14px;
		line-height: 14px;
		margin: 3px 0 0 3px;
	}

	.infoicon1 {
		float: left;
		width: 8px;
		height: 40px;
		background: linear-gradient(0deg, #ff5f88, #ffb983);
	}

	.infoicon2 {
		float: left;
		width: 6px;
		height: 25px;
		background: linear-gradient(0deg, #ff5f88, #ffb983);
	}

	.list1 {
		.module-container {
			padding: 10px;

			.module {
				display: flex;
				color: #0055ff;
				font-size: 16px;
				flex-wrap: wrap;

				.module-item {
					flex: 1;
					display: flex;
					flex-direction: column;
					border: 1px solid #0055ff;
					border-radius: 5px;
					margin: 0 5px 5px 0;
					max-width: 140px;
					overflow: hidden;
					min-width: 140px;

					.name {
						font-size: 14px;
						display: flex;
						justify-content: center;
						align-items: center;
						// padding: 5px 10px;
						text-align: center;
						// border-right: 1px solid #0055ff;
						// border-bottom: 1px solid #0055ff;
						background: #0055ff;
						color: #ffffff;
						height: 25px;
					}

					.score {
						height: 20px;
						display: flex;
						justify-content: center;
						align-items: center;
						// padding: 5px 10px;
						text-align: center;
						// border-right: 1px solid #0055ff;
						// border-bottom: 1px solid #0055ff;
						font-weight: 600;
						height: 25px;
					}

					.score.asdf {
						color: red;
						width: 50px;
					}
				}

				// .module-item:first-child {
				// 	border-left: 1px solid #0055ff;
				// }
			}
		}

		.charts-container {
			font-weight: 600;
		}

		.list-item {
			margin-bottom: 15px;
			padding: 0 10px;
			color: #000000;

			.title {
				padding: 10px 0 0 15px;
				font-size: 16px;
			}

			.charts {
				width: 730px;
				height: 230px;
			}

			.index-table {
				display: flex;
				border-left: 1px solid #888888;

				.head {
					flex: 1;
					display: flex;
					flex-direction: column;
					// max-width: 150px;
					border-top: 1px solid #888888;

					div {
						text-align: center;
						flex: 1;
						border-right: 1px solid #888888;
						border-bottom: 1px solid #888888;
						// padding: 10px;
						font-size: 14px;
						overflow: hidden;
					}
				}
			}

			.remark {
				padding: 5px 0;
				font-size: 16px;
				text-indent: 2em;
			}
		}

		// .list-item:nth-child(2n + 1) {
		// 	background-color: #ed8591;

		// 	.index-table {
		// 		background-color: #ed9ba5;
		// 	}
		// }
	}

	.list2 {
		.module-container {
			padding: 30px 30px 10px 30px;

			.module {
				display: flex;
				color: #0055ff;
				font-size: 16px;
				flex-wrap: wrap;

				.module-item {
					flex: 1;
					display: flex;
					flex-direction: column;
					border: 1px solid #0055ff;
					border-radius: 5px;
					margin: 0 5px 5px 0;
					max-width: 126.5px;
					overflow: hidden;
					min-width: 126.5px;

					.name {
						font-size: 14px;
						display: flex;
						justify-content: center;
						align-items: center;
						// padding: 5px 10px;
						text-align: center;
						// border-right: 1px solid #0055ff;
						// border-bottom: 1px solid #0055ff;
						background: #0055ff;
						color: #ffffff;
						height: 25px;
					}

					.score {
						height: 20px;
						display: flex;
						justify-content: center;
						align-items: center;
						// padding: 5px 10px;
						text-align: center;
						// border-right: 1px solid #0055ff;
						// border-bottom: 1px solid #0055ff;
						font-weight: 600;
						height: 25px;
					}

					.score.asdf {
						color: red;
						width: 50px;
					}
				}

				// .module-item:first-child {
				// 	border-left: 1px solid #0055ff;
				// }
			}
		}

		.charts-container {
			font-weight: 600;
			height: 150px;
			width: 530px;
		}

		.list-item {
			margin-bottom: 5px;
			padding: 0 30px;
			color: #000000;

			.title {
				padding: 2px 0 2px 15px;
				font-size: 16px;
			}

			.charts {
				width: 530px;
				height: 120px;
			}

			.index-table {
				display: flex;
				border-left: 1px solid #888888;

				.head {
					flex: 1;
					display: flex;
					flex-direction: column;
					// max-width: 150px;
					border-top: 1px solid #888888;

					div {
						text-align: center;
						flex: 1;
						border-right: 1px solid #888888;
						border-bottom: 1px solid #888888;
						// padding: 10px;
						font-size: 14px;
						overflow: hidden;
					}
				}
			}

			.remark {
				padding: 5px 0;
				font-size: 16px;
				text-indent: 2em;
			}
		}

		// .list-item:nth-child(2n + 1) {
		// 	background-color: #ed8591;

		// 	.index-table {
		// 		background-color: #ed9ba5;
		// 	}
		// }
	}

	.el-table {
		::v-deep .el-table__cell {
			padding: 2px;

			.cell {
				padding: 0;
			}
		}
	}

	.score {
		height: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		// padding: 5px 10px;
		text-align: center;
		// border-right: 1px solid #0055ff;
		// border-bottom: 1px solid #0055ff;
		font-weight: 600;
		height: 25px;

	}

	.text-remark1 {
		font-weight: 600;
	}

	.text-remark2 {
		font-weight: 600;
	}
</style>