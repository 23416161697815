var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cont-whole-box"},[_c('div',{staticClass:"cent-topbutt-box",staticStyle:{"padding-top":"10px"}},[_c('div',{staticClass:"top-butt-box"},[_c('div',{class:[
          _vm.classType === 1 ? 'cent-butt-sty whole-col' : '',
          'cent-butt whole-col-hov'
        ],on:{"click":function($event){return _vm.switchTab(1)}}},[_vm._v(" 个人分体系 ")]),_c('div',{class:[
          _vm.classType === 2 ? 'cent-butt-sty whole-col' : '',
          'cent-butt whole-col-hov'
        ],on:{"click":function($event){return _vm.switchTab(2)}}},[_vm._v(" 个人特色体系 ")]),_c('div',{class:[
          _vm.classType === 3 ? 'cent-butt-sty whole-col' : '',
          'cent-butt whole-col-hov'
        ],on:{"click":function($event){return _vm.switchTab(3)}}},[_vm._v(" 班级成长体系 ")]),_c('div',{class:[
          _vm.classType === 4 ? 'cent-butt-sty whole-col' : '',
          'cent-butt whole-col-hov'
        ],on:{"click":function($event){return _vm.switchTab(4)}}},[_vm._v(" 个人综合分设置 ")]),_c('div',{class:[
          _vm.classType === 5 ? 'cent-butt-sty whole-col' : '',
          'cent-butt whole-col-hov'
        ],on:{"click":function($event){return _vm.switchTab(5)}}},[_vm._v(" 班级综合分设置 ")]),_c('div',{class:[
          _vm.classType === 6 ? 'cent-butt-sty whole-col' : '',
          'cent-butt whole-col-hov'
        ],on:{"click":function($event){return _vm.switchTab(6)}}},[_vm._v(" 背景图设置 ")]),_c('div',{class:[
          _vm.classType === 7 ? 'cent-butt-sty whole-col' : '',
          'cent-butt whole-col-hov'
        ],on:{"click":function($event){return _vm.switchTab(7)}}},[_vm._v(" 个人成长树 ")]),_c('div',{class:[
          _vm.classType === 8 ? 'cent-butt-sty whole-col' : '',
          'cent-butt whole-col-hov'
        ],on:{"click":function($event){return _vm.switchTab(8)}}},[_vm._v(" 班级树 ")])])]),_c('div',{staticStyle:{"padding":"0px 30px"}},[_c('div',{staticClass:"tab-heng-box",staticStyle:{"margin-top":"10px"}},[_c('router-view',{attrs:{"activities":_vm.activities,"setList":_vm.setList,"backGroundImageData":_vm.backGroundImageData,"classScoreSet":_vm.classScoreSet,"personScoreSet":_vm.personScoreSet},on:{"saveBackGroundImageData":_vm.save,"getGroupSet":_vm.getGroupSet}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }