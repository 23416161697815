<template>
  <el-dialog
    :title="`${fatherData.XKXZ}(${fatherData.NJXZ})`"
    :visible.sync="show"
    width="90%"
    :show-close="false"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="ZSMK-container">
      <div class="ZSMK-item" v-for="(item, index) in form.MKlist" :key="index">
        <div class="MK-top">
          <div class="name" @click="editMK_Name(item, index)">
            {{ item.MC }}
          </div>
          <div class="sort" @click="editMK_Name(item, index)">
            排序：{{ item.PXH }}
          </div>
          <el-select
            class="select1"
            size="small"
            v-model="item.SZLX"
            placeholder="请选择"
          >
            <el-option
              v-for="item in levelTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-show="item.SZLX === 1"
            class="select2"
            size="small"
            filterable
            v-model="item.GLDJId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in levelOptions.filter(item => item.DJLX === 2)"
              :key="item.Id"
              :label="item.DJMC"
              :value="item.Id"
            >
            </el-option>
          </el-select>
          <el-button
            size="small"
            style="margin-left:10px"
            type="warning"
            @click="delMK(item, index)"
            >删除此模块</el-button
          >
          <el-button size="small" type="success" @click="addIndex(item)"
            >新增字段</el-button
          >
          <el-button size="small" type="primary" @click="ZSset(item)"
            >展示设置</el-button
          >
        </div>
        <div class="MK-talbe">
          <el-table :data="item.zdList" style="width: 100%">
            <el-table-column prop="XH" label="序号" />
            <el-table-column prop="MC" label="字段名称" />
            <el-table-column label="指标名称">
              <template slot-scope="{ row }">
                <span>{{
                  row.ZSMKGLZBInfo && row.ZSMKGLZBInfo.length > 0
                    ? row.ZSMKGLZBInfo.map(zb => zb.ZBMC).join()
                    : '----'
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="显示方式">
              <template slot-scope="scope">
                {{ scope.row | handleShowFormat(levelOptions) }}
              </template>
            </el-table-column>
            <el-table-column label="占比">
              <template slot-scope="scope">
                <span>{{ scope.row.BFB }}%</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="small"
                  @click="editIndex(item, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  size="small"
                  @click="delIndex(scope.row, index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div class="addZSMK">
      <el-button
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="newForm.show = true"
        >增加展示模块</el-button
      >
    </div>

    <div class="QMCC">
      <el-checkbox v-model="form.QMCC.checked">期末测查</el-checkbox>
      <el-input-number
        v-model="form.QMCC.BFB"
        size="small"
        :precision="2"
        :min="0"
        :max="10000"
        placeholder="请输入内容"
      />%
      <el-checkbox-group v-model="form.QMCC.checkList">
        <el-checkbox :label="1">月考</el-checkbox>
        <el-checkbox :label="2">半期</el-checkbox>
        <el-checkbox :label="3">期末</el-checkbox>
      </el-checkbox-group>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('update:show', false)">关 闭</el-button>
      <el-button type="primary" @click="AddShowModule">保 存</el-button>
    </span>

    <!-- 新增模块弹窗 -->
    <el-dialog
      title="新增模块"
      :visible.sync="newForm.show"
      width="30%"
      append-to-body
      :close-on-click-modal="false"
      :show-close="false"
    >
      <el-form ref="newForm" :model="newForm" :rules="newForm.rules">
        <el-form-item prop="name">
          <el-input
            v-model="newForm.name"
            placeholder="输入名称：1-20字之间"
          ></el-input>
        </el-form-item>
        <el-form-item prop="xh">
          <el-input-number
            :min="1"
            v-model="newForm.xh"
            placeholder="请设置排序"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newForm.show = false">取 消</el-button>
        <el-button type="primary" @click="addNew">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增字段时 勾选活动弹窗 -->
    <el-dialog
      title="请选择活动"
      :visible.sync="activityShow"
      width="50%"
      append-to-body
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="activity-container">
        <el-checkbox-group v-model="checkedActivity" @change="activityChange">
          <el-checkbox
            v-for="item in activityOption"
            :key="item.HdId"
            :label="item.HdId"
            :disabled="
              !!EvalutionFormat && item.EvalutionFormat !== EvalutionFormat
            "
            >{{ item.Hdmc }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            activityShow = false
            EvalutionFormat = ''
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="addActivity">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 指标字段 -->
    <SetIndex
      :show.sync="setIndex.show"
      :indexTree="setIndex.tree"
      :fatherData="setIndex.data"
      :XKFZ="fatherData"
      :formData="setIndex.row"
      :activity="setIndex.activity"
      :levelOptions="levelOptions"
      @refresh="GetShowModule"
    />
    <!-- 展示设置 -->
    <ZSSZ
      :show.sync="ZSSZ.show"
      :fatherData="ZSSZ.data"
      :XKFZ="fatherData"
      @refresh="GetShowModule"
    />
  </el-dialog>
</template>

<script>
import SetIndex from './SetIndex.vue'
import ZSSZ from './ZSSZ.vue'
import { GetMKActivity, GetMKIndicators } from '@/api/growthReportSet.js'
import {
  GetShowModule,
  AddShowModule,
  DeleteShowModule,
  AddShowModuleSet
} from '@/api/growthReportSet.js'

export default {
  props: {
    show: [Boolean],
    fatherData: [Object],
    levelOptions: [Array]
  },
  components: {
    SetIndex,
    ZSSZ
  },
  data() {
    let valid_XH = (rule, value, callback) => {
      let mk = this.form.MKlist.find(
        (item, index) => index === this.newForm.idx
      )
      if (
        mk &&
        this.form.MKlist.find(item => item.Id !== mk.Id && item.PXH === value)
      )
        return callback(new Error('排序不能重复'))

      if (!mk && this.form.MKlist.some(item => item.PXH === value))
        return callback(new Error('排序不能重复'))

      return callback()
    }
    return {
      userInfo: {},
      currentTermId: '',
      terms: [],
      levelTypeOptions: [
        {
          label: '分数转等级',
          value: 1
        },
        {
          label: '星数',
          value: 2
        }
      ],
      levelOptions2: [],
      newForm: {
        name: '',
        idx: '',
        xh: 1,
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            {
              min: 1,
              max: 20,
              message: '长度在 1 到 20 个字符',
              trigger: 'blur'
            }
          ],
          xh: [{ validator: valid_XH, trigger: 'blur' }]
        },
        show: false
      },
      form: {
        QMCC: {
          id: '',
          checked: false,
          BFB: 0,
          checkList: []
        },
        MKlist: [
          {
            id: '',
            PXH: 1, //序号
            MC: '展示模块1', //名称
            SJLX: 0, //数据类型 1模块信息2考试测查3指标设置
            SZLX: 0, //设置类型 等级,//2星数
            BFB: 0, //百分百
            GLDJId: '',
            zdList: [],
            ZSSZInfo: {
              ZSFS: 0, //展示方式(1横向,//2纵向)
              ZSLX: 0, //展示类型 1自定义描述语 2使用字段等级描述展示，描述语之间用逗号分隔 3使用统计数据展示 4展示字段等级描述 5展示问卷问题及获得等级
              ZDYMSY: 'string', //自定义描述语
              SFQY: 0, //是否启用(0未启用,//1启用)
              ZPZB: 0, //主评占比
              ZJZB: 0, //自评占比
              HPZB: 0 //互评占比
            }
          }
        ]
      },
      // 指标字段
      setIndex: {
        show: false,
        data: {},
        row: {},
        tree: [],
        activity: []
      },
      // 展示设置
      ZSSZ: {
        show: false,
        data: {},
        form: {}
      },
      // 活动相关
      activityOption: [],
      activityShow: false,
      checkedActivity: [],
      EvalutionFormat: '' //当前评价方式 勾选活动时 只能勾选同一种评价方式的活动
    }
  },
  async mounted() {
    let user = localStorage.getItem('userinfo')
    if (user) this.userInfo = JSON.parse(user)
    this.currentTermId = localStorage.getItem('currentterm')

    // 获取学期
    await this.getTerm(this.userInfo.SSZZId, this.userInfo.Token)
  },
  filters: {
    handleShowFormat(row, levelOptions) {
      if (row.SZLX === 1) {
        if (levelOptions) {
          let dj = levelOptions.find(item => item.Id === row.GLDJId)
          return dj ? dj.DJMC : '----'
        } else return '----'
      } else if (row.SZLX === 2) return '星数'
      else if (row.SZLX === 3) return '分数'
      else return '----'
    }
  },
  methods: {
    // 获取模块
    async GetShowModule() {
      this.activityShow = false
      let res = await GetShowModule({
        Id: this.fatherData.FZId,
        ssxx: this.userInfo.SSZZId,
        token: this.userInfo.Token
      })

      let mkList =
        res && res.ZSMKInfo && res.ZSMKInfo.filter(item => item.SJLX === 1)

      let QMCC =
        res && res.ZSMKInfo && res.ZSMKInfo.find(item => item.SJLX === 2)

      if (mkList && mkList.length > 0) {
        mkList.sort((a, b) => a.PXH - b.PXH)
        // 筛选每个展示模块的字段
        mkList.forEach(item => {
          // 筛选每个字段的指标
          let zdList = res.ZSMKInfo.filter(el => el.FId === item.Id)
          if (zdList && zdList.length > 0) {
            item.zdList = zdList
            item.zdList.sort((a, b) => a.PX - b.PX)
          } else item.zdList = []

          if (res.ZSSZInfo && res.ZSSZInfo.length > 0) {
            let ZSSZInfo = res.ZSSZInfo.find(it => it.ZSMKId === item.Id)
            item.ZSSZInfo = ZSSZInfo || {}
          }
        })
      }

      this.form.MKlist = mkList || []
      // 如果某个模块的展示设置没有的 就给个默认值
      for (let i = 0; i < this.form.MKlist.length; i++) {
        if (
          !this.form.MKlist[i].ZSSZInfo ||
          Object.keys(this.form.MKlist[i].ZSSZInfo || {}).length === 0
        ) {
          await AddShowModuleSet({
            Id: this.form.MKlist[i].Id,
            ZSMKId: '', //展示模块Id
            ZSFS: 1, //展示方式(1横向,2纵向)
            ZSLX: 1, //展示类型 1自定义描述语 2使用字段等级描述展示，描述语之间用逗号分隔 3使用统计数据展示 4展示字段等级描述 5展示问卷问题及获得等级
            ZDYMSY: ' ', //自定义描述语
            SFQY: 0, //是否启用(0未启用,1启用)
            ZPZB: 100, //主评占比
            ZJZB: 0, //自评占比
            HPZB: 0, //互评占比
            token: this.userInfo.Token
          })
          this.GetShowModule()
        }
      }
      if (QMCC) {
        console.log(QMCC, 'QMCC')
        QMCC.SFGX ? (QMCC.checked = true) : (QMCC.checked = false)
        QMCC.checkList = []
        if (QMCC.YKSFGX) QMCC.checkList.push(1)
        if (QMCC.BQSFGX) QMCC.checkList.push(2)
        if (QMCC.QMSFGX) QMCC.checkList.push(3)
        this.form.QMCC = QMCC
      } else {
        this.form.QMCC.checked = false
        this.form.QMCC.BFB = ''
        this.form.QMCC.checkList = []
        this.form.QMCC.Id = ''
      }
    },
    // 本地新增模块
    addNew() {
      this.$refs.newForm.validate(res => {
        if (res) {
          if (this.newForm.idx !== '') {
            let mk = this.form.MKlist.find(
              (item, idx) => idx === this.newForm.idx
            )

            mk.MC = this.newForm.name
            mk.PXH = this.newForm.xh
            this.AddShowModule()
          } else {
            this.form.MKlist.push({
              id: '',
              PXH: this.newForm.xh, //序号
              MC: this.newForm.name, //名称
              SJLX: 0, //数据类型 1模块信息2考试测查3指标设置
              SZLX: 2, //设置类型 等级,//2星数
              BFB: '', //百分百
              GLDJId: '',
              zdList: [],
              ZSSZInfo: {
                ZSFS: 0, //展示方式(1横向,//2纵向)
                ZSLX: 0, //展示类型 1自定义描述语 2使用字段等级描述展示，描述语之间用逗号分隔 3使用统计数据展示 4展示字段等级描述 5展示问卷问题及获得等级
                ZDYMSY: '', //自定义描述语
                SFQY: 0, //是否启用(0未启用,//1启用)
                ZPZB: 0, //主评占比
                ZJZB: 0, //自评占比
                HPZB: 0 //互评占比
              }
            })
          }
          this.newForm.idx = ''
          this.newForm.name = ''
          this.newForm.show = false
        }
      })
    },
    // 数据库新增模块
    async AddShowModule() {
      let mkList = []
      let QMCC = []

      try {
        if (
          this.form.mkList.some(item => item.SZLX === 1 && item.GLDJId === '')
        )
          return this.$message.error('请选择等级')
      } catch {
        //
      }

      mkList = this.form.MKlist.reduce((pre, cur) => {
        return pre.concat({
          Id: cur.Id || '',
          FId: this.fatherData.FZId, // 父id
          PXH: cur.PXH,
          MC: cur.MC, //名称
          SJLX: 1, //数据类型 1模块信息2考试测查3指标设置
          SZLX: cur.SZLX, //设置类型 等级,//2星数
          BFB: 0, // 百分百
          GLDJId: cur.SZLX === 1 ? cur.GLDJId : '', // 关联等级Id
          SFGX: 0, // 期末测查是否勾选 0未勾选，1勾选
          QMCC: '', // 期末测查
          YKSFGX: 0, // 月考是勾选 0未勾选，1勾选
          BQSFGX: 0, // 半期是否勾选 0未勾选，1勾选
          QMSFGX: 0 //期末是否勾选 0未勾选，1勾选
        })
      }, [])

      QMCC = [
        {
          Id: this.form.QMCC.Id || '',
          FId: this.fatherData.FZId, // 父id
          XH: 0, //序号
          MC: '', //名称
          SJLX: 2, //数据类型 1模块信息2考试测查3指标设置
          SZLX: 0, //设置类型 等级,//2星数
          BFB: this.form.QMCC.BFB, // 百分百
          GLDJId: '', // 关联等级Id
          SFGX: this.form.QMCC.checked ? 1 : 0, // 期末测查是否勾选 0未勾选，1勾选
          QMCC: '', // 期末测查
          YKSFGX: this.form.QMCC.checkList.includes(1) ? 1 : 0, // 月考是勾选 0未勾选，1勾选
          BQSFGX: this.form.QMCC.checkList.includes(2) ? 1 : 0, // 半期是否勾选 0未勾选，1勾选
          QMSFGX: this.form.QMCC.checkList.includes(3) ? 1 : 0 //期末是否勾选 0未勾选，1勾选
        }
      ]

      await AddShowModule({
        id: this.fatherData.FZId, // 传学科分组Id=FZId
        ZSMKInfo: mkList.concat(QMCC),
        ZSSZInfo: [],
        token: this.userInfo.Token
      })
      this.GetShowModule()
      this.$message.success('操作成功')
    },
    // 删除模块
    delMK(data, index) {
      this.$confirm('确认删除？')
        .then(async () => {
          if (data.Id) {
            await DeleteShowModule({
              Id: data.Id,
              token: this.userInfo.Token
            })
            this.GetShowModule()
          } else {
            this.form.MKlist.splice(index, 1)
          }
          this.$message.success('操作成功')
        })
        .catch(() => {
          this.$message('取消删除')
        })
    },
    // 新增字段指标
    addIndex(MK) {
      // 没有id不允许新增字段
      if (!MK.Id) return this.$message.error('请先保存模块')
      this.checkedActivity = []
      this.setIndex.data = MK
      this.setIndex.row = {}
      this.activityShow = true
    },
    // 获取学期
    getTerm(SchoolId, token) {
      //获取学期
      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/accountmanage/GetTerm',
          {
            SchoolId,
            token
          },
          res => {
            if (res.Code === 0) this.terms = res.Target || []
            resolve()
          }
        )
      })
    },
    // 勾选活动确认
    async addActivity() {
      if (this.checkedActivity.length === 0)
        return this.$message.warning('活动为空！')
      let term = this.terms.find(item => item.Id === this.currentTermId)
      // 根据活动获取对应指标
      let res = await GetMKIndicators({
        ssxx: this.userInfo.SSZZId, //是	string	所属学校
        hdId: this.checkedActivity.join(','), //是	string	活动Id多个以逗号分割如：A,B,C
        njStr: this.fatherData.NJXZS.join(), //是	string	年级多个以逗号分割如：1,2
        termId: term.Id,
        termBeginTime: term.BeginTime,
        termEndTime: term.EndTime,
        token: this.userInfo.Token
      })
      if (res.length > 0) {
        this.setIndex.activity = this.activityOption.reduce((pre, cur) => {
          let obj = this.checkedActivity.find(id => cur.HdId === id)
          return obj ? pre.concat(cur) : pre
        }, [])
        this.setIndex.tree = res
        this.setIndex.show = true
        this.EvalutionFormat = ''
      } else this.$message.error('未获取到指标')
    },
    // 编辑字段指标
    editIndex(MK, data) {
      this.setIndex.row = data
      this.setIndex.data = MK
      if (data.HdList) {
        this.checkedActivity = JSON.parse(data.HdList).map(item => item.HdId)
        this.addActivity()
      }
    },
    // 删除字段指标
    async delIndex(data, index) {
      await DeleteShowModule({
        Id: data.Id,
        token: this.userInfo.Token
      })
      this.GetShowModule()
      this.$message.success('操作成功')
    },
    // 展示设置
    ZSset(MK) {
      if (!MK.Id) return this.$message.error('请先保存模块')
      this.ZSSZ.data = MK
      this.ZSSZ.show = true
    },
    activityChange(val) {
      if (val && val.length > 0) {
        let obj = this.activityOption.find(item => item.HdId === val[0])
        if (obj) this.EvalutionFormat = obj.EvalutionFormat
      } else this.EvalutionFormat = ''
    },
    // 修改模块名称
    editMK_Name(mk, idx) {
      this.newForm.idx = idx
      this.newForm.name = mk.MC
      this.newForm.xh = mk.PXH
      this.newForm.show = true
    }
  },
  watch: {
    async show(val) {
      if (val) {
        this.GetShowModule()

        // 当前学期
        let currentTerm = this.terms.find(
          item => item.Id === this.currentTermId
        )

        if (currentTerm) {
          // 获取当前学期活动
          let res = await GetMKActivity({
            njStr: this.fatherData.NJXZS.join(),
            ssxx: this.userInfo.SSZZId, //	是	string	学校id
            startDate: currentTerm.BeginTime, //	是	date	开始日期
            endDate: currentTerm.EndTime, //	是	date	结束日期
            token: this.userInfo.Token
          })
          if (res.length > 0) {
            this.activityOption = res.sort((a, b) =>
              a.Hdmc.localeCompare(b.Hdmc)
            )
          } else this.$message.error('未获取到活动')
        } else {
          this.$message.error('未获取到当前学期')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ZSMK-container {
  .ZSMK-item {
    margin-bottom: 25px;
    padding: 10px;
    box-shadow: 1px 1px 2px 2px #eceeee;
    .MK-top {
      display: flex;
      align-items: center;
      margin: 10px 0;
      padding: 10px 0;
      box-shadow: 1px 1px 2px 2px #f9fbfb;
      .name {
        cursor: pointer;
      }
      .sort {
        padding-left: 10px;
        cursor: pointer;
      }
      .select1 {
        width: 115px;
        margin-left: 10px;
      }
    }
  }
}
.addZSMK {
  margin: 10px 0;
  padding: 10px 0;
  box-shadow: 1px 1px 2px 2px #f9fbfb;
}
.QMCC {
  display: flex;
  align-items: center;
  margin: 10px 0;
  box-shadow: 1px 1px 2px 2px #f9fbfb;
  padding: 10px 0;
  .el-input-number {
    margin: 0 10px;
    ::v-deep .el-input__inner {
      padding: 0 10px;
    }
    ::v-deep .el-input-number__decrease {
      display: none;
    }
    ::v-deep .el-input-number__increase {
      display: none;
    }
  }
  .el-checkbox-group {
    margin-left: 15px;
  }
}
.activity-container {
  .el-checkbox {
    margin-bottom: 15px;
    box-shadow: 1px 1px 2px 2px #f9fbfb;
  }
}
</style>
