import { render, staticRenderFns } from "./curriculaActivity.vue?vue&type=template&id=31ea3428&scoped=true&"
import script from "./curriculaActivity.vue?vue&type=script&lang=js&"
export * from "./curriculaActivity.vue?vue&type=script&lang=js&"
import style0 from "./curriculaActivity.vue?vue&type=style&index=0&id=31ea3428&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31ea3428",
  null
  
)

export default component.exports