<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<el-row :gutter="20">
					<el-col :span="12">
						<div :class="'starback' + this.exportType">
							<img :class="'staricon' + this.exportType" src="../../../../style/growth/honor_star.png" />
							<br />
							<span :class="'honortext' + this.exportType">
								获得了<font :class="'honornumber' + this.exportType">
									{{ xsinfo.XJGR }}
								</font>个星级个人
							</span>
						</div>
					</el-col>
					<el-col :span="12">
						<div :class="'trophyback' + this.exportType">
							<img :class="'trophyicon' + this.exportType"
								src="../../../../style/growth/honor_trophy.png" />
							<br />
							<span :class="'honortext' + this.exportType">
								获得了<font :class="'honornumber' + this.exportType">
									{{ xsinfo.HJS }}
								</font>个奖项
							</span>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="2" v-if="hjlist.length > 0">
					<el-col :span="12">
						<div :class="'honordetail' + this.exportType">
							<el-image :src="hjlist[0].FjList[0].FJLJ" :class="'honordetalimage' + this.exportType"
								v-if="hjlist[0].FjList != null && hjlist[0].FjList.length > 0">
								<div slot="error" class="image-slot">
									<img :class="'honordetalimage' + this.exportType"
										src="../../../../style/growth/honor_trophy.png" />
								</div>
							</el-image>
							<img v-else :class="'honordetalimage' + this.exportType"
								src="../../../../style/growth/honor_trophy.png" />
							<br />
							<span :class="'honordetailtext' + this.exportType">
								{{ hjlist[0].HJMC }} {{ hjlist[0].LBMC }} {{ hjlist[0].HJDJMC }}
							</span>
						</div>
					</el-col>
					<el-col :span="12" v-if="hjlist.length > 1">
						<div :class="'honordetail' + this.exportType">
							<el-image :src="hjlist[1].FjList[0].FJLJ" :class="'honordetalimage' + this.exportType"
								v-if="hjlist[1].FjList != null && hjlist[1].FjList.length > 0">
								<div slot="error" class="image-slot">
									<img :class="'honordetalimage' + this.exportType"
										src="../../../../style/growth/honor_trophy.png" />
								</div>
							</el-image>
							<img v-else :class="'honordetalimage' + this.exportType"
								src="../../../../style/growth/honor_trophy.png" />
							<br />
							<span :class="'honordetailtext' + this.exportType">
								{{ hjlist[1].HJMC }} {{ hjlist[1].LBMC }} {{ hjlist[1].HJDJMC }}
							</span>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="2" v-if="hjlist.length > 2">
					<el-col :span="12">
						<div :class="'honordetail' + this.exportType">
							<el-image :src="hjlist[2].FjList[0].FJLJ" :class="'honordetalimage' + this.exportType"
								v-if="hjlist[2].FjList != null && hjlist[2].FjList.length > 0">
								<div slot="error" class="image-slot">
									<img :class="'honordetalimage' + this.exportType"
										src="../../../../style/growth/honor_trophy.png" />
								</div>
							</el-image>
							<img v-else :class="'honordetalimage' + this.exportType"
								src="../../../../style/growth/honor_trophy.png" />
							<br />
							<span :class="'honordetailtext' + this.exportType">
								{{ hjlist[2].HJMC }} {{ hjlist[2].LBMC }} {{ hjlist[2].HJDJMC }}
							</span>
						</div>
					</el-col>
					<el-col :span="12" v-if="hjlist.length > 3">
						<div :class="'honordetail' + this.exportType">
							<el-image :src="hjlist[3].FjList[0].FJLJ" :class="'honordetalimage' + this.exportType"
								v-if="hjlist[3].FjList != null && hjlist[3].FjList.length > 0">
								<div slot="error" class="image-slot">
									<img :class="'honordetalimage' + this.exportType"
										src="../../../../style/growth/honor_trophy.png" />
								</div>
							</el-image>
							<img v-else :class="'honordetalimage' + this.exportType"
								src="../../../../style/growth/honor_trophy.png" />
							<br />
							<span :class="'honordetailtext' + this.exportType">
								{{ hjlist[3].HJMC }} {{ hjlist[3].LBMC }} {{ hjlist[3].HJDJMC }}
							</span>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	import "./template.css";
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				backgroudimage: "",
				exportType: 1,
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "我的荣誉"
				},
				xsinfo: {
					SSNJ: "",
					NJ: "",
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					XJGR: "",
					HJS: ""
				},
				hjlist: []
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.Id = xsdata.Id;
				// this.xsinfo.XM = xsdata.XM;
				// this.xsinfo.XBM = xsdata.XBM;
				// this.xsinfo.ZP = xsdata.ZP;
				// this.xsinfo.XQAH = xsdata.XQAH;
				// this.xsinfo.SSXX = xsdata.SSXX;
				// this.xsinfo.SSXQ = xsdata.SSXQ;
				// this.xsinfo.SSXN = xsdata.SSXN;
				// this.xsinfo.XQLX = xsdata.XQLX;
				// this.xsinfo.BZRMC = xsdata.BZRMC;
				// this.xsinfo.NL = xsdata.NL;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXTB = xsdata.XXTB;
				// this.xsinfo.BJMC = xsdata.BJMC;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXBM = xsdata.XXBM;
				// this.xsinfo.XZ = xsdata.XZ;
				// this.xsinfo.SSBJ = xsdata.SSBJ;
				// this.xsinfo.SSNJ = xsdata.SSNJ;
				// this.xsinfo.NJ = xsdata.NJ;
				if (
					this.xsinfo.ZP == null ||
					this.xsinfo.ZP == undefined ||
					this.xsinfo.ZP == ""
				) {
					this.xsinfo.ZP =
						this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}
				this.getxsreportdata();
				this.gethonordata();
			},
			getxsreportdata: function() {
				this.$api.post(
					"v1/awards/GetAwardCount", {
						SSXX: this.xsinfo.SSXX,
						SSXQ: this.xqid,
						XSId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							var info = r.Target;
							if (info != null && info != undefined) {
								if (info.AwardList != null && info.AwardList != undefined) {
									this.xsinfo.HJS = info.AwardList.reduce(function(total, item) {
										return item.AwardCount + total;
									}, 0);
								}
								if (info.PromotedList != null && info.PromotedList != undefined) {
									this.xsinfo.XJGR = info.PromotedList.reduce(function(total, item) {
										return item.AwardCount + total;
									}, 0);
								}
							}
						}
					}
				);
			},
			gethonordata: function() {
				let pageinfo = {
					PageIndex: 1,
					PageSize: 4
				};
				this.$api.post(
					"v1/awards/GetAwardForStudent", {
						SSXX: this.xsinfo.SSXX,
						SSXQ: this.xqid,
						CYId: this.xsinfo.Id,
						AuditStatu: 2,
						CYLB: 1,
						AwardType: 1,
						token: this.token,
						PagerInfo: pageinfo
					},
					r => {
						if (r.Code == 0) {
							this.hjlist = [];
							this.hjlist = r.Target;
						}
					}
				);
			}
		},
		computed: {}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/honor_detail.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding: 40px 40px 0px 40px;
		height: 970px;
		width: 760px;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/honor_detail.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding: 30px 30px 0px 30px;
		height: 715px;
		width: 534px;
	}

	.trophyicon1 {
		width: 150px;
		height: 150px;
		margin: 25px 0px 0px 0px;
	}

	.trophyicon2 {
		width: 120px;
		height: 120px;
		margin: 15px 0px 0px 0px;
	}

	.staricon1 {
		width: 150px;
		height: 150px;
		margin: 25px 0px 0px 0px;
	}

	.staricon2 {
		width: 120px;
		height: 120px;
		margin: 15px 0px 0px 0px;
	}

	.starback1 {
		height: 280px;
		background: #ffac03;
		border-radius: 13px;
		text-align: center;
		vertical-align: middle;
	}

	.starback2 {
		height: 200px;
		background: #ffac03;
		border-radius: 8px;
		text-align: center;
		vertical-align: middle;
	}

	.trophyback1 {
		height: 280px;
		background: #b2df00;
		border-radius: 13px;
		text-align: center;
		vertical-align: middle;
	}

	.trophyback2 {
		height: 200px;
		background: #b2df00;
		border-radius: 8px;
		text-align: center;
		vertical-align: middle;
	}

	.honortext1 {
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		margin: 20px 0 0 0;
		line-height: 100px;
	}

	.honortext2 {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		margin: 10px 0 0 0;
	}

	.honornumber1 {
		height: 79px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		-webkit-text-stroke: 3px #ffffff;
		text-stroke: 3px #ffffff;
	}

	.honornumber2 {
		height: 79px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.honordetail1 {
		height: 300px;
		text-align: center;
		vertical-align: middle;
		margin: 20px 0px 0px 0px;
	}

	.honordetail2 {
		height: 220px;
		text-align: center;
		vertical-align: middle;
		margin: 15px 0px 0px 0px;
	}

	.honordetalimage1 {
		width: 370px;
		height: 250px;
	}

	.honordetalimage2 {
		width: 260px;
		height: 180px;
	}

	.honordetailtext1 {
		width: 370px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #333333;
		line-height: 28px;
		text-align: left;
	}

	.honordetailtext2 {
		width: 260px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #333333;
		line-height: 22px;
		text-align: left;
	}
</style>