<template>
  <div class="cont-whole-box" style="text-align: center;">
    <el-form
      :model="XSXXBInfo"
      label-width="90px"
      style="width: 500px; display: inline-block;"
      :rules="rules"
      ref="sbmitfrom"
    >
      <el-form-item style="margin-top:22px" label="学生姓名" prop="XM">
        <el-input v-model="XSXXBInfo.XM"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="XBM">
        <el-radio-group
          v-model="XSXXBInfo.XBM"
          style="float: left; margin-top: 15px;"
        >
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="身份证" prop="SFZJH">
        <el-input v-model="XSXXBInfo.SFZJH"></el-input>
      </el-form-item>
      <el-form-item label="学籍号" prop="XJH">
        <el-input v-model="XSXXBInfo.XJH"></el-input>
      </el-form-item>
      <el-form-item label="民族">
        <el-input v-model="XSXXBInfo.MZ"></el-input>
      </el-form-item>
      <el-form-item label="家长电话" prop="LXDH">
        <el-input v-model="XSXXBInfo.LXDH"></el-input>
      </el-form-item>
      <el-form-item label="籍贯">
        <el-input v-model="XSXXBInfo.JG"></el-input>
      </el-form-item>
      <el-form-item label="出生日期" prop="CSRQ">
        <el-date-picker
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          v-model="XSXXBInfo.CSRQ"
          style="width: 100%;"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="家庭地址">
        <el-input v-model="XSXXBInfo.XZZ"></el-input>
      </el-form-item>
      <el-form-item label="Rf_Cardid">
        <el-input v-model="XSXXBInfo.KH"></el-input>
      </el-form-item>
      <el-form-item label="班级" prop="SSBJ">
        <el-input v-model="className" style="width: 88%;"></el-input
        >&nbsp;&nbsp;
        <a class="c-blue" @click="dialogFormVisible = true">选择</a>
      </el-form-item>
      <el-form-item label="IDKH卡号" prop="IDKH">
        <el-input v-model="XSXXBInfo.IDKH"></el-input>
      </el-form-item>
      <el-form-item label="学生头像">
        <!-- <el-image :src="XSXXBInfo.ZP"
        style="vertical-align:middle; width:150px;height:150px; margin-left:-230px"></el-image>-->
        <div style="width: 112px;">
          <Upload
            @getResultUrl="getUploadUrl"
            :left="0"
            :width="100"
            :height="100"
            :itemPic="
              XSXXBInfo.ZPSLT ? XSXXBInfo.ZPSLT : $store.state.defaultPhoto
            "
          />
        </div>
      </el-form-item>
      <br />
      <br />
      <br />
      <br />
      <el-form-item>
        <el-button
          type="primary"
          @click="submitshowdata"
          :loading="submitLoading"
          >保存</el-button
        >
        <el-button @click="canceladd">取消</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
      title="选择班级"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :before-close="initGradeAndClass"
    >
      <el-form :model="XSXXBInfo" label-width="80px">
        <div
          class="multi-opt-box"
          style="padding-bottom: 0px; border-bottom: 1px solid #e5e5e5;"
        >
          <div class="multi-opt">
            <div class="multi-opt-rig">
              <!--内容：2个字时“b-word-widt”，3个字时“c-word-widt”,4.5个字时“ef-word-widt”-->
              <div
                @click="selectGrade(item.Id)"
                class="ef-word-widt"
                v-for="(item, index) in schoolOrgList"
                :key="index"
              >
                <a
                  :class="[
                    item.Id === gradeId ? 'opt-rigsty whole-back' : '',
                    'ef-word-widta whole-back-hover'
                  ]"
                  >{{ item.Name }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="popo-xk-cent"
          style="height: 130px; box-sizing: border-box; padding-left: 10px;"
        >
          <div
            @click="selectclass(item)"
            v-for="(item, index) in schoolOrgList.find(c => c.Id === gradeId)
              ? schoolOrgList.find(c => c.Id === gradeId).ChidOrgList
              : []"
            :key="index"
            class="disilb-box"
            style="width: 160px;"
          >
            <div
              :class="[item.Id === classId ? 'tabli-xz-sty' : '', 'tabli-xz']"
            ></div>
            <div class="tablil-xz-name epp-ov" title="以位参加">
              {{ item.Name }}
            </div>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveGradeAndClass">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isInteger, validateIdNo, validatePhone } from '@/style/js/Validator.js'

import Upload from '@/components/Upload.vue'
export default {
  name: 'EditStudent',
  components: {
    Upload
  },
  data() {
    let validXJH = (rule, value, callback) => {
      if (value.length > 20) return callback(new Error('不能超过20个字符'))
      else return callback()
    }
    let validXM = (rule, value, callback) => {
      if (value.length > 20) return callback(new Error('不能超过20个字符'))
      else return callback()
    }
    return {
      token: this.$store.state.token,
      dialogFormVisible: false,
      schoolOrgList: [],
      className: '',
      XSXXBInfo: {
        Id: '',
        CJR: '',
        CJSJ: '2019-09-02 09:45:15',
        SYZT: 0,
        SFSC: 0,
        XM: '',
        XJH: '',
        SFZJH: '',
        XBM: 1,
        LXDH: '',
        JG: '',
        CSRQ: '',
        XZZ: '',
        SSXX: '',
        SSXZ: '',
        SSBJ: '',
        SSNJ: '',
        XMPY: '',
        ZP: '',
        ZPSLT: '',
        KH: '',
        MZ: '',
        SFBY: '2',
        XH: ''
      },
      fileList: [],
      webFilePath: '',
      fileServerApiPath: '',
      userName: '',
      userId: '',
      gradeId: '',
      classId: '',
      rules: {
        XM: [
          {
            required: true,
            message: '请输入学生姓名',
            trigger: 'change'
          },
          {
            validator: validXM,
            trigger: 'change'
          }
        ],
        XBM: [
          {
            required: true,
            message: '请选择性别',
            trigger: 'change'
          },
          {
            validator: isInteger,
            message: '请选择性别',
            trigger: 'change'
          }
        ],
        XJH: [
          {
            required: true,
            message: '请输入学籍号',
            trigger: 'change'
          },
          {
            validator: validXJH,
            trigger: 'change'
          }
        ],
        SSBJ: [
          {
            required: true,
            message: '选择班级',
            trigger: 'change'
          }
        ],
        CSRQ: [
          {
            required: true,
            message: '请选择出生日期',
            trigger: 'change'
          }
        ],
        SFZJH: [
          {
            validator: validateIdNo,
            message: '格式不正确',
            trigger: 'change'
          }
        ],
        LXDH: [
          {
            validator: validatePhone,
            message: '请输入手机号',
            trigger: 'change'
          }
        ]
      },
      submitLoading: false
    }
  },
  created: function() {
    // 当前用户
    const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    this.XSXXBInfo.CJR = userinfo.id
    this.XSXXBInfo.SSXX = userinfo.SSZZId

    this.fileServerApiPath = userinfo.fileServerApiPath
    this.webFilePath = userinfo.webFilePath
    this.userName = userinfo.DLM
    this.userId = userinfo.Id
    if (this.$route.params.xsxxbInfo) {
      this.XSXXBInfo = this.$route.params.xsxxbInfo
      this.gradeId = this.XSXXBInfo.SSNJ
      this.classId = this.XSXXBInfo.SSBJ
    }
    this.$api.post(
      'v1/accountmanage/GetSchoolOrg',
      { SchoolId: this.XSXXBInfo.SSXX, token: this.token },
      r => {
        if (r.Code === 0) {
          this.schoolOrgList = r.Target
          this.initGradeAndClass()
        }
      }
    )
  },
  methods: {
    initGradeAndClass() {
      this.dialogFormVisible = false
      this.gradeId =
        this.schoolOrgList &&
        this.schoolOrgList.length > 0 &&
        this.schoolOrgList[0].Id
          ? this.schoolOrgList[0].Id
          : ''
      this.classId = ''
      this.className = ''
      if (
        this.XSXXBInfo !== null &&
        this.XSXXBInfo.SSNJ &&
        this.XSXXBInfo.SSBJ
      ) {
        this.gradeId = this.XSXXBInfo.SSNJ
        this.classId = this.XSXXBInfo.SSBJ
        if (this.schoolOrgList) {
          const grade = this.schoolOrgList.find(c => c.Id === this.gradeId)
          if (grade) {
            this.className = grade.ChidOrgList.find(c => c.Id === this.classId)
              ? grade.ChidOrgList.find(c => c.Id === this.classId).Name
              : ''
          }
        }
      }
    },
    handleRemove() {},
    handlePreview() {},
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 上传图片路径
    getUploadUrl(filePath) {
      this.XSXXBInfo.ZPSLT = filePath
    },
    // 取消
    canceladd: function() {
      this.$router.push({ path: 'StudentList' })
    },
    selectGrade(data) {
      this.gradeId = data
    },
    selectclass: function(data) {
      this.classId = data.Id
      this.className = data.Name
    },
    saveGradeAndClass() {
      this.dialogFormVisible = false
      this.XSXXBInfo.SSNJ = this.gradeId
      this.XSXXBInfo.SSBJ = this.classId
    },
    // 保存
    submitshowdata: function() {
      if (this.XSXXBInfo.SSBJ === '') {
        this.$message({
          type: 'info',
          message: '未选中班级'
        })
        return
      }
      this.$refs.sbmitfrom.validate(valid => {
        if (valid) {
          this.submitLoading = true
          this.$api.post(
            'v1/student/AddXSXXB',
            {
              XSXXBInfo: this.XSXXBInfo,
              UserName: this.userName,
              UserId: this.userId,
              token: this.token
            },
            r => {
              if (r.Code === 0) {
                this.$notify({
                  type: 'success',
                  message: r.Message,
                  duration: 3000
                })
                this.$router.push({ path: 'StudentList' })
                this.submitLoading = false
              } else this.submitLoading = false
            }
          )
        } else {
          this.submitLoading = false
          return false
        }
      })
    }
  }
}
</script>
