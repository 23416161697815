<template>
  <div class="comprehensive">
    <!-- 综合素养 -->
    <el-form
      class="comprehensive-form"
      ref="comprehensiveForm"
      :model="form"
      :rules="rules"
    >
      <!-- 顶部  名称 图形 范围  -->
      <div class="comprehensive-top">
        <el-form-item
          class="ZHSY"
          style="width:320px"
          prop="ZHSY"
          label="显示名称："
        >
          <el-input
            style="width:220px"
            placeholder="报告名称"
            v-model="form.ZHSY"
            size="small"
          />
        </el-form-item>
        <el-form-item class="TXYS" prop="TXYS" label="图形样式：">
          <el-select size="small" v-model="form.TXYS" placeholder="请选择">
            <el-option
              v-for="item in TXYSoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button
          size="small"
          style="margin-left:15px"
          type="primary"
          @click="createTemplate"
          >生成模板</el-button
        >
        <el-button
          size="small"
          :loading="PYloading"
          style="margin-left:15px"
          type="primary"
          @click="createPY"
          >生成评语</el-button
        >
        <el-form-item class="ZTXSFW" prop="ZTXSFW" label="总图显示范围：">
          <el-checkbox-group v-model="form.ZTXSFW">
            <el-checkbox disabled :label="1">学生</el-checkbox>
            <el-checkbox :label="2">班级平均</el-checkbox>
            <el-checkbox :label="3">年级平均</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item
          class="KQCZBG"
          prop="KQCZBG"
          label="开启本学期家长端成长报告"
        >
          <el-switch
            v-model="form.KQCZBG"
            active-color="#13ce66"
            inactive-color="#ccc"
            @change="whetherOpen"
          >
          </el-switch>
        </el-form-item>
      </div>
      <!-- 二级页面设置 -->
      <el-form-item label="二级页面设置：" prop="EJYMSZ">
        <el-select
          size="small"
          v-model="form.EJYMSZ"
          placeholder="请选择"
          @change="EJYMSZchange"
        >
          <el-option
            v-for="item in EJYMSZoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="EJYMZSFW" prop="EJYMZSFW" label="二级页面展示范围：">
        <el-checkbox-group v-model="form.EJYMZSFW">
          <el-checkbox disabled :label="1">学生</el-checkbox>
          <el-checkbox :label="2">班级平均</el-checkbox>
          <el-checkbox :label="3">年级平均</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!-- 一级维度设置 -->
      <div class="first-level-set">
        <div
          class="first-item"
          v-for="(item, index) in form.WDZSBGInfo"
          :key="item.index"
        >
          <el-form-item :prop="`WDZSBGInfo[${index}].ZT2`">
            <el-checkbox
              :disabled="form.EJYMSZ === 1 || index === 0"
              @change="$forceUpdate()"
              v-model="item.ZT2"
              >{{ item.WDMC }}
            </el-checkbox>
          </el-form-item>
          <el-form-item
            style="width:230px"
            class="ZSMC"
            :rules="rules.ZSMC"
            :prop="`WDZSBGInfo[${index}].ZSMC`"
          >
            <el-input
              style="width:100%"
              v-model="item.ZSMC"
              placeholder="请输入展示名称"
              size="small"
              @input="$forceUpdate()"
            />
          </el-form-item>
          <el-form-item
            :style="{ 'margin-left': form.EJYMSZ === 1 ? '10px' : 0 }"
            class="WDId"
            :prop="`WDZSBGInfo[${index}].WdId`"
            :rules="rules.WdId"
          >
            <el-select
              size="small"
              multiple
              :disabled="form.EJYMSZ === 1 && index !== 0"
              :multiple-limit="form.EJYMSZ === 3 ? 100 : 1"
              v-model="item.WdId"
              placeholder="请选择"
              @change="WDIdSelectChange"
            >
              <el-option
                v-for="item in dimensionOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="GLDJLX"
            label="学期总评:"
            :prop="`WDZSBGInfo[${index}].GLDJLX`"
          >
            <el-select
              size="small"
              style="width:90px"
              v-model="item.GLDJLX"
              placeholder="请选择"
            >
              <el-option
                v-for="item in levelTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="GLDJ"
            :prop="`WDZSBGInfo[${index}].GLDJ`"
            :rules="form.WDZSBGInfo[index].GLDJLX === 3 ? rules.GLDJ : []"
          >
            <el-select
              v-show="item.GLDJLX === 3"
              size="small"
              clearable
              v-model="item.GLDJ"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in levelOptions.filter((item) => item.DJLX !== 1)"
                :key="item.Id"
                :label="item.DJMC"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button
            v-if="form.EJYMSZ !== 1"
            :disabled="index === 0"
            type="danger"
            size="small"
            class="del-button"
            @click="deletFirstDimension(item, index)"
            >删除</el-button
          >
          <el-button
            type="primary"
            class="set-button"
            size="small"
            @click="showSecondPageDialog(item)"
            >设置</el-button
          >
        </div>
        <el-button
          :disabled="form.EJYMSZ === 1"
          type="primary"
          size="small"
          @click="addFirstLevel"
          icon="el-icon-plus"
          >新增展示</el-button
        >
        <el-button type="primary" size="small" @click="submit">保存</el-button>
        <el-button type="primary" size="small" @click="showComment"
          >进入评语设置</el-button
        >
        <el-button type="primary" size="small" @click="showXKset"
          >学科设置</el-button
        >
      </div>
    </el-form>

    <!-- 德体美劳二级页面弹窗 -->
    <DTMLdialog
      :firstLevel="form"
      :DTMLform="DTMLform"
      :show.sync="DTMLshow"
      :levelOptions="levelOptions"
      :dimensionOption="dimensionOption"
      :refreshData="getiFrstDimensionReport"
    />
    <!-- 智育二级页面弹窗 -->
    <ZYdialog
      :firstLevel="form"
      :ZYform="ZYform"
      :show.sync="ZYformShow"
      :activities="activities"
      :fixActivity="fixActivity"
      :levelOptions="levelOptions"
      :dimensionOption="dimensionOption"
      :refreshData="getiFrstDimensionReport"
      :handleNum="handleNum"
    />

    <!-- 评语 -->
    <Comment
      :show.sync="comentShow"
      :activities="activities"
      :fixActivity="fixActivity"
      :firstLevel="form.WDZSBGInfo"
      :levelOptions="levelOptions"
    />

    <!-- 学科设置 -->
    <XKlist
      :show.sync="XKlistObj.visible"
      :levelOptions="levelOptions"
      :firstLevel="XKlistObj.obj"
    />
  </div>
</template>

<script>
import DTMLdialog from "./DTMLdialog.vue";
import ZYdialog from "./ZYdialog.vue";
import Comment from "../CommentSet/Index.vue";
import XKlist from "@/views/Integrated/GrowthReportSet/XKList/XKlist.vue";

import {
  saveFirstLevel,
  WhetherOpen,
  editFirstLevel,
  getFirstLevel,
  saveFirstDimension,
  deletFirstDimension,
  getiFrstDimensionReport,
  createTemplate,
  CreatePyData,
  getFirstDimensionSubject,
} from "@/api/growthReportSet.js";

export default {
  props: [
    "TXYSoptions",
    "EJYMSZoptions",
    "levelOptions",
    "activities",
    "fixActivity",
    "dataDimension",
  ],

  components: {
    DTMLdialog,
    ZYdialog,
    Comment,
    XKlist,
  },
  data() {
    return {
      userInfo: {},
      form: {
        Id: "",
        KQCZBG: false,
        ZHSY: "",
        TXYS: 1,
        ZTXSFW: [1], //总图显示范围
        EJYMSZ: 2,
        EJYMZSFW: [1], //二级页面显示范围
        WDZSBGInfo: [
          {
            Id: "", // 空就是新增，有Id值就是编辑
            WDMC: "学业维度", //维度名称
            LX: 1, //类型：1学业，2其他
            ZT: "", //状态：1启用 2禁用
            ZT2: true, //辅助
            ZSMC: "", // 展示名称
            WdId: [], //一级维度id
            GLDJ: "", // 关联等级
            GLDJLX: 1, //  1分数，2不展示，3关联等级
            YK: 0, // 月考1启用，2非启用
            YKBL: 0, // 月考比例
            QZ: 0, //期中状态1启用，2非启用
            QZBL: 0, //期中比例
            QM: 0, //期末1启用，2非启用
            QMBL: 0, //期末比例
          },
        ],
      },
      rules: {
        ZHSY: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { max: 15, message: "长度限制在15字以内", trigger: "blur" },
        ],
        ZTXSFW: [
          { required: true, message: "请选择总图显示范围", trigger: "blur" },
        ],
        EJYMZSFW: [
          {
            required: true,
            message: "请选择二级页面显示范围",
            trigger: "blur",
          },
        ],
        ZSMC: [
          { required: true, message: "不能为空", trigger: "blur" },
          { max: 15, message: "别名长度15字以内", trigger: "blur" },
        ],
        WdId: [{ required: true, message: "不能为空", trigger: "blur" }],
        GLDJ: [{ required: true, message: "请选择展示等级", trigger: "blur" }],
      },
      dimensionOption: [], //维度
      totalData: {},
      // 智育表单
      ZYform: {},
      ZYformShow: false,
      // 德体美劳弹窗
      DTMLform: {},
      DTMLshow: false,
      // 评语显示
      comentShow: false,
      XKlistObj: {
        visible: false,
        obj: {},
      },
      levelTypeOptions: [
        {
          label: "分数",
          value: 1,
        },
        {
          label: "不展示",
          value: 2,
        },
        {
          label: "等级",
          value: 3,
        },
      ],
      PYloading: false,
    };
  },
  provide() {
    return {
      data: {
        secodeLevel: this.ZHGLSZBInfo,
      },
    };
  },
  mounted() {
    let userInfo = localStorage.getItem("userinfo");
    if (userInfo) this.userInfo = JSON.parse(userInfo);

    this.getDimension();
    this.getFirstLevel();
    this.getiFrstDimensionReport();
  },
  methods: {
    // 获取维度
    getDimension() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/dimension/GetDimensionTree",
          {
            LX: 1,
            SchoolId: this.userInfo.SSZZId,
            token: this.userInfo.Token,
          },
          (res) => {
            if (res.Code === 0) {
              let arr = res.Target || [];
              arr.forEach((item) => {
                item.leaf = false;
                item.disabled = false;
                if (item.children && item.children.length > 0) {
                  item.children.forEach((it) => {
                    it.leaf = true;
                    it.disabled = false;
                  });
                }
              });
              this.dimensionOption = arr;

              resolve();
            }
          },
          (err) => reject(err)
        );
      });
    },
    // 获取一级界面-综合素养
    async getFirstLevel() {
      let res = await getFirstLevel({
        SSXX: this.userInfo.SSZZId,
        token: this.userInfo.Token,
        PageIndex: 0,
        PageSize: 1,
      });
      if (res && res[0]) {
        let obj = res[0];
        obj.EJYMZSFW = this.handleNum(0, obj.EJYMZSFW);
        obj.ZTXSFW = this.handleNum(0, obj.ZTXSFW);
        obj.KQCZBG === 1 ? (obj.KQCZBG = true) : (obj.KQCZBG = false);
        obj.ZHSY = obj.XSMC;

        Object.assign(this.form, obj);
      }
    },
    // 获取一级维度设置
    async getiFrstDimensionReport() {
      let res = await getiFrstDimensionReport({
        SSXX: this.userInfo.SSZZId,
        token: this.userInfo.Token,
      });
      // 处理一级维度数据
      if (res.WDZSBGInfo && res.WDZSBGInfo instanceof Array) {
        this.totalData = res;
        if (!res.WDZSBGInfo.some((item) => item.LX === 1)) {
          res.WDZSBGInfo.push({
            Id: "", // 空就是新增，有Id值就是编辑
            WDMC: "学业维度", //维度名称
            LX: 1, //类型：1学业，2其他
            ZT: 1, //状态：1启用 2禁用
            ZT2: true, //辅助
            ZSMC: "", // 展示名称
            WdId: [], //一级维度id
            GLDJ: "", // 关联等级
            GLDJLX: 1, //  1分数，2不展示，3关联等级
            YK: 0, // 月考1启用，2非启用
            YKBL: 0, // 月考比例
            QZ: 0, //期中状态1启用，2非启用
            QZBL: 0, //期中比例
            QM: 0, //期末1启用，2非启用
            QMBL: 0, //期末比例
          });
        }
        res.WDZSBGInfo.sort((a, b) => a.LX - b.LX);

        res.WDZSBGInfo.forEach((item, index) => {
          item.ZT === 1 ? (item.ZT2 = true) : (item.ZT2 = false);
          item.GLDJ = item.GLDJLX === 3 ? item.GLDJ : "";
          if (item.LX === 1) {
            item.WDMC = "学业维度";
          } else {
            if (this.form.EJYMSZ === 1) item.WDMC = item.ZSMC;
            else item.WDMC = `展示${index}`;
          }
        });
        this.form.WDZSBGInfo = JSON.parse(JSON.stringify(res.WDZSBGInfo));
      }
    },
    // 新增一级维度设置
    addFirstLevel() {
      if (this.form.WDZSBGInfo.length === 10)
        return this.$message.warning("最多10个");

      this.form.WDZSBGInfo.push({
        Id: "", // 空就是新增，有Id值就是编辑
        WDMC: `展示${this.form.WDZSBGInfo.length + 1}`, //维度名称
        LX: 2, //类型：1学业，2其他
        ZT: "", //状态：1启用 2禁用
        ZT2: true,
        ZSMC: "", // 展示名称
        WdId: [], //维度id
        GLDJ: "", // 关联等级
        GLDJLX: 1, //  1分数，2不展示，3关联等级
        YK: 0, // 月考1启用，2非启用
        YKBL: 0, // 月考比例
        QZ: 0, //期中状态1启用，2非启用
        QZBL: 0, //期中比例
        QM: 0, //期末1启用，2非启用
        QMBL: 0, //期末比例
        SSXX: this.userInfo.SSZZId, //所属学校Id
        UserId: this.userInfo.Id, //用户Id
      });
    },
    // 保存
    submit() {
      this.$refs.comprehensiveForm.validate(async (res) => {
        if (res) {
          let obj = JSON.parse(JSON.stringify(this.form));
          obj.KQCZBG ? (obj.KQCZBG = 1) : (obj.KQCZBG = 0);
          obj.ZTXSFW = this.handleNum(1, obj.ZTXSFW);
          obj.EJYMZSFW = this.handleNum(1, obj.EJYMZSFW);
          obj.SSXX = this.userInfo.SSZZId;
          obj.UserId = this.userInfo.Id;
          obj.token = this.userInfo.Token;
          delete obj.WDZSBGInfo;
          if (obj.Id) {
            await editFirstLevel(obj);
          } else {
            await saveFirstLevel(obj);
          }

          let arr = JSON.parse(JSON.stringify(this.form.WDZSBGInfo));
          arr.forEach((item, index) => {
            item.XH = index;
            item.GLDJ = item.GLDJLX === 3 ? item.GLDJ : "";
            item.ZT2 ? (item.ZT = 1) : (item.ZT = 2);
            item.SSXX = this.userInfo.SSZZId;
            item.UserId = this.userInfo.Id;
            item.token = this.userInfo.Token;
          });

          await saveFirstDimension({
            WDZSBGInfo: arr,
            token: this.userInfo.Token,
          });
          this.getiFrstDimensionReport();
          this.$message.success("保存成功");
        }
      });
    },
    // 开启成长报告
    async whetherOpen(val) {
      await WhetherOpen({
        ssxx: this.userInfo.SSZZId, //	是	string	所属学校
        KQCZBG: val ? 1 : 0, //	是	int	0关闭，1开启
        token: this.userInfo.Token,
      });
    },
    // 删除一级维度
    deletFirstDimension(data, index) {
      this.$confirm("确认删除？")
        .then(() => {
          if (data.Id)
            deletFirstDimension({ Id: data.Id, token: this.userInfo.Token });
          this.form.WDZSBGInfo.splice(index, 1);
        })
        .catch(() => {
          //
        });
    },
    // 二级页面设置弹窗
    async showSecondPageDialog(val) {
      if (!val.Id) return this.$message.warning("请先保存");

      let obj = {};
      Object.assign(obj, val);
      obj.activities = JSON.parse(JSON.stringify(this.activities));
      obj.dataDimension = JSON.parse(JSON.stringify(this.dataDimension));

      let res = await getFirstDimensionSubject({
        ssxx: this.userInfo.SSZZId, //	是	string	学校Id
        wdId: val.WdId, //	是	arrry	一级维度Id
        token: this.userInfo.Token,
      });
      let fixActivityArr = JSON.parse(JSON.stringify(this.fixActivity));
      if (res.length > 0) {
        fixActivityArr = fixActivityArr.filter((it) => it.GLId !== 40);
        res.forEach((item) => {
          fixActivityArr.push({
            EJWDId: "",
            GLDJId: "",
            GLDJLX: "",
            GLId: 40,
            GLSJLX: 3,
            checked: false,
            label: `${item.KCMC}学业总评`,
            XKId: item.Id,
          });
        });
      }
      obj.fixActivity = fixActivityArr;
      if (this.totalData.ZHGLSZBInfo && this.totalData.ZHGLSZBInfo) {
        let arr = this.totalData.ZHGLSZBInfo.filter(
          (item) => item.WJId === val.Id
        );
        arr.forEach((item) => {
          // "GLSJLX": 0,关联数据类型1.维度 2.自定义活动 3.固定活动
          if (item.GLSJLX === 1) {
            let res = obj.dataDimension.find((el) => el.GLId === item.SJLX);
            if (res) {
              res.checked = true;
              res.EJWDId = item.EJWDId;
            }
          }

          if (item.GLSJLX === 2) {
            let res = obj.activities.find((el) => el.GLId === item.GLId);
            if (res) {
              res.checked = true;
              res.GLDJId = item.GLDJId;
              res.GLDJLX = item.GLDJLX;
            }
          }

          if (item.GLSJLX === 3) {
            if (item.SJLX === 7 || item.SJLX === 2 || item.SJLX === 20) {
              let res = obj.fixActivity.find((el) => el.GLId === item.SJLX);
              if (res) {
                res.checked = true;
                res.EJWDId = item.EJWDId;
              }
            }
            if (item.SJLX === 40) {
              let res3 = obj.fixActivity.find((it) => it.XKId === item.XKId);
              if (!res3) {
                res3 = obj.fixActivity.find((it) => it.GLId === item.SJLX);
              }
              if (res3) res3.checked = true;
            }
            if (
              item.SJLX === 5 ||
              item.SJLX === 6 ||
              item.SJLX === 8 ||
              item.SJLX === 38 ||
              item.SJLX === 39
            ) {
              let res2 = {};
              if (val.LX === 1) {
                res2 = obj.fixActivity.find((el) => el.GLId === item.SJLX);
              } else {
                res2 = obj.dataDimension.find((el) => el.GLId === item.SJLX);
              }
              if (res2) {
                res2.checked = true;
                res2.EJWDId = item.EJWDId;
              }
            }
          }
        });
      }

      let ykstring = ["YK", "QZ", "QM"];
      ykstring.forEach((key) => {
        if (obj[key] === 1) obj[key] = true;
        if (obj[key] === 2 || obj[key] === 0) {
          obj[key] = false;
          obj[`${key}BL`] = 0;
        } else obj[`${key}BL`] = obj[`${key}BL`];
      });
      // "LX": 类型：1学业，2其他
      if (val.LX === 1) {
        val.WDMC ? (obj.WDMC = val.WDMC) : (obj.WDMC = val.ZSMC);
        obj.XSFW = this.handleNum(0, val.XSFW);
        this.ZYform = obj;
        this.ZYformShow = true;
      } else {
        delete obj.XSFW;
        this.DTMLform = obj;
        this.DTMLshow = true;
      }
    },
    // 显示评语
    showComment() {
      if (this.form.WDZSBGInfo.some((item) => !item.Id))
        return this.$message.warning("请先保存");
      this.comentShow = true;
    },
    //处理总图显示范围和二级页面显示范围
    handleNum(type, data) {
      //总图显示范围(1学生，2班级平均，3年级平均 4学生+班级 5学生+年级 6学生+班级+年级 7班级+年级)
      //二级页面展示范围(1学生，2班级平均，3年级平均 4学生+班级 5学生+年级 6学生+班级+年级 7班级+年级)
      let val = 0;
      if (type) {
        if (!data && !(data instanceof Array)) {
          return [];
        }
        if (data.includes(1) && !data.includes(2) && !data.includes(3)) val = 1;
        if (!data.includes(1) && data.includes(2) && !data.includes(3)) val = 2;
        if (!data.includes(1) && !data.includes(2) && data.includes(3)) val = 3;
        if (data.includes(1) && data.includes(2) && !data.includes(3)) val = 4;
        if (data.includes(1) && !data.includes(2) && data.includes(3)) val = 5;
        if (data.includes(1) && data.includes(2) && data.includes(3)) val = 6;
        if (!data.includes(1) && data.includes(2) && data.includes(3)) val = 7;
        return val;
      } else {
        if (data === 1) val = [1];
        if (data === 2) val = [2];
        if (data === 3) val = [3];
        if (data === 4) val = [1, 2];
        if (data === 5) val = [1, 3];
        if (data === 6) val = [1, 2, 3];
        if (data == 7) val = [2, 3];
        if (!data) val = [];
        return val;
      }
    },
    // 二级页面设置变化
    EJYMSZchange(val) {
      if (val === 1) {
        let arr = [
          {
            Id: this.form.WDZSBGInfo[0].Id || "", // 空就是新增，有Id值就是编辑
            WDMC: "学业维度", //维度名称
            LX: 1, //类型：1学业，2其他
            ZT: "", //状态：1启用 2禁用
            ZT2: true, //辅助
            ZSMC: "学业维度", // 展示名称
            WdId: [], //一级维度id
            GLDJ: "", // 关联等级
            GLDJLX: 1, //  1分数，2不展示，3关联等级
            YK: 0, // 月考1启用，2非启用
            YKBL: 0, // 月考比例
            QZ: 0, //期中状态1启用，2非启用
            QZBL: 0, //期中比例
            QM: 0, //期末1启用，2非启用
            QMBL: 0, //期末比例
          },
        ];
        this.form.WDZSBGInfo = arr;
      } else {
        let arr = [];
        this.form.WDZSBGInfo.forEach((item, index) => {
          arr.push({
            Id: item.Id || "", // 空就是新增，有Id值就是编辑
            WDMC: index === 0 ? "学业维度" : `展示${index + 1}`, //维度名称
            LX: index === 0 ? 1 : 2, //类型：1学业，2其他
            ZT: "", //状态：1启用 2禁用
            ZT2: item.ZT2, //辅助
            ZSMC: item.ZSMC || "", // 展示名称
            WdId: item.WdId, //一级维度id
            GLDJ: item.GLDJ, // 关联等级
            GLDJLX: item.GLDJLX, //  1分数，2不展示，3关联等级
            YK: item.YK || 0, // 月考1启用，2非启用
            YKBL: item.YKBL || 0, // 月考比例
            QZ: item.QZ || 0, //期中状态1启用，2非启用
            QZBL: item.QZBL || 0, //期中比例
            QM: item.QM || 0, //期末1启用，2非启用
            QMBL: item.QMBL || 0, //期末比例
          });
        });
        this.form.WDZSBGInfo = arr;
      }
    },
    // 生成模板
    async createTemplate() {
      await createTemplate({
        ssxx: this.userInfo.SSZZId, //	是	string	所属学校Id
        xqid: localStorage.getItem("currentterm"), //	是	string	学期Id
        userId: this.userInfo.Id, //	是	string	用户Id
        token: this.userInfo.Token,
      });
      this.$message.success("操作成功");
    },
    // 学业维度变化
    WDIdSelectChange(e) {
      this.$forceUpdate();
      if (this.form.EJYMSZ === 1 && e && e.length > 0) {
        let arr = [];

        arr.push(this.form.WDZSBGInfo[0]);
        let option = this.dimensionOption.filter((item) => item.value !== e[0]);
        option.forEach((item) => {
          arr.push({
            Id: "", // 空就是新增，有Id值就是编辑
            WDMC: item.label, //维度名称
            LX: 2, //类型：1学业，2其他
            ZT: "", //状态：1启用 2禁用
            ZT2: true, //辅助
            ZSMC: item.label, // 展示名称
            WdId: [item.value], //一级维度id
            GLDJ: "", // 关联等级
            GLDJLX: 1, //  1分数，2不展示，3关联等级
            YK: 0, // 月考1启用，2非启用
            YKBL: 0, // 月考比例
            QZ: 0, //期中状态1启用，2非启用
            QZBL: 0, //期中比例
            QM: 0, //期末1启用，2非启用
            QMBL: 0, //期末比例
          });
        });

        this.form.WDZSBGInfo = arr;
      }
    },
    showXKset() {
      if (
        !this.form.WDZSBGInfo ||
        this.form.WDZSBGInfo.length === 0 ||
        !this.form.WDZSBGInfo[0].Id
      )
        return this.$message.error("请先保存");
      this.XKlistObj.visible = true;
      this.XKlistObj.obj = this.form.WDZSBGInfo[0];
    },
    async createPY() {
      this.PYloading = true;
      let res = await CreatePyData({
        token: this.userInfo.Token,
        ssxx: this.userInfo.SSZZId,
      });
      this.$message(res);
      this.PYloading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.comprehensive {
  .comprehensive-form {
    min-width: 1050px;
    position: relative;
    box-shadow: 1px 1px 2px 2px #f9fbfb;

    .title {
    }
    // 综合素养顶部
    .comprehensive-top {
      ::v-deep .el-form-item__error {
        left: 50%;
        transform: translateX(-100%);
      }
      .ZHSY,
      .TXYS {
        display: inline-block;
        width: 300px;
        .el-input {
          width: 120px;
        }
      }
      .KQCZBG {
        position: absolute;
        right: 0;
        top: 0;
        width: 220px;
      }
    }
    .ZTXSFW {
      ::v-deep .el-form-item__error {
        left: 110px;
      }
    }
    // 一级维度设置
    .first-level-set {
      .first-item {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .ZSMC {
          padding: 0 10px;
        }
        .el-input,
        .el-select {
          width: 140px;
        }
        .WDId {
          .el-select {
            width: 250px;
          }
        }
        .GLDJLX {
          width: 165px;
          padding: 0 0 0 10px;
        }
        .GLDJ {
          width: 150px;
        }
        .el-button {
          position: relative;
          top: -11px;
        }
      }
    }
  }
}
</style>
