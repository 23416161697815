var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.visible,"width":"40%","top":"1%","center":"","before-close":_vm.closeDialog,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{ref:"formData",staticClass:"demo-ruleForm",attrs:{"model":_vm.formData,"rules":_vm.rules,"status-icon":"","label-position":"right"}},[_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"物品名称","prop":"WPMC"}},[_c('el-input',{staticStyle:{"width":"80%"},model:{value:(_vm.formData.WPMC),callback:function ($$v) {_vm.$set(_vm.formData, "WPMC", $$v)},expression:"formData.WPMC"}})],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"兑换价格","prop":"DHDJ"}},[_c('el-input',{staticStyle:{"width":"80%"},attrs:{"disabled":_vm.formData.Id && _vm.formData.DHKSRQ
            ? new Date(_vm.formData.DHKSRQ.replace(/-/g, '/')) <= new Date()
            : false},model:{value:(_vm.formData.DHDJ),callback:function ($$v) {_vm.$set(_vm.formData, "DHDJ", $$v)},expression:"formData.DHDJ"}})],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"物品总数量","prop":"ZSL"}},[_c('el-input',{staticStyle:{"width":"80%"},attrs:{"disabled":_vm.formData.Id && _vm.formData.DHKSRQ
            ? new Date(_vm.formData.DHKSRQ.replace(/-/g, '/')) <= new Date()
            : false},model:{value:(_vm.formData.ZSL),callback:function ($$v) {_vm.$set(_vm.formData, "ZSL", $$v)},expression:"formData.ZSL"}})],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"兑换开始时间","prop":"DHKSRQ"}},[_c('el-date-picker',{staticStyle:{"width":"80%"},attrs:{"disabled":_vm.formData.Id && _vm.formData.DHKSRQ
            ? new Date(_vm.formData.DHKSRQ.replace(/-/g, '/')) <= new Date()
            : false,"type":"datetime","placeholder":"选择日期时间","editable":false,"value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.formData.DHKSRQ),callback:function ($$v) {_vm.$set(_vm.formData, "DHKSRQ", $$v)},expression:"formData.DHKSRQ"}})],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"兑换结束时间","prop":"DHJZRQ"}},[_c('el-date-picker',{staticStyle:{"width":"80%"},attrs:{"type":"datetime","placeholder":"选择日期时间","editable":false,"value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.formData.DHJZRQ),callback:function ($$v) {_vm.$set(_vm.formData, "DHJZRQ", $$v)},expression:"formData.DHJZRQ"}})],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"物品状态","prop":"SYZT"}},[_c('el-select',{staticStyle:{"width":"80%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.formData.SYZT),callback:function ($$v) {_vm.$set(_vm.formData, "SYZT", $$v)},expression:"formData.SYZT"}},_vm._l(([
            { value: 1, label: '正常' },
            { value: 2, label: '暂停' }
          ]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"使用结束时间","prop":"SYJSSJ"}},[_c('el-date-picker',{staticStyle:{"width":"80%"},attrs:{"type":"datetime","placeholder":"选择日期时间","editable":false,"value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.formData.SYJSSJ),callback:function ($$v) {_vm.$set(_vm.formData, "SYJSSJ", $$v)},expression:"formData.SYJSSJ"}})],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"物品分类","prop":"FLId"}},[_c('el-select',{staticStyle:{"width":"80%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.formData.FLId),callback:function ($$v) {_vm.$set(_vm.formData, "FLId", $$v)},expression:"formData.FLId"}},_vm._l((_vm.category),function(item){return _c('el-option',{key:item.Id,attrs:{"label":item.ArticleCategoryName,"value":item.Id}})}),1)],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"物品属性","prop":"WPLB"}},[_c('el-select',{staticStyle:{"width":"80%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.formData.WPLB),callback:function ($$v) {_vm.$set(_vm.formData, "WPLB", $$v)},expression:"formData.WPLB"}},_vm._l(([
            { value: 1, label: '实物' },
            { value: 2, label: '虚拟' }
          ]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"物品描述"}},[_c('el-input',{staticStyle:{"width":"80%"},attrs:{"type":"textarea","autosize":{ minRows: 2, maxRows: 2 },"placeholder":"请输入内容"},model:{value:(_vm.formData.BZ),callback:function ($$v) {_vm.$set(_vm.formData, "BZ", $$v)},expression:"formData.BZ"}})],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"每人每期兑换次数限制："}},[_c('el-input-number',{staticStyle:{"width":"80%"},attrs:{"placeholder":"请输入整数","min":1,"max":10000,"precision":0},model:{value:(_vm.formData.DHSL),callback:function ($$v) {_vm.$set(_vm.formData, "DHSL", $$v)},expression:"formData.DHSL"}})],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"允许兑换的年级："}},[_c('el-checkbox',{attrs:{"label":"全选"},on:{"change":function($event){_vm.formData.grades = _vm.gradeCheckAll
            ? _vm.gradeList.map(function (item) { return item.Number; })
            : []}},model:{value:(_vm.gradeCheckAll),callback:function ($$v) {_vm.gradeCheckAll=$$v},expression:"gradeCheckAll"}}),_c('el-checkbox-group',{on:{"change":function($event){_vm.gradeCheckAll =
            _vm.gradeList.length > 0 &&
            _vm.formData.grades.length === _vm.gradeList.length}},model:{value:(_vm.formData.grades),callback:function ($$v) {_vm.$set(_vm.formData, "grades", $$v)},expression:"formData.grades"}},_vm._l((_vm.gradeList),function(grade){return _c('el-checkbox',{key:grade.Id,attrs:{"label":grade.Number}},[_vm._v(_vm._s(grade.Name))])}),1)],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"排序"}},[_c('el-input-number',{attrs:{"size":"small","placeholder":"排序","min":1},model:{value:(_vm.formData.PXH),callback:function ($$v) {_vm.$set(_vm.formData, "PXH", $$v)},expression:"formData.PXH"}})],1),_c('el-form-item',{staticClass:"el-item-input",attrs:{"label-width":_vm.labelWidth,"label":"物品图标"}},[_c('Upload',{attrs:{"itemPic":_vm.formData.WPTP ? _vm.formData.WPTP : _vm.$store.state.defaultImage,"width":50,"height":50,"circle":true},on:{"getResultUrl":_vm.getResultUrl}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitting},on:{"click":_vm.confirmDialog}},[_vm._v("确 定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }