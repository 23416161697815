<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + this.exportType">
			<div :class="'basetitle' + this.exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + this.exportType">
				<!-- <div id="subChart" :class="'marchart' + this.exportType" v-show="xsinfo.SFZSEJYM != 3"></div> -->

				<div :class="'charttitle' + this.exportType" v-show="xybgdata.some(o=>o.XValue)">
					<el-row :gutter="10">
						<el-col :span="1">
							<div :class="'infoicon' + this.exportType"></div>
						</el-col>
						<el-col :span="23">
							学业总评
						</el-col>
					</el-row>
				</div>
				<div :class="'detailrow' + this.exportType" v-show="xybgdata.some(o=>o.XValue)">
					<div v-for="(course, index) in xybgdata" :key="index">
						<div v-if="course.XValue" :class="'coursediv' + exportType">
							<el-row :class="'coursetitle' + exportType">
								{{ course.kcmc }}
							</el-row>
							<el-row :class="'courselevel' + exportType">
								{{ course.XValue }}
							</el-row>
						</div>
					</div>
				</div>
				<div :id="'courseBarChart'+info.YM" :class="'scorebar' + this.exportType" v-show="xybgdata.some(o=>o.XValue)">
				</div>

				<div :class="'charttitle' + this.exportType" v-show="xybgdata.some(o=>o.KValue)">
					<el-row :gutter="10">
						<el-col :span="1">
							<div :class="'infoicon' + this.exportType"></div>
						</el-col>
						<el-col :span="23">
							期末测查
						</el-col>
					</el-row>
				</div>
				<div :class="'detailrow' + this.exportType" v-show="xybgdata.some(o=>o.KValue)">
					<div v-for="(course, index) in xybgdata" :key="index">
						<div v-if="course.KValue" :class="'coursediv' + exportType">
							<el-row :class="'coursetitle' + exportType">
								{{ course.kcmc }}
							</el-row>
							<el-row :class="'courselevel' + exportType">
								{{ course.KValue }}
							</el-row>
						</div>
					</div>
				</div>
				<div :id="'courseBarChartks'+info.YM" :class="'scorebar' + this.exportType" v-show="xybgdata.some(o=>o.KValue)">
				</div>

				<!-- <div :class="'charttitle' + this.exportType">
					<el-row :gutter="10">
						<el-col :span="1">
							<div :class="'infoicon' + this.exportType"></div>
						</el-col>
						<el-col :span="23">
							总体评价
						</el-col>
					</el-row>
					<el-row :gutter="10">
						<el-col :span="24" :class="'msy' + this.exportType">
							{{ xsinfo.xybgms }}
						</el-col>
					</el-row>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				courselist: [],
				backgroudimage: "",
				exportType: 1,
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "学业成长"
				},
				xsinfo: {
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					SFZSEJYM: 1, //1环状图2柱状图3不展示
					xybgms: ""
				},
				xybgdata: [],
				Type: 0
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: "",
						courseBarChart: null,
						courseBarChartks: null,
						reportChart: null
					};
				}
			}
		},
		methods: {
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.Id = xsdata.xsxx.Id;
				// this.xsinfo.XM = xsdata.xsxx.XM;
				// this.xsinfo.XBM = xsdata.xsxx.XBM;
				// this.xsinfo.ZP = xsdata.xsxx.ZP;
				// this.xsinfo.XQAH = xsdata.xsxx.XQAH;
				// this.xsinfo.SSXX = xsdata.xsxx.SSXX;
				// this.xsinfo.SSXQ = xsdata.xsxx.SSXQ;
				// this.xsinfo.SSXN = xsdata.xsxx.SSXN;
				// this.xsinfo.XQLX = xsdata.xsxx.XQLX;
				// this.xsinfo.BZRMC = xsdata.xsxx.BZRMC;
				// this.xsinfo.NL = xsdata.xsxx.NL;
				// this.xsinfo.XXMC = xsdata.xsxx.XXMC;
				// this.xsinfo.XXTB = xsdata.xsxx.XXTB;
				// this.xsinfo.SSBJ = xsdata.xsxx.SSBJ;
				// this.xsinfo.BJMC = xsdata.xsxx.BJMC;
				// this.xsinfo.XXMC = xsdata.xsxx.XXMC;
				// this.xsinfo.XXBM = xsdata.xsxx.XXBM;
				// this.xsinfo.XZ = xsdata.xsxx.XZ;
				if (this.xsinfo.ZP == null || this.xsinfo.ZP == undefined || this.xsinfo.ZP == "") {
					this.xsinfo.ZP = this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}
				this.xybgdata = [];
				// this.info = xsdata.pageData;
				var templatedata = JSON.parse(xsdata.XYCZ);
				// console.log(templatedata, "templatedata");
				var template = JSON.parse(localStorage.getItem("schooltemplate_" + this.xqid)).XYCZ;
				// console.log(template, "template");
				// templatedata.sort((a, b) => a.Id.localeCompare(b.Id));
				if (templatedata) {
					this.Type = template.Type;
					this.xybgdata = templatedata;
					this.courselist = template.XYCZXQ;


					if (this.xybgdata != null &&
						this.xybgdata != undefined &&
						this.xybgdata.length > 0 &&
						this.courselist != null &&
						this.courselist != undefined &&
						this.courselist.length > 0
					) {
						this.xybgdata.forEach(item => {
							var course = this.courselist.filter(c => c.Id == item.Id)[0];
							item.kcmc = course ? course.Name : "";
						});
						this.xybgdata = this.xybgdata.sort((a, b) => b.kcmc.localeCompare(a.kcmc))
					}
					this.drawcharts();
				}
				// this.getcourselist();
				// this.getxsreportdata();
			},
			drawRadar(grdata, bjdata, njdata, radarindicator) {
				var fontsize = this.exportType == 1 ? 20 : 15;

				if (
					this.reportChart != null &&
					this.reportChart != "" &&
					this.reportChart != undefined
				) {
					this.reportChart.dispose();
				}
				this.reportChart = this.$echarts.init(
					document.getElementById("subChart"+this.info.YM)
				);
				this.reportChart.setOption({
					color: [
						"rgba(78, 199, 239)",
						"rgba(138, 215, 68)",
						"rgba(253, 108, 147)"
					],
					title: {
						show: false
					},
					legend: {
						orient: "vertical",
						left: "left",
						textStyle: {
							color: "#333333",
							fontSize: fontsize,
							fontWeight: 400
						},
						data: ["个人表现", "班级表现", "年级表现"]
					},
					radar: {
						indicator: radarindicator,
						axisName: {
							color: "#333333",
							fontSize: fontsize,
							fontWeight: 400
						}
					},
					series: [{
						type: "radar",
						data: [{
								value: grdata,
								name: "个人表现",
								areaStyle: {
									color: "rgba(78, 199, 239, 0.3)"
								}
							},
							{
								value: bjdata,
								name: "班级表现",
								areaStyle: {
									color: "rgba(138, 215, 68, 0.3)"
								}
							},
							{
								value: njdata,
								name: "年级表现",
								areaStyle: {
									color: "rgba(253, 108, 147, 0.3)"
								}
							}
						]
					}]
				});
			},
			drawCourseBar(xarry, xsarr, bjarr, njarr) {
				if (this.courseBarChart) {
					this.courseBarChart.dispose();
				}
				let courseBarChartDom = document.getElementById('courseBarChart'+this.info.YM);
				if (courseBarChartDom) {
					let bt_list = ['个人'];
					let data_list = [];

					let obj = new Object();

					obj.name = "个人";
					obj.type = "bar";
					obj.data = xsarr;
					obj.itemStyle = {
						color: "#4EC7EF"
					};

					data_list.push(obj);
					if (this.Type == 1 || this.Type == 3) {
						let obj1 = new Object();
						obj1.name = "班级";
						obj1.type = "bar";
						obj1.data = bjarr;
						obj1.itemStyle = {
							color: "#8AD744"
						};
						data_list.push(obj1);
						bt_list.push('班级');
					}
					if (this.Type == 2 || this.Type == 3) {
						let obj2 = new Object();
						obj2.name = "年级";
						obj2.type = "bar";
						obj2.data = njarr;
						obj2.itemStyle = {
							color: "#FD6C93"
						};
						data_list.push(obj2);
						bt_list.push('年级');
					}
					this.courseBarChart = this.$echarts.init(courseBarChartDom);
					this.courseBarChart.setOption({
						xAxis: {
							data: xarry,
							axisLabel: {
								// color: "#A7C6CD",
								// fontFamily: "PingFangSC-Medium",
								// fontSize: 13,
								// fontWeight: 500,
								interval: 0,
							}
						},
						yAxis: {
							show: false
						},
						legend: {
							bottom: "10",
							data: bt_list
						},
						series: data_list
					});
				}
			},
			drawCourseBarks(xarry, xsarr, bjarr, njarr) {
				if (
					this.courseBarChartks != null &&
					this.courseBarChartks != "" &&
					this.courseBarChartks != undefined
				) {
					this.courseBarChartks.dispose();
				}
				let courseBarChartksDom = document.getElementById("courseBarChartks"+this.info.YM);
				if (courseBarChartksDom) {
					let bt_list = ['个人'];
					let data_list = [];
					
					let obj = new Object();
					
					obj.name = "个人";
					obj.type = "bar";
					obj.data = xsarr;
					obj.itemStyle = {
						color: "#4EC7EF"
					};
					
					data_list.push(obj);
					if (this.Type == 1 || this.Type == 3) {
						let obj1 = new Object();
						obj1.name = "班级";
						obj1.type = "bar";
						obj1.data = bjarr;
						obj1.itemStyle = {
							color: "#8AD744"
						};
						data_list.push(obj1);
						bt_list.push('班级');
					}
					if (this.Type == 2 || this.Type == 3) {
						let obj2 = new Object();
						obj2.name = "年级";
						obj2.type = "bar";
						obj2.data = njarr;
						obj2.itemStyle = {
							color: "#FD6C93"
						};
						data_list.push(obj2);
						bt_list.push('年级');
					}
					this.courseBarChartks = this.$echarts.init(courseBarChartksDom);
					this.courseBarChartks.setOption({
						xAxis: {
							data: xarry,
							axisLabel: {
								// color: "#A7C6CD",
								// fontFamily: "PingFangSC-Medium",
								// fontSize: 13,
								// fontWeight: 500,
								interval: 0,
							}
						},
						yAxis: {
							show: false
						},
						legend: {
							bottom: "10",
							data: bt_list
						},
						series: data_list
					});
				}
			},
			getxsreportdata: function() {
				this.$api.post(
					"v1/growthreport/GetGrowthReport", {
						SSXX: this.xsinfo.SSXX,
						SSXQ: this.xsinfo.SSXQ,
						XQXN: this.xsinfo.SSXN,
						XQLX: this.xsinfo.XQLX,
						XXBM: this.xsinfo.XXBM,
						XSId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							var info = r.Target;
							if (info != null && info != undefined) {
								if (info.SchoolStuReBasicSet != null && info.SchoolStuReBasicSet != undefined) {
									this.xsinfo.SFZSEJYM = info.SchoolStuReBasicSet.SFZSEJYM;
								}
								if (info.StudentGrowthReport != null && info.StudentGrowthReport != undefined) {
									if (info.StudentGrowthReport.XYBG != null && info.StudentGrowthReport.XYBG !=
										undefined) {
										this.xybgdata = JSON.parse(info.StudentGrowthReport.XYBG);
										if (
											this.xybgdata != null &&
											this.xybgdata != undefined &&
											this.xybgdata.length > 0 &&
											this.courselist != null &&
											this.courselist != undefined &&
											this.courselist.length > 0
										) {
											this.xybgdata.forEach(item => {
												var course = this.courselist.filter(c => c.Id == item
													.CourseId)[0];
												item.kcmc = course ? course.Name : "";
											});
										}
									}
									this.xsinfo.xybgms = info.StudentGrowthReport.XYBGMSNR;
									this.drawcharts();
								}
							}
						}
					}
				);
			},
			getcourselist: function() {
				this.$api.post(
					"v1/accountmanage/GetSchoolCurriculum", {
						SchoolId: this.ssxx,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							this.courselist = r.Target;
						}
					}
				);
			},
			drawcharts: function() {
				if (this.xybgdata != null && this.xybgdata != undefined && this.xybgdata.length > 0) {
					// var dfmax = Math.max.apply(Math, this.xybgdata.map(item => {
					// 	return item.KScore;
					// }));
					// var cavgmax = Math.max.apply(Math, this.xybgdata.map(item => {
					// 	return item.KCAVG;
					// }));
					// var gavgmax = Math.max.apply(Math, this.xybgdata.map(item => {
					// 	return item.KGAVG;
					// }));
					// var max = Math.max(dfmax, gavgmax, cavgmax);
					// let indicator = this.xybgdata.map(item => {
					// 	return Object.assign({}, {
					// 		name: item.kcmc,
					// 		max: max * 1.2
					// 	});
					// });

					// this.drawRadar(
					// 	this.xybgdata.map(item => {
					// 		return item.KScore;
					// 	}),
					// 	this.xybgdata.map(item => {
					// 		return item.KCAVG;
					// 	}),
					// 	this.xybgdata.map(item => {
					// 		return item.KGAVG;
					// 	}),
					// 	indicator
					// );
					this.drawCourseBar(
						this.xybgdata.map(item => {
							return item.kcmc;
						}),
						this.xybgdata.map(item => {
							return item.XScore;
						}),
						this.xybgdata.map(item => {
							return item.XCAVG;
						}),
						this.xybgdata.map(item => {
							return item.XGAVG;
						})
					);
					this.drawCourseBarks(
						this.xybgdata.filter(o=>o.KValue).map(item => {
							return item.kcmc;
						}),
						this.xybgdata.filter(o=>o.KValue).map(item => {
							return item.KScore;
						}),
						this.xybgdata.filter(o=>o.KValue).map(item => {
							return item.KCAVG;
						}),
						this.xybgdata.filter(o=>o.KValue).map(item => {
							return item.KGAVG;
						})
					);
				}
			}
		},
		mounted() {},
		computed: {}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		background-image: url("../../../../style/growth/dot_orange_radius.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		background-image: url("../../../../style/growth/dot_orange_radius.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 715px;
		width: 100%;
	}

	// .marchart1 {
	// 	height: 340px;
	// 	width: 760px;
	// 	margin-left: 40px;
	// 	background: #ffffff;
	// 	border-radius: 15px;
	// 	margin-top: 20px;
	// }

	// .marchart2 {
	// 	height: 240px;
	// 	width: 535px;
	// 	margin-left: 30px;
	// 	background: #ffffff;
	// 	border-radius: 10px;
	// 	margin-top: 15px;
	// }

	.scorebar1 {
		height: 280px;
		width: 790px;
		margin-left: 25px;
		border-radius: 15px;
	}

	.scorebar2 {
		height: 190px;
		width: 555px;
		margin-left: 20px;
		border-radius: 10px;
	}

	.detailrow1 {
		min-height: 80px;
		width: 780px;
		margin-top: 10px;
		margin-left: 30px;
		border-radius: 15px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #080808;
		display: flex;
		flex-wrap: wrap;
	}

	.detailrow2 {
		min-height: 60px;
		width: 555px;
		margin-top: 10px;
		margin-left: 20px;
		border-radius: 10px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #080808;
		display: flex;
		flex-wrap: wrap;
	}

	.charttitle1 {
		margin-top: 15px;
		margin-left: 40px;
		width: 770px;
		color: #333333;
		text-align: left;
		line-height: 30px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		display: inline-block;
	}

	.charttitle2 {
		margin-top: 15px;
		margin-left: 30px;
		width: 545px;
		color: #333333;
		text-align: left;
		line-height: 18px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		display: inline-block;
	}

	.infoicon1 {
		width: 8px;
		height: 30px;
		background: linear-gradient(0deg, #ff5f88, #ffb983);
	}

	.infoicon2 {
		width: 6px;
		height: 18px;
		background: linear-gradient(0deg, #ff5f88, #ffb983);
	}

	.coursediv1 {
		height: 56px;
		min-width: 120px;
		max-width: 240px;
		text-align: center;
		background: #14d089;
		border: 3px solid #14d089;
		border-radius: 13px;
		margin: 0px 5px 10px 5px;
	}

	.coursediv2 {
		height: 42px;
		min-width: 90px;
		max-width: 160px;
		text-align: center;
		background: #14d089;
		border: 1px solid #14d089;
		border-radius: 8px;
		margin: 0px 5px 5px 5px;
	}

	.coursetitle1 {
		height: 27px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #ffffff;
	}

	.coursetitle2 {
		height: 19px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #ffffff;
	}

	.courselevel1 {
		text-align: center;
		vertical-align: middle;
		background: #ffffff;
		border-radius: 13px;
		height: 29px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #14d089;
	}

	.courselevel2 {
		text-align: center;
		vertical-align: middle;
		background: #ffffff;
		border-radius: 8px;
		height: 22px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #14d089;
	}

	.msy1 {
		margin-top: 10px;
		line-height: 22px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
	}

	.msy2 {
		margin-top: 10px;
		line-height: 16px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
	}
</style>