var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popv-cent"},[_c('div',{staticClass:"stud-box"},[_c('div',{staticClass:"operate-box",staticStyle:{"padding-bottom":"10px","margin-top":"10px","font-size":"14px"}},[_vm._v(" 每个学生参与的活动总数设置 "),_c('el-input',{staticClass:"popv-inpt",staticStyle:{"width":"50px"},attrs:{"placeholder":"请输入","size":"medium"},model:{value:(_vm.data.StudentTotalityLimit),callback:function ($$v) {_vm.$set(_vm.data, "StudentTotalityLimit", $$v)},expression:"data.StudentTotalityLimit"}})],1),_c('div',{staticClass:"col-back-box",staticStyle:{"font-size":"14px","height":"220px","overflow-x":"hidden","overflow-y":"auto"}},[_c('div',{staticClass:"operate-box",staticStyle:{"padding-bottom":"10px","margin-top":"10px"}},_vm._l((_vm.basicData.CategoryList),function(item){return _c('div',{key:item.Id,staticClass:"seac-btn-box",staticStyle:{"margin-right":"10px"},on:{"click":function($event){_vm.currentCategoryId = item.Id}}},[_c('a',{class:[
              'seac-btn-boxa',
              {
                'btn-boxa-sty whole-back border-col border-col whole-back-hover':
                  item.Id === _vm.currentCategoryId
              }
            ],attrs:{"href":"javascript:void(0);"}},[_vm._v(_vm._s(item.Name))])])}),0),_c('div',{staticClass:"er-mianbox-l"},_vm._l((_vm.gradeSigUpLimitList.filter(
            function (c) { return c.CategoryId === _vm.currentCategoryId; }
          )[0].GradeSigUpLimit),function(item){return _c('div',{key:item.GradeNum,staticClass:"er-box-l",staticStyle:{"width":"140px"}},[_vm._v(" "+_vm._s(item.GradeNumStr)+" "),_c('el-input',{staticClass:"popv-inpt",staticStyle:{"width":"50px"},attrs:{"placeholder":"请输入","size":"medium"},model:{value:(item.CountStr),callback:function ($$v) {_vm.$set(item, "CountStr", $$v)},expression:"item.CountStr"}}),_vm._v("个 ")],1)}),0)]),_c('div',{staticClass:"prompt-title-box"},[_vm._v("温馨提示：输入\"-1\"时表示不限制个数")]),_c('div',{staticClass:"cent-bott-butt"},[_c('el-button',{staticStyle:{"margin":"0px 25px"},attrs:{"size":"medium","loading":_vm.isSubmitting},on:{"click":_vm.submit}},[_vm._v("确认")]),_c('el-button',{staticStyle:{"margin":"0px 25px"},attrs:{"size":"medium"},on:{"click":_vm.cancel}},[_vm._v("取消")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }