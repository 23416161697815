<template>
  <!-- 学校管理员开启成长对话 -->
  <div>
    <div class="cz-cont-box">
      <div class="cz-cont-name">• &nbsp;作业管理版块</div>
      <div class="cz-cont-box">
        作业评定等级：
        <el-select v-model="djid" placeholder="请选择作业等级">
          <el-option
            v-for="item in relationgrade"
            :key="item.Id"
            :label="item.DJMC"
            :value="item.Id"
          >
          </el-option>
        </el-select>
        <el-button
          style="margin-left:10px!important;"
          @click="submithomeworklevel()"
          class="cent-bott-butta"
          type="primary"
          :disabled="isDisabled"
          >提交</el-button
        >
      </div>
    </div>
    <div class="cz-cont-box">
      <div class="cz-cont-name">• &nbsp;设置评语生成时间</div>
      <div class="cz-cont-box">
        学期结束前多少天执行：
        <el-input-number
          v-model="PJKSZQ"
          :min="0"
          :max="30"
          placeholder="学期结束前多少天执行"
        ></el-input-number>
        <el-button
          style="margin-left:10px!important;"
          @click="submitcommentset()"
          class="cent-bott-butta"
          type="primary"
          :disabled="isDisabled"
          >提交</el-button
        >
      </div>
      <div class="cent-bott-butt" style="margin:10px 0px 0px 0px"></div>
    </div>
    <div class="cz-cont-box">
      <div class="cz-cont-name">• &nbsp;扫码评价活动设置</div>
      <div class="cz-cont-box">
        <el-checkbox-group v-model="sceva">
          <el-checkbox
            v-for="item in scanActivity"
            :key="item.Id"
            :label="item.Id"
            >{{ item.PJHDMC }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div class="cent-bott-butt" style="margin:10px 0px 0px 0px">
        <a class="cent-bott-butta" style="width: 100px;" @click="save">保存</a>
      </div>
    </div>
    <div class="cz-cont-box">
      <div class="cz-cont-name">• &nbsp;大屏展示活动设置</div>
      <div class="cz-cont-box">
        <el-checkbox-group v-model="carplayeva" :max="3">
          <el-checkbox
            v-for="item in screenActivity"
            :key="item.Id"
            :label="item.Id"
            >{{ item.PJHDMC }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div class="cent-bott-butt" style="margin:10px 0px 0px 0px">
        <a class="cent-bott-butta" style="width: 100px;" @click="savecarplay"
          >保存</a
        >
      </div>
    </div>
    <div class="cz-cont-box">
      <div class="cz-cont-name">• &nbsp;教师端总榜单的活动设置</div>
      <div class="cz-cont-box">
        <el-checkbox-group v-model="activityTotalList">
          <el-checkbox
            v-for="item in honorActivity.filter(item => item.SFYXZJHD !== 1)"
            :key="item.Id"
            :label="item.Id"
            >{{ item.PJHDMC }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div class="cz-cont-box">
        <div
          v-for="item in honorActivity.filter(item => item.SFYXZJHD === 1)"
          :key="item.Id"
          class="main-activity"
        >
          <div class="main-activity-name">
            <el-checkbox
              v-model="item.all"
              v-if="item.ChildEvaList && item.ChildEvaList.length > 0"
              @change="checkAll(item)"
              >{{ item.PJHDMC }}</el-checkbox
            >
            {{
              !item.ChildEvaList || item.ChildEvaList.length === 0
                ? `${item.PJHDMC}(本学期暂无子活动)`
                : ''
            }}
            <span
              v-if="item.ChildEvaList && item.ChildEvaList.length > 0"
              class="arrow"
              :class="{ rotate90: item.showChild }"
              @click="handleShow(item)"
              ><i class="el-icon-arrow-right"></i
            ></span>
          </div>
          <el-checkbox-group
            class="main-children"
            :class="{
              'show-child': item.showChild,
              'close-child': !item.showChild,
              ['main-children' + item.Id]: true
            }"
            v-model="item.activityTotalList"
            @change="checkChild(item)"
          >
            <el-checkbox
              v-for="it in item.ChildEvaList"
              :key="it.Id"
              :label="it.Id"
              >{{ it.PJHDMC }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
      <div class="cent-bott-butt" style="margin:10px 0px 0px 0px">
        <a
          class="cent-bott-butta"
          style="width: 100px;"
          @click="saveActivityTotalList"
          >保存</a
        >
      </div>
    </div>
    <!-- 报表下载设置 -->
    <div class="clear-week-evaluation">
      <div style="padding-right:5px">
        报表下载设置： 已设置活动（到子活动）及家校共育活动
      </div>
      <div style="padding:10px">
        <el-table :data="reportTags" style="width: 100%">
          <el-table-column prop="hdlx" label="类别" width="180">
          </el-table-column>
          <el-table-column prop="name" label="名称" width="180">
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button
                size="small"
                type="danger"
                @click="delReportSet(scope.row)"
                >删除</el-button
              >
              <el-button
                size="small"
                type="primary"
                @click="showReportTimeList(scope.row)"
                >设置</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-button type="primary" size="small" @click="handleShowReport"
        >添加</el-button
      >
    </div>
    <div class="cz-cont-box">
      <div class="cz-cont-name">• &nbsp;家校互动图标</div>
      <div class="cz-cont-box">
        <div
          v-for="(item, index) in interactiveItems"
          :key="index"
          class="interactive-item jf-center"
        >
          <div class="interactive-item-content jf-center">
            <el-avatar :src="item.TPLJ">
              <img
                src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
              />
            </el-avatar>
            <div>{{ item.MS }}</div>
            <el-checkbox-group v-model="item.typeArray">
              <el-checkbox :label="1">家长端</el-checkbox>
              <el-checkbox :label="2">教师端</el-checkbox>
            </el-checkbox-group>
            <div>
              <el-button type="text" @click="editInteractiveItem(item, index)"
                >编辑</el-button
              >
              <el-button type="text" @click="removeInteractiveItem(index)"
                >删除</el-button
              >
            </div>
          </div>
          <el-divider v-if="interactiveItems.length > 0"></el-divider>
        </div>
        <div class="interactive-item">
          <el-button
            type="text"
            icon="el-icon-plus"
            @click="editInteractiveItem(undefined)"
          ></el-button>
        </div>
      </div>
      <div class="cent-bott-butt" style="margin:10px 0px 0px 0px">
        <a
          class="cent-bott-butta"
          style="width: 100px;"
          @click="saveInteractiveItems"
          >保存</a
        >
      </div>
    </div>
    <div class="cz-cont-box ">
      <div class="cz-cont-box switch-block">
        <div>
          <span>
            开启成长对话
          </span>
          <el-switch
            @change="setGrowthConversation"
            v-model="growthConversation.enable"
            active-color="#13ce66"
            inactive-color="#c7c7c7"
          >
          </el-switch>
        </div>
      </div>

      <div class="growth-conversation">
        <el-checkbox-group v-model="growthConversation.checkArr">
          <el-checkbox label="1">
            自评
            <el-input
              v-model="growthConversation.selfEvaluateAlias"
              size="small"
              placeholder="评价端显示名称"
            />
          </el-checkbox>
          <el-checkbox label="4">
            师评
            <el-input
              v-model="growthConversation.teacherEvaluateAlias"
              size="small"
              placeholder="评价端显示名称"
            />
          </el-checkbox>
          <el-checkbox label="2">
            互评
            <el-input
              v-model="growthConversation.eachOtherAlias"
              size="small"
              placeholder="评价端显示名称"
            />
          </el-checkbox>
        </el-checkbox-group>
        <div class="radio-block">
          <el-radio
            v-show="growthConversation.checkArr.includes('2')"
            v-model="growthConversation.eachOtherType"
            :label="1"
            >班级内互评</el-radio
          >
          <el-radio
            v-show="growthConversation.checkArr.includes('2')"
            v-model="growthConversation.eachOtherType"
            :label="2"
            >班级小组内互评</el-radio
          >
        </div>
      </div>
      <div class="save-btn ">
        <el-button
          class="cent-bott-butta"
          style="width: 100px;"
          type="info"
          plain
          @click="setGrowthConversation"
          >保存</el-button
        >
      </div>
    </div>

    <div class="clear-week-evaluation">
      <span>主评次数按周累计</span>
      <el-switch
        v-model="growthConversation.mainEvalu"
        active-color="#13ce66"
        inactive-color="#c7c7c7"
      >
      </el-switch>
      <div class="btn">
        <el-button
          class="cent-bott-butta"
          style="width: 100px;"
          type="info"
          plain
          @click="setGrowthConversation"
          >保存</el-button
        >
      </div>
    </div>

    <!-- 课堂评价活动设置 -->
    <div class="clear-week-evaluation">
      <div>
        <span style="padding-right:5px">课堂评价活动设置：</span>
        <el-select
          size="small"
          v-model="classEvaluation"
          style="margin-right:10px"
          placeholder="请选择"
        >
          <el-option
            v-for="item in activityOptions"
            :key="item.Id"
            :label="item.PJHDMC"
            :value="item.Id"
          >
          </el-option>
        </el-select>
        <span>课间评价时间：</span>
        <el-input-number
          size="small"
          :min="0"
          v-model="classEvaluationTime"
        />{{ ` 分` }}
      </div>
      <div class="btn">
        <el-button
          class="cent-bott-butta"
          style="width: 100px;"
          type="info"
          plain
          @click="saveClassEvaluation"
          >保存</el-button
        >
      </div>
    </div>

    <el-dialog
      :visible.sync="editInteractiveVisible"
      :close-on-click-modal="false"
      :before-close="
        () => {
          editInteractiveVisible = false
        }
      "
      top="45vh"
      width="30%"
      center
    >
      <el-form
        label-width="70px"
        class="jf-center"
        style="flex-direction: column;"
      >
        <el-form-item style="width:270px" label="上传图标">
          <Upload
            :itemPic="
              interactiveInfo.TPLJ
                ? interactiveInfo.TPLJ
                : $store.state.defaultImage
            "
            :width="50"
            :height="50"
            @getResultUrl="getResultUrl"
            :circle="true"
            :fileType="['image/png']"
          />
        </el-form-item>
        <el-form-item style="width:270px" label="文字描述">
          <el-input
            style="max-width:200px"
            v-model="interactiveInfo.MS"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item style="width:270px" label="类型">
          <el-checkbox-group v-model="interactiveInfo.typeArray">
            <el-checkbox :label="1">家长端</el-checkbox>
            <el-checkbox :label="2">教师端</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="editInteractiveVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="addInteractiveItem"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加报表弹窗 -->
    <el-dialog
      title="报表下载设置"
      :visible.sync="addReportDialog"
      :close-on-click-modal="false"
      top="30vh"
      width="50%"
      center
    >
      <div class="report-checked-container">
        <div
          class="main-item"
          v-for="(item, index) in reportChecked"
          :key="index"
        >
          <div class="main-title">
            <span class="main-arrow" @click="handleReportShowChild(item, 1)"
              ><i
                class="el-icon-arrow-right"
                :class="{ 'arrow-show': item.show }"
              ></i
            ></span>
            {{ item.name }}：
          </div>
          <div class="child-container" :class="{ 'child-show': item.show }">
            <el-checkbox-group v-model="item.checked">
              <div class="child-item" v-for="it in item.children" :key="it.id">
                <el-checkbox
                  :disabled="JXGY_disabled.includes(it.id)"
                  :label="it.id"
                  >{{ it.name }}
                </el-checkbox>
                <!-- <el-radio
                  v-model="it.radio"
                  :disabled="!item.checked.some(el => el === it.id)"
                  :label="1"
                  >word</el-radio
                > -->
                <!-- <el-radio
                  v-model="it.radio"
                  :disabled="!item.checked.some(el => el === it.id)"
                  :label="2"
                  >pdf</el-radio
                > -->
              </div>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button size="small" @click="addReportDialog = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="reportCheckedClick"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 报表时间段列表 -->
    <el-dialog
      title="报表时间段"
      :visible.sync="reportTimeListVisible"
      :close-on-click-modal="false"
      top="30vh"
      width="70%"
      center
    >
      <div class="report-time-list">
        <div style="margin-bottom:15px">
          <el-radio
            size="small"
            :disabled="reportTimeList && reportTimeList.some(it => it.LX === 2)"
            v-model="timeForm.timeType"
            label="1"
            border
            >按月生成</el-radio
          >
          <el-radio size="small" v-model="timeForm.timeType" label="2" border
            >按指定时间段生成</el-radio
          >
        </div>
        <el-button type="primiary" size="samll" @click="addTimeList()"
          >添加</el-button
        >
        <el-table :data="reportTimeList" style="width: 100%">
          <el-table-column prop="SJDMC" label="时间段名称" width="180">
          </el-table-column>
          <el-table-column prop="KSSJ" label="开始时间" width="180">
          </el-table-column>
          <el-table-column prop="JSSJ" label="结束时间" width="180">
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button
                size="small"
                type="danger"
                @click="delReportTime(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 设置报表时间段 -->
    <el-dialog
      title=""
      :visible.sync="addTimeListVisible"
      top="30vh"
      width="50%"
      center
    >
      <div class="set-report-time">
        <el-form ref="timeForm" :model="timeForm" label-width="120px">
          <el-form-item label="时间段名称：">
            <el-input
              style="width:200px"
              v-model="timeForm.name"
              placeholder="输入名字..."
            />
          </el-form-item>
          <el-form-item label="时间段选择：">
            <el-date-picker
              v-model="timeForm.times"
              type="daterange"
              :default-time="['00:00:00', '23:59:59']"
              range-separator="至"
              :picker-options="pickerOptions"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-button type="primiary" size="samll" @click="submitTime"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '@/components/Upload.vue'
import { formatDate, formatDate2 } from '@/utils/date'

export default {
  components: {
    Upload
  },
  data() {
    let _that = this
    return {
      token: '',
      user: {},
      schoolId: '',
      termid: '',
      djid: '',
      isDisabled: false,
      relationgrade: [],
      sceva: [],
      carplayeva: [],
      allevalution: [],
      PJKSZQ: 0,
      activityTotalList: [],
      interactiveItems: [],
      editInteractiveVisible: false,
      interactiveInfo: {},
      growthConversation: {
        //开启成长对话
        enable: false,
        checkArr: [],
        selfEvaluateAlias: '', //自评别名
        teacherEvaluateAlias: '', //师评别名
        eachOtherAlias: '', //互评别名
        eachOtherType: 1,
        // 主评次数
        mainEvalu: false,
        Id: ''
      },
      classEvaluation: '',
      classEvaluationTime: 3,
      activityOptions: [],
      term: {},
      activityTotalListOption: [], //教师端总榜单活动option
      activityTeacherListOption: [], //存储已设置的教师端总榜单活动
      allModule: [], //模块
      scanActivity: [], //扫码活动
      screenActivity: [], //大屏活动
      honorActivity: [], //总榜单活动
      jxgyList: [], //家校共育
      // 家校共育报表设置
      reportTags: [],
      JXGY_disabled: [],
      reportChecked: [
        {
          name: '家校共育',
          id: '1',
          checked: [],
          show: false,
          children: [
            {
              name: '家校共育1',
              id: '1.1',
              radio: ''
            },
            {
              name: '家校共育2',
              id: '1.2',
              radio: ''
            }
          ]
        },
        {
          name: '主活动1',
          id: '2',
          checked: [],
          show: false,
          children: [
            {
              name: '子活动2.1',
              id: '2.1',
              radio: ''
            },
            {
              name: '子活动2.2',
              id: '2.2',
              radio: ''
            }
          ]
        }
      ], //报表勾选
      addReportDialog: false, //新增报表弹框
      reportTimeListVisible: false, //报表时间列表弹框
      curReport: {}, //当前点击的报表下载
      reportTimeList: [], //报表时间段
      addTimeListVisible: false,
      timeForm: {
        timeType: '1',
        name: '',
        times: [],
        beginTime: '',
        endTime: ''
      },
      pickerOptions: {
        disabledDate(val) {
          return (
            val &&
            (val < new Date(formatDate(_that.curReport.KSSJ)) ||
              val > new Date(formatDate(_that.curReport.JSSJ)))
          )
        }
      }
    }
  },

  async created() {
    this.token = this.$store.state.token
    this.user = JSON.parse(localStorage.getItem('userinfo'))
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId
      }
    }
    this.termid = localStorage.getItem('currentterm')
    let term = localStorage.getItem('curTerm')
    this.term = term ? JSON.parse(term) : {}
    await this.GetEvaluationActivity()
    await this.getJXGY()
    this.init()
    this.loadrelationgrade()
    this.loadhomeworklevel()
    this.loadcommentset()
    this.GetGrowthCommentSet()
  },
  methods: {
    loadcommentset: function() {
      this.$api.post(
        'v1/homework/GetCommentSet',
        {
          SSXX: this.schoolId,
          //  SSXQ: this.termid,
          token: this.token
        },
        r => {
          if (r.Code == 0 && r.Target != null && r.Target != undefined) {
            this.PJKSZQ = r.Target.PJKSZQ
          }
        }
      )
    },
    submitcommentset: function() {
      this.isDisabled = true
      this.$api.post(
        'v1/homework/AddCommentSet',
        {
          PJKSZQ: this.PJKSZQ,
          SSXX: this.schoolId,
          SSXQ: this.termid,
          YHId: this.user.Id,
          token: this.token
        },
        r => {
          this.isDisabled = false
          if (r.Code == 0) {
            this.$message({
              type: 'info',
              message: '保存成功',
              showClose: true
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    loadhomeworklevel: function() {
      this.$api.post(
        'v1/homework/GetHomeWorkLevel',
        { SSXX: this.schoolId, token: this.token },
        r => {
          if (r.Code === 0 && r.Target != null && r.Target != undefined) {
            this.djid = r.Target.DJId
          }
        }
      )
    },
    loadrelationgrade: function() {
      this.$api.post(
        'v1/relationgrade/GetRelationGradList',
        { SchoolId: this.schoolId, token: this.token },
        r => {
          if (r.Code == 0 && r.Target != null && r.Target != undefined) {
            var list = r.Target
            for (var i = 0; i < list.length; i++) {
              if (list[i].DJLX == 1) {
                this.relationgrade.push(list[i])
              }
            }
          }
        }
      )
    },
    submithomeworklevel: function() {
      this.isDisabled = true
      this.$api.post(
        'v1/homework/AddHomeWorkLevel',
        {
          DJId: this.djid,
          SSXX: this.schoolId,
          YHId: this.user.Id,
          token: this.token
        },
        r => {
          this.isDisabled = false
          if (r.Code == 0) {
            this.$message({
              type: 'info',
              message: '保存成功',
              showClose: true
            })
          } else {
            this.$message({
              type: 'error',
              message: r.Message,
              showClose: true
            })
          }
        }
      )
    },
    async init() {
      // 固定活动编码 这里的固定活动只显示了5个 课辅活动 家校共育 作业管理 成绩管理 获奖管理
      this.allModule = [
        {
          Id: '3',
          CodeOrId: 'CurriculaVariable',
          Name: '课辅活动',
          PJHDMC: '课辅活动'
        },
        {
          Id: '7',
          CodeOrId: 'HomesShooling',
          Name: '家校共育',
          PJHDMC: '家校共育'
        },
        {
          Id: '2',
          CodeOrId: 'HomeWork',
          Name: '作业管理',
          PJHDMC: '作业管理'
        },
        {
          Id: '9',
          CodeOrId: 'ScoreManager',
          Name: '成绩管理',
          PJHDMC: '成绩管理'
        },
        {
          Id: '4',
          CodeOrId: 'Hornor',
          Name: '荣誉管理',
          PJHDMC: '荣誉管理'
        }
      ]

      // 扫一扫的活动包括课辅活动和自定义活动(被评对象为学生的过程活动)（只显示主活动）
      // 校级展示的活动包括课辅活动和自定义活动(被评对象为学生的过程活动 最多勾选三个活动)（只显示主活动）
      // 总榜单的活动（包括所有评价学生自定义活动，班级体系，固定活动，与自定义成长体系设置的活动保持一致）(且有子活动的 要折叠显示子活动)

      this.$api.post(
        'v1/evaluationactivity/GetSchoolEva',
        {
          token: this.token,
          SchoolId: this.schoolId,
          GetDataType: 0, // 根据被评对象获取 1学生 2教师 3班级 4小组
          IsGetChildEva: 1, //是否获取子活动
          BeginTime: this.term.BeginTime,
          EndTime: this.term.EndTime
        },
        r => {
          if (r.Code === 0 && r.Target) {
            r.Target.forEach(item => {
              if (item.SFYXZJHD === 1) {
                item.showChild = false
                item.all = false
                item.activityTotalList = []
              }
            })
            this.allevalution = r.Target
            // 处理报表下载数据(家校共育和老师评学生自定义过程主子活动)
            let reportDatas1 = this.jxgyList.reduce((pre, cur) => {
              if (
                (new Date(cur.KSRQ) >= new Date(this.term.BeginTime) &&
                  new Date(cur.KSRQ) <= new Date(this.term.EndTime)) ||
                (new Date(cur.JFRQ) >= new Date(this.term.BeginTime) &&
                  new Date(cur.JFRQ) <= new Date(this.term.EndTime))
              ) {
                pre.push({
                  name: cur.JHMC,
                  id: cur.Id,
                  radio: ''
                })
              }
              return pre
            }, [])

            let reportDatas2 = this.allevalution.reduce((pre, cur) => {
              if (
                cur.HDLX === 1 &&
                cur.SFYXZJHD === 1 &&
                cur.BPJDXLX === 1 &&
                cur.ChildEvaList &&
                cur.ChildEvaList.length > 0
              ) {
                let obj = {
                  name: cur.PJHDMC,
                  id: cur.Id,
                  checked: [],
                  show: false,
                  children: []
                }
                cur.ChildEvaList.forEach(child => {
                  obj.children.push({
                    name: child.PJHDMC,
                    id: child.Id,
                    radio: ''
                  })
                })
                pre.push(obj)
              }
              return pre
            }, [])
            reportDatas2.unshift({
              name: '家校共育',
              id: '7',
              checked: [],
              show: false,
              children: reportDatas1
            })
            this.reportChecked = reportDatas2
            // 根据活动开始结束时间筛选当前学期可用活动
            this.$api.post(
              '/v1/accountmanage/GetSchoolModuleOrEva',
              {
                token: this.token,
                SchoolId: this.schoolId, //	是	string	学校Id
                TermYear: this.term.SSXN, //	是	string	当前学期学年（课辅活动使用）
                IsAll: 1 //	是	int	是否获取全部1全部2只获取学年课辅活动（2主要是获取历史学年的课辅活动）
              },
              res => {
                if (res.Code === 0) {
                  this.reportChecked.forEach(item => {
                    // 找到子活动的开始结束时间 如果没有的或者不是当前学期的 剔除掉
                    if (
                      item.id !== '7' &&
                      item.children &&
                      item.children.length > 0
                    ) {
                      item.children = item.children.filter(it => {
                        let childDetail = res.Target.find(el => el.Id === it.id)
                        if (childDetail) {
                          it.KSSJ = childDetail.KSSJ
                          it.JSSJ = childDetail.JSSJ
                        }
                        return it.KSSJ
                      })
                    }
                  })
                }
              }
            )
            this.scanActivity = this.allevalution.filter(
              item =>
                item.BPJDXLX === 1 &&
                item.HDLX === 1 &&
                (item.SFYXZJHD === 2 || item.SFYXZJHD === 1)
            )

            this.screenActivity = this.allevalution.filter(
              item =>
                item.BPJDXLX === 1 &&
                item.HDLX === 1 &&
                (item.SFYXZJHD === 2 || item.SFYXZJHD === 1)
            )

            let CurriculaVariable = this.allModule.find(
              item => item.CodeOrId === 'CurriculaVariable'
            )
            if (CurriculaVariable) {
              this.scanActivity.push(CurriculaVariable)
              this.screenActivity.push(CurriculaVariable)
            }
            this.scanActivity.sort((a, b) => a.PJHDMC.localeCompare(b.PJHDMC))
            this.screenActivity.sort((a, b) => a.PJHDMC.localeCompare(b.PJHDMC))

            this.honorActivity = [
              ...this.allevalution.filter(item => item.BPJDXLX === 1),
              ...this.allModule
            ]

            this.honorActivity.sort((a, b) => a.PJHDMC.localeCompare(b.PJHDMC))

            this.$api.post(
              '/v1/customactivityset/GetSchoolZDYHDSZBAll', //获取设置页面所有被勾选的活动 SZLX: 1.扫码勾选 2.大屏展示 3.总榜单 4.报表
              {
                token: this.token,
                SchoolId: this.schoolId
                // XQId: this.termid,//如果传了学期 跨学期后就无法获取到之前设置的活动
              },
              r => {
                if (r.Code === 0 && r.Target && r.Target.length > 0) {
                  const scan = r.Target.filter(c => c.SZLX === 1) //扫码勾选
                  scan.forEach(item => {
                    if (item.HDId === '3') {
                      this.sceva.push('3')
                    } else {
                      this.allevalution.forEach(el => {
                        if (el.Id === item.HDId) this.sceva.push(el.Id)
                      })
                    }
                  })
                  const screen = r.Target.filter(c => c.SZLX === 2) //大屏勾选
                  screen.forEach(item => {
                    if (item.HDId === '3') {
                      this.carplayeva.push('3')
                    } else {
                      this.allevalution.forEach(el => {
                        if (el.Id === item.HDId) this.carplayeva.push(el.Id)
                      })
                    }
                  })

                  let honor = r.Target.filter(c => c.SZLX === 3) //榜单勾选
                  honor.forEach(item => {
                    let hd = this.allevalution.find(el => el.Id === item.HDId)
                    if (hd) {
                      this.activityTotalList.push(hd.Id)
                    } else if (
                      this.allModule.find(fix => fix.Id === item.HDId)
                    ) {
                      this.activityTotalList.push(
                        this.allModule.find(fix => fix.Id === item.HDId).Id
                      )
                    } else {
                      this.allevalution.find(it => {
                        if (
                          it.SFYXZJHD === 1 &&
                          it.ChildEvaList &&
                          it.ChildEvaList.length > 0
                        ) {
                          let hd = it.ChildEvaList.find(i => i.Id === item.HDId)
                          if (hd) {
                            it.activityTotalList.push(hd.Id)
                            this.handleShow(it, 1)
                            return true
                          } else {
                            return false
                          }
                        } else return false
                      })
                    }
                  })
                  let reportChecked = r.Target.filter(c => c.SZLX === 4) //家校共育报表设置勾选
                  this.reportTags = this.reportChecked.reduce((pre, cur) => {
                    let res = cur.children.filter(it => {
                      let bbb = reportChecked.find(i => i.HDId === it.id)
                      if (bbb) {
                        it.SZId = bbb.Id
                        return true
                      }
                    })
                    if (res) {
                      res.forEach(i => {
                        cur.checked.push(i.id)
                        this.JXGY_disabled.push(i.id)
                        pre.push({
                          SZId: i.SZId,
                          ZHDId: cur.id || '',
                          hdlx: cur.name,
                          id: i.id,
                          name: i.name,
                          radio: '' //暂时只有word类型
                        })
                      })
                    }
                    return pre
                  }, [])
                }
              }
            )
          }
        }
      )
      this.getInteractiveItems()
    },
    save() {
      if (this.sceva == null || this.sceva.length == 0) {
        this.$message({
          type: 'info',
          message: '未选中活动'
        })
        return
      }
      this.$confirm('确认提交？').then(() => {
        let AddEvaDatas = this.sceva.reduce((pre, cur) => {
          let res = this.scanActivity.find(item => item.Id === cur)
          return pre.concat({
            EvaId: res.Id,
            EvaType: res.Id.length > 5 ? 1 : Number(res.Id)
          })
        }, [])
        // 执行提交方法
        this.$api.post(
          '/v1/customactivityset/AddSchoolZDYHDSZB',
          {
            token: this.token,
            AddEvaDatas,
            SchoolId: this.schoolId,
            SZLX: 1,
            UserId: this.user.Id,
            XQId: this.termid
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: 'success',
                message: r.Message
              })
            }
          }
        )
      })
    },
    savecarplay() {
      if (this.carplayeva == null || this.carplayeva.length == 0) {
        this.$message({
          type: 'info',
          message: '未选中活动'
        })
        return
      }
      this.$confirm('确认提交？').then(() => {
        let AddEvaDatas = this.carplayeva.reduce((pre, cur) => {
          let res = this.screenActivity.find(item => item.Id === cur)
          return pre.concat({
            EvaId: res.Id,
            EvaType: res.Id.length > 5 ? 1 : Number(res.Id)
          })
        }, [])
        // 执行提交方法
        this.$api.post(
          '/v1/customactivityset/AddSchoolZDYHDSZB',
          {
            token: this.token,
            AddEvaDatas,
            SchoolId: this.schoolId,
            SZLX: 2,
            UserId: this.user.Id,
            XQId: this.termid
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: 'success',
                message: r.Message
              })
            }
          }
        )
      })
    },
    // 获取教师端总榜单的活动设置
    getActivityTotalList(dataSource) {
      this.$api.post(
        '/v1/growthsystem/GetPersonAndGroupSetBasicData',
        {
          token: this.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0) {
            let data = r.Target
            if (data) {
              this.activityTotalListOption = data.filter(
                c =>
                  (c.EvaluatedObject === 1 || c.EvaluationActivityType > 1) &&
                  c.CYFS !== 42
              )
              var list1 = data.filter(
                c =>
                  (c.EvaluatedObject === 1 || c.EvaluationActivityType > 1) &&
                  c.CYFS != 42
              )
              var list2 = dataSource

              for (var i = 0; i < list1.length; i++) {
                var source = list2.find(c => c.HDId === list1[i].Id)
                if (source !== undefined) {
                  //存在
                  this.activityTotalList.push(list1[i].Id)
                }
              }
            }
          }
        }
      )
    },
    // 教师总榜单保存
    saveActivityTotalList() {
      if (
        this.activityTotalList == null ||
        this.activityTotalList.length == 0
      ) {
        this.$message({
          type: 'info',
          message: '未选中活动'
        })
        return
      }

      let childs = this.honorActivity.reduce((pre, cur) => {
        if (cur.SFYXZJHD === 1 && cur.activityTotalList.length > 0) {
          let arr = cur.activityTotalList.map(el => ({
            EvaId: el,
            EvaType: 1,
            ZHDId: cur.Id
          }))
          pre = pre.concat(arr)
        }
        return pre
      }, [])

      let activityTotalList = this.activityTotalList.reduce((pre, cur) => {
        let res = this.honorActivity
          .filter(item => item.SFYXZJHD !== 1)
          .find(item => item.Id === cur)
        return pre.concat({
          EvaId: res.Id,
          EvaType: res.Id.length > 5 ? 1 : Number(res.Id)
        })
      }, [])
      this.$confirm('确认提交？').then(() => {
        // 执行提交方法
        this.$api.post(
          '/v1/customactivityset/AddSchoolZDYHDSZB',
          {
            token: this.token,
            AddEvaDatas: [...activityTotalList, ...childs],
            SchoolId: this.schoolId,
            SZLX: 3,
            UserId: this.user.Id,
            XQId: this.termid
          },
          r => {
            if (r.Code === 0) {
              this.$message({
                type: 'success',
                message: r.Message
              })
            }
          }
        )
      })
    },
    editInteractiveItem(data, index = -1) {
      this.interactiveInfo = { type: 0, typeArray: [] }
      if (data) {
        this.interactiveInfo = JSON.parse(JSON.stringify(data))
      }
      this.interactiveInfo.index = index
      // this.interactiveInfo.typeArray =
      //   this.interactiveInfo.QYLX === 0
      //     ? []
      //     : this.interactiveInfo.QYLX === 3
      //     ? [1, 2]
      //     : [this.interactiveInfo.QYLX];
      this.editInteractiveVisible = true
    },
    addInteractiveItem() {
      this.interactiveInfo.QYLX =
        this.interactiveInfo.typeArray.length > 0
          ? eval(this.interactiveInfo.typeArray.join('+'))
          : 0
      if (this.interactiveInfo.index !== -1) {
        this.interactiveItems.splice(
          this.interactiveInfo.index,
          1,
          this.interactiveInfo
        )
      } else {
        this.interactiveItems.push(this.interactiveInfo)
      }

      this.editInteractiveVisible = false
    },
    removeInteractiveItem(index) {
      this.interactiveItems.splice(index, 1)
    },
    getResultUrl(path) {
      this.interactiveInfo.TPLJ = path
    },
    getInteractiveItems() {
      this.$api.post(
        '/v1/customactivityset/GetInteractive',
        {
          SSXX: this.schoolId,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.interactiveItems = r.Target
            this.interactiveItems.map(c => {
              c.typeArray = c.QYLX === 0 ? [] : c.QYLX === 3 ? [1, 2] : [c.QYLX]
            })
          }
        }
      )
    },
    saveInteractiveItems() {
      this.interactiveItems.map(c => {
        c.QYLX = c.typeArray.length > 0 ? eval(c.typeArray.join('+')) : 0
      })
      this.$api.post(
        '/v1/customactivityset/SaveInteractive',
        {
          SSXX: this.schoolId,
          CJR: this.user.Id,
          InteractiveList: this.interactiveItems,
          token: this.token
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: 'success',
              message: r.Message
            })
          }
        }
      )
    },
    // 获取成长对话设置
    GetGrowthCommentSet() {
      this.$api.post(
        'v1/customactivityset/GetGrowthCommentSet',
        {
          SchoolId: this.schoolId,
          token: this.token
        },
        res => {
          if (res.Code === 0 && res.Target) {
            res.Target.XZSZ
              ? (this.growthConversation.mainEvalu = true)
              : (this.growthConversation.mainEvalu = false)

            if (res.Target.FJSX === 'enable')
              this.growthConversation.enable = true
            else this.growthConversation.enable = false

            let arr = []
            if (res.Target.SP) {
              this.growthConversation.teacherEvaluateAlias = res.Target.SP
              arr.push('4')
            }
            if (res.Target.HP) {
              this.growthConversation.eachOtherAlias = res.Target.HP
              this.growthConversation.eachOtherType = res.Target.HPZT
              arr.push('2')
            }
            if (res.Target.ZP) {
              this.growthConversation.selfEvaluateAlias = res.Target.ZP
              arr.push('1')
            }
            // 课堂评价活动相关
            this.growthConversation.Id = res.Target.Id
            this.classEvaluation = res.Target.KTPJHD
            this.classEvaluationTime = res.Target.YHPJSJ || 0
            this.growthConversation.checkArr = arr
          }
        }
      )
    },
    // 保存成长对话
    setGrowthConversation() {
      if (this.growthConversation.checkArr.includes('1')) {
        if (!this.growthConversation.selfEvaluateAlias.trim())
          this.growthConversation.selfEvaluateAlias = '自评'
      } else this.growthConversation.selfEvaluateAlias = ''

      if (this.growthConversation.checkArr.includes('2')) {
        if (!this.growthConversation.eachOtherAlias.trim())
          this.growthConversation.eachOtherAlias = '互评'
      } else this.growthConversation.eachOtherAlias = ''

      if (this.growthConversation.checkArr.includes('4')) {
        if (!this.growthConversation.teacherEvaluateAlias.trim())
          this.growthConversation.teacherEvaluateAlias = '师评'
      } else this.growthConversation.teacherEvaluateAlias = ''

      this.$api.post(
        'v1/customactivityset/SetGrowthCommentSet',
        {
          SchoolId: this.schoolId,
          Enable: this.growthConversation.enable,
          CreatorId: this.user.Id,
          ZP: this.growthConversation.selfEvaluateAlias,
          SP: this.growthConversation.teacherEvaluateAlias,
          HP: this.growthConversation.eachOtherAlias,
          HPZT: this.growthConversation.eachOtherAlias
            ? this.growthConversation.eachOtherType
            : 0, //班级小组互评
          XZSZ: this.growthConversation.mainEvalu ? 1 : 0, //主评次数按周清零
          token: this.token,
          Id: this.growthConversation.Id || null
          // KTPJHD: this.classEvaluation || null
        },
        r => {
          if (r.Code === 0) {
            this.$message({
              type: 'success',
              message: r.Message
            })

            this.GetGrowthCommentSet()
          }
        }
      )
    },
    // 获取活动所有活动
    GetEvaluationActivity() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/evaluationactivity/GetEvaluationActivityList',
          {
            SchoolId: this.schoolId,
            pagerInfo: {
              pageIndex: 1,
              pageSize: 9000,
              totalCount: 0
            },
            token: this.token
          },
          res => {
            if (res.Code === 0 && res.Target && res.Target.length > 0) {
              this.activityOptions = res.Target.reduce((pre, cur) => {
                // 老师主评学生 课表参与 过程 通用指标横向（倒数二级或横向别名）
                if (
                  cur.HDCPR === 1 &&
                  cur.BPJDXLX === 1 &&
                  cur.JZKBLX === 3 &&
                  cur.HDLX === 1 &&
                  cur.PJFS === 1 &&
                  (cur.ZBCXFS === 2 || cur.ZBCXFS === 3)
                ) {
                  return pre.concat(cur)
                } else return pre
              }, []).sort((a, b) => a.PJHDMC.localeCompare(b.PJHDMC))

              resolve()
            }
          }
        )
      })
    },
    // 保存课堂评价活动设置
    saveClassEvaluation() {
      if (!this.classEvaluation)
        return this.$message.error('请选择课堂评价活动')
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/customactivityset/SetClassEvaluationActivity',
          {
            Id: this.growthConversation.Id || undefined, //
            KTPJHD: this.classEvaluation,
            YHPJSJ: this.classEvaluationTime,
            token: this.token,
            SchoolId: this.schoolId
          },
          res => {
            if (res.Code === 0) {
              this.$message.success('保存成功')
              resolve()
            }
          }
        )
      })
    },
    // 获取学校自定义活动、课辅活动、固定功能模块（包括已删除活动）
    getSchoolModuleOrEva() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          '/v1/accountmanage/GetSchoolModuleOrEva',
          {
            SchoolId: this.schoolId, // 是 string 学校Id
            TermYear: this.term.SSXN, // 是 string 当前学期学年（课辅活动使用）
            IsAll: 1, // 是 int 是否获取全部1全部2只获取学年课辅活动（2主要是获取历史学年的课辅活动）
            token: this.token
          },
          res => {
            if (res.Code === 0 && res.Target) {
              resolve(res.Target)
            }
          }
        )
      })
    },
    // 处理展开子活动时的高度
    handleShow(item, type) {
      this.$nextTick(() => {
        let dom = document.querySelector(`.main-children${item.Id}`)
        if (type) {
          dom.style.height = dom.scrollHeight + 1 + 'px'
          item.showChild = true
          item.all = true
        } else {
          if (item.showChild === false) {
            dom.style.height = dom.scrollHeight + 1 + 'px'
          } else {
            dom.style.height = 0.5 + 'px'
          }
          item.showChild = !item.showChild
        }
      })
    },
    // 处理主活动全选
    checkAll(item) {
      if (item.all) {
        item.activityTotalList = item.ChildEvaList.map(it => it.Id)
        if (!item.showChild) {
          this.handleShow(item)
        }
      } else {
        item.activityTotalList = []
      }
    },
    // 处理子活动
    checkChild(item) {
      item.all = item.activityTotalList.length === item.ChildEvaList.length
    },
    // 获取家校共育
    getJXGY() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          'v1/extracurricular/GetJXGYList',
          {
            SSXX: this.schoolId,
            SSXQ: this.termid,
            JHMC: '',
            PagerInfo: {
              pageIndex: 1,
              pageSize: 999
            },
            token: this.token
          },
          res => {
            if (res.Code === 0) {
              this.jxgyList = res.Target || []
              resolve()
            }
          }
        )
      })
    },
    // 删除报表设置
    delReportSet(val) {
      this.$confirm('确认删除？').then(() => {
        let parent = this.reportChecked.find(item => item.id === val.ZHDId)
        if (parent) {
          this.JXGY_disabled = this.JXGY_disabled.filter(it => it !== val.id)
          parent.checked = parent.checked.filter(it => it !== val.id)
          this.$api.post(
            '/v1/customactivityset/DeleteSchoolZDYHDSZB',
            {
              Id: val.SZId,
              token: this.token
            },
            res => {
              if (res.Code === 0) {
                this.reportTags = this.reportTags.filter(
                  item => item.SZId !== val.SZId
                )
                this.$message.success('删除成功')
              }
            }
          )
        }
      })
    },
    // 显示报表活动勾选
    handleShowReport() {
      this.addReportDialog = true
      this.reportChecked.forEach(item => {
        if (item.checked && item.checked.length > 0) {
          this.handleReportShowChild(item)
        }
      })
    },
    // 处理报表设置展开子元素
    handleReportShowChild(item, type) {
      if (type) {
        item.show = !item.show
      } else {
        item.show = true
      }
      this.$nextTick(() => {
        let doms = document.querySelectorAll(
          '.report-checked-container .main-item .child-container'
        )
        let idx = this.reportChecked.findIndex(it => it.id === item.id)
        if (doms && doms.length > 0 && idx !== -1) {
          if (item.show) {
            doms[idx].style.height = doms[idx].scrollHeight + 'px'
          } else {
            doms[idx].style.height = 1 + 'px'
          }
        }
      })
    },
    // 报表勾选确定 提交
    reportCheckedClick() {
      this.$confirm('确认提交？').then(() => {
        this.addReportDialog = false
        let arr = this.reportChecked.reduce((pre, cur) => {
          if (cur.checked) {
            cur.checked.forEach(id => {
              let res = cur.children.find(
                it => it.id === id && !this.JXGY_disabled.includes(id)
              )
              if (res)
                pre.push({
                  ZHDId: cur.id || '',
                  hdlx: cur.name,
                  id: res.id,
                  name: res.name,
                  redio: res.redio || 1
                })
            })
          }
          return pre
        }, [])
        if (!arr || arr.length === 0)
          return this.$message.error('请勾选要添加的活动')

        // 执行提交方法
        this.$api.post(
          '/v1/customactivityset/AddSchoolZDYHDSZB',
          {
            token: this.token,
            AddEvaDatas: arr.map(item => ({
              EvaId: item.id,
              EvaType: item.ZHDId.length > 3 ? 1 : '7',
              ZHDId: item.ZHDId
            })),
            SchoolId: this.schoolId,
            XQId: this.termid,
            SZLX: 4,
            UserId: this.user.Id
          },
          r => {
            if (r.Code === 0) {
              this.reportTags = [...this.reportTags, ...arr]
              this.JXGY_disabled = [
                ...this.JXGY_disabled,
                ...arr.map(it => it.id)
              ]
              this.$message({
                type: 'success',
                message: r.Message
              })
              this.init()
            }
          }
        )
      })
    },
    // 显示报表时间段
    showReportTimeList(row) {
      console.log(row, 'rowwww')

      this.getReportList(row)
      // 获取子活动或者家校共育详情
      if (row.ZHDId.length > 2) {
        // 子活动
        let parent = this.reportChecked.find(item => item.id === row.ZHDId)
        let child = parent.children.find(it => it.id === row.id)
        this.curReport = {
          ...row,
          KSSJ: child.KSSJ,
          JSSJ: child.JSSJ
        }
        this.reportTimeListVisible = true
      } else {
        // 家校共育
        let child = this.jxgyList.find(item => item.Id === row.id)
        if (child) {
          this.curReport = {
            ...row,
            KSSJ: child.KSRQ,
            JSSJ: child.JFRQ
          }
          this.reportTimeListVisible = true
        } else {
          this.$message.error('未获取到家校共育详情')
        }
      }
    },
    // 获取时间段列表
    getReportList(row) {
      this.$api.post(
        '/v1/dcbbjh/GetDCBBJHListBySZId',
        {
          SZId: row.SZId,
          token: this.token
        },
        res => {
          if (res.Code === 0) {
            let arr = res.Target || []
            arr.sort((a, b) => new Date(a.KSSJ) - new Date(b.KSSJ))
            this.reportTimeList = arr
            if (this.reportTimeList.some(item => item.LX === 2))
              this.timeForm.timeType = '2'
          }
        }
      )
    },
    // 添加报表时间段
    addTimeList() {
      if (this.timeForm.timeType === '1') {
        this.submitTime()
      } else {
        this.addTimeListVisible = true
      }
    },
    // 提交时间段
    submitTime() {
      let DCBBJHBInfo = []
      if (this.timeForm.timeType === '1') {
        // 按月生成 每个月都要单独传开始结束时间
        console.log(this.curReport, 'wewewe')
        let KSSJ = new Date(formatDate(this.curReport.KSSJ))
        let JSSJ = new Date(formatDate(this.curReport.JSSJ))
        let beginYear = KSSJ.getFullYear()
        let endYear = JSSJ.getFullYear()
        let beginMonth = KSSJ.getMonth() + 1
        let endMonth = JSSJ.getMonth() + 1

        if (beginYear === endYear) {
          // 同年
          for (let i = beginMonth; i <= endMonth; i++) {
            let KSSJ = ''
            let JSSJ = ''
            if (i === beginMonth) {
              KSSJ = new Date(formatDate(this.curReport.KSSJ))
              JSSJ = new Date(new Date(beginYear, i, 0).setHours(23, 59, 59))
            } else if (i === endMonth) {
              KSSJ = new Date(new Date(endYear, i - 1, 1).setHours(0, 0, 0))
              JSSJ = new Date(formatDate(this.curReport.JSSJ))
            } else {
              KSSJ = new Date(new Date(beginYear, i - 1, 1).setHours(0, 0, 0))
              JSSJ = new Date(new Date(beginYear, i, 0).setHours(23, 59, 59))
            }
            DCBBJHBInfo.push({
              Id: null,
              CJR: this.user.Id,
              CJSJ: formatDate2(new Date()),
              SZId: this.curReport.SZId,
              LX: Number(this.timeForm.timeType),
              SJDMC: i + '月',
              KSSJ: formatDate2(KSSJ),
              JSSJ: formatDate2(JSSJ),
              SFZXJH: 2
            })
          }
        } else {
          // 跨年
          for (let i = beginMonth; i <= endMonth + 12; i++) {
            let KSSJ = ''
            let JSSJ = ''
            if (i === beginMonth && i <= 12) {
              KSSJ = new Date(formatDate(this.curReport.KSSJ))
              JSSJ = new Date(new Date(beginYear, i, 0).setHours(23, 59, 59))
            } else if (i === endMonth && i > 12) {
              KSSJ = new Date(
                new Date(endYear, i - 12 - 1, 1).setHours(0, 0, 0)
              )
              JSSJ = new Date(formatDate(this.curReport.JSSJ))
            } else {
              if (i <= 12) {
                KSSJ = new Date(new Date(beginYear, i - 1, 1).setHours(0, 0, 0))
                JSSJ = new Date(new Date(beginYear, i, 0).setHours(23, 59, 59))
              } else {
                KSSJ = new Date(
                  new Date(endYear, i - 12 - 1, 1).setHours(0, 0, 0)
                )
                JSSJ = new Date(
                  new Date(endYear, i - 12, 0).setHours(23, 59, 59)
                )
              }
            }
            DCBBJHBInfo.push({
              Id: null,
              CJR: this.user.Id,
              CJSJ: formatDate2(new Date()),
              SZId: this.curReport.SZId,
              LX: Number(this.timeForm.timeType),
              SJDMC: i + '月',
              KSSJ: formatDate2(KSSJ),
              JSSJ: formatDate2(JSSJ),
              SFZXJH: 2
            })
          }
        }
      } else {
        if (!this.timeForm.name || !this.timeForm.name.trim())
          return this.$message.error('请输入名称')
        if (!this.timeForm.times || this.timeForm.times.length === 0)
          return this.$message.error('请选择时间')
        DCBBJHBInfo = [
          {
            Id: null,
            CJR: this.user.Id,
            CJSJ: formatDate2(new Date()),
            SZId: this.curReport.SZId,
            LX: Number(this.timeForm.timeType),
            SJDMC: this.timeForm.name,
            KSSJ: formatDate2(this.timeForm.times[0]),
            JSSJ: formatDate2(this.timeForm.times[1]),
            SFZXJH: 2
          }
        ]
      }
      DCBBJHBInfo = DCBBJHBInfo.filter(
        item =>
          !this.reportTimeList.find(
            it => it.KSSJ === item.KSSJ && it.JSSJ === item.JSSJ
          )
      )
      if (DCBBJHBInfo.length === 0) return this.$message.warning('时间段已存在')
      this.$api.post(
        '/v1/dcbbjh/AddDCBBJH',
        {
          DCBBJHBInfo,
          token: this.token
        },
        res => {
          if (res.Code === 0) {
            this.$message.success('添加成功')
            this.getReportList(this.curReport)
            this.timeForm.times = []
            this.timeForm.name = ''
            this.addTimeListVisible = false
          }
        }
      )
    },
    // 删除时间段
    delReportTime(row) {
      console.log(row, 'delReportTime')
      this.$api.post(
        '/v1/dcbbjh/DeleteDCBBJH',
        {
          Id: row.Id,
          token: this.token
        },
        res => {
          if (res.Code === 0) {
            this.$message.success('删除成功')
            this.getReportList(this.curReport)
          }
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.main-activity {
  padding: 10px 0;
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  .main-activity-name {
    position: relative;
    padding: 0 0 5px 15px;
    .arrow {
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.5s;
      cursor: pointer;
      &:hover {
        color: #409eff;
        font-size: 14px;
        font-weight: bold;
      }
      &.rotate90 {
        transform: rotate(90deg);
      }
    }
  }
  .main-children {
    overflow: scroll;
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
    scrollbar-width: none;
    transition: all 0.5s;
    &.show-child {
      height: 9px;
    }
    &.close-child {
      height: 0.5px;
    }
  }
}
.interactive-item {
  flex-direction: column;
}
.interactive-item-content {
  width: 100%;
  font-size: 14px;
  justify-content: space-between;
}
.switch-block {
  display: flex;
  span {
    padding-right: 5px;
  }
}
.growth-conversation {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .el-checkbox-group {
    min-width: 723px;
    .el-input {
      width: 160px;
    }
  }
  .el-checkbox {
    padding-right: 5px;
  }
  .radio-block {
    min-width: 226px;
    .el-radio {
      margin-right: 5px;
    }
  }
}
.save-btn {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button {
    background-color: #f1f2f3;
    color: #999999;
    border: 1px solid #e5e5e5;
  }
  .el-button:hover {
    background-color: #f1f2f3;
    color: #999999;
    border: 1px solid #e5e5e5;
  }
}
.clear-week-evaluation {
  margin: 15px 0;
  padding: 10px;
  border: 0.5px solid #f2f2f2;
  .el-switch {
    margin-left: 10px;
  }
  .btn {
    margin: 10px;
    display: flex;
    justify-content: center;
  }
}
.report-checked-container {
  .main-item {
    .main-title {
      padding-bottom: 10px;
      .main-arrow {
        cursor: pointer;
        font-size: 14px;
        .el-icon-arrow-right {
          transition: all 0.5s;
          &.arrow-show {
            transform: rotate(90deg);
          }
        }
      }
    }
    .child-container {
      padding-left: 10px;
      padding-top: 10xp;
      height: 1px;
      overflow: hidden;
      transition: all 0.5s;
      .child-item {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
