<template>
  <el-dialog
    title="智能评语设置"
    :visible.sync="show"
    width="90%"
    :show-close="false"
    :close-on-click-modal="false"
    v-loading="loading"
  >
    <!--  一级维度评语-->
    <div class="first-level">
      <div class="block">
        <div class="example" style="display:flex">
          <span class="span1"> 例，</span>
          <span class="span2"> 你本学期的综合素养表现</span>
          <span class="span3">优秀,</span>
          <span class="span4"> 打败了 </span>
          <span class="span5"> 80% </span>
          <span class="span6"> 的同学 </span>
        </div>
        <div
          class="first-level-item"
          v-for="item in firstLevel2"
          :key="item.WJId"
        >
          <el-checkbox v-model="item.SFGXSZ2">{{ item.label }}</el-checkbox>
          <el-input
            size="small"
            v-model="item.MSY1"
            :placeholder="`你本学期在${item.label}的表现`"
          ></el-input>
          <el-select
            size="small"
            filterable
            v-model="item.DJId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.Id"
              :label="item.DJMC"
              :value="item.Id"
            >
            </el-option>
          </el-select>
          <el-input
            class="text1"
            size="small"
            v-model="item.MSY2"
            placeholder="打败了"
          ></el-input>
          <div>xx</div>
          <el-input
            class="text2"
            size="small"
            v-model="item.MSY3"
            placeholder="的同学"
          ></el-input>
        </div>
      </div>
    </div>
    <!-- 自定义评价活动评语 -->
    <div class="custom-comment">
      <div class="custom-item" v-for="(item, index) in customList" :key="index">
        <div class="top">
          <div v-if="item.type === 2" style="margin-right:10px">
            <el-checkbox v-model="item.plus.SFGXSZ1"></el-checkbox>
          </div>
          <div class="name">{{ item.label }}</div>
          <el-button
            class="del-button"
            size="small"
            type="danger"
            @click="del(item, index)"
            >删除</el-button
          >
        </div>
        <div class="plus" v-if="item.type === 1">
          <div class="plus-top">
            <el-checkbox v-model="item.plus.SFGXSZ1">
              加分项:(取该生加分次数最多的前几项指标进行评语展示)</el-checkbox
            >
            <el-input-number
              size="small"
              class="num"
              :precision="0"
              :min="1"
              :max="1000"
              v-model="item.plus.SZXGS"
            />项
          </div>

          <div class="custom-content">
            <el-input
              size="small"
              v-model="item.plus.MSY1"
              placeholder="孩子在课堂上多次得到"
            />
            <div style="min-width:63px">
              xxx,xxx
            </div>
            <el-input
              size="small"
              v-model="item.plus.MSY2"
              placeholder="老师给你大大的赞！"
            />
          </div>
          <div class="example">
            示例:孩子在课堂上多次得到课前准备、积极发言方面的表扬，老师给你大大的赞！
          </div>
        </div>
        <div class="minus" v-if="item.type === 1">
          <div class="minus-top">
            <el-checkbox v-model="item.minus.SFGXSZ1"
              >减分项:(取该生减分最多的指标项且该项累计次数超过N次的进行评语展示)</el-checkbox
            >
            <el-input-number
              class="num"
              size="small"
              :precision="0"
              :min="0"
              :max="1000"
              v-model="item.minus.SZXGS"
            />次
          </div>

          <div class="custom-content">
            <el-input
              size="small"
              v-model="item.minus.MSY1"
              placeholder="孩子你在课堂上表现欠佳的是"
            />
            <div style="min-width:63px">
              xxx,xxx
            </div>
            <el-input
              size="small"
              v-model="item.minus.MSY2"
              placeholder="下次可得注意改进哟！"
            />
          </div>
          <div class="example">
            示例:孩子你在课堂上表现欠佳的是：下次可得注意改进哟！
          </div>
        </div>
        <div class="kfhd" v-if="item.type === 2">
          <div class="custom-content">
            <el-input
              size="small"
              v-model="item.plus.MSY1"
              placeholder="积极参加学校的课辅活动"
            />
            <div style="min-width:63px">
              xxx,xxx
            </div>
            <el-input
              size="small"
              v-model="item.plus.MSY2"
              placeholder="并得到了"
            />
            <div style="min-width:63px">
              xxx、xxxx
            </div>
            <el-input
              size="small"
              v-model="item.plus.MSY3"
              placeholder="方面的表扬"
            />
          </div>
          <div class="example">
            示例:积极参加学校的课辅活动课程围棋社、书法课，并得到了棋艺精湛、用笔流畅等方面的表扬；
          </div>
        </div>
      </div>
    </div>

    <!-- 新增按钮 -->
    <div class="add-custom">
      <div class="label">添加自定义活动</div>
      <div>
        <el-select
          v-model="selected"
          placeholder="请选择"
          filterable
          size="small"
          @change="selectedChange"
        >
          <el-option
            v-for="item in activities"
            :key="item.GLId"
            :label="item.label"
            :disabled="customList.some((it) => it.ZDYHD === item.GLId)"
            :value="item.GLId"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 结束语 -->
    <div class="finish-comment">
      <div class="label">
        <el-checkbox v-model="finishChecked">结束语</el-checkbox>
      </div>
      <div class="finish-item" v-for="(item, index) in finishList" :key="index">
        <el-input-number
          size="small"
          :precision="2"
          :min="-1000"
          :max="1000"
          v-model="item.DF"
        />
        <span class="disilb-box-ll"> ≤ xx &lt;</span>
        <el-input-number
          size="small"
          :precision="2"
          :min="-1000"
          :max="1000"
          v-model="item.GF"
        />
        <div>%</div>
        <el-input
          ref="msy"
          size="small"
          type="textarea"
          :rows="2"
          v-model="item.MSY"
          @input="MSY_change"
          placeholder="不超过120字 "
        />
      </div>
    </div>

    <!-- 附件 -->
    <div class="accessory">
      <div class="label">
        *附件选择（选中勾选活动的前9张加分项的附件照片
        <!-- <el-checkbox v-model="accessory.checked"
          ></el-checkbox
        > -->
      </div>
      <div class="accessory-activities">
        <el-checkbox-group v-model="accessory.checkList">
          <el-checkbox
            v-for="item in accessory.option"
            :key="item.GLId"
            :label="item.GLId"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('update:show', false)">取 消</el-button>
      <el-button type="primary" @click="addComment">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addComment, getComment } from "@/api/growthReportSet.js";

export default {
  props: {
    show: [Boolean],
    activities: [Array],
    fixActivity: [Array],
    firstLevel: [Array],
    levelOptions: [Array],
  },
  data() {
    return {
      userInfo: {},
      form: {
        // 顶部一级评语
        AddZNPYSZ: [
          {
            Id: "", // 新增传NULL,编辑传对应Id值,
            SFGXSZ: 0,
            PYLX: 0,
            MSY1: "string",
            BFB: 0,
            MSY2: "string",
            MSY3: "string",
            SSXX: "string",
            UserId: "string",
          },
        ],
        // 自定义活动评语
        AddKTBXPYSZ: [
          {
            Id: "", // 新增传NULL,//编辑传对应Id值,//
            SFGXSZ: 0, // 是否勾选设置 不勾选0，已勾选1
            FZXX: 1, //  1加分项 2减分项
            ZDYHD: "string", // 自定义活动
            SZXLX: 0, // 设置项类型:1表扬2待改进
            SZXGS: 0, // 设置项个数
            MSY1: "string", // 描述语1
            MSY2: "string", // 描述语2
            SSXX: "string", // 学校Id
            UserId: "string", // 用户Id
          },
        ],
        // 课辅活动评语设置
        AddKFHDPYSZ: [
          {
            Id: "", // 新增传NULL,编辑传对应Id值,
            SFGXSZ: 0,
            MSY1: "string",
            MSY2: "string",
            MSY3: "string",
            SSXX: "string",
            UserId: "string",
          },
        ],
        //  结束语设置
        AddXSCJPYSZ: [
          {
            Id: "", // 新增传NULL,编辑传对应Id值,
            SFGXSZ: 0,
            GF: 0,
            DF: 0,
            MSY: "string",
            SSXX: "string",
            UserId: "string",
          },
        ],
        // 附件选择
        AddPYSZFJXZ: [
          {
            Id: "", // 新增传NULL,编辑传对应Id值,
            ZDYHD: "",
            KHHD: 0,
            JXGY: 0,
            SSXX: "string",
            UserId: "string",
          },
        ],
      },
      // 顶部
      firstLevel2: [
        {
          Id: "", // 新增传NULL,编辑传对应Id值,
          label: "",
          SFGXSZ: 0, //是否勾选设置 不勾选0，已勾选1
          SFGXSZ2: false, //是否勾选设置 不勾选0，已勾选1
          PYLX: 0, //评语类型
          MSY1: "", // 描述语1
          BFB: 0, //百分比
          DJId: "",
          MSY2: "", //描述语2
          MSY3: "", //描述语3
          WJId: "",
        },
      ],
      // 自定义活动
      customList: [],
      // 选择的自定义活动
      selected: "",
      // 结束语
      finishChecked: false,
      finishList: [
        {
          Id: "", //新增传NULL,编辑传对应Id值,
          SFGXSZ: 0, //是否勾选设置 不勾选0，已勾选1
          GF: 0,
          DF: 0,
          MSY: "",
        },
        {
          Id: "", //新增传NULL,编辑传对应Id值,
          SFGXSZ: 0, //是否勾选设置 不勾选0，已勾选1
          GF: 0,
          DF: 0,
          MSY: "",
        },
        {
          Id: "", //新增传NULL,编辑传对应Id值,
          SFGXSZ: 0, //是否勾选设置 不勾选0，已勾选1
          GF: 0,
          DF: 0,
          MSY: "",
        },
        {
          Id: "", //新增传NULL,编辑传对应Id值,
          SFGXSZ: 0, //是否勾选设置 不勾选0，已勾选1
          GF: 0,
          DF: 0,
          MSY: "",
        },
        {
          Id: "", //新增传NULL,编辑传对应Id值,
          SFGXSZ: 0, //是否勾选设置 不勾选0，已勾选1
          GF: 0,
          DF: 0,
          MSY: "",
        },
      ],
      // 附件
      accessory: {
        checked: false,
        checkList: [],
        option: [],
      },
      loading: false,
    };
  },
  mounted() {
    let user = localStorage.getItem("userinfo");
    if (user) this.userInfo = JSON.parse(user);
  },
  methods: {
    init(res) {
      if (res) {
        let { KFHDPYSZ, KTBXPYSZ, XSCJPYSZ, PYSZFJXZ, ZNPYSZ } = res;

        // 顶部一级评语
        let firstLevel2 = [];

        this.firstLevel.forEach((item) => {
          let obj = (ZNPYSZ || []).find((el) => el.WJId === item.Id);
          firstLevel2.push({
            Id: obj ? obj.Id : "",
            label: item.ZSMC || item.WDMC,
            SFGXSZ: 0, //是否勾选设置 不勾选0，已勾选1
            SFGXSZ2: obj ? !!obj.SFGXSZ : false, //是否勾选设置 不勾选0，已勾选1
            PYLX: item.LX, //评语类型 1.学业维度 2.其它维度
            DJId: obj
              ? obj.DJId
                ? obj.DJId.length > 2
                  ? obj.DJId
                  : Number(obj.DJId)
                : ""
              : "",
            MSY1: obj ? obj.MSY1 : "", // 描述语1
            MSY2: obj ? obj.MSY2 : "", //描述语2
            MSY3: obj ? obj.MSY3 : "", //描述语3
            WJId: item.Id,
          });
        });

        this.firstLevel2 = firstLevel2;

        // 课辅活动
        let customList = [];
        if (KFHDPYSZ && KFHDPYSZ[0]) {
          let obj = this.activities.find((item) => item.GLId === 3) || {};
          customList.push({
            Id: KFHDPYSZ[0].Id,
            label: obj.label,
            type: 2,
            ZDYHD: 3,
            plus: {
              SFGXSZ: 0, // 是否勾选设置 不勾选0，已勾选1
              SFGXSZ1: KFHDPYSZ[0].SFGXSZ ? true : false, // 是否勾选设置 不勾选0，已勾选1
              ZDYHD: 3, //自定义活动id
              MSY1: KFHDPYSZ[0].MSY1,
              MSY2: KFHDPYSZ[0].MSY2,
              MSY3: KFHDPYSZ[0].MSY3,
            },
          });
        }
        // 自定义活动(课堂表现)
        if (KTBXPYSZ && KTBXPYSZ.length > 0) {
          this.activities.forEach((hd) => {
            let res = KTBXPYSZ.filter((item) => item.ZDYHD === hd.GLId);
            if (res && res.length > 0) {
              let plus = {};
              let minus = {};
              let plusObj = res.find((item) => item.SZXLX === 1) || {};
              let minusObj = res.find((item) => item.SZXLX === 2) || {};
              plus = {
                Id: plusObj.Id || "",
                SFGXSZ: 0, // 是否勾选设置 不勾选0，已勾选1
                SFGXSZ1: plusObj.SFGXSZ ? true : false, // 是否勾选设置 不勾选0，已勾选1
                ZDYHD: plusObj.ZDYHD || "", //自定义活动id
                SZXLX: 1, //设置项类型:1加分 2减分
                SZXGS: plusObj.SZXGS || 0, // 设置项个数
                MSY1: plusObj.MSY1 || "",
                MSY2: plusObj.MSY2 || "",
              };
              minus = {
                Id: minusObj.Id || "",
                SFGXSZ: 0, // 是否勾选设置 不勾选0，已勾选1
                SFGXSZ1: minusObj.SFGXSZ ? true : false, // 是否勾选设置 不勾选0，已勾选1
                ZDYHD: minusObj.ZDYHD || "", //自定义活动id
                SZXLX: 2, //设置项类型:1加分 2减分
                SZXGS: minusObj.SZXGS || "", // 设置项个数
                MSY1: minusObj.MSY1 || "",
                MSY2: minusObj.MSY2 || "",
              };
              customList.push({
                label: hd.label,
                type: 1,
                ZDYHD: plusObj.ZDYHD,
                plus,
                minus,
              });
            }
          });
        }
        this.customList = customList;

        // 结束语
        if (XSCJPYSZ && XSCJPYSZ.length > 0) {
          this.finishChecked = XSCJPYSZ.some((item) => item.SFGXSZ === 1);
          let arr = (arr = XSCJPYSZ.map((item) => {
            return {
              Id: item.Id,
              SFGXSZ: 0,
              GF: item.GF,
              DF: item.DF,
              MSY: item.MSY,
            };
          }));

          this.finishList = arr.sort((a, b) => a.GF - b.GF);
        }

        // 附件
        if (PYSZFJXZ && PYSZFJXZ.length > 0) {
          this.accessory.checkList = PYSZFJXZ.reduce((pre, cur) => {
            if (cur.JXGY) return pre.concat(7);
            else if (cur.KHHD) return pre.concat(3);
            else return pre.concat(cur.ZDYHD);
          }, []);
        }
      }
    },
    // 保存
    async addComment() {
      if (
        this.finishChecked &&
        this.finishList.some((item, idx) => !this.percentChange(item, idx))
      )
        return;

      if (this.finishList.some((item) => item.MSY && item.MSY.length > 120))
        return this.$message.error("结束语不能超过120个");

      await addComment(this.handleAdd());
      this.$message.success("操作成功");
      this.$emit("update:show", false);
    },
    // 提交前处理数据
    handleAdd() {
      let form = {};
      // 顶部评语
      form.AddZNPYSZ = this.firstLevel2.reduce((pre, cur) => {
        return pre.concat({
          Id: cur.Id ? cur.Id : "", //新增传NULL,编辑传对应Id值,
          WJId: cur.WJId, //对应的一级设置id
          SFGXSZ: cur.SFGXSZ2 ? 1 : 0, //是否勾选设置 不勾选0，已勾选1
          PYLX: cur.PYLX, //评语类型
          DJId: cur.DJId, //等级id
          MSY1: cur.MSY1 ? cur.MSY1 : `你本学期在${cur.label}表现`, // 描述语1
          BFB: 0, //百分比
          MSY2: cur.MSY2 || "打败了",
          MSY3: cur.MSY3 || "的同学", // 描述语3
          SSXX: this.userInfo.SSZZId,
          UserId: this.userInfo.Id,
        });
      }, []);

      // 课堂表现
      form.AddKTBXPYSZ = this.customList.reduce((pre, cur) => {
        if (cur.type === 1) {
          if (cur.plus) {
            pre = pre.concat({
              Id: cur.plus.Id ? cur.plus.Id : null, // 新增传NULL,//编辑传对应Id值,//
              SFGXSZ: cur.plus.SFGXSZ1 ? 1 : 0, // 是否勾选设置 不勾选0，已勾选1
              ZDYHD: cur.plus.ZDYHD || cur.Id, // 自定义活动
              SZXLX: 1, // 设置项类型:1表扬2待改进
              SZXGS: cur.plus.SZXGS, // 设置项个数
              MSY1: cur.plus.MSY1 || `孩子在${cur.label}多次得到`, // 描述语1
              MSY2: cur.plus.MSY2 || "方面的表扬，老师给你大大的赞！", // 描述语2
              SSXX: this.userInfo.SSZZId,
              UserId: this.userInfo.Id,
            });
          }
          if (cur.minus) {
            pre = pre.concat({
              Id: cur.minus.Id ? cur.minus.Id : null, // 新增传NULL,//编辑传对应Id值,//
              SFGXSZ: cur.minus.SFGXSZ1 ? 1 : 0, // 是否勾选设置 不勾选0，已勾选1
              ZDYHD: cur.minus.ZDYHD || cur.Id, // 自定义活动
              SZXLX: 2, // 设置项类型:1表扬2待改进
              SZXGS: cur.minus.SZXGS, // 设置项个数
              MSY1: cur.minus.MSY1 || `孩子你在${cur.label}表现欠佳的是：`, // 描述语1
              MSY2: cur.minus.MSY2 || "下次可得注意改进哟！", // 描述语2
              SSXX: this.userInfo.SSZZId,
              UserId: this.userInfo.Id,
            });
          }
          return pre;
        } else return pre;
      }, []);

      // 课辅活动评语设置
      let kfhd = this.customList.find((item) => item.type === 2);
      if (kfhd && kfhd.plus.SFGXSZ1) {
        form.AddKFHDPYSZ = [
          {
            Id: kfhd.Id || "", // 新增传NULL,编辑传对应Id值,
            SFGXSZ: 1, // 是否勾选设置 不勾选0，已勾选1
            MSY1: kfhd.MSY1 || "积极参加学校的课辅活动课程",
            MSY2: kfhd.MSY2 || "并得到了",
            MSY3: kfhd.MSY3 || "等方面的表扬",
            SSXX: this.userInfo.SSZZId,
            UserId: this.userInfo.Id,
          },
        ];
      } else form.AddKFHDPYSZ = [];

      // 结束语
      if (this.finishChecked) {
        form.AddXSCJPYSZ = this.finishList.reduce((pre, cur) => {
          return pre.concat({
            Id: cur.Id || null, // 新增传NULL,编辑传对应Id值,
            SFGXSZ: 1, // 是否勾选设置 不勾选0，已勾选1
            GF: cur.GF,
            DF: cur.DF,
            MSY: cur.MSY || "",
            SSXX: this.userInfo.SSZZId,
            UserId: this.userInfo.Id,
          });
        }, []);
      } else form.AddXSCJPYSZ = [];

      // 附件
      form.AddPYSZFJXZ = this.accessory.checkList.reduce((pre, cur) => {
        return pre.concat({
          Id: null, //新增传NULL,编辑传对应Id值,
          ZDYHD: cur !== 7 && cur !== 3 ? cur : "", //自定义活动
          KHHD: cur === 3 ? 1 : 0, //课辅活动
          JXGY: cur === 7 ? 1 : 0, //家校共育
          SSXX: this.userInfo.SSZZId,
          UserId: this.userInfo.Id,
        });
      }, []);

      form.ssxx = this.userInfo.SSZZId;
      form.token = this.userInfo.Token;
      return form;
    },
    // 选择自定义活动
    selectedChange(val) {
      if (val === "") return;
      let res = this.activities.find((item) => item.GLId === val);
      if (val === 3) {
        this.customList.push({
          Id: res.GLId ? res.GLId : null,
          label: res.label,
          type: 2,
          ZDYHD: res.GLId,
          plus: {
            SFGXSZ: 0, // 是否勾选设置 不勾选0，已勾选1
            SFGXSZ1: false, // 是否勾选设置 不勾选0，已勾选1
            ZDYHD: 3, //自定义活动id
            MSY1: "",
            MSY2: "",
            MSY3: "",
          },
        });
      } else {
        this.customList.push({
          Id: res.GLId ? res.GLId : null,
          label: res.label,
          type: 1,
          ZDYHD: res.GLId,
          plus: {
            SFGXSZ: 0, // 是否勾选设置 不勾选0，已勾选1
            SFGXSZ1: false, // 是否勾选设置 不勾选0，已勾选1
            ZDYHD: "", //自定义活动id
            SZXLX: 1, //设置项类型:1加分 2减分
            SZXGS: 1, // 设置项个数
            MSY1: "",
            MSY2: "",
          },
          minus: {
            SFGXSZ: 0, // 是否勾选设置 不勾选0，已勾选1
            SFGXSZ1: false, // 是否勾选设置 不勾选0，已勾选1
            ZDYHD: "", //自定义活动id
            SZXLX: 2, //设置项类型:1加分 2减分
            SZXGS: 1, // 设置项个数
            MSY1: "",
            MSY2: "",
          },
        });
      }
      this.selected = "";
    },
    // 结束语百分比校验
    percentChange(val, idx) {
      if (val.DF && val.GF === undefined) {
        this.$message.error("请完善结束语区间");
        return false;
      } else if (
        val.DF !== undefined &&
        val.GF !== undefined &&
        val.DF > val.GF
      ) {
        this.$message.error("结束语百分比下限不能超过上限");
        return false;
      } else if (this.handlePercent(val, idx)) {
        this.$message.error("结束语百分比区间不能重叠");
        return false;
      } else {
        return true;
      }
    },
    // 处理结束语百分几
    handlePercent(val, index) {
      return this.finishList.some((item, idx) => {
        if (
          index !== idx &&
          item.GF !== "" &&
          item.GF !== undefined &&
          item.GF !== null &&
          Number(item.GF) !== 0 &&
          item.DF !== "" &&
          item.DF !== undefined &&
          item.DF !== null &&
          Number(item.DF) !== 0
        ) {
          let data = this.finishList[index];
          if (
            data &&
            data.GF !== "" &&
            data.GF !== undefined &&
            data.GF !== null &&
            Number(item.GF) !== 0 &&
            data.DF !== "" &&
            data.DF !== undefined &&
            data.DF !== null &&
            Number(data.DF) !== 0
          ) {
            if (
              this.finishList[index].GF <= item.GF &&
              this.finishList[index].GF > item.DF
            ) {
              return true;
            } else if (
              this.finishList[index].DF < item.GF &&
              this.finishList[index].DF >= item.DF
            ) {
              return true;
            } else if (
              this.finishList[index].GF >= item.GF &&
              this.finishList[index].DF <= item.DF
            ) {
              return true;
            } else return false;
          }
          return false;
        } else return false;
      });
    },
    del(data, index) {
      this.customList = this.customList.filter((item, idx) => idx !== index);
    },
    // 描述语字长超120 变红提示
    MSY_change(e) {
      this.$nextTick(() => {
        this.$refs.msy.forEach((item) => {
          if (item.value && item.value.length > 120)
            item.$el.style.border = "1px solid red";
          else item.$el.style.border = "none";
        });
      });
    },
  },
  watch: {
    async show(val) {
      if (val) {
        let res = this.fixActivity.find((item) => item.label === "家校共育");
        this.accessory.option = this.activities
          ? JSON.parse(JSON.stringify(this.activities))
          : [];
        this.accessory.option.push(JSON.parse(JSON.stringify(res || {})));
        this.loading = true;
        let target = await getComment({
          SSXX: this.userInfo.SSZZId,
          token: this.userInfo.Token,
        });
        this.loading = false;
        this.init(target);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.first-level {
  .block {
    margin: 15px 10px;
    .example {
      span {
        width: 160px;
        text-align: center;
        &.span4 {
          width: 233px;
          text-align: right;
        }
      }
      .span1 {
        width: 100px;
      }

      .span5 {
        width: 110px;
        text-align: right;
      }
      .span6 {
        width: 60px;
      }
    }
    .first-level-item {
      display: flex;
      align-items: center;
      margin: 10px 5px;
      box-shadow: 1px 1px 2px 2px #f9fbfb;
      .el-input {
        width: 200px;
        margin: 0 10px;
      }
      .el-input-number {
        width: 100px;
        margin: 0 10px;
        ::v-deep .el-input-number__decrease {
          display: none;
        }
        ::v-deep .el-input-number__increase {
          display: none;
        }
      }
      .text1 {
        margin-left: 10px;
      }
      .percent {
        margin: 0 10px;
      }
    }
  }
}
.custom-comment {
  margin: 10px;
  .custom-item {
    box-shadow: 1px 1px 2px 2px #f9fbfb;
    margin: 10px 0;
    .top {
      display: flex;
      align-items: center;
      margin: 10px 0;
      .name {
        font-size: 16px;
        font-weight: 700;
      }
      .el-button {
        margin-left: 527px;
      }
      .el-checkbox {
        ::v-deep .el-checkbox__label {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    .plus,
    .minus {
      .plus-top,
      .minus-top,
      .custom-content {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .el-input {
          width: 330px;
          margin: 0 10px;
        }
        .num {
          width: 50px;
          margin-left: 10px;
          ::v-deep .el-input-number__decrease {
            display: none;
          }
          ::v-deep .el-input-number__increase {
            display: none;
          }
          ::v-deep .el-input__inner {
            padding: 0;
          }
        }
      }
      .example {
        padding: 10px 0;
      }
    }
    .plus {
      margin-bottom: 10px;
    }
    .kfhd {
      .custom-content {
        display: flex;
        align-items: center;
        margin: 10px;
        box-shadow: 1px 1px 2px 2px #f9fbfb;

        .el-input {
          max-width: 300px;
        }
      }
    }
  }
}
.add-custom {
  display: flex;
  align-items: center;
  margin: 20px 10px;
  box-shadow: 1px 1px 2px 2px #f9fbfb;
  .label {
    margin-right: 10px;
  }
}
.finish-comment {
  margin: 10px 10px;
  box-shadow: 1px 1px 2px 2px #f9fbfb;
  .label {
  }
  .finish-item {
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 2px 2px #f9fbfb;
    margin: 10px 0;
    .dashed {
      font-size: 20px;
      padding: 0 8px;
    }
    .el-input-number {
      width: 50px;
      ::v-deep .el-input-number__decrease {
        display: none;
      }
      ::v-deep .el-input-number__increase {
        display: none;
      }
      ::v-deep .el-input__inner {
        padding: 0;
      }
    }
  }
}
.accessory {
  margin: 20px 10px;
  box-shadow: 1px 1px 2px 2px #f9fbfb;
  .label {
    margin: 10px 0;
    box-shadow: 1px 1px 2px 2px #f9fbfb;
  }
}
</style>
