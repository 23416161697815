var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.visible,"width":"30%","center":"","before-close":_vm.closeDialog,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"tab-shu-box"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formData},on:{"validate":_vm.validate}},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"tab-shu-titl pos-r"},[_vm._v(" 报表代码 "),_c('div',{staticClass:"prompt-butt-box"},[_vm._v("注")]),_c('div',{staticClass:"prompt-butt-center"},[_vm._v(" 注：报表代码需要和前端开发人员协商一致，且报表代码不允许重复。 ")])]),_c('td',[_c('el-form-item',{ref:"BBDM",staticStyle:{"height":"16px"},attrs:{"rules":[
                  {
                    required: true,
                    message: '请输入报表代码',
                    trigger: 'change'
                  }
                ],"prop":"BBDM"}},[_c('el-input',{staticClass:"jf-form",attrs:{"placeholder":"请输入内容","size":"small","prop":"BBDM"},model:{value:(_vm.formData.BBDM),callback:function ($$v) {_vm.$set(_vm.formData, "BBDM", $$v)},expression:"formData.BBDM"}})],1)],1)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("报表名称")]),_c('td',[_c('el-form-item',{ref:"BBMC",staticStyle:{"height":"16px"},attrs:{"rules":[
                  {
                    required: true,
                    message: '请输入报表名称',
                    trigger: 'change'
                  }
                ],"prop":"BBMC"}},[_c('el-input',{staticClass:"jf-form",attrs:{"placeholder":"请输入内容","size":"small","prop":"BBMC"},model:{value:(_vm.formData.BBMC),callback:function ($$v) {_vm.$set(_vm.formData, "BBMC", $$v)},expression:"formData.BBMC"}})],1)],1)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("支撑评价方式")]),_c('td',[_c('el-checkbox',{on:{"change":_vm.checkedPJFS},model:{value:(_vm.formData.TYZB),callback:function ($$v) {_vm.$set(_vm.formData, "TYZB", $$v)},expression:"formData.TYZB"}},[_vm._v("通用指标")]),_c('el-checkbox',{on:{"change":_vm.checkedPJFS},model:{value:(_vm.formData.XXZB),callback:function ($$v) {_vm.$set(_vm.formData, "XXZB", $$v)},expression:"formData.XXZB"}},[_vm._v("星星指标")]),_c('el-checkbox',{on:{"change":_vm.checkedPJFS},model:{value:(_vm.formData.WJ),callback:function ($$v) {_vm.$set(_vm.formData, "WJ", $$v)},expression:"formData.WJ"}},[_vm._v("问卷")])],1)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("报表类别")]),_c('td',[_c('el-form-item',{ref:"BBLX",staticStyle:{"height":"16px"},attrs:{"rules":[
                  {
                    required: true,
                    message: '请选择报表类别',
                    trigger: 'change'
                  }
                ],"prop":"BBLX"}},[_c('el-select',{staticClass:"jf-form",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","size":"small","prop":"BBLX"},on:{"change":_vm.checkedPJFS},model:{value:(_vm.formData.BBLX),callback:function ($$v) {_vm.$set(_vm.formData, "BBLX", $$v)},expression:"formData.BBLX"}},_vm._l((_vm.reportType.filter(function (c) { return _vm.reportFilter.includes(c.Key); }
                    )),function(item){return _c('el-option',{key:item.Key,attrs:{"label":item.Value,"value":item.Key}})}),1)],1)],1)]),(
              (_vm.formData.TYZB || _vm.formData.XXZB || _vm.formData.WJ) && _vm.formData.BBLX
            )?_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("报表形状")]),_c('td',[_c('el-form-item',{ref:"BBXZ",staticStyle:{"height":"16px"},attrs:{"rules":[
                  {
                    required: true,
                    message: '请选择报表形状',
                    trigger: 'change'
                  }
                ],"prop":"BBXZ"}},[_c('el-select',{staticClass:"jf-form",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","size":"small"},model:{value:(_vm.formData.BBXZ),callback:function ($$v) {_vm.$set(_vm.formData, "BBXZ", $$v)},expression:"formData.BBXZ"}},_vm._l((_vm.shapes.filter(function (c) { return _vm.shapeLimit.includes(c.Key); }
                    )),function(item){return _c('el-option',{key:item.Key,attrs:{"label":item.Value,"value":item.Key}})}),1)],1)],1)]):_vm._e(),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("状态")]),_c('td',[_c('el-form-item',{ref:"SYZT",staticStyle:{"height":"16px"},attrs:{"rules":[
                  {
                    required: true,
                    message: '请选择状态',
                    trigger: 'change'
                  }
                ],"prop":"SYZT"}},[_c('el-select',{staticClass:"jf-form",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","size":"small","prop":"SYZT"},model:{value:(_vm.formData.SYZT),callback:function ($$v) {_vm.$set(_vm.formData, "SYZT", $$v)},expression:"formData.SYZT"}},_vm._l((_vm.statu),function(item){return _c('el-option',{key:item.Key,attrs:{"label":item.Value,"value":item.Key}})}),1)],1)],1)])])])])],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirmDialog}},[_vm._v("确 定")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }