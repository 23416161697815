var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cont-whole-box"},[_c('div',{staticClass:"cent-topbutt-box cz-top-bacbox"},[_c('div',{staticClass:"top-butt-box"},[_c('div',{class:[
          _vm.menuTab === 0 ? 'cent-butt-sty whole-col' : '',
          'cent-butt whole-col-hov'
        ],on:{"click":function($event){return _vm.switchTab(0)}}},[_vm._v(" 通用设置 ")]),_c('div',{class:[
          _vm.menuTab === 1 ? 'cent-butt-sty whole-col' : '',
          'cent-butt whole-col-hov'
        ],on:{"click":function($event){return _vm.switchTab(1)}}},[_c('div',{class:[_vm.currentType === 1 ? 'tablil-xz-sty' : '', 'tablil-xz'],on:{"click":function($event){return _vm.switchTab(1)}}}),_vm._v(" 使用体系生成报告 ")]),_c('div',{class:[
          _vm.menuTab === 2 ? 'cent-butt-sty whole-col' : '',
          'cent-butt whole-col-hov'
        ],on:{"click":function($event){return _vm.switchTab(2)}}},[_c('div',{class:[_vm.currentType === 2 ? 'tablil-xz-sty' : '', 'tablil-xz'],on:{"click":function($event){return _vm.switchTab(2)}}}),_vm._v(" 使用纬度生成报告 ")])])]),_c('div',{staticStyle:{"padding":"30px","box-sizing":"border-box"}},[_c('div',{staticClass:"sm-conten-tit"},[_vm._v(_vm._s(_vm.title))]),(_vm.menuTab === 0 && _vm.enableGeneral1)?_c('general1',{attrs:{"setInfo":_vm.setInfo,"basicInfo":_vm.basicInfo},on:{"toGeneral2":_vm.toGeneral2}}):_vm._e(),(_vm.menuTab === 0 && _vm.enableGeneral2)?_c('general2',{attrs:{"setInfo":_vm.setInfo,"basicInfo":_vm.basicInfo},on:{"toGeneral1":_vm.toGeneral1}}):_vm._e(),(_vm.menuTab === 1 && _vm.enableSystem)?_c('systemSet',{attrs:{"setInfo":_vm.setInfo,"basicInfo":_vm.basicInfo,"SZLB":_vm.currentType},on:{"toGeneral1":_vm.toGeneral1}}):_vm._e(),(_vm.menuTab === 2 && _vm.enableDimension)?_c('dimensionSet',{attrs:{"setInfo":_vm.setInfo,"basicInfo":_vm.basicInfo,"SZLB":_vm.currentType},on:{"toGeneral1":_vm.toGeneral1}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }