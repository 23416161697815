<template>
  <el-dialog
    :title="`${XKFZ.XKXZ}(${XKFZ.NJXZ})${fatherData.MC} / ${activityStr}`"
    :visible.sync="show"
    width="90%"
    custom-class="setIndex-dialog"
    append-to-body
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div class="SetIndex">
      <span class="tips">*右键指标名称可全选</span>
      <div class="left">
        <el-tree
          :data="indexTree"
          ref="tree"
          node-key="ZbId"
          check-strictly
          show-checkbox
          :default-expanded-keys="expandedKeys"
          :default-checked-keys="checkedKeys"
          :props="defaultProps"
        >
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <span class="mc" @contextmenu.prevent="rightClick(data)">{{
              node.label
            }}</span>
            <span class="bz">{{ data.Zbbz }}</span>
          </div></el-tree
        >
      </div>
      <div class="right">
        <div class="MKMC">
          <div class="label">字段名称：</div>
          <el-input v-model="form.MKMC" size="small" />
        </div>
        <div class="ZSFS">
          <div class="label">显示方式：</div>
          <el-select
            size="small"
            style="width:100px;padding-right:10px"
            v-model="form.SZLX"
            placeholder="请选择"
          >
            <el-option
              v-for="item in levelTypeOption"
              v-show="handleShowLevel_Btn(0, item)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-show="form.SZLX === 1"
            size="small"
            filterable
            v-model="form.DJId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in levelOptions.filter(item => item.DJLX !== 1)"
              :key="item.Id"
              :label="item.DJMC"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="BFB">
          <div class="label">占比：</div>
          <el-input-number
            v-model="form.BFB"
            size="small"
            :precision="2"
            :min="-99999"
            :max="99999"
          />%
        </div>
        <div class="XH">
          <div class="label">排序：</div>
          <el-input-number
            v-model="form.XH"
            size="small"
            :precision="0"
            :min="1"
            :max="100"
          ></el-input-number>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:show', false)"
        >取 消</el-button
      >
      <el-button type="primary" size="small" @click="submit(1)"
        >勾选指标保存</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="submit(2)"
        :disabled="!!formData.Id || handleShowLevel_Btn(1)"
        >关联顶级指标填充</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="submit(3)"
        :disabled="!!formData.Id || handleShowLevel_Btn(2)"
        >关联末级指标填充</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { AddShowModuleField } from "@/api/growthReportSet.js";

export default {
  props: {
    show: [Boolean],
    indexTree: [Array],
    fatherData: [Object],
    XKFZ: [Object],
    activity: {
      type: Array,
      default() {
        return [];
      }
    },
    formData: [Object],
    levelOptions: [Array]
  },
  data() {
    return {
      userInfo: {},
      defaultProps: {
        children: "children",
        label: "Zbmc"
      },
      form: {
        MKMC: "",
        DJId: "3",
        BFB: "",
        XH: "",
        SZLX: 3
      },
      expandedKeys: [],
      checkedKeys: [],
      activityStr: "",
      levelTypeOption: [
        {
          label: "等级",
          value: 1
        },
        {
          label: "星数",
          value: 2
        },
        {
          label: "分数",
          value: 3
        }
      ]
    };
  },
  mounted() {
    let user = localStorage.getItem("userinfo");
    if (user) this.userInfo = JSON.parse(user);
    this.currentTerm = localStorage.getItem("currentterm");
  },
  methods: {
    // 处理顶级 给每一级指标赋值他的顶级指标id
    handleIndexFirst(list, id) {
      list.forEach(item => {
        item.firstId = id;
        if (item.children && item.children.length > 0)
          this.handleIndexFirst(item.children, id);
      });
    },
    async submit(btnType) {
      let XHarr = [];
      let max = "";
      if (this.fatherData.zdList && this.fatherData.zdList.length > 0) {
        XHarr = this.fatherData.zdList.map(item => item.XH);
        max = Math.max(...XHarr);
      }

      if (this.form.BFB === "" || this.form.BFB === undefined)
        return this.$message.error("请输入占比");
      if (this.form.SZLX === 1 && this.form.DJId === "")
        return this.$message.error("请选择等级");
      if (btnType === 1) {
        if (!this.form.MKMC) return this.$message.error("请输入字段名称");
        if (this.form.MKMC.length > 20)
          return this.$message.error("字段名称长度不能超过15个字符");

        try {
          // 序号处理
          if (XHarr.length > 0) {
            if (this.form.XH) {
              let abc = this.fatherData.zdList.find(
                item => item.XH === this.form.XH
              );
              console.log(this.form, abc.Id, "eeee");
              if (
                abc &&
                abc.Id !== this.form.Id &&
                XHarr.includes(this.form.XH)
              )
                return this.$message.error("排序已存在，请重新输入");
            } else {
              this.form.XH = max + 1;
            }
          }
        } catch {
          //
        }

        let checked = this.$refs.tree.getCheckedNodes();
        let res = [];
        if (checked && checked.length > 0) {
          checked.forEach(item => this.handlecheckedIndex(item, checked, res));
        } else return this.$message.error("请勾选指标");
        console.log(res, "指标勾选保存");
        await AddShowModuleField(this.handleIndex(1, res));
      } else {
        let res = [];
        let checked = this.$refs.tree.getCheckedNodes();
        console.log(checked, "checked");

        if (btnType === 2) {
          if (checked && checked.length > 0) {
            let res2 = [];
            checked.forEach(item => {
              let id = "";
              item.first ? (id = item.ZbId) : (id = item.firstId);
              let idx = res2.findIndex(el => el === id);
              console.log(idx, "idx");
              if (idx === -1) res2.push(id);
            });

            this.indexTree.forEach(item => {
              res2.forEach(id => {
                if (item.ZbId === id) res.push(item);
              });
            });
          } else {
            this.indexTree.forEach(item => {
              let idx = res.findIndex(el => el.ZbId === item.ZbId);
              if (idx === -1) res.push(item);
            });
          }
        } else {
          this.recursionZB(
            btnType,
            checked && checked.length > 0 ? checked : this.indexTree,
            null,
            res
          );
        }
        console.log(res, "res");
        await AddShowModuleField(this.handleIndex(btnType, res, XHarr, max));
      }
      this.$emit("refresh");
      this.$emit("update:show", false);
    },
    // 处理勾选指标中的一级指标
    handlecheckedIndex(index, checked, res) {
      if (index.first) {
        if (!res.find(item => item.ZbId === index.ZbId)) res.push(index);
      } else {
        if (!index.FId) {
          if (!res.find(item => item.ZbId === index.ZbId)) res.push(index);
        } else {
          let node = checked.find(el => el.ZbId === index.FId);
          if (node) this.handlecheckedIndex(node, checked, res);
          else {
            if (!res.find(item => item.ZbId === index.ZbId)) res.push(index);
          }
        }
      }
    },
    handleIndex(type, checked, XHarr, max) {
      let obj = {};
      let ZSMKInfo = [];
      let ZSMKGLZBInfo = [];
      let HdList = this.activity.map(hd => {
        return {
          EvalutionFormat: hd.EvalutionFormat,
          HdId: hd.HdId,
          Hdmc: hd.Hdmc
        };
      });
      if (type === 1) {
        ZSMKGLZBInfo = checked.reduce((pre, cur) => {
          return pre.concat({
            ZBId: cur.ZbId,
            ZBLX: cur.ZbLx,
            HDXS: cur.HDXS
          });
        }, []);
        obj = {
          ssxx: this.userInfo.SSZZId,
          xqid: this.currentTerm,
          id: this.fatherData.Id, //模块id
          ZSMKInfo: [
            {
              Id: this.formData.Id,
              MC: this.form.MKMC, //名称
              SJLX: 3, //数据类型 1模块信息2考试测查3指标设置
              SZLX: this.form.SZLX, //设置类型 1等级,//2星数 3//分数
              BFB: this.form.BFB, // 百分百
              XH: this.form.XH, // 序号
              GLDJId: this.form.SZLX === 1 ? this.form.DJId : "", // 关联等级Id
              HdList: JSON.stringify(HdList),
              ZSMKGLZBInfo
            }
          ],
          token: this.userInfo.Token
        };
      } else {
        ZSMKInfo = checked.reduce((pre, cur, index) => {
          return pre.concat({
            MC: cur.Zbmc, //名称
            SJLX: 3, //数据类型 1模块信息2考试测查3指标设置
            SZLX: this.form.SZLX, //设置类型 1等级,//2星数 3//分数
            BFB: this.form.BFB, // 百分百
            XH: index + 1 + max, // 序号
            GLDJId: this.form.SZLX === 1 ? this.form.DJId : "", // 关联等级Id
            HdList: JSON.stringify(HdList),
            ZSMKGLZBInfo: [
              {
                ZBId: cur.ZbId,
                ZBLX: cur.ZbLx,
                HDXS: cur.HDXS
              }
            ]
          });
        }, []);
        console.log(this.userInfo, "wwww");
        obj = {
          ssxx: this.userInfo.SSZZId,
          xqid: this.currentTerm,
          id: this.fatherData.Id, //模块
          ZSMKInfo,
          token: this.userInfo.Token
        };
      }

      return obj;
    },

    // 处理关于星数  提交按钮的启用
    handleShowLevel_Btn(type, level) {
      // 等级显隐
      if (!type) {
        if (level.Id !== "2") return true;
        // 星数等级 勾选的活动评价方式全是星星才显示
        else return this.activity.every(hd => hd.EvalutionFormat === 2);
      } else {
        // 这两个按钮只使用通用或者星星
        if (this.activity.some(item => item.EvalutionFormat === 3)) return true;
      }
    },
    // 递归指标 找末级
    recursionZB(type, IndexList, parent, res) {
      IndexList.forEach(Index => {
        if (Index.children && Index.children.length > 0) {
          console.log(Index, "wwwww");
          this.recursionZB(type, Index.children, Index, res);
        } else {
          if (type === 2) {
            console.log(Index, parent, "index");
            // 倒数第二级
            if (Index.FId) {
              if (parent) {
                let idx2 = res.findIndex(it => it.ZbId === parent.ZbId);
                if (idx2 === -1) res.push(parent);
              }
            } else {
              let idx = res.findIndex(it => it.ZbId === Index.ZbId);
              if (idx === -1) res.push(Index);
            }
          } else {
            console.log(12, "12");
            // 末级
            let idx = res.findIndex(it => it.ZbId === Index.ZbId);
            if (idx === -1) res.push(Index);
          }
        }
      });
    },
    // tree右键全选或者全空
    rightClick(data) {
      if (!data.children || data.children.length === 0) return;
      let res = [];
      this.filterLastIndex(data, res);
      let checked = this.$refs.tree.getCheckedNodes();
      console.log(checked, "checked");
      // 判断该指标的子孙级指标是否有被勾选
      let flag = "";
      let arr = [];
      flag = res.every(id => checked.some(item => item.ZbId === id));
      if (flag) {
        // 自己和子孙级全被勾选 就全空
        arr = checked.filter(item => !res.some(id => id === item.ZbId));
        this.$refs.tree.setCheckedKeys(arr.map(item => item.ZbId));
      } else {
        console.log(data, "data");
        // 没有被勾选的设置为勾选
        res.forEach(id => {
          let idx = checked.findIndex(item => item.ZbId === id);
          if (idx === -1) arr.push(id);
        });
        this.$refs.tree.setCheckedKeys([
          ...arr,
          ...checked.map(item => item.ZbId)
        ]);
      }
    },
    // 辅助tree右键
    filterLastIndex(data, res) {
      let idx = res.findIndex(id => id === data.ZbId);
      if (idx === -1) res.push(data.ZbId);
      if (data.children && data.children.length > 0) {
        data.children.forEach(item => this.filterLastIndex(item, res));
      }
    }
  },
  watch: {
    show(val) {
      if (val) {
        let arr = this.activity.map(item => item.Hdmc);
        if (arr && arr.length > 0) {
          this.activityStr = arr.join(" / ");
        }
        // 给每个指标赋值它的顶级指标id 方便关联一级指标
        this.indexTree.forEach(item => {
          item.first = true;
          if (item.children && item.children.length > 0)
            this.handleIndexFirst(item.children, item.ZbId);
        });

        if (this.formData && Object.keys(this.formData).length > 0) {
          if (
            this.formData.ZSMKGLZBInfo &&
            this.formData.ZSMKGLZBInfo.length > 0
          ) {
            let arr = this.formData.ZSMKGLZBInfo.reduce((pre, cur) => {
              return pre.concat(cur.ZBId);
            }, []);
            this.checkedKeys = this.expandedKeys = arr;
          }

          this.form.MKMC = this.formData.MC;
          this.form.SZLX = this.formData.SZLX;
          this.form.DJId = this.formData.GLDJId;
          this.form.BFB = this.formData.BFB;
          this.form.XH = this.formData.XH;
          this.form.Id = this.formData.Id;
        } else {
          this.form.MKMC = "";
          this.form.SZLX = 3;
          this.form.DJId = "";
          this.form.BFB = "";
          this.form.XH = "";
          this.form.Id = "";
          this.checkedKeys = this.expandedKeys = [];
        }
      }
    }
  }
};
</script>
<style lang="scss">
.setIndex-dialog {
  box-sizing: border-box;
  min-width: 1000px;
  .el-dialog__body {
    box-sizing: border-box;
    min-width: 1000px;
    overflow: hidden;
  }
}
</style>
<style lang="scss" scoped>
.SetIndex {
  display: flex;
  position: relative;
  .tips {
    position: absolute;
    top: -11px;
    left: 0;
    font-size: 12px;
    color: #ccc;
  }
  .left {
    flex: 1;
    height: 400px;
    margin: 10px;
    padding: 10px;
    box-shadow: 1px 1px 2px 2px #f3f2f2;
    border-radius: 2px;
    overflow-y: auto;
    .custom-tree-node {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: hidden;
      .bz {
        padding-left: 20px;
      }
    }
  }
  .right {
    width: 500px;
    height: 400px;
    margin: 10px;
    padding: 10px;
    box-shadow: 1px 1px 2px 2px #f3f2f2;
    border-radius: 2px;
    .MKMC,
    .ZSFS,
    .BFB,
    .XH {
      display: flex;
      align-items: center;
      margin: 15px 0;
      padding: 10px;
      box-shadow: 1px 1px 2px 2px #f3f2f2;
      .label {
        width: 70px;
        margin-right: 15px;
      }
      .el-input,
      .el-select,
      .el-input-number {
        width: 200px;
        ::v-deep .el-input-number__decrease {
          display: none;
        }
        ::v-deep .el-input-number__increase {
          display: none;
        }
      }
    }
  }
}
</style>
