<template>
  <el-dialog
    title=""
    :visible.sync="show"
    width="90%"
    append-to-body
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div>
      <el-button type="primary" size="small" @click="add">新增</el-button>
      <el-input size="small" placeholder="请输入学科名称" v-model="seachText">
        <el-button
          slot="append"
          size="small"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input>
    </div>
    <el-table
      :data="searchData.length > 0 ? searchData : xkList"
      style="width: 100%"
    >
      <el-table-column prop="XKXZ" label="学科"> </el-table-column>
      <el-table-column prop="NJXZ" label="年级"> </el-table-column>
      <el-table-column label="学业总评">
        <template slot-scope="scope">
          <span>{{ scope.row | handleXYZP(levelOptions) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="edit(scope.row)"
            >设置</el-button
          >
          <el-button type="danger" size="small" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button
        @click="$emit('update:show', false)"
        size="small"
        icon="el-icon-circle-close"
        >关闭</el-button
      >
    </span>

    <!-- 新增学科 -->
    <XK-Form
      :show.sync="addXK.visible"
      :disabledNj="disabledNj"
      :njOption="njOption"
      :xkOption="xkOption"
      :levelOptions="levelOptions"
      :firstLevel="firstLevel"
      @refresh="GetSubjectSet"
    />

    <!-- 编辑学科模块 -->
    <XKMK
      :show.sync="XKMK.visible"
      :levelOptions="levelOptions"
      :fatherData="XKMK.data"
    />
  </el-dialog>
</template>

<script>
import XK_Form from "./XK_Form.vue";
import XKMK from "./XKMK.vue";
import { GetSubjectSet, DeleteSubjectSet } from "@/api/growthReportSet.js";

export default {
  components: {
    XKForm: XK_Form,
    XKMK
  },
  props: {
    show: Boolean,
    levelOptions: [Array],
    firstLevel: [Object]
  },
  data() {
    return {
      userInfo: {},
      xkList: [],
      xkOption: [],
      njOption: [],
      disabledNj: [],
      addXK: {
        visible: false,
        form: {}
      },
      XKMK: {
        data: {},
        visible: false
      },
      seachText: "",
      searchData: []
    };
  },
  async mounted() {
    let user = localStorage.getItem("userinfo");
    if (user) this.userInfo = JSON.parse(user);
    else this.$message.error("没有获取到userinfo");
    await this.getNJ();
    await this.getXk();
    this.GetSubjectSet();
  },
  filters: {
    handleXYZP(val, levelOptions) {
      if (val.DJId) {
        let res = levelOptions.find(item => item.Id === val.DJId);
        if (res) {
          return res.DJMC;
        } else return "";
      } else return "星数";
    }
  },
  methods: {
    // 获取学科
    getXk() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/xxkcb/GetXXKCBList",
          {
            SchoolId: this.userInfo.SSZZId,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.xkOption = res.Target || [];
              resolve();
            } else reject(res);
          },
          err => reject(err)
        );
      });
    },
    // 获取学科表
    async GetSubjectSet() {
      let res = await GetSubjectSet({
        id: this.firstLevel.Id
      });
      let FZIds = res.map(item => item.FZId); //搜集分组id
      if (FZIds && FZIds.length > 0) {
        let arr = [];
        // 去重
        FZIds.forEach(id => {
          let idx = arr.findIndex(item => item === id);
          if (idx === -1) arr.push(id);
        });

        let arr2 = [];
        // 归组
        arr.forEach(FZId => {
          arr2.push(res.filter(item => item.FZId === FZId));
        });
        // 处理成表格数据
        this.xkList = arr2.reduce((pre, cur) => {
          return pre.concat({
            DJId: cur[0].DJId,
            Id: cur[0].FZId,
            NJXZ: cur[0].NJXZ,
            XKXZ: cur.map(item => item.XKXZ),
            XYZP: cur[0].XYZP
          });
        }, []);
      } else this.xkList = [];
    },
    add() {
      console.log(222, "2222");
      let arr = [];
      let XKstr = "";
      // 搜集所有学科
      this.xkList.forEach((item, index) => {
        XKstr += item.XKXZ + (index === this.xkList.length - 1 ? "" : ",");
      });
      // 去重
      let arr2 = [];
      XKstr.split(",").forEach(item => {
        let idx = arr2.findIndex(el => el === item);
        if (idx === -1) arr2.push(item);
      });

      // 搜集每个学科已有的年级
      arr2.forEach(item => {
        let NJstr = "";
        this.xkList.forEach(el => {
          if (el.XKXZ.includes(item)) {
            NJstr += el.NJXZ + ",";
          }
        });
        arr.push({
          xk: item,
          nj: NJstr.substring(0, NJstr.lastIndexOf(",")).split(",")
        });
      });
      this.disabledNj = arr;
      this.addXK.visible = true;
    },
    async del(data) {
      await DeleteSubjectSet({ id: data.Id });
      this.$message.success("操作成功");
      this.GetSubjectSet();
    },
    edit(data) {
      this.XKMK.data = data;
      this.XKMK.visible = true;
    },
    getNJ() {
      return new Promise((resolve, reject) => {
        this.$api.post(
          "v1/accountmanage/GetSchoolOrg",
          {
            SchoolId: this.userInfo.SSZZId,
            token: this.userInfo.Token
          },
          res => {
            if (res.Code === 0) {
              this.njOption = res.Target || [];
              this.njOption.forEach(item => this.$set(item, "disabled", false));
              resolve();
            } else reject(res);
          },
          err => reject(err)
        );
      });
    },
    handleSearch() {
      if (this.seachText && this.seachText.trim()) {
        let res = this.xkList.filter(item =>
          item.XKXZ.includes(this.seachText.trim())
        );
        this.searchData = res.length > 0 ? res : this.xkList;
      } else {
        this.searchData = [];
      }
    }
  }
};
</script>

<style></style>
