<template>
  <el-dialog
    :title="`${XKFZ.XKXZ}(${XKFZ.NJXZ})${fatherData.MC} / ${activityStr}`"
    :visible.sync="show"
    width="90%"
    custom-class="setIndex-dialog"
    append-to-body
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div class="SetIndex">
      <div class="left">
        <el-tree
          :data="indexTree"
          ref="tree"
          node-key="ZbId"
          check-strictly
          show-checkbox
          :default-expanded-keys="expandedKeys"
          :default-checked-keys="checkedKeys"
          :props="defaultProps"
        >
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <span class="mc">{{ node.label }}</span>
            <span class="bz">{{ data.Zbbz }}</span>
          </div></el-tree
        >
      </div>
      <div class="right">
        <div class="MKMC">
          <div class="label">字段名称：</div>
          <el-input v-model="form.MKMC" size="small" />
        </div>
        <div class="ZSFS">
          <div class="label">显示方式：</div>
          <el-select size="small" v-model="form.DJId" placeholder="请选择">
            <el-option
              v-for="item in levelOptions2"
              v-show="handleShowLevel_Btn(0, item)"
              :key="item.Id"
              :label="item.DJMC"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="BFB">
          <div class="label">占比：</div>
          <el-input-number
            v-model="form.BFB"
            size="small"
            :precision="0"
            :min="0"
            :max="100"
          />%
        </div>
        <div class="XH">
          <div class="label">排序：</div>
          <el-input-number
            v-model="form.XH"
            size="small"
            :precision="0"
            :min="1"
            :max="100"
          ></el-input-number>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:show', false)"
        >取 消</el-button
      >
      <el-button type="primary" size="small" @click="submit(1)"
        >勾选指标保存</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="submit(2)"
        :disabled="!!formData.Id || handleShowLevel_Btn(1)"
        >关联倒数二级指标填充</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="submit(3)"
        :disabled="!!formData.Id || handleShowLevel_Btn(2)"
        >关联末级指标填充</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { AddShowModuleField } from "@/api/growthReportSet.js";

export default {
  props: {
    show: [Boolean],
    indexTree: [Array],
    fatherData: [Object],
    XKFZ: [Object],
    activity: {
      type: Array,
      default() {
        return [];
      }
    },
    formData: [Object],
    levelOptions: [Array]
  },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "Zbmc"
      },
      form: {
        MKMC: "",
        DJId: "3",
        BFB: "",
        XH: ""
      },
      expandedKeys: [],
      checkedKeys: [],
      activityStr: "",
      levelOptions2: []
    };
  },
  mounted() {
    this.levelOptions2 = JSON.parse(JSON.stringify(this.levelOptions));
    this.levelOptions2 = [
      {
        DJMC: "分数",
        Id: "3"
      },
      {
        DJMC: "星数",
        Id: "2"
      },
      ...this.levelOptions2.filter(
        item => item.DJLX && (item.DJLX === 2 || item.DJLX === 3)
      )
    ];
  },
  methods: {
    async submit(btnType) {
      if (this.form.BFB === "" || this.form.BFB === undefined)
        return this.$message.error("请输入占比");
      if (btnType === 1) {
        if (!this.form.MKMC) return this.$message.error("请输入字段名称");
        if (this.form.MKMC.length > 20)
          return this.$message.error("字段名称长度不能超过15个字符");
        let checked = this.$refs.tree.getCheckedNodes();
        if (checked.length === 0) return this.$message.error("请勾选指标");
        await AddShowModuleField(this.handleIndex(1, checked));
      } else {
        let res = [];
        this.recursionZB(btnType, this.indexTree, null, res);
        await AddShowModuleField(this.handleIndex(btnType, res));
      }
      this.$emit("refresh");
      this.$emit("update:show", false);
    },
    handleIndex(type, checked) {
      let obj = {};
      let ZSMKInfo = [];
      let ZSMKGLZBInfo = [];
      let HdList = this.activity.map(hd => {
        return {
          EvalutionFormat: hd.EvalutionFormat,
          HdId: hd.HdId,
          Hdmc: hd.Hdmc
        };
      });
      if (type === 1) {
        ZSMKGLZBInfo = checked.reduce((pre, cur) => {
          return pre.concat({
            ZBId: cur.ZbId,
            ZBLX: cur.ZbLx
          });
        }, []);
        obj = {
          id: this.fatherData.Id, //模块id
          ZSMKInfo: [
            {
              Id: this.formData.Id,
              MC: this.form.MKMC, //名称
              SJLX: 3, //数据类型 1模块信息2考试测查3指标设置
              SZLX: this.form.DJId === "2" ? 2 : this.form.DJId === "3" ? 3 : 1, //设置类型 1等级,//2星数 3//分数
              BFB: this.form.BFB, // 百分百
              XH: this.form.XH, // 序号
              GLDJId: this.form.DJId, // 关联等级Id
              HdList: JSON.stringify(HdList),
              ZSMKGLZBInfo
            }
          ]
        };
      } else {
        ZSMKInfo = checked.reduce((pre, cur, index) => {
          return pre.concat({
            MC: cur.Zbmc, //名称
            SJLX: 3, //数据类型 1模块信息2考试测查3指标设置
            SZLX: this.form.DJId === "2" ? 2 : this.form.DJId === "3" ? 3 : 1, //设置类型 1等级,//2星数 3//分数
            BFB: this.form.BFB, // 百分百
            XH: index + 1, // 序号
            GLDJId: this.form.DJId, // 关联等级Id
            HdList: JSON.stringify(HdList),
            ZSMKGLZBInfo: [
              {
                ZBId: cur.ZbId,
                ZBLX: cur.ZbLx
              }
            ]
          });
        }, []);

        obj = {
          id: this.fatherData.Id, //模块
          ZSMKInfo
        };
      }

      return obj;
    },
    // 处理关于星数  提交按钮的启用
    handleShowLevel_Btn(type, level) {
      // 等级显隐
      if (!type) {
        if (level.Id !== "2") return true;
        // 星数等级 勾选的活动评价方式全是星星才显示
        else return this.activity.every(hd => hd.EvalutionFormat === 2);
      } else {
        // 这两个按钮只使用通用或者星星
        if (this.activity.some(item => item.EvalutionFormat === 3)) return true;
      }
    },
    // 递归指标 找倒数第二级（只有一级就算作倒数第二级） || 找末级
    recursionZB(type, IndexList, parent, res) {
      IndexList.forEach(Index => {
        if (Index.children && Index.children.length > 0) {
          this.recursionZB(type, Index.children, Index, res);
        } else {
          if (type === 2) {
            // 倒数第二级
            if (parent) {
              let idx2 = res.findIndex(it => it.ZbId === parent.ZbId);
              if (idx2 === -1) res.push(parent);
            } else {
              let idx = res.findIndex(it => it.ZbId === Index.ZbId);
              if (idx === -1) res.push(Index);
            }
          } else {
            // 末级
            let idx = res.findIndex(it => it.ZbId === Index.ZbId);
            if (idx === -1) res.push(Index);
          }
        }
      });
    }
  },
  watch: {
    show(val) {
      if (val) {
        let arr = this.activity.map(item => item.Hdmc);
        if (arr && arr.length > 0) {
          this.activityStr = arr.join(" / ");
        }
        if (this.formData && Object.keys(this.formData).length > 0) {
          if (
            this.formData.ZSMKGLZBInfo &&
            this.formData.ZSMKGLZBInfo.length > 0
          ) {
            let arr = this.formData.ZSMKGLZBInfo.reduce((pre, cur) => {
              return pre.concat(cur.ZBId);
            }, []);
            this.checkedKeys = this.expandedKeys = arr;
          }

          this.form.MKMC = this.formData.MC;
          this.form.DJId = this.formData.GLDJId;
          this.form.BFB = this.formData.BFB;
          this.form.XH = this.formData.XH;
        } else {
          this.form.MKMC = "";
          this.form.DJId = "3";
          this.form.BFB = "";
          this.form.XH = "";
          this.checkedKeys = this.expandedKeys = [];
        }
      }
    }
  }
};
</script>
<style lang="scss">
.setIndex-dialog {
  box-sizing: border-box;
  min-width: 1000px;
  .el-dialog__body {
    box-sizing: border-box;
    min-width: 1000px;
    overflow: hidden;
  }
}
</style>
<style lang="scss" scoped>
.SetIndex {
  display: flex;
  .left {
    flex: 1;
    height: 400px;
    margin: 10px;
    padding: 10px;
    box-shadow: 1px 1px 2px 2px #f3f2f2;
    border-radius: 2px;
    .custom-tree-node {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: hidden;
      .bz {
        padding-left: 20px;
      }
    }
  }
  .right {
    width: 500px;
    height: 400px;
    margin: 10px;
    padding: 10px;
    box-shadow: 1px 1px 2px 2px #f3f2f2;
    border-radius: 2px;
    .MKMC,
    .ZSFS,
    .BFB,
    .XH {
      display: flex;
      align-items: center;
      margin: 15px 0;
      padding: 10px;
      box-shadow: 1px 1px 2px 2px #f3f2f2;
      .label {
        width: 70px;
        margin-right: 15px;
      }
      .el-input,
      .el-select,
      .el-input-number {
        width: 200px;
        ::v-deep .el-input-number__decrease {
          display: none;
        }
        ::v-deep .el-input-number__increase {
          display: none;
        }
      }
    }
  }
}
</style>
