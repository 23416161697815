var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"answer-table",attrs:{"title":"答案模板","visible":_vm.visible,"width":"50%","before-close":_vm.close},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.addAnswer(0)}}},[_vm._v("新增")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"prop":"MBMC","label":"模板名称"}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.addAnswer(1, row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.del(row)}}},[_vm._v("删除")]),_c('el-button',{attrs:{"type":row.SYZT === 1 ? 'success' : 'danger',"size":"mini"},on:{"click":function($event){return _vm.enabled(row)}}},[_vm._v(_vm._s(row.SYZT === 1 ? "启用" : "停用"))])]}}])})],1),_c('Pager',{attrs:{"pagerInfo":_vm.pager},on:{"changeIndex":_vm.changeIndex}}),_c('el-dialog',{staticClass:"answer-form-dialog",attrs:{"title":_vm.formDialog.title,"visible":_vm.formDialog.visible,"width":"60%","before-close":_vm.submit,"append-to-body":""},on:{"update:visible":function($event){return _vm.$set(_vm.formDialog, "visible", $event)}}},[_c('el-form',{ref:"form",staticClass:"answer-form",attrs:{"model":_vm.formDialog.form,"label-width":"80px"}},[_c('el-form-item',{staticStyle:{"display":"inline-block","width":"70%"},attrs:{"label":"模板名称","prop":"MBMC","rules":[
          {
            required: true,
            message: '模板名称不能为空',
            trigger: 'blur',
          } ]}},[_c('el-input',{model:{value:(_vm.formDialog.form.MBMC),callback:function ($$v) {_vm.$set(_vm.formDialog.form, "MBMC", $$v)},expression:"formDialog.form.MBMC"}})],1),_c('el-form-item',{staticStyle:{"display":"inline-block"},attrs:{"label":"启用","prop":"state"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949"},model:{value:(_vm.formDialog.form.state),callback:function ($$v) {_vm.$set(_vm.formDialog.form, "state", $$v)},expression:"formDialog.form.state"}})],1),_c('el-form-item',{staticClass:"jf_wj_dx",attrs:{"prop":"WJDAList","rules":[
          {
            validator: _vm.checkAnswerLength,
            message: '缺少答案',
            trigger: 'blur',
          } ]}}),_vm._l((_vm.formDialog.form.WJDAList),function(item,idx){return _c('div',{key:idx,staticClass:"answer-item"},[_c('el-form-item',{attrs:{"prop":("WJDAList[" + idx + "].TMQZ"),"rules":[
            {
              required: true,
              message: '不能为空',
              trigger: 'blur',
            } ]}},[_c('el-select',{staticStyle:{"width":"65px"},attrs:{"size":"small","placeholder":"请选择"},model:{value:(item.TMQZ),callback:function ($$v) {_vm.$set(item, "TMQZ", $$v)},expression:"item.TMQZ"}},_vm._l((_vm.ZM),function(e){return _c('el-option',{key:e.value,attrs:{"label":e.label,"value":e.value,"disabled":_vm.handleZMabled(_vm.formDialog.form.WJDAList, e.value)}})}),1)],1),_c('el-form-item',{staticStyle:{"flex":"1"},attrs:{"label-width":"10px","prop":("WJDAList[" + idx + "].DAMC"),"rules":[
            {
              required: true,
              message: '答案名称不能为空',
              trigger: 'blur',
            } ]}},[_c('el-input',{attrs:{"size":"small","readonly":item.FXLX === 2},model:{value:(item.DAMC),callback:function ($$v) {_vm.$set(item, "DAMC", $$v)},expression:"item.DAMC"}})],1),_c('el-form-item',{staticStyle:{"width":"140px"},attrs:{"label-width":"65px","label":"分数：","prop":("WJDAList[" + idx + "].DF"),"rules":[
            {
              required: true,
              message: '不能为空',
              trigger: 'blur',
            } ]}},[_c('el-input-number',{attrs:{"size":"small","precision":2,"min":0,"max":999},model:{value:(item.DF),callback:function ($$v) {_vm.$set(item, "DF", $$v)},expression:"item.DF"}})],1),_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.delAnswer(idx)}}},[_vm._v("删除")])],1)}),_c('div',{staticClass:"push-answer"},[_c('el-button',{staticClass:"normal-answer",attrs:{"size":"mini","plain":"","disabled":_vm.formDialog.form.WJDAList.length === 26,"icon":"el-icon-plus"},on:{"click":function($event){return _vm.pushAnswer(1)}}},[_vm._v("添加普通选项")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.formDialog.form.WJDAList.some(function (item) { return item.FXLX === 2; })),expression:"!formDialog.form.WJDAList.some((item) => item.FXLX === 2)"}],staticClass:"else-answer",attrs:{"size":"mini","plain":"","disabled":_vm.formDialog.form.WJDAList.length === 26,"icon":"el-icon-plus"},on:{"click":function($event){return _vm.pushAnswer(2)}}},[_vm._v("添加'其他'选项")])],1)],2),_c('div',{staticClass:"footer"},[_c('el-button',{attrs:{"type":"info","size":"mini"},on:{"click":_vm.submit}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.submit(1)}}},[_vm._v("确定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }