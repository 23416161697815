<template>
	<div class="bkimg" :style="{ backgroundImage: 'url(' + info.TPLJ + ') ' }">
		<div :class="'template-wrap-' + exportType">
			<div :class="'basetitle' + exportType">
				{{ info.BT }}
			</div>
			<div :class="'totalback' + exportType">
				<div :class="'detailinfo' + exportType">
					<div :class="'xsdiv' + exportType">
						<img :src="xsinfo.ZP" :class="'studenticon' + exportType" />
						<div :class="'xsinfodiv' + exportType">
							<div :class="'xsinfotitle' + exportType">
								{{ xsinfo.XM }}
							</div>
							<br />
							<div :class="'xsinfoclass' + exportType">
								{{ xsinfo.BJMC }}
							</div>
						</div>
					</div>
					<div :class="'totalscore' + exportType">
						<div :class="'scoredesc' + exportType">
							得星数
						</div>
						<br />
						<div :class="'scoretext' + exportType">
							{{ xsinfo.ZFS }}
						</div>
					</div>
					<div :class="'performancedetaildiv' + exportType" v-for="(item,index) in activitylist" :key="index">
						<div style="background-color: #ffffff;" :class="'perdiv' + exportType">
							<el-row>
								<el-col>
									<div :class="'activity' + exportType">
										<div v-if="item.img">
											<img :class="'hdimg'+exportType" :src="item.img" />
										</div>
										<span style="padding-left: 10px;">{{ item.HDMC}}</span>
										<span
											style="color: #ff9600;font-size: 22px;">（{{Math.round(item.DXS) }}星）</span>
									</div>
								</el-col>
							</el-row>
							<div :class="'index-container'+exportType">
								<div class="index main-index" v-if="item.IndexList.filter(o=>o.pjxs==4).length>0">
									<div class="index-item" v-for="(mode,index) in item.IndexList.filter(o=>o.pjxs==4)"
										:key="index">
										<div class="name">{{mode.ZBMC}} ：</div>
										<div class="star" v-if="mode.DXS<=5">
											<img :class="'indexscore' + exportType" v-for="i in mode.DXS" :key="i"
												src="../../../../style/growth/staricon.png" />
										</div>
										<div class="star" v-else>
											{{mode.DXS}}颗<img :class="'indexscore' + exportType"
												src="../../../../style/growth/staricon.png" />
										</div>
									</div>
									<div class="img">主</div>
								</div>
								<div class="index oneself-index" v-if="item.IndexList.filter(o=>o.pjxs==1).length>0">
									<div class="index-item" v-for="(mode,index) in item.IndexList.filter(o=>o.pjxs==1)"
										:key="index">
										<div class="name">{{mode.ZBMC}} ：</div>
										<div class="star" v-if="mode.DXS<=5">
											<img :class="'indexscore' + exportType" v-for="i in mode.DXS" :key="i"
												src="../../../../style/growth/staricon.png" />
										</div>
										<div class="star" v-else>
											{{mode.DXS}}颗<img :class="'indexscore' + exportType"
												src="../../../../style/growth/staricon.png" />
										</div>
									</div>
									<div class="img">自</div>
								</div>
							</div>
							<div :class="'activitydesc' + exportType" v-if="item.PJNR">
								{{ item.PJNR }}
							</div>
							<div>
								<img :class="'attachimage' + exportType" :src="mode" v-for="(mode,index) in item.FjList"
									:key="index" />
								<!-- <img :class="'attachimage' + exportType" :src="mode.FJLJ" v-for="(mode,index) in item.FjList" :key="index" />
								<img :class="'attachimage' + exportType" :src="mode.FJLJ" v-for="(mode,index) in item.FjList" :key="index" /> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "./template.css";
	export default {
		data() {
			return {
				ssxx: "",
				xqid: "",
				token: "",
				backgroudimage: "",
				exportType: 1,
				schoolinfo: {
					bookname: "学生成长评价手册",
					schoolname: "重庆市巴南区世贸小学",
					pagename: "星星主子活动"
				},
				xsinfo: {
					SSNJ: "",
					NJ: "",
					Id: "",
					XM: "",
					XBM: 1,
					JG: "",
					XZZ: "",
					ZP: "",
					MZ: "",
					XH: "",
					XQAH: "",
					BZRMC: "",
					XQMC: "",
					SSBJ: "",
					BJMC: "",
					SSXX: "",
					XXMC: "",
					XXBM: "",
					XXTB: "",
					XZ: "",
					NL: "",
					SSXQ: "",
					SSXN: "",
					XQLX: 1,
					ZFS: "0"
				},
				activitylist: []
			};
		},
		created() {
			this.xqid = localStorage.getItem("templateTerm");
			var tempuser = localStorage.getItem("userinfo");
			if (tempuser) {
				var user = JSON.parse(tempuser);
				this.ssxx = user.SSZZId;
				this.token = user.Token;
			}
			this.exportType = this.$route.query.exportType ?
				this.$route.query.exportType * 1 :
				1;
			this.backgroudimage = this.$route.query.tydt;
			if (this.info.TPLJ == "" && this.backgroudimage != "") {
				this.info.TPLJ = this.backgroudimage;
			}
		},
		props: {
			info: {
				type: Object,
				default: () => {
					return {
						SSXX: "",
						YM: 0,
						GLMB: "",
						MBMC: "",
						BT: "",
						TPLJ: "",
						GLId: "",
						GLLX: 0,
						FJSM: ""
					};
				}
			}
		},
		methods: {
			changexsdata(xsdata) {
				this.xsinfo = {
					...this.xsinfo,
					...xsdata.xsxx
				};
				// this.xsinfo.Id = xsdata.Id;
				// this.xsinfo.XM = xsdata.XM;
				// this.xsinfo.XBM = xsdata.XBM;
				// this.xsinfo.ZP = xsdata.ZP;
				// this.xsinfo.XQAH = xsdata.XQAH;
				// this.xsinfo.SSXX = xsdata.SSXX;
				// this.xsinfo.SSXQ = xsdata.SSXQ;
				// this.xsinfo.SSXN = xsdata.SSXN;
				// this.xsinfo.XQLX = xsdata.XQLX;
				// this.xsinfo.BZRMC = xsdata.BZRMC;
				// this.xsinfo.NL = xsdata.NL;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXTB = xsdata.XXTB;
				// this.xsinfo.BJMC = xsdata.BJMC;
				// this.xsinfo.XXMC = xsdata.XXMC;
				// this.xsinfo.XXBM = xsdata.XXBM;
				// this.xsinfo.XZ = xsdata.XZ;
				// this.xsinfo.SSBJ = xsdata.SSBJ;
				// this.xsinfo.SSNJ = xsdata.SSNJ;
				// this.xsinfo.NJ = xsdata.NJ;
				if (this.xsinfo.ZP == null || this.xsinfo.ZP == undefined || this.xsinfo.ZP == "") {
					this.xsinfo.ZP =
						this.xsinfo.XBM == 1 ?
						require("../../../../style/images/defaultStudentBoy.png") :
						require("../../../../style/images/defaultStudentGirl.png");
				}
				var template = JSON.parse(localStorage.getItem("schooltemplate_" + this.xqid)).ZHSY;
				// console.log(template)
				var templatedata = JSON.parse(xsdata.ZHSY);
				// console.log(templatedata)
				if (templatedata) {
					let template_temp = template.WDBG.find(o => o.Id == 'W' + this.info.GLId[0]);
					let subdata_temp = templatedata.find(o => o.Id == 'W' + this.info.GLId[0]);
					let hdid = 'E' + this.info.GLId[1];
					if (template_temp && subdata_temp) {
						// console.log(template_temp, "template_temp");
						// console.log(subdata_temp, "subdata_temp");
						let hd_template_temp = template_temp.Hd.find(o => o.Id == hdid);
						let main_hd = subdata_temp.WDBG.find(o => o.Id == hdid);
						let son_hd = subdata_temp.WDBG.filter(o => o.ZHDId == hdid);
						// console.log(hd_template_temp, 'hd_template_temp')
						// console.log(main_hd, "main_hd");
						// console.log(son_hd, "son_hd");
						this.xsinfo.ZFS = main_hd ? parseInt(main_hd.Score) : 0;
						this.activitylist = [];
						if (hd_template_temp.ZHD && hd_template_temp.ZHD.length > 0 && son_hd.length > 0) {
							for (let i = 0; i < son_hd.length; i++) {
								if (this.activitylist.length < 2) {
									let obj = new Object();
									obj.FjList = [];
									if (son_hd[i].Images) {
										let imgs = son_hd[i].Images.split(',');
										for (let y = 0; y < imgs.length; y++) {
											if (y < 3) {
												obj.FjList.push(imgs[y])
											}
										}
									}
									// obj.FjList.push(
									// 	'http://47.115.151.188:5001/FilePath//UploadFile//zj/slt/2017%E7%BA%A71%E7%8F%AD/%E7%9B%9B%E4%BC%98.jpg'
									// );
									// obj.FjList.push(
									// 	'http://47.115.151.188:5001/FilePath//UploadFile//zj/slt/2017%E7%BA%A71%E7%8F%AD/%E7%9B%9B%E4%BC%98.jpg'
									// );
									// obj.FjList.push(
									// 	'http://47.115.151.188:5001/FilePath//UploadFile//zj/slt/2017%E7%BA%A71%E7%8F%AD/%E7%9B%9B%E4%BC%98.jpg'
									// );
									let zhd_temp = hd_template_temp.ZHD.find(o => o.Id == son_hd[i].Id);
									obj.PJNR = son_hd[i].MS;
									// obj.PJNR = '数据库拉会计师的徕卡四季豆已请问您拉会计师打瞌睡分担好打多少';
									obj.HDMC = zhd_temp ? zhd_temp.Name : hd_template_temp.Name;
									obj.img = zhd_temp ? zhd_temp.Image : '';
									obj.DXS = son_hd[i].Score;
									obj.IndexList = [];

									for (let t = 0; t < son_hd[i].TYOrXXPJ.length; t++) {
										if (obj.IndexList.filter(o => o.pjxs == son_hd[i].TYOrXXPJ[t].PJXS).length < 3) {
											// let wd_template_temp = template_temp.Hd.find(o => o.Type == 1);
											let zb_temp = hd_template_temp.TYOrXXPJ.find(o => o.Id == son_hd[i].TYOrXXPJ[t]
												.Id);

											let children_obj = new Object();
											children_obj.ZBMC = zb_temp.Name;
											children_obj.DXS = parseInt(son_hd[i].TYOrXXPJ[t].Value);
											children_obj.pjxs = son_hd[i].TYOrXXPJ[t].PJXS;
											obj.IndexList.push(children_obj);
										}
									}
									this.activitylist.push(obj);
								}
							}
						} else {
							if (main_hd) {
								if (this.activitylist.length < 2) {
									let obj = new Object();
									obj.FjList = [];
									if (main_hd.Images) {
										for (let i = 0; i < main_hd.Images.length; i++) {
											if (i < 3) {
												obj.FjList.push(main_hd.Images[i])
											}
										}
									}
									obj.PJNR = main_hd.MS;
									obj.HDMC = hd_template_temp.Name;
									obj.DXS = main_hd.Value;
									obj.IndexList = [];
									for (let i = 0; i < main_hd.TYOrXXPJ.length; i++) {
										if (obj.IndexList.filter(o => o.pjxs == main_hd.TYOrXXPJ[i].PJXS).length < 2) {
											let zb_temp = hd_template_temp.TYOrXXPJ.find(o => o.Id == main_hd.TYOrXXPJ[i]
												.Id);
											// console.log(hd_template_temp.TYOrXXPJ)
											// console.log(main_hd.TYOrXXPJ[i])
											let children_obj = new Object();
											children_obj.ZBMC = zb_temp.Name;
											children_obj.DXS = main_hd.TYOrXXPJ[i].Value;
											children_obj.pjxs = main_hd.TYOrXXPJ[i].PJXS;
											obj.IndexList.push(children_obj);
										}
									}
									this.activitylist.push(obj);
								}
							}
						}
					}
				}

				// this.getxsreportdata();
				// this.gettotalscore();
			},
			getxsreportdata: function() {
				let pageinfo = {
					PageIndex: 1,
					PageSize: 2
				};
				this.$api.post(
					"v1/evaluationreport/GetStudentStarRecent", {
						HDId: this.info.GLId,
						SSXX: this.xsinfo.SSXX,
						SSXQ: this.xsinfo.SSXQ,
						XSId: this.xsinfo.Id,
						token: this.token,
						PagerInfo: pageinfo
					},
					r => {
						if (r.Code == 0) {
							this.activitylist = [];
							this.activitylist = r.Target;
						}
					}
				);
			},
			gettotalscore: function() {
				this.$api.post(
					"v1/evaluationreport/GetStudentTotalStar", {
						HDId: this.info.GLId,
						SSXX: this.xsinfo.SSXX,
						SSXQ: this.xsinfo.SSXQ,
						XSId: this.xsinfo.Id,
						token: this.token
					},
					r => {
						if (r.Code == 0) {
							this.xsinfo.ZFS = r.Target.DXS;
						}
					}
				);
			}
		},
		mounted: function() {},
		computed: {}
	};
</script>

<style lang="scss" scoped>
	.basetitle1 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 140px;
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/*#3ee5a5*/
		-webkit-text-stroke: 2px #ffffff;
		text-stroke: 2px #ffffff;
	}

	.basetitle2 {
		text-align: center;
		vertical-align: middle;
		width: 100%;
		line-height: 90px;
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bolder;
		color: green;
		/* #00a1e9 #3ee5a5*/
		-webkit-text-stroke: 1px #ffffff;
		text-stroke: 1px #ffffff;
	}

	.studenticon1 {
		width: 145px;
		height: 145px;
		text-align: center;
		float: left;
		margin-top: 20px;
		margin-left: 20px;
	}

	.studenticon2 {
		width: 80px;
		height: 80px;
		text-align: center;
		float: left;
		margin-top: 20px;
		margin-left: 20px;
	}

	.clboth {
		clear: both;
	}

	.totalback1 {
		// background-image: url("../../../../style/growth/rectangle_back.png");
		background-image: url("../../../../style/growth/medicalreport_detail_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 20px;
		height: 1000px;
		width: 100%;
	}

	.totalback2 {
		// background-image: url("../../../../style/growth/rectangle_back.png");
		background-image: url("../../../../style/growth/medicalreport_detail_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding-top: 10px;
		height: 720px;
		width: 100%;
	}

	.xsdiv1 {
		height: 170px;
		width: 556px;
	}

	.xsdiv2 {
		height: 120px;
		width: 430px;
	}

	.detailinfo1 {
		position: relative;
		// background: #d08efc;
		// background-image: url("../../../../style/growth/medicalreport_detail_back.png");
		border: 0px solid #cbe0fd;
		border-radius: 25px;
		width: 800px;
		height: 980px;
		margin-left: 20px;
	}

	.detailinfo2 {
		position: relative;
		// background: #d08efc;
		// background-image: url("../../../../style/growth/medicalreport_detail_back.png");
		border: 0px solid #cbe0fd;
		border-radius: 15px;
		width: 570px;
		height: 700px;
		margin-left: 10px;
	}

	.xsinfodiv1 {
		margin-top: 55px;
		margin-left: 20px;
		float: left;
	}

	.xsinfodiv2 {
		margin-top: 30px;
		margin-left: 10px;
		float: left;
	}

	.xsinfotitle1 {
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		line-height: 45px;
	}

	.xsinfotitle2 {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
	}

	.xsinfoclass1 {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #000000;
		line-height: 30px;
		opacity: 0.8;
	}

	.xsinfoclass2 {
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #000000;
		line-height: 20px;
		opacity: 0.8;
		margin-top: -10px;
	}

	.indexscore1 {
		width: 18px;
		height: 18px;
		line-height: 35px;
		margin: 0 0 0 5px;
	}

	.indexscore2 {
		width: 14px;
		height: 14px;
		line-height: 14px;
		margin: 3px 0 0 3px;
	}

	.indexname1 {
		width: 120px;
		height: 40px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #080808;
		line-height: 40px;
	}

	.indexname2 {
		width: 100px;
		height: 30px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #080808;
		line-height: 30px;
	}

	.performancedetaildiv1 {
		width: 760px;
		margin: 10px;
		max-height: 310px;
		// background: #ffffff;
		border-radius: 20px;
		margin-top: 125px;
	}

	.performancedetaildiv2 {
		width: 530px;
		margin: 0 10px 10px 10px;
		max-height: 230px;
		background: #ffffff;
		border-radius: 15px;
		margin-top: 78px;
	}

	.perdiv1 {
		width: 760px;
		max-height: 310px;
		background: #ffffff;
		border-radius: 10px;
		padding: 10px 10px 5px 10px;
	}

	.perdiv2 {
		width: 530px;
		max-height: 230px;
		background: #ffffff;
		border-radius: 15px;
		padding: 10px 10px 5px 10px;
	}

	.totalscore1 {
		position: absolute;
		left: 50%;
		top: 120px;
		transform: translateX(-50%);
		width: 360px;
		text-align: center;
		height: 177px;
		padding-top: 5px;
		background-image: url("../../../../style/growth/score_back1.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;

	}

	.totalscore2 {
		position: absolute;
		left: 50%;
		top: 65px;
		transform: translateX(-50%);
		width: 270px;
		text-align: center;
		height: 133px;
		padding-top: 5px;
		background-image: url("../../../../style/growth/score_back1.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.scoretext1 {
		font-size: 50px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #22a049;
		margin-top: -20px;
		line-height: normal;
	}

	.scoretext2 {
		font-size: 40px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #22a049;
		margin-top: -20px;
		line-height: normal;
	}

	.scoredesc1 {
		width: 100%;
		margin-top: 25px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #666666;
	}

	.scoredesc2 {
		width: 100%;
		margin-top: 15px;
		font-size: 10px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #666666;
	}

	.activitydesc1 {
		width: 770px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		padding: 0px 10px;
		color: #080808;
		line-height: 30px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.activitydesc2 {
		width: 540px;
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		padding: 0px 5px;
		color: #080808;
		line-height: 20px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.attachimage1 {
		width: 180px;
		height: 160px;
		margin: 5px 20px 0 20px;
		padding-left: 25px;
	}

	.attachimage2 {
		width: 125px;
		height: 105px;
		margin: 5px 10px 0 10px;
		padding-left: 20px;
	}

	.activity1 {
		height: 40px;
		font-size: 25px;
		color: #080808;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 40px;
		// margin-top: -15px;
		display: flex;
	}

	.activity2 {
		height: 30px;
		font-size: 18px;
		color: #080808;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 40px;
		display: flex;
	}

	.index-container1 {
		.index {
			position: relative;

			.index-item {
				// margin-top: -5px;
				display: flex;
				height: 20px;
				min-width: 200px;
				padding: 5px 0 0 60px;

				.name {
					font-size: 14px;
					color: #000000;
					font-family: Microsoft YaHei;
					min-width: 100px;
					max-width: 350px;
					text-align: right;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					line-height: 20px;
				}

				.star {
					padding-left: 10px;
					min-width: 60px;
					display: flex;
					font-size: 14px;
					color: #000000;
				}
			}

			.img {
				position: absolute;
				right: 30%;
				top: 50%;
				height: 25px;
				width: 25px;
				background-image: url('../../../../style/growth/message.png');
				background-repeat: no-repeat;
				background-size: 100%;
				line-height: 25px;
				text-align: center;
				color: #ffffff;
				transform: translateY(-50%);
				padding-top: 5px;
			}
		}

		.oneself-index {
			margin-top: 5px;
		}
	}

	.index-container2 {
		.index {
			position: relative;
			padding-top: 5px;

			.index-item {
				// margin-top: -5px;
				display: flex;
				height: 20px;
				min-width: 200px;
				padding: 5px 0 5px 60px;

				.name {
					font-size: 14px;
					color: #000000;
					font-family: Microsoft YaHei;
					min-width: 100px;
					max-width: 350px;
					text-align: right;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					line-height: 20px;
				}

				.star {
					padding-left: 10px;
					min-width: 60px;
					display: flex;
					font-size: 14px;
					color: #000000;
				}
			}

			.img {
				position: absolute;
				right: 30%;
				top: 50%;
				height: 25px;
				width: 25px;
				background-image: url('../../../../style/growth/message.png');
				background-repeat: no-repeat;
				background-size: 100%;
				line-height: 25px;
				text-align: center;
				color: #ffffff;
				transform: translateY(-50%);
				padding-top: 5px;
			}
		}

		.oneself-index {
			margin-top: 5px;
		}
	}

	.hdimg1 {
		width: 50px;
		height: 50px;
		border-radius: 50px;
	}

	.hdimg2 {
		width: 50px;
		height: 50px;
		border-radius: 50px;
	}
</style>