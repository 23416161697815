var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cont-whole-box"},[_c('div',{staticStyle:{"padding":"30px"}},[_c('div',{staticClass:"tab-shu-box margno-t"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.data}},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("考试名称")]),_c('td',[_c('el-form-item',{staticStyle:{"margin-bottom":"unset"},attrs:{"prop":"KSMC","rules":[
                    {
                      required: true,
                      message: '请输入考试名称',
                      trigger: 'change'
                    },
                    {
                      min: 1,
                      max: 50,
                      message: '不能超过50 个字符',
                      trigger: 'change'
                    }
                  ],"inline-message":true}},[_c('el-input',{staticClass:"jf-inpt",staticStyle:{"width":"400px"},attrs:{"size":"medium","placeholder":"请输入"},model:{value:(_vm.data.KSMC),callback:function ($$v) {_vm.$set(_vm.data, "KSMC", $$v)},expression:"data.KSMC"}})],1)],1)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("考试类型")]),_c('td',[_c('div',{staticStyle:{"margin-left":"8px","display":"inline-block"}},[_c('el-form-item',{staticStyle:{"margin-bottom":"unset"},attrs:{"prop":"KSXZM","rules":[
                      {
                        required: true,
                        message: '请选择考试类型',
                        trigger: 'change'
                      }
                    ],"inline-message":true}},[_c('el-radio-group',{model:{value:(_vm.data.KSXZM),callback:function ($$v) {_vm.$set(_vm.data, "KSXZM", $$v)},expression:"data.KSXZM"}},_vm._l((_vm.kslx),function(item,index){return _c('el-radio',{key:index,attrs:{"label":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1)],1)])]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("展示范围")]),_c('td',[_c('div',{staticStyle:{"margin-left":"8px","display":"inline-block"}},[_c('el-form-item',{attrs:{"prop":"ZSFW","inline-message":true}},[_c('el-radio-group',{model:{value:(_vm.data.ZSFW),callback:function ($$v) {_vm.$set(_vm.data, "ZSFW", $$v)},expression:"data.ZSFW"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("不展示")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("班级平均")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("年级平均")]),_c('el-radio',{attrs:{"label":3}},[_vm._v("班级和年级平均")])],1)],1)],1)])]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("课程")]),_c('td',_vm._l((_vm.basicInfo.GradeList),function(item,index){return _c('div',{key:index,staticClass:"tab-td-box",staticStyle:{"height":"auto"}},[_c('div',{staticClass:"disilb-box-l",staticStyle:{"width":"80px","vertical-align":"top"}},[_c('div',{staticClass:"tablil-xz-name c-g-9"},[_vm._v(_vm._s(item.Name)+":")])]),_c('div',{staticStyle:{"display":"inline-block","width":"89%"}},[_c('el-checkbox',{staticStyle:{"margin-right":"30px","margin-bottom":"6px"},attrs:{"disabled":_vm.basicInfo.CourseList.filter(
                          function (c) { return c.Grade == item.Number; }
                        ).length === 0,"indeterminate":_vm.courseCheck[index].isIndeterminate},on:{"change":function($event){return _vm.handleCheckAll(
                          _vm.courseCheck[index].checkAll,
                          item.Number,
                          item.MultiSchool,
                          index
                        )}},model:{value:(_vm.courseCheck[index].checkAll),callback:function ($$v) {_vm.$set(_vm.courseCheck[index], "checkAll", $$v)},expression:"courseCheck[index].checkAll"}},[_vm._v("全选")]),_c('el-checkbox-group',{staticStyle:{"display":"inline-block"},on:{"change":function($event){return _vm.handleChecked(_vm.courseCheck[index].KSNJXKList, index)}},model:{value:(_vm.courseCheck[index].KSNJXKList),callback:function ($$v) {_vm.$set(_vm.courseCheck[index], "KSNJXKList", $$v)},expression:"courseCheck[index].KSNJXKList"}},_vm._l((_vm.basicInfo.CourseList.find(
                          function (c) { return c.Grade === item.Number; }
                        )
                          ? _vm.basicInfo.CourseList.find(
                              function (c) { return c.Grade === item.Number; }
                            ).GradeCourseList
                          : []),function(course,i){return _c('el-checkbox',{key:i,attrs:{"label":_vm.courseCheckData[index][i]}},[_vm._v(_vm._s(course.Name))])}),1)],1)])}),0)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("录入时间段")]),_c('td',[_c('el-form-item',{staticStyle:{"margin-bottom":"unset"},attrs:{"prop":'dateRange',"rules":[
                    {
                      validator: function (rule, value, callback) {
                        if (value.length < 2 || !value[0] || !value[1]) {
                          return callback(new _vm.$window.Error(rule.message));
                        } else { return callback(); }
                      },
                      message: '请选择录入时间',
                      trigger: 'change'
                    }
                  ],"inline-message":true}},[_c('el-date-picker',{staticClass:"jf-inpt",staticStyle:{"background-color":"#fbfbfb"},attrs:{"size":"medium","type":"daterange","range-separator":"~","start-placeholder":"开始日期","end-placeholder":"结束日期","value-format":"yyyy-MM-dd"},model:{value:(_vm.data.dateRange),callback:function ($$v) {_vm.$set(_vm.data, "dateRange", $$v)},expression:"data.dateRange"}})],1)],1)]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("录入人")]),_c('td',[_c('div',{staticStyle:{"margin-left":"8px"}},[_c('el-form-item',{staticStyle:{"margin-bottom":"unset"},attrs:{"prop":"LRRLX","rules":[
                      {
                        required: true,
                        message: '请选择录入人',
                        trigger: 'change'
                      },
                      {
                        validator: function (rule, value, callback) {
                          if (
                            value == 3 &&
                            (!rule.zdlrr || rule.zdlrr.length === 0)
                          ) {
                            return callback(new _vm.$window.Error(rule.message));
                          } else { return callback(); }
                        },
                        message: '请选择指定录入人',
                        trigger: 'change',
                        zdlrr: _vm.data.KSLRRYBList
                      }
                    ],"inline-message":true}},[_c('el-radio-group',{model:{value:(_vm.data.LRRLX),callback:function ($$v) {_vm.$set(_vm.data, "LRRLX", $$v)},expression:"data.LRRLX"}},_vm._l((_vm.lrrlx),function(item,index){return _c('el-radio',{key:index,attrs:{"label":item.value}},[_vm._v(_vm._s(item.label))])}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.LRRLX === 3),expression:"data.LRRLX === 3"}],staticClass:"tab-td-ovebox"},_vm._l((_vm.data.KSLRRYBList
                          ? _vm.data.KSLRRYBList.filter(function (c) { return c.BSLX === 1; })
                          : []),function(item,index){return _c('div',{key:index,staticClass:"name-del-box"},[_vm._v(" "+_vm._s(_vm.basicInfo.TeacherList.find(function (c) { return c.Id === item.CZRId; }) ? _vm.basicInfo.TeacherList.find( function (c) { return c.Id === item.CZRId; } ).Name : "")+" "),_c('div',{staticClass:"name-del-zz"},[_c('a',{staticClass:"name-del-zza",on:{"click":function($event){return _vm.removeTeacher(item.CZRId, 1)}}})])])}),0),(_vm.data.LRRLX === 3)?_c('div',{staticClass:"tab-wz-buttl",staticStyle:{"margin-left":"15px"}},[_c('a',{staticClass:"tab-wz-butta c-guree",on:{"click":function($event){return _vm.openTeacherDialog(1)}}},[_vm._v("去指定录入人")])]):_vm._e()],1)],1)])]),_c('tr',[_c('td',{staticClass:"tab-shu-titl"},[_vm._v("修正人")]),_c('td',[_vm._l((_vm.data.KSLRRYBList
                    ? _vm.data.KSLRRYBList.filter(function (c) { return c.BSLX === 2; })
                    : []),function(item,index){return _c('div',{key:index,staticClass:"name-del-box"},[_vm._v(" "+_vm._s(_vm.basicInfo.TeacherList.find(function (c) { return c.Id === item.CZRId; }) ? _vm.basicInfo.TeacherList.find(function (c) { return c.Id === item.CZRId; }) .Name : "")+" "),_c('div',{staticClass:"name-del-zz"},[_c('a',{staticClass:"name-del-zza",on:{"click":function($event){return _vm.removeTeacher(item.CZRId, 2)}}})])])}),_c('div',{staticClass:"tab-wz-buttl"},[_c('a',{staticClass:"tab-wz-butta c-guree",on:{"click":function($event){return _vm.openTeacherDialog(2)}}},[_vm._v("添加")])])],2)])])])])],1),_c('div',{staticClass:"cent-bott-butt"},[_c('a',{staticClass:"cent-bott-butta",on:{"click":_vm.submit}},[_vm._v("确 认")]),_c('a',{staticClass:"cent-bott-butta",on:{"click":_vm.cancel}},[_vm._v("取 消")])])]),_c('el-dialog',{staticClass:"jf-form",attrs:{"top":"15%","width":"30%","title":"选择老师","visible":_vm.teacherListDialogVisible,"close-on-click-modal":false,"append-to-body":"","before-close":_vm.closeDialogTeacherListDialog},on:{"update:visible":function($event){_vm.teacherListDialogVisible=$event}}},[_c('div',{staticClass:"stud-box"},[_c('div',{staticStyle:{"font-size":"14px","margin-bottom":"10px"}},[_c('el-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入关键字"},model:{value:(_vm.searchTeacherName),callback:function ($$v) {_vm.searchTeacherName=$$v},expression:"searchTeacherName"}})],1),_c('ul',{staticClass:"stud-box-ul",staticStyle:{"height":"250px"}},_vm._l((_vm.basicInfo.TeacherList.filter(
            function (c) { return c.Name.indexOf(_vm.searchTeacherName) != -1; }
          )),function(item){return _c('li',{key:item.Id,class:[
            'stud-box-ulli',
            { 'stud-ul-sty': _vm.tmpManagerIds.indexOf(item.Id) != -1 }
          ],on:{"click":function($event){return _vm.selectedTeacher(item.Id)}}},[_c('img',{staticClass:"stud-box-ulli-img",attrs:{"src":item.Photo
                ? item.Photo
                : item.Gender === 2
                ? _vm.$store.state.defaultTeacherFemale
                : _vm.$store.state.defaultTeacherMale}}),_c('div',{staticClass:"stud-name"},[_vm._v(_vm._s(item.Name))])])}),0)]),_c('div',{staticClass:"cent-bott-butt"},[_c('el-button',{on:{"click":_vm.confirmSelectTeacher}},[_vm._v("确定")]),_c('el-button',{on:{"click":_vm.closeDialogTeacherListDialog}},[_vm._v("取消")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }